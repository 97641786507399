import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GuiService } from 'src/app/shared/gui.service';
import { SignInService } from './sign-in.service';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/shared/storage.service';
import { LoaderService } from 'src/app/shared/loader.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  requestMade = false;

  email = null;
  password = null;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private guiService: GuiService,
    private signInService: SignInService,
    private storageService: StorageService,
    private loader: LoaderService
  ) { }

  ngOnInit(): void {
    if (this.storageService.loggedInUser) {
      this.router.navigate(['dashboard']);
    } else {
      this.guiService.showNavbar = false;
      this.guiService.showToggleMenu = false;
      this.guiService.showProfileMenu = false;
    }
  }

  signIn() {
    if (!this.email) {
      this.toastr.error('Please enter email.')
      return;
    }
    if (!this.password) {
      this.toastr.error('Please enter password.')
      return;
    }
    var formData: FormData = new FormData();
    formData.append('email', this.email);
    formData.append('password', this.password);
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.signInService.signIn(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.guiService.showNavbar = true;
            this.guiService.showToggleMenu = true;
            this.guiService.showProfileMenu = true;
            if (response.data) {
              this.storageService.loggedInUser = response.data[0];
            }
            this.router.navigate(['dashboard']);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          this.toastr.error(err);
        }
      );
    }
  }
}

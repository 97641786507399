import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { BookingsComponent } from './pages/bookings/bookings.component';
import { DefaultSessionsComponent } from './pages/sessions/default-sessions/default-sessions.component';
import { OverrideSessionsComponent } from './pages/sessions/override-sessions/override-sessions.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { ClientProfileComponent } from './pages/client-profile/client-profile.component';
import { ExercisesComponent } from './pages/exercises/exercises.component';
import { MeasurementsComponent } from './pages/measurements/measurements.component';
import { MembershipComponent } from './pages/membership/membership.component';
import { WorkoutComponent } from './pages/workout/workout.component';
import { ChallengeComponent } from './pages/challenge/challenge.component';
import { StickersComponent } from './pages/stickers/stickers.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { ErrorComponent } from './pages/error/error.component';
import { EquipmentsComponent } from './pages/equipments/equipments.component';

const routes: Routes = [
  { path: '', redirectTo: 'sign-in', pathMatch: 'full' },
  { path: 'sign-in', component: SignInComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'bookings', component: BookingsComponent },
  { path: 'sessions', component: DefaultSessionsComponent },
  { path: 'override-sessions', component: OverrideSessionsComponent },
  { path: 'clients', component: ClientsComponent },
  { path: 'client-profile', component: ClientProfileComponent },
  { path: 'exercises', component: ExercisesComponent },
  { path: 'measurements', component: MeasurementsComponent },
  { path: 'workout', component: WorkoutComponent },
  { path: 'membership', component: MembershipComponent },
  { path: 'challenge', component: ChallengeComponent },
  { path: 'stickers', component: StickersComponent },
  { path: 'notifications', component: NotificationsComponent },
  { path: 'report', component: ReportsComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'payment', component: PaymentComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'equipments', component: EquipmentsComponent },
  { path: '**', redirectTo: "sign-in" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

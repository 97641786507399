<div class="container-fluid main-content">
    <div class="row">
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
                <h4 class="my-2">Measurements</h4>
            </div>
        </div>
    </div>
    <div class="row pt-5">
        <div class="col-lg-2 d-none d-lg-block">
            <div class="d-flex align-items-center justify-content-between search-box">
                <input type="text" class="form-control" placeholder="Search..." autocomplete="off"
                    [(ngModel)]="searchText" (input)="getMeasurementList(1)">
                <div class="icon px-2"><i class="fa fa-search"></i></div>
            </div>
        </div>
        <div class="col-lg-10 text-right align-self-center">
            <ul class="table-feature">
                <li>
                    <div class="view-btn pointer">
                        <div [class.active]="viewActive" (click)="filterMeasurementList(true)">View Active</div>
                        <div>/</div>
                        <div [class.active]="!viewActive" (click)="filterMeasurementList(false)">View All</div>
                    </div>
                </li>
                <li>
                    <div class="pagination" *ngIf="pager.pages && pager.pages.length">
                        <ul class="page-number">
                            <li (click)="setPage(pager.currentPage - 1)" [class.disabled]="pager.currentPage === 1">
                                <i class="fa fa-angle-left"></i>
                            </li>
                            <li *ngFor="let page of pager.pages" (click)="setPage(page)"
                                [class.active]="pager.currentPage === page">
                                {{ page }}
                            </li>
                            <li (click)="setPage(pager.currentPage + 1)"
                                [class.disabled]="pager.currentPage === pager.totalPages">
                                <i class="fa fa-angle-right"></i>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <div class="sort-by">
                        <select [(ngModel)]="pageSize" (change)="getMeasurementList(1)">
                            <option [value]="50">50/Pages</option>
                            <option [value]="100">100/Pages</option>
                            <option [value]="150">150/Pages</option>
                        </select>
                    </div>
                </li>
                <li>
                    <div class="sort-by">
                        <select [(ngModel)]="orderBy" (change)="getMeasurementList(1)">
                            <option [value]="''">Sort by</option>
                            <option [value]="'asc'">A To Z</option>
                            <option [value]="'desc'">Z To A</option>
                        </select>
                    </div>
                </li>
                <li>
                    <div class="primary-btn pointer" (click)="openAddUpdateMeasurementModal(1,null)">
                        Add Measurement <img src="assets/images/add-white.png" alt="images">
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="table-responsive table-list">
                <table class="table">
                    <thead class="thead-light">
                        <tr>
                            <th>Measurement Title</th>
                            <th>Measurement Unit</th>
                            <th>Measurement Position</th>
                            <th class="text-center">Active</th>
                            <th class="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of filteredMeasurementList">
                            <td>{{item.title}}</td>
                            <td>{{item.unit}} </td>
                            <td>{{(item.position != 0)?item.position:''}}</td>
                            <td>
                                <div class="text-center">
                                    <img *ngIf="item.active" src="assets/images/check-square.png"
                                        class="image-btn pointer" alt="images" />
                                </div>
                            </td>
                            <td>
                                <div class="text-center">
                                    <img src="assets/images/edit.png" class="image-btn pointer" alt="images"
                                        (click)="openAddUpdateMeasurementModal(0,item)" />
                                    <img src="assets/images/delete.png" class="image-btn pointer pl-2" alt="images"
                                        (click)="openDeleteDialog(item)" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<div class="modal fade p-0" id="add-measurement" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered add-session-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header text-center">
                <div class="d-flex w-100">
                    <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
                    <div class="modal-title w-100">{{(isCreate)?'Add':'Update'}} Measurement</div>
                </div>
            </div>
            <div class="modal-body">
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Title:</div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control no-radius" placeholder="Enter title" [(ngModel)]="title">
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Unit:</div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control no-radius" placeholder="Enter unit" [(ngModel)]="unit">
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Allow Position:</div>
                    <div class="col-sm-8">
                        <div class="d-flex align-items-center">
                            <i *ngIf="!allowPosition" (click)="allowPosition=true;"
                                class="fa fa-square-o checkbox secondary-color inactive"></i>
                            <i *ngIf="allowPosition" (click)="allowPosition=false;"
                                class="fa fa-check-square-o checkbox success-color"></i>
                        </div>
                    </div>
                </div>
                <div *ngIf="allowPosition" class="row mb-3 align-items-center">
                    <div class="col-sm-4">Position:</div>
                    <div class="col-sm-8">
                        <ng-select [items]="positionList" placeholder="Select position" bindValue="id" bindLabel="id"
                            [clearable]="false" [(ngModel)]="position">
                        </ng-select>
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Active:</div>
                    <div class="col-sm-8">
                        <div class="d-flex align-items-center">
                            <i *ngIf="!active" (click)="active=true;"
                                class="fa fa-square-o checkbox secondary-color inactive"></i>
                            <i *ngIf="active" (click)="active=false;"
                                class="fa fa-check-square-o checkbox success-color"></i>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 text-center">
                    <div class="btn pointer no-radius mb-2 mx-2 secondary-bg text-white" (click)="clearData()">
                        Clear Form
                    </div>
                    <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="saveMeasurement()">
                        Save & Close
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-delete (onConfirm)="deleteMeasurement()"></app-delete>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ChallengeService } from './challenge.service';
import { PagerService } from 'src/app/shared/pager.service';
import { environment } from 'src/environments/environment';
import { LoaderService } from 'src/app/shared/loader.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-challenge',
  templateUrl: './challenge.component.html',
  styleUrls: ['./challenge.component.scss']
})
export class ChallengeComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  requestMade = false;
  isCreate = false;

  currentDateObj = null;

  searchText = '';
  viewActive = true;
  iniFlag: any = 1;
  pager: any = {};
  rowCount = 0;
  pageSize = 50;
  pageIndex = 1;
  orderBy = 'asc';

  challengeList = null;
  challengeListDropdown = null;
  challengeListToAssign = null;
  clientList: any;
  filteredChallengeList = null;

  challengeId = null;
  title = null;
  challengeType = null;
  description = null;
  imageURL = null;
  frequency = null;
  active = null;

  client_id = null;
  challenge_id = null;
  notification_message = null;

  fileBase64 = null;
  fileExtension = null;

  deleteItem = null;
  frequencyTypeList = null;
  frequencyType = null;
  weekDays: string[] = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
  selectedDays: string[] = [];


  constructor(
    private toastr: ToastrService,
    private pagerService: PagerService,
    private challengeService: ChallengeService,
    private loader: LoaderService
  ) { }

  ngOnInit(): void {
    this.clearData();
    this.getChallengeList(1);
    this.getChallengeListToAssign(1);
    this.getClientList();
    this.getFrequencyTypeList();
    this.getAllChallengeList();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleDay(day: string) {
    const index = this.selectedDays.indexOf(day);
    if (index > -1) {
      this.selectedDays.splice(index, 1);
    } else {
      this.selectedDays.push(day);
    }
    this.frequency = this.selectedDays
      .map(day => day.toLowerCase())
      .sort((a, b) => this.weekDays.indexOf(a) - this.weekDays.indexOf(b))
      .join(',');
  }

  getFrequencyTypeList() {
    this.frequencyTypeList = [
      { value: 'daily' },
      { value: 'days' },
      { value: 'per_week' },
      { value: 'per_month' },
    ];
  }

  setPage(page: number) {
    if (page < 1) { return; }
    if (page > this.pager.totalPages) { return; }

    this.pageIndex = page;
    this.pager = this.pagerService.getPager(this.rowCount, this.pageSize, this.pageIndex);
    if (this.iniFlag === 0) {
      this.getChallengeList(0);
    }
  }

  onFrequencyTypeChange() {
    this.selectedDays = []; // Reset selected days
    this.frequency = undefined; // Reset frequency to undefined
}

  getAllChallengeList() {
    this.loader.show();
    this.challengeService.getAllChallengeList().pipe(takeUntil(this.destroy$)).subscribe(
      (response) => {
        if (response.data) {
          this.challengeListDropdown = response.data;
        } else {
          this.challengeListDropdown = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  getClientList() {
    this.loader.show();
    this.challengeService.getClientList().pipe(takeUntil(this.destroy$)).subscribe(
      (response) => {
        if (response.data) {
          this.clientList = response.data;
        } else {
          this.clientList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  getChallengeList(item) {
    this.loader.show();
    if (item === 1) {
      this.pageIndex = 1;
      this.iniFlag = 1;
    }
    var formData: FormData = new FormData();
    formData.append('searchname', this.searchText);
    formData.append('page_no', this.pageIndex.toString());
    formData.append('page_size', this.pageSize.toString());
    formData.append('order_by', this.orderBy.toString());
    this.challengeService.getChallengeList(formData).pipe(takeUntil(this.destroy$)).subscribe(
      (response) => {
        if (response.data) {
          this.challengeList = response.data;
          this.rowCount = response.totalrow;
          if (this.iniFlag === 1) {
            this.setPage(1);
            this.iniFlag = 0;
          }
          this.filterChallengeList(this.viewActive)
        } else {
          this.challengeList = [];
          this.filteredChallengeList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  getChallengeListToAssign(item) {
    this.loader.show();
    var formData: FormData = new FormData();
    formData.append('searchname', this.searchText);
    formData.append('page_no', this.pageIndex.toString());
    formData.append('page_size', this.pageSize.toString());
    formData.append('order_by', this.orderBy.toString());
    this.challengeService.getChallengeList(formData).pipe(takeUntil(this.destroy$)).subscribe(
      (response) => {
        if (response.data) {
          this.challengeListToAssign = response.data;

        } else {
          this.challengeListToAssign = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  filterChallengeList(view): void {
    this.viewActive = view;
    this.filteredChallengeList = [];
    this.filteredChallengeList = (this.viewActive) ? this.challengeList.filter((e) => e.active) : this.challengeList;
    this.sortChallengeList();
  }

  sortChallengeList() {
    if (this.orderBy == 'asc') {
      this.filteredChallengeList.sort((a, b) => {
        return a.title.toLowerCase() > b.title.toLowerCase()
          ? 1
          : a.title.toLowerCase() < b.title.toLowerCase()
          ? -1
          : 0;
      });
    } else {
      this.filteredChallengeList.sort((a, b) => {
        return a.title.toLowerCase() > b.title.toLowerCase()
          ? -1
          : a.title.toLowerCase() < b.title.toLowerCase()
          ? 1
          : 0;
      });
    }
  }

  openImageLink(item) {
    window.open(environment.BASE_URL + item.cover_image, '_blank');
  }

  openVideoLink(item) {
    window.open(item.video_url, '_blank');
  }

  openAddUpdateChallengeModal(isCreate, data) {
    this.clearData();
    this.isCreate = isCreate;
    if (!this.isCreate) {
      this.challengeId = data.id;
      this.title = data.title;
      this.frequency = data.frequency;
      this.selectedDays = this.frequency ? this.frequency.split(',') : [];
      this.description = data.description;
      this.imageURL = data.cover_image;
      this.active = data.active;
      this.frequencyType = data.frequency_type
    }
    $('#add-challenge').modal('show');
  }
  openAssignChallengeModal(data) {
    this.clearData();

    this.challenge_id = data.id;

    $('#assign-challenge').modal('show');
  }
  clearData(): void {
    this.challengeId = null;
    this.title = null;
    this.description = null;
    this.imageURL = null;
    this.frequency = null;
    this.frequencyType = null;
    this.active = null;

    this.client_id = null;
    this.challenge_id = null;
    this.notification_message = null;
  }

  saveChallenge() {
    const validateErrors = {
      title: !this.title ? 'Please enter title.' : null,
      frequency: !this.frequency ? 'Please enter frequency.' : null,
      frequencyType: !this.frequencyType ? 'Please select frequency type.' : null,
    };
    
    for (const [key, errorToPass] of Object.entries(validateErrors)) {
      if (errorToPass) {
        this.toastr.error(errorToPass);
        return;
      }
    }
    var formData: FormData = new FormData();
    formData.append('title', this.title);
    formData.append('frequency', this.frequency);
    formData.append('frequency_type', this.frequencyType);
    formData.append('description', (this.description) ? this.description : '');
    formData.append('active', (this.active) ? '1' : '0');
    if (this.isCreate) {
      this.createChallenge(formData);
    } else {
      formData.append('challenge_id', this.challengeId);
      this.updateChallenge(formData);
    }
  }

  createChallenge(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.challengeService.createChallenge(formData).pipe(takeUntil(this.destroy$)).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-challenge').modal('hide');
            this.getChallengeList(1);
            this.getAllChallengeList();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  updateChallenge(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.challengeService.updateChallenge(formData).pipe(takeUntil(this.destroy$)).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-challenge').modal('hide');
            this.getChallengeList(1);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  openDeleteDialog(item) {
    this.deleteItem = item;
    $('#delete-dialog').modal('show');
  }

  deleteChallenge() {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      var formData: FormData = new FormData();
      formData.append('challenge_id', this.deleteItem.id);
      this.challengeService.deleteChallenge(formData).pipe(takeUntil(this.destroy$)).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            this.getChallengeList(1);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }
  assignChallenge() {

    var formData: FormData = new FormData();

    formData.append('client_id', this.client_id);
    formData.append('challenge_id', this.challenge_id);
    formData.append('notification_message', this.notification_message);
    formData.append('challenge_status', '1');
    this.assignChallengeToClients(formData);

  }
  assignChallengeToClients(formData) {

    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.challengeService.assignChallengeToClient(formData).pipe(takeUntil(this.destroy$)).subscribe(
        (response) => {
          console.log(response);
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#assign-challenge').modal('hide');
            this.getChallengeList(1);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide()
        }, (err) => {
          this.requestMade = false;
          console.log(err);
        }
      );
    }
  }
}

<div class="container-fluid main-content">
    <div class="row">
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
                <h4 class="my-2">Sessions List</h4>
                <img src="assets/images/setting.png" alt="images" class="setting-btn" data-toggle="modal"
                    data-target="#session-type" />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-4 align-self-center">
            <div class="d-flex align-items-center justify-content-between pb-3">
                <div class="m-0">
                    Week Sunday {{selectedWeek[0] | date:'dd/MM/yyyy'}} - {{selectedWeek[6] | date:'dd/MM/yyyy'}}
                </div>
                <div class="d-flex">
                    <div class="nav-btn ml-1 pointer" (click)="goToSession(-1)">
                        <i class="fa fa-chevron-left left"></i>
                    </div>
                    <div class="nav-btn ml-1 pointer" (click)="goToSession(1)">
                        <i class="fa fa-chevron-right right"></i>
                    </div>
                </div>
            </div>

        </div>
        <div class="col-lg-8 text-right">
            <ul class="table-feature">
                <li>
                    <div class="view-btn pointer">
                        <div [class.active]="viewActive" (click)="filterSessionList(true)">View Active</div>
                        <div>/</div>
                        <div [class.active]="!viewActive" (click)="filterSessionList(false)">View All</div>
                    </div>
                </li>
                <li>
                    <div class="image-btn pointer" (click)="viewCalender=false;">
                        <img [hidden]="!viewCalender" src="assets/images/list.png" class="image-btn" />
                        <img [hidden]="viewCalender" src="assets/images/list-active.png" class="image-btn" />
                    </div>
                </li>
                <li>
                    <div class="image-btn pointer" (click)="viewCalender=true;">
                        <img [hidden]="viewCalender" src="assets/images/calendar.png" class="image-btn" />
                        <img [hidden]="!viewCalender" src="assets/images/calendar-active.png" class="image-btn" />
                    </div>
                </li>
                <li>
                    <div class="primary-btn pointer" (click)="openAddUpdateSessionModal(1,null)">
                        Add session <img src="assets/images/add-white.png">
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div [hidden]="!viewCalender">
        <full-calendar #fullcalendar *ngIf="calendarVisible" [options]="calendarOptions"></full-calendar>
    </div>
    <div [hidden]="viewCalender" class="row">
        <div class="col-12">
            <div class="table-responsive table-list">
                <table class="table">
                    <thead class="thead-light">
                        <tr>
                            <th>Session Time</th>
                            <th>Session Name</th>
                            <th>Size</th>
                            <th>Days of Week</th>
                            <!-- <th>Trainer</th> -->
                            <th class="text-center">Active</th>
                            <th class="text-center px-3">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of filteredSessionList">
                            <td>
                                {{'1111-11-11 ' + item.start_time | date:'h'}}:{{'1111-11-11 ' + item.start_time | date:'mm'}}
                                {{'1111-11-11 ' + item.start_time | date:'aa'}}
                                -
                                {{'1111-11-11 ' + item.end_time | date:'h'}}:{{'1111-11-11 ' + item.end_time | date:'mm'}}
                                {{'1111-11-11 ' + item.end_time | date:'aa'}}
                            </td>
                            <td>{{item.name}}</td>
                            <td>{{item.size}}</td>
                            <td>{{item.daysOfWeek}}</td>
                            <!-- <td>{{(item.first_name || '') + ' ' + (item.last_name || '')}}</td> -->
                            <td>
                                <div class="text-center">
                                    <img *ngIf="item.active" src="assets/images/check-square.png"
                                        class="image-btn pointer" />
                                </div>
                            </td>
                            <td>
                                <div class="text-center">
                                    <img src="assets/images/edit.png" class="image-btn pointer"
                                        (click)="openAddUpdateSessionModal(0,item)" />
                                    <img src="assets/images/delete.png" class="image-btn pointer pl-2"
                                        (click)="openDeleteDialog('session',item)" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<app-add-session *ngIf="isLoad" [isCreate]="isCreate" [overrideId]="0" [sessionData]="sessionData"
    (onRefresh)="onRefresh($event)"></app-add-session>

<!-- #region Session Type -->
<div class="modal fade" id="session-type" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered session-type-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="d-flex w-100">
                    <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
                    <div class="modal-title w-100 text-center">Session Type Configurations</div>
                </div>
            </div>
            <div class="modal-body px-4">
                <div class="row">
                    <div class="col-lg-12 text-right">
                        <ul class="table-feature">
                            <li>
                                <div class="primary-btn pointer" (click)="openAddUpdateSessionTypeModal(1,null)">
                                    Add session type <img src="assets/images/add-white.png">
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="table-responsive table-list">
                            <table class="table">
                                <thead class="thead-light">
                                    <tr>
                                        <th style="width: 40%;">Title</th>
                                        <th style="width: 30%;">Type</th>
                                        <th style="width: 10%;">Active</th>
                                        <th style="width: 20%;" class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of sessionTypeList;">
                                        <td>{{item.title}}</td>
                                        <td>{{item.type}}</td>
                                        <td>
                                            <div class="text-center">
                                                <img *ngIf="item.is_active" src="assets/images/check-square.png"
                                                    class="image-btn pointer" />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="text-center">
                                                <img src="assets/images/edit.png" class="image-btn pointer" alt="images"
                                                    (click)="openAddUpdateSessionTypeModal(0,item);" />
                                                <img src="assets/images/delete.png" class="image-btn pointer pl-2"
                                                    alt="images" (click)="openDeleteDialog('session_type',item);" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="sessionTypeList.length==0">
                                        <td colspan="4" class="text-center">No Records</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- #endregion -->

<!-- #region Add Update Notes -->
<div class="modal fade" id="add-update-session-type" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="d-flex w-100">
                    <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
                    <div class="modal-title w-100 text-center">{{(isCreate)?'Add':'Update'}} Session Type
                    </div>
                </div>
            </div>
            <div class="modal-body px-4">
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Title:</div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control no-outline" placeholder="Enter title"
                            [(ngModel)]="sessionTypeTitle">
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Type:</div>
                    <div class="col-sm-8">
                        <ng-select [items]="sessionTypeTypeList" placeholder="Select type" bindValue="id" bindLabel="id"
                            [clearable]="false" [(ngModel)]="sessionTypeType">
                        </ng-select>
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Active:</div>
                    <div class="col-sm-8">
                        <div class="d-flex align-items-center">
                            <i *ngIf="!sessionTypeActive" (click)="sessionTypeActive=true;"
                                class="fa fa-square-o checkbox secondary-color inactive"></i>
                            <i *ngIf="sessionTypeActive" (click)="sessionTypeActive=false;"
                                class="fa fa-check-square-o checkbox success-color"></i>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 text-center">
                    <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white"
                        (click)="addUpdateSessionType();">
                        {{(isCreate)?'Add':'Update'}} Session Type
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- #endregion -->

<app-delete (onConfirm)="deleteRecord()"></app-delete>
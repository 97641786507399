<div class="modal fade p-0" id="add-session" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered add-session-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header text-center">
                <div class="d-flex w-100">
                    <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
                    <div class="modal-title w-100">{{(isCreate)?'Add':'Update'}} Session</div>
                </div>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="row m-0 pb-3">
                            <div class="col-lg-3 p-0 align-self-center">
                                <div>Start Time:</div>
                            </div>
                            <div class="col-lg-9 p-0 d-flex align-self-center">
                                <ng-select class="datetime" placeholder='HH' [items]="hours" bindLabel="value"
                                    bindValue="value" [clearable]="false" [(ngModel)]="startTime.hour"
                                    (change)="calculateDuration()">
                                </ng-select>
                                <ng-select class="datetime" placeholder='MM' [items]="minutes" bindLabel="value"
                                    bindValue="value" [clearable]="false" [(ngModel)]="startTime.minute"
                                    (change)="calculateDuration()">
                                </ng-select>
                                <div class="pl-2 d-flex align-self-center pointer meridiem">
                                    <div [class.selected]="startTime.meridiem=='AM'"
                                        (click)="startTime.meridiem='AM';calculateDuration()">
                                        AM
                                    </div>
                                    <div [class.selected]="startTime.meridiem=='PM'"
                                        (click)="startTime.meridiem='PM';calculateDuration()">
                                        PM
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="row m-0 pb-3">
                            <div class="col-lg-3 p-0 align-self-center">
                                <div>End Time:</div>
                            </div>
                            <div class="col-lg-9 p-0 d-flex align-self-center">
                                <ng-select class="datetime" [items]="hours" placeholder='HH' bindLabel="value"
                                    bindValue="value" [clearable]="false" [(ngModel)]="endTime.hour"
                                    (change)="calculateDuration()">
                                </ng-select>
                                <ng-select class="datetime" [items]="minutes" placeholder='MM' bindLabel="value"
                                    bindValue="value" [clearable]="false" [(ngModel)]="endTime.minute"
                                    (change)="calculateDuration()">
                                </ng-select>
                                <div class="pl-2 d-flex align-self-center pointer meridiem">
                                    <div [class.selected]="endTime.meridiem=='AM'"
                                        (click)="endTime.meridiem='AM';calculateDuration()">AM
                                    </div>
                                    <div [class.selected]="endTime.meridiem=='PM'"
                                        (click)="endTime.meridiem='PM';calculateDuration()">PM
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row pb-3">
                    <div class="col-lg-6">
                        <div class="row m-0">
                            <div class="col-lg-3 p-0">
                                <div class="morning-title">Duration:</div>
                            </div>
                            <div class="col-lg-9 p-0">
                                <div>
                                    {{duration || 'Calculate based on end time minus start time'}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row pb-4">
                    <div class="col-lg-2">
                        <div>Days of week:</div>
                    </div>
                    <div class="col-lg-10">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="row pb-3 align-items-center" *ngFor="let item of daysOfWeek">
                                    <div class="col-3 pr-0">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div>{{item.dayName}}</div>
                                            <i *ngIf="!item.active" (click)="item.active=true;"
                                                class="fa fa-square-o checkbox secondary-color inactive"></i>
                                            <i *ngIf="item.active" (click)="item.active=false;"
                                                class="fa fa-check-square-o checkbox success-color"></i>
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="form-group row mb-0 align-items-center">
                                            <div class="col-sm-3">Trainer:</div>
                                            <div class="col-sm-9">
                                                <ng-select placeholder="Select trainer" [items]="trainerList"
                                                    bindValue="id" bindLabel="name" [readonly]="!item.active"
                                                    [clearable]="false" [(ngModel)]="item.trainer">
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="row mb-3 align-items-center">
                                    <div class="col-sm-4">Session Name:</div>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control no-radius"
                                            placeholder="Enter session Name" [(ngModel)]="sessionName">
                                    </div>
                                </div>
                                <div class="row mb-3 align-items-center">
                                    <div class="col-sm-4">Session Type:</div>
                                    <div class="col-sm-8">
                                        <ng-select [items]="sessionTypeList" placeholder="Select session type"
                                            bindValue="id" bindLabel="title" [clearable]="false"
                                            [(ngModel)]="sessionType">
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="row mb-3 align-items-center">
                                    <div class="col-sm-4">Session Size:</div>
                                    <div class="col-sm-8">
                                        <input type="number" class="form-control no-radius"
                                            placeholder="Enter session max capacity" [(ngModel)]="sessionSize">
                                    </div>
                                </div>
                                <div class="row mb-3 align-items-center">
                                    <div class="col-sm-4 pr-0">Credit Required:</div>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control no-radius"
                                            placeholder="Enter credit required" [(ngModel)]="creditRequired">
                                    </div>
                                </div>
                                <div class="row mb-3 align-items-center">
                                    <div class="col-sm-4">Session Active:</div>
                                    <div class="col-sm-8">
                                        <div class="d-flex align-items-center">
                                            <i *ngIf="!sessionActive" (click)="sessionActive=true;"
                                                class="fa fa-square-o checkbox secondary-color inactive"></i>
                                            <i *ngIf="sessionActive" (click)="sessionActive=false;"
                                                class="fa fa-check-square-o checkbox success-color"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3 align-items-center">
                                    <div class="col-sm-4">Waitlist Active:</div>
                                    <div class="col-sm-8">
                                        <div class="d-flex align-items-center">
                                            <i *ngIf="!waitlistActive" (click)="waitlistActive=true;"
                                                class="fa fa-square-o checkbox secondary-color inactive"></i>
                                            <i *ngIf="waitlistActive" (click)="waitlistActive=false;waitlistSize=null;"
                                                class="fa fa-check-square-o checkbox success-color"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3 align-items-center">
                                    <div class="col-sm-4">Waitlist Size:</div>
                                    <div class="col-sm-8 ">
                                        <input type="number" class="form-control no-radius"
                                            placeholder="Enter waitlist size" [disabled]="!waitlistActive"
                                            [(ngModel)]="waitlistSize">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 text-center">
                    <div class="btn pointer no-radius mb-2 mx-2 secondary-bg text-white" (click)="clearData()">
                        Clear Form
                    </div>
                    <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="saveSession()">
                        Save & Close
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { StickersService } from './stickers.service';
import { PagerService } from 'src/app/shared/pager.service';
import { environment } from 'src/environments/environment';
import { LoaderService } from 'src/app/shared/loader.service';
declare var $: any;

@Component({
  selector: 'app-stickers',
  templateUrl: './stickers.component.html',
  styleUrls: ['./stickers.component.scss']
})
export class StickersComponent implements OnInit {

  assignStickersId: any;
  assignStickersClients: any;
  notificationMsg: any;

  stickersList: any;
  membershipTypeList: any;
  sessionTypeList: any;
  membershipLocalityList: any;
  groupList: any;
  filteredStickerList = null;
  clientList = null;

  requestMade = false;
  isCreate = false;
  deleteItem = null;

  currentDateObj = null;
  stickerDropdownList = [];

  searchText = '';
  viewActive = true;
  iniFlag: any = 1;
  pager: any = {};
  rowCount = 0;
  pageSize = 50;
  pageIndex = 1;
  orderBy = 'asc';

  stickerId = null;
  title = null;
  description = null;
  automated = null;
  time = null;
  query = null;
  content = null;
  active = null;

  img_uncollected = null;
  img_uncollectedBase64 = null;
  img_uncollectedExtension = null;

  img_collected = null;
  img_collectedBase64 = null;
  img_collectedExtension = null;

  constructor(
    private toastr: ToastrService,
    private stickersService: StickersService,
    private pagerService: PagerService,
    private loader: LoaderService
  ) { }

  ngOnInit(): void {
    this.getStickersList(1);
    this.getClientList();
    this.getAllStickerList();
  }

  getStickersList(item) {
    this.loader.show();
    if (item === 1) {
      this.pageIndex = 1;
      this.iniFlag = 1;
    }

    var formData: FormData = new FormData();
    formData.append('searchname', this.searchText);
    formData.append('page_no', this.pageIndex.toString());
    formData.append('page_size', this.pageSize.toString());
    formData.append('order_by', this.orderBy.toString());

    this.stickersService.getStickersList(formData).subscribe(
      (response) => {
        if (response.data) {
          this.stickersList = response.data;
          this.rowCount = response.totalrow;
          if (this.iniFlag === 1) {
            this.setPage(1);
            this.iniFlag = 0;
          }
          this.filterStickerList(this.viewActive)
        } else {
          this.stickersList = [];
          this.filteredStickerList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  setPage(page: number) {
    if (page < 1) { return; }
    if (page > this.pager.totalPages) { return; }

    this.pageIndex = page;
    this.pager = this.pagerService.getPager(this.rowCount, this.pageSize, this.pageIndex);
    if (this.iniFlag === 0) {
      this.getStickersList(0);
    }
  }

  filterStickerList(view): void {
    this.viewActive = view;
    this.filteredStickerList = [];
    this.filteredStickerList = (this.viewActive) ? this.stickersList.filter((e) => e.active) : this.stickersList;
  }

  openImageLink(item) {
    window.open(environment.BASE_URL + item, '_blank');
  }

  openAddUpdateStickerModal(isCreate, data) {
    this.clearData();
    this.isCreate = isCreate;
    if (!this.isCreate) {
      this.stickerId = data.id;
      this.title = data.title;
      this.description = data.criteria;
      this.img_uncollected = data.image_uncollected;
      this.img_collected = data.image_collected;
      this.automated = data.automated;
      if (+data.automated === 1) {
        this.time = data.time;
        this.query = data.query;
        this.content = data.content;
      }
      this.active = data.active;
    }
    $('#add-sticker').modal('show');
  }

  clearData(): void {
    this.stickerId = null;
    this.title = null;
    this.description = null;
    this.img_uncollected = null;
    this.img_collected = null;
    this.automated = null;
    this.time = null;
    this.query = null;
    this.content = null;
    this.active = null;
  }

  onFileChange($event, name) {
    if (name === "img_uncollected") {
      this.img_uncollected = $event.target.files[0];
      this.img_uncollectedExtension = '.' + this.img_uncollected.name.split('.').pop();
      var myReader: FileReader = new FileReader();
      myReader.onloadend = (e) => {
        this.img_uncollectedBase64 = myReader.result;
      }
      myReader.readAsDataURL(this.img_uncollected);
    } else if (name === "img_collected") {
      this.img_collected = $event.target.files[0];
      this.img_collectedExtension = '.' + this.img_collected.name.split('.').pop();
      var myReader: FileReader = new FileReader();
      myReader.onloadend = (e) => {
        this.img_collectedBase64 = myReader.result;
      }
      myReader.readAsDataURL(this.img_collected);
    }
  }


  saveSticker() {

    if (!this.title) {
      this.toastr.error('Please enter title.');
      return;
    } if (!this.description) {
      this.toastr.error('Please enter description.');
      return;
    }

    if (this.automated) {
      if (!this.time) {
        this.toastr.error('Please enter time.');
        return;
      } if (!this.query) {
        this.toastr.error('Please enter query.');
        return;
      } if (!this.content) {
        this.toastr.error('Please enter message content.');
        return;
      }
    }

    if (this.isCreate) {
      if (!this.img_uncollectedBase64) {
        this.toastr.error('Please upload Image Uncollected.');
        return;
      } if (!this.img_collectedBase64) {
        this.toastr.error('Please upload Image Collected.');
        return;
      }
    }

    var formData: FormData = new FormData();
    formData.append('title', this.title);
    formData.append('description', this.description);
    formData.append('automated', (this.automated) ? '1' : '0');
    formData.append('time', this.time);
    formData.append('query', this.query);
    formData.append('content', this.content);
    formData.append('active', (this.active) ? '1' : '0');
    console.log(this.img_uncollected);
    if (this.img_uncollectedBase64) {
      formData.append('img_uncollected', this.img_uncollectedBase64);
      formData.append('img_uncollected_ext', this.img_uncollectedExtension);
    }
    if (this.img_collectedBase64) {
      formData.append('img_collected', this.img_collectedBase64);
      formData.append('img_collected_ext', this.img_collectedExtension);
    }
    if (this.isCreate) {
      this.createSticker(formData);
    } else {
      formData.append('sticker_id', this.stickerId);
      this.updateSticker(formData);
    }
  }


  createSticker(formData) {

    if (!this.requestMade) {
      this.requestMade = true;
      this.loader.show();
      this.stickersService.createSticker(formData).subscribe(
        (response) => {

          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-sticker').modal('hide');
            this.getStickersList(1);
            this.getAllStickerList();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }


  updateSticker(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.stickersService.updateSticker(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-sticker').modal('hide');
            this.getStickersList(1);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  openDeleteDialog(item) {
    this.deleteItem = item;
    $('#delete-dialog').modal('show');
  }

  deleteSticker() {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      var formData: FormData = new FormData();
      formData.append('sticker_id', this.deleteItem.id);
      this.stickersService.deleteSticker(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            this.getStickersList(1);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }


  openAssignStickerModal(data) {

    this.assignStickersId = data.id;
    $('#assign-sticker').modal('show');
  }

  getClientList() {
    this.loader.show();
    this.stickersService.getClientList().subscribe(
      (response) => {
        this.requestMade = false;
        if (response.status) {
          this.clientList = response.data;
        } else {
          this.toastr.error(response.message);
        }
        this.loader.hide();
      }, (err) => {
        this.requestMade = false;
        this.loader.hide();
        console.log(err);
      }
    );
  }

  assignSticker() {

    if (!this.assignStickersId) {
      this.toastr.error('Please select sticker.');
      return;
    } if (!this.assignStickersClients) {
      this.toastr.error('Please select clients.');
      return;
    } if (!this.notificationMsg) {
      this.toastr.error('Please enter notification message.');
      return;
    }

    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      var formData: FormData = new FormData();
      formData.append('sticker_id', this.assignStickersId);
      formData.append('client_id', this.assignStickersClients);
      formData.append('message', this.notificationMsg);
      this.stickersService.assignStickerToClient(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            this.getStickersList(1);
            $('#assign-sticker').modal('hide');
            this.assignStickersId = null;
            this.assignStickersClients = null;
            this.notificationMsg = null;
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  getAllStickerList() {
    this.loader.show();
    this.stickersService.getAllStickerList().subscribe(
      (response) => {
        if (response.status) {
          this.stickerDropdownList = response.data;
        } else {
          this.stickerDropdownList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }
}

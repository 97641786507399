<div class="header">
    <div class="d-flex">
        <button *ngIf="guiService.showToggleMenu" type="button"
            class="btn btn-sm px-3 font-size-16 header-item waves-effect" (click)="toggleNav()">
            <i class="fa fa-fw fa-bars"></i>
        </button>
        <img src="assets/logo.png" class="logo">
    </div>
    <div class="d-flex" *ngIf="guiService.showProfileMenu">
        <div class="dropdown d-inline-block">
            <button type="button" class="btn header-item">
                <span
                    class="d-none d-xl-inline-block ml-3">{{storageService.loggedInUser?.first_name + ' ' + storageService.loggedInUser?.last_name}}</span>
                <img class="rounded-circle header-profile-user" [src]="storageService.profileImage"
                    onerror="this.onerror=null;this.src='assets/avatar.png';">
            </button>
        </div>
    </div>
</div>
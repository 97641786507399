import { environment } from 'src/environments/environment';

export class BookingApi {
    static readonly getTodayBookingList = environment.API_URL + 'gettodaybooking';
    static readonly getBookingList = environment.API_URL + 'getbooking';
    static readonly addBooking = environment.API_URL + 'addbooking';
    static readonly updateBooking = environment.API_URL + 'editbooking';
    static readonly getBookingHistory = environment.API_URL + 'gethistorybooking';
    static readonly getAuditBookingList = environment.API_URL + 'getauditbooking';
    static readonly getAuditBookingHistory = environment.API_URL + 'getaudithistorybooking';
    static readonly getBookingConfiguration = environment.API_URL + 'getbookingconfig';
    static readonly addBookingConfiguration = environment.API_URL + 'addbookingconfig';
    static readonly updateBookingConfiguration = environment.API_URL + 'updatebookingconfig';
    static readonly getBookingCount = environment.API_URL + 'getclient_bookingcount';
}

export class FitnessLogApi {
    static readonly getClientFitnessLogList = environment.API_URL + 'getclientwise_fitnesslog';
    static readonly getClientExerciseFitnessLogList = environment.API_URL + 'getclientandexercise_fitnesslog';
    static readonly addFitnessLog = environment.API_URL + 'addfitnesslog';
    static readonly updateFitnessLog = environment.API_URL + 'updatefitnesslog';
    static readonly deleteFitnessLog = environment.API_URL + 'deletefitnesslog';
}

export class SessionApi {
    static readonly getSessionList = environment.API_URL + 'sessionlist';
    static readonly getDateWiseSessionList = environment.API_URL + 'getdatewise_sessionlist';
    static readonly createSession = environment.API_URL + 'addsession';
    static readonly updateSession = environment.API_URL + 'editsession';
    static readonly deleteSession = environment.API_URL + 'deletesession';
    static readonly getTrainerList = environment.API_URL + 'gettrainer';
    static readonly getSessionTypeList = environment.API_URL + 'getsession_type';
    static readonly createSessionType = environment.API_URL + 'addsession_type';
    static readonly updateSessionType = environment.API_URL + 'updatesession_type';
    static readonly deleteSessionType = environment.API_URL + 'deletesession_type';
    static readonly getSessionOverrideList = environment.API_URL + 'getsession_override';
    static readonly createOverrideSession = environment.API_URL + 'addsession_override';
    static readonly updateOverrideSession = environment.API_URL + 'updatesession_override';
    static readonly getOverrideSessionList = environment.API_URL + 'getoverride_sessionlist';
    static readonly deactiveAllOverrideSession = environment.API_URL + 'deactive_alloverridesession';
    static readonly deleteAllOverrideSession = environment.API_URL + 'delete_alloverridesession';
    static readonly sessionTypeList = environment.API_URL + 'sessionTypeList';
}

export class ClientApi {
    static readonly getOnlyClientList = environment.API_URL + 'getonlyclient';
    static readonly getClientList = environment.API_URL + 'getclient';
    static readonly getClientList2 = environment.API_URL + 'getclient2';
    static readonly createClient = environment.API_URL + 'addclient';
    static readonly updateClient = environment.API_URL + 'editclient';
    static readonly deleteClient = environment.API_URL + 'deleteclient';
    static readonly getClientDetails = environment.API_URL + 'getclient_details';
    static readonly getClientGoalList = environment.API_URL + 'getclient_goallist';
    static readonly getGoalList = environment.API_URL + 'getgoals';
    static readonly createGoal = environment.API_URL + 'addgoals';
    static readonly updateGoal = environment.API_URL + 'updategoals';
    static readonly deleteGoal = environment.API_URL + 'deletegoals';
    static readonly getAttendance = environment.API_URL + 'getclient_attendance';
    static readonly updateAttendance = environment.API_URL + 'updateclient_attendance';
    static readonly getPersonalGoalList = environment.API_URL + 'getclient_personal_goals';
    static readonly createPersonalGoal = environment.API_URL + 'addclient_personal_goals';
    static readonly updatePersonalGoal = environment.API_URL + 'updateclient_personal_goals';
    static readonly deletePersonalGoal = environment.API_URL + 'deleteclient_personal_goals';
    static readonly getInjuryList = environment.API_URL + 'getclient_injuries';
    static readonly createInjury = environment.API_URL + 'addclient_injuries';
    static readonly updateInjury = environment.API_URL + 'updateclient_injuries';
    static readonly deleteInjury = environment.API_URL + 'deleteclient_injuries';
    static readonly getNoteList = environment.API_URL + 'getclient_notes';
    static readonly createNote = environment.API_URL + 'addclient_notes';
    static readonly updateNote = environment.API_URL + 'updateclient_notes';
    static readonly deleteNote = environment.API_URL + 'deleteclient_notes';
    static readonly replacePassword = environment.API_URL + 'replacepassword';
    static readonly dashboardCounts = environment.API_URL + 'adminDashboardCounts';
    static readonly todaySessionList = environment.API_URL + 'gettoday_sessionlist';
    static readonly getClientListWithoutPagination = environment.API_URL + 'getClientListWithoutPagination';
    static readonly getAll_ChallengeList = environment.API_URL + 'getAllChallengeList';
    static readonly groupList = environment.API_URL + 'groupList';
    static readonly createGroup = environment.API_URL + 'createGroup';
    static readonly updateGroup = environment.API_URL + 'updateGroup';
    static readonly deleteGroup = environment.API_URL + 'deleteGroup';
    static readonly activityTimeline = environment.API_URL + 'adminActivityTimeline';
}

export class ExerciseApi {
    static readonly getExerciseList = environment.API_URL + 'getexercise';
    static readonly createExercise = environment.API_URL + 'addexercise';
    static readonly updateExercise = environment.API_URL + 'updateexercise';
    static readonly deleteExercise = environment.API_URL + 'deleteexercise';
    static readonly getAllExerciseList = environment.API_URL + 'getExerciseList';
}

export class WorkoutApi {
    static readonly getWorkoutList = environment.API_URL + 'getWorkout';
    static readonly createWorkout = environment.API_URL + 'addWorkout';
    static readonly updateWorkout = environment.API_URL + 'editWorkout';
    static readonly deleteWorkout = environment.API_URL + 'deleteWorkout';
    static readonly getClientWorkoutLogList = environment.API_URL + 'getclientwise_Workoutlog';
    static readonly getClientExerciseWorkoutLogList = environment.API_URL + 'getclientandexercise_Workoutlog';
    static readonly getWorkoutDetailById = environment.API_URL + 'getWorkoutDetailById';
    static readonly getAdminWorkoutList = environment.API_URL + 'getAdminWorkoutList';
    static readonly getAllWorkoutList = environment.API_URL + 'getAllWorkoutList';
    static readonly assignWorkoutToClients = environment.API_URL + 'assignWorkoutToClients';
    static readonly getAllClientAssignedWorkoutList = environment.API_URL + 'getAllClientAssignedWorkoutList';
    static readonly deleteAssignedClientWorkout = environment.API_URL + 'deleteAssignedClientWorkout';
    static readonly getAllAssignedClientWorkoutLog = environment.API_URL + 'getAllAssignedClientWorkoutLog';
    static readonly deleteAssignedClientWorkoutLog = environment.API_URL + 'deleteClientWorkoutLog';
    static readonly updateAssignedWorkout = environment.API_URL + 'updateAssignedWorkout';
    static readonly getClientWorkoutAllLogs = environment.API_URL + 'getClientWorkoutAllLogs';
    static readonly getUpdatedExercise = environment.API_URL + 'getUpdatedExercise';
}

export class MeasurementApi {
    static readonly getMeasurementList = environment.API_URL + 'getmeasurement';
    static readonly createMeasurement = environment.API_URL + 'addmeasurement';
    static readonly updateMeasurement = environment.API_URL + 'updatemeasurement';
    static readonly deleteMeasurement = environment.API_URL + 'deletemeasurement';
    static readonly getClientMeasurementLogList = environment.API_URL + 'getclientwise_measurementlog';
    static readonly getClientExerciseMeasurementLogList = environment.API_URL + 'getclientandexercise_measurementlog';
    static readonly getMeasurementLogList = environment.API_URL + 'getmeasurementlog';
    static readonly addMeasurementLog = environment.API_URL + 'addmeasurementlog';
    static readonly updateMeasurementLog = environment.API_URL + 'updatemeasurementlog';
    static readonly deleteMeasurementLog = environment.API_URL + 'deletemeasurementlog';
}

export class ChallengeApi {
    static readonly getChallengeList = environment.API_URL + 'getAdminChallengeList';
    static readonly createChallenge = environment.API_URL + 'createChallenge';
    static readonly updateChallenge = environment.API_URL + 'editChallenge';
    static readonly deleteChallenge = environment.API_URL + 'deleteChallenge';
    static readonly assignChallengeToClient = environment.API_URL + 'assignChallengeToClient';
    static readonly getClientChallengeList = environment.API_URL + 'getClientChallengeList';
    static readonly deleteClientChallenge = environment.API_URL + 'deleteClientChallenge';
    static readonly getAllChallengeList = environment.API_URL + 'getChallengeList';
    static readonly getClientChallengeLogList = environment.API_URL + 'getClientChallengeLogList';
}

export class FoodDiaryApi {
    static readonly getFoodDiaryList = environment.API_URL + 'getdatewise_fooddiary';
    static readonly addFoodDiary = environment.API_URL + 'addfooddiary';
    static readonly updateFoodDiary = environment.API_URL + 'updatefooddiary';
    static readonly deleteFoodDiary = environment.API_URL + 'deletefooddiary';
}

export class UserApi {
    static readonly signIn = environment.API_URL + 'adminlogin';
    static readonly resetPassword = environment.API_URL + 'resetpassword';
    static readonly changePassword = environment.API_URL + 'changepassword';
    static readonly updateNotificationConfig = environment.API_URL + 'update_notificationconfig';
}

export class NotificationTypeApi {
    static readonly getNotificationTypes = environment.API_URL + 'getnotificationtype';
    static readonly createNotificationType = environment.API_URL + 'addnotificationtype';
    static readonly updateNotificationType = environment.API_URL + 'updatenotificationtype';
    static readonly deleteNotificationType = environment.API_URL + 'deletenotification_type';
    static readonly sendNotificationToUsers = environment.API_URL + 'sendNotificationToUsers';
}

export class TeamMemberApi {
    static readonly getTeamMemberList = environment.API_URL + 'getteam_member';
    static readonly addTeamMember = environment.API_URL + 'addteam_member';
    static readonly updateTeamMember = environment.API_URL + 'updateteam_member';
    static readonly deleteTeamMember = environment.API_URL + 'deleteteam_member';
}

export class DocumentApi {
    static readonly getDocumentList = environment.API_URL + 'getdocuments';
    static readonly addDocument = environment.API_URL + 'adddocuments';
    static readonly updateDocument = environment.API_URL + 'updatedocuments';
    static readonly deleteDocument = environment.API_URL + 'deletedocuments';
}

export class BlogApi {
    static readonly getAdminBlogList = environment.API_URL + 'getBlogList';
    static readonly createBlog = environment.API_URL + 'createBlog';
    static readonly editBlog = environment.API_URL + 'editBlog';
    static readonly deleteBlog = environment.API_URL + 'deleteBlog';
}

export class MembershipApi {
    static readonly getMembershipList = environment.API_URL + 'getMembershipList';
    static readonly getMembershipTypeList = environment.API_URL + 'getMembershipTypeList';
    static readonly getMembershipLocalityList = environment.API_URL + 'getMembershipLocalityList';
    static readonly groupList = environment.API_URL + 'groupList';
    static readonly createMembership = environment.API_URL + 'createMembership';
    static readonly editMembership = environment.API_URL + 'editMembership';
    static readonly deleteMembership = environment.API_URL + 'deleteMembership';
    static readonly getPurchasedMembershipListByClientId = environment.API_URL + 'getPurchasedMembershipListByClientId';
    static readonly getBillingCreditListByClientId = environment.API_URL + 'getBillingCreditListByClientId';
    static readonly assignBillToClient = environment.API_URL + 'assignBillToClient';
    static readonly getPaymentMethodList = environment.API_URL + 'getPaymentMethodList';
    static readonly updateBillingCreditData = environment.API_URL + 'updateBillingCreditData';
    static readonly getClientPaymentList = environment.API_URL + 'getClientPaymentList';
    static readonly checkPaymentStatus = environment.API_URL + 'checkPaymentStatus';
    static readonly checkCreditCardPaymentStatus = environment.API_URL + 'checkCreditCardPaymentStatus';
    static readonly checkBenefitPaymentStatus = environment.API_URL + 'checkBenefitPaymentStatus';
    static readonly updateClientPaymentData = environment.API_URL + 'updateClientPaymentData';
    static readonly clientRequestToPauseMembership = environment.API_URL + 'clientRequestToPauseMembership';
    static readonly getPausedMembershipDetailByCMid = environment.API_URL + 'getPausedMembershipDetailByCMid';
    static readonly upgradeDowngradeMembership = environment.API_URL + 'upgradeDowngradeMembership';
    static readonly cancelMembership = environment.API_URL + 'cancelMembership';
    static readonly updateClientMembershipData = environment.API_URL + 'updateClientMembershipData';
    static readonly getMembershipListWithoutPagination = environment.API_URL + 'getMembershipListWithoutPagination';
    static readonly purchaseMembership = environment.API_URL + 'purchaseMembership';
    static readonly addPurchasedMembershipData = environment.API_URL + 'addPurchasedMembershipData';
    static readonly getClientPreTrialMembershipSessionListAdmin = environment.API_URL + 'getClientPreTrialMembershipSessionListAdmin';
    static readonly addUpdatePreTrialOneOnOneSession = environment.API_URL + 'addUpdatePreTrialOneOnOneSession';
    static readonly deletePreTrialOneOnOneSession = environment.API_URL + 'deletePreTrialOneOnOneSession';
    static readonly saveMembershipSetting = environment.API_URL + 'addMembershipGlobalConfigData';
    static readonly getMembershipGlobalConfigData = environment.API_URL + 'getMembershipGlobalConfigData';
    static readonly getMembershipListForUserGroup = environment.API_URL + 'getMembershipListForUserGroupAdmin';
    static readonly resumeMembershipManually = environment.API_URL + 'resumeMembershipManually';
}

export class StickersApi {
    static readonly getAdminStickerList = environment.API_URL + 'getAdminStickerList';
    static readonly editSticker = environment.API_URL + 'editSticker';
    static readonly createSticker = environment.API_URL + 'createSticker';
    static readonly deleteSticker = environment.API_URL + 'deleteSticker';
    static readonly assignStickerToClient = environment.API_URL + 'assignStickerToClient';
    static readonly getClientStickerList = environment.API_URL + 'getAdminClientStickerList';
    static readonly deleteClientSticker = environment.API_URL + 'deleteClientSticker';
    static readonly updateAssignedSticker = environment.API_URL + 'updateAssignedSticker';
    static readonly getAllStickerList = environment.API_URL + 'getAllStickerList';
}

export class ReportApi {
    static readonly getReportList = environment.API_URL + 'getReportsList';
    static readonly createReport = environment.API_URL + 'createReport';
    static readonly updateReport = environment.API_URL + 'editReport';
    static readonly deleteReport = environment.API_URL + 'deleteReport';
    static readonly getReportResult = environment.API_URL + 'getReportResult';
}

export class PaymentApi {
    static readonly getHash = environment.API_URL + 'getHash';
    static readonly createSession = environment.API_URL + 'createSession';
    static readonly updateSession = environment.API_URL + 'updateSession';
    static readonly getClientCheckoutData = environment.API_URL + 'getClientCheckoutData';
    static readonly sendPayRequest = environment.API_URL + 'sendPayRequest';
}

export class EquipmentApi {
    static readonly equipment = environment.API_URL + 'equipment';
    static readonly clientEquipment = environment.API_URL + 'clientEquipment';
    static readonly deleteAssignedClientEquipment = environment.API_URL + 'deleteClientEquipment';
    static readonly getEquipmentForExercises = environment.API_URL + 'getExercisesEquipments';
}
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ClientsService } from './clients.service';
import { PagerService } from 'src/app/shared/pager.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { LoaderService } from 'src/app/shared/loader.service';
declare var $: any;

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  requestMade = false;
  isCreate = true;

  baseURL = '';
  selectedTab = '';
  overdueStatus = 0;
  searchText = '';
  // For debouce of search, see: https://stackblitz.com/edit/angular-debounce-input-example?file=src%2Fapp%2Fapp.component.ts,src%2Fapp%2Fapp.component.html,src%2Fapp%2Fhello.component.ts
  public searchName: string;
  searchUpdate = new Subject<string>();

  viewActive = true;
  iniFlag: any = 1;
  pager: any = {};
  rowCount = 0;
  pageSize = 50;
  pageIndex = 1;
  orderBy = 'asc';

  clientList = null;
  filteredClientList = null;

  genderList = null;
  userTypeList = null;
  statusList = null;

  newPassword = '';
  confirmPassword = '';
  clientId = null;
  userDetails = null;

  goalList = [];
  goalId = '';
  goalTitle = '';

  groupList = [];
  groupId = '';
  groupTitle = '';

  deleteItem = null;
  deleteFrom = '';

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private pagerService: PagerService,
    private clientService: ClientsService,
    private loader: LoaderService
  ) { }

  ngOnInit(): void {
    let { status } = window.history.state;
    this.overdueStatus = status ? status : 0;
    
    // Debounce search, see: https://stackblitz.com/edit/angular-debounce-input-example?file=src%2Fapp%2Fapp.component.ts,src%2Fapp%2Fapp.component.html,src%2Fapp%2Fhello.component.ts
    this.searchUpdate.pipe(
      debounceTime(200),
      distinctUntilChanged())
      .subscribe(value => {
        this.searchText = value;
        this.filterClientName();
      });

    let { tab } = window.history.state;
    this.selectedTab = tab ? tab : 'Goals';
    this.baseURL = environment.BASE_URL;
    this.getClientList(1);
    this.getGenderList();
    this.getUserTypeList();
    this.getStatusList();
    this.getGroupList();
    this.clearData();
  }

  setPage(page: number) {
    if (page < 1) { return; }
    if (page > this.pager.totalPages) { return; }

    this.pageIndex = page;
    this.pager = this.pagerService.getPager(this.rowCount, this.pageSize, this.pageIndex);
    if (this.iniFlag === 0) {
      this.getClientList(0);
    }
  }

  getClientList(item) {
    this.loader.show();
    if (item === 1) {
      this.pageIndex = 1;
      this.iniFlag = 1;
    }
    var formData: FormData = new FormData();
    formData.append('searchname', this.searchText);
  
    formData.append('dueoverdue_status', this.overdueStatus.toString());
    formData.append('page_no', this.pageIndex.toString());
    formData.append('page_size', this.pageSize.toString());
    formData.append('order_by', this.orderBy.toString());
    this.clientService.getClientList2(formData).subscribe(
      (response) => {
        if (response.data) {
          this.clientList = response.data;
          this.rowCount = response.totalrow ? response.totalrow : 0;
          if (this.iniFlag === 1) {
            this.setPage(1);
            this.iniFlag = 0;
          }
          this.filterClientList(true);
        } else {
          this.clientList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  filterClientList(view): void {
    this.viewActive = view;
    this.filteredClientList = [];
    this.filteredClientList = (this.viewActive) ? this.clientList.filter((e) => e.active) : this.clientList;
    this.sortClientList();
  }

  filterClientName(): void {
    this.filteredClientList = [];
    if (this.searchText == '') {
      this.filterClientList(this.viewActive);
      return;
    }
    const searchTextLower = this.searchText.toLowerCase();
    this.filteredClientList = this.clientList.filter((e: any) => {
      const fullName = (e.first_name + ' ' + e.last_name).toLowerCase();
      if (fullName.includes(searchTextLower)) {
        // Check active filter
        if (this.viewActive && e.active) {
          return true;
        } else if (!this.viewActive) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });
    this.sortClientList();
  }

  sortClientList() {
    if (this.orderBy == 'asc') {
      this.filteredClientList.sort((a, b) => {
        if (a.first_name.toLowerCase() > b.first_name.toLowerCase()) { return 1; }
        if (a.first_name.toLowerCase() < b.first_name.toLowerCase()) { return -1; }
        if (a.last_name.toLowerCase() > b.last_name.toLowerCase()) { return 1; }
        if (a.last_name.toLowerCase() < b.last_name.toLowerCase()) { return -1; }
      });
    } else {
      this.filteredClientList.sort((a, b) => {
        if (a.first_name.toLowerCase() > b.first_name.toLowerCase()) { return -1; }
        if (a.first_name.toLowerCase() < b.first_name.toLowerCase()) { return 1; }
        if (a.last_name.toLowerCase() > b.last_name.toLowerCase()) { return 1; }
        if (a.last_name.toLowerCase() < b.last_name.toLowerCase()) { return -1; }
      });
    }
  }

  getDaysAgo(date) {
    if (!date) {
      return 0;
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    // console.log('Today: ', today);
    // Need to add 3 hours to the time as the date passed is 
    const lastDate = new Date(date);
    const lastDatePlusThree = new Date();
    lastDatePlusThree.setTime(Math.ceil(lastDate.getTime() + (3 * 60 * 60 * 1000)));
    // console.log('Last Date: ', lastDatePlusThree);
    const timeDiffMs = today.getTime() - lastDatePlusThree.getTime();
    const timeDiffDays = Math.ceil(timeDiffMs / (1000 * 60 * 60 *24));
    // console.log('Day Diff: ', timeDiffDays);
    return timeDiffDays;
  }

  getGenderList() {
    this.genderList = [];
    this.genderList.push({ value: 'Male' });
    this.genderList.push({ value: 'Female' });
  }

  getUserTypeList() {
    this.userTypeList = [];
    this.userTypeList.push({ value: 'Admin' });
    this.userTypeList.push({ value: 'Trainer' });
    this.userTypeList.push({ value: 'Client' });
  }

  getStatusList() {
    this.statusList = [];
    this.statusList.push({ id: 1, value: 'Active' });
    this.statusList.push({ id: 0, value: 'Inactive' });
  }

  openAddUpdateClientModal(isCreate, data) {
    this.clearData();
    this.isCreate = isCreate;
    if (!this.isCreate) {
      this.clientId = data.id;
      this.userDetails.first_name = data.first_name;
      this.userDetails.last_name = data.last_name;
      this.userDetails.email = data.email;
      this.userDetails.phone = data.phone;
      this.userDetails.dob = moment(data.dob).format('YYYY-MM-DD');
      this.userDetails.gender = data.gender;
      this.userDetails.groups = data.groups;
      this.userDetails.user_type = data.user_type;
      this.userDetails.active = data.active;
      this.userDetails.feature_video = (data.feature_video) ? true : false;
      this.userDetails.feature_program = (data.feature_program) ? true : false;
      this.userDetails.feature_log = (data.feature_log) ? true : false;
      this.userDetails.feature_diary = (data.feature_diary) ? true : false;
      this.userDetails.feature_measurements = (data.feature_measurements) ? true : false;
      this.userDetails.feature_challenges = (data.feature_challenges) ? true : false;
      this.userDetails.feature_stickers = (data.feature_stickers) ? true : false;
    }
    $('#add-client').modal('show');
  }

  clearData(): void {
    this.userDetails = {
      first_name: null,
      last_name: null,
      email: null,
      phone: null,
      dob: null,
      gender: null,
      groups: null,
      user_type: null,
      active: null,
      feature_video: 1,
      feature_program: null,

      feature_log: 1,
      feature_diary: 1,
      feature_measurements: 1,
      
      feature_challenges: 1,
      feature_stickers: 1,
    }
  }

  saveClient() {
    if (!this.userDetails.first_name) {
      this.toastr.error('Please enter first name.');
      return;
    }
    if (!this.userDetails.last_name) {
      this.toastr.error('Please enter last name.');
      return;
    }
    if (!this.userDetails.email) {
      this.toastr.error('Please enter email.');
      return;
    }
    if (!this.userDetails.phone) {
      this.toastr.error('Please enter phone.');
      return;
    }
    if (!this.userDetails.dob) {
      this.toastr.error('Please select date of birth.');
      return;
    }
    if (!this.userDetails.gender) {
      this.toastr.error('Please select gender.');
      return;
    }
    if (!this.userDetails.groups) {
       this.toastr.error('Please select group.');
       return;
    }
    if (!this.userDetails.user_type) {
      this.toastr.error('Please select user type.');
      return;
    }
    if (this.userDetails.active == null) {
      this.toastr.error('Please select status.');
      return;
    }
    if (this.isCreate && !this.newPassword) {
      this.toastr.error('Please enter password.');
      return;
    }
    if (this.newPassword != this.confirmPassword) {
      this.toastr.error('Password and confirm password not match.');
      return;
    }

    var formData: FormData = new FormData();
    formData.append('first_name', this.userDetails.first_name);
    formData.append('last_name', this.userDetails.last_name);
    formData.append('email', this.userDetails.email);
    formData.append('phone', this.userDetails.phone);
    formData.append('dob', this.userDetails.dob);
    formData.append('gender', this.userDetails.gender);
    formData.append('groups', this.userDetails.groups);
    formData.append('user_type', this.userDetails.user_type);
    formData.append('active', this.userDetails.active);
    formData.append('feature_video', (this.userDetails.feature_video) ? '1' : '0');
    formData.append('feature_program', (this.userDetails.feature_program) ? '1' : '0');
    formData.append('feature_log', (this.userDetails.feature_log) ? '1' : '0');
    formData.append('feature_diary', (this.userDetails.feature_diary) ? '1' : '0');
    formData.append('feature_measurements', (this.userDetails.feature_measurements) ? '1' : '0');
    formData.append('feature_challenges', (this.userDetails.feature_challenges) ? '1' : '0');
    formData.append('feature_stickers', (this.userDetails.feature_stickers) ? '1' : '0');
    if (this.newPassword) {
      formData.append('password', this.newPassword);
    }
    if (this.isCreate) {
      this.createClient(formData);
    } else {
      formData.append('client_id', this.clientId);
      this.updateClient(formData);
    }
  }

  createClient(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.clientService.createClient(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-client').modal('hide');
            this.getClientList(1);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  updateClient(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.clientService.updateClient(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-client').modal('hide');
            this.getClientList(1);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  goToProfile(data) {
    this.router.navigate(['client-profile'], { state: { client_id: data.id } });
    return;
  }

  openAddUpdateGoalDialog() {
    this.getGoalList();
    this.getGroupList();
    $('#add-update-goal').modal('show');
  }

  getGoalList() {
    this.loader.show();
    this.clientService.getGoalList().subscribe(
      (response) => {
        if (response.status && response.data) {
          this.goalList = response.data;
        } else {
          this.goalList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  setUpdateGoal(goal) {
    this.isCreate = false;
    this.goalId = goal.id;
    this.goalTitle = goal.goals_title;
  }

  addUpdateGoal() {
    if (!this.goalTitle) {
      this.toastr.error('Please enter goal title.');
      return;
    }
    var formData: FormData = new FormData();
    formData.append('goals_title', this.goalTitle);
    formData.append('status', '1');
    if (this.isCreate) {
      this.createGoal(formData);
    } else {
      formData.append('goal_id', this.goalId);
      this.updateGoal(formData);
    }
  }

  createGoal(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.clientService.createGoal(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            this.goalTitle = '';
            this.isCreate = true;
            this.getGoalList();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  updateGoal(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.clientService.updateGoal(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            this.goalTitle = '';
            this.isCreate = true;
            this.getGoalList();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  getGroupList() {
    this.loader.show();
    this.clientService.getGroupList().subscribe(
      (response) => {
        if (response.status && response.data) {
          this.groupList = response.data;
        } else {
          this.groupList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  setUpdateGroup(Group) {
    this.isCreate = false;
    this.groupId = Group.id;
    this.groupTitle = Group.name;
  }

  addUpdateGroup() {
    if (!this.groupTitle) {
      this.toastr.error('Please enter group title.');
      return;
    }
    var formData: FormData = new FormData();
    formData.append('name', this.groupTitle);
    if (this.isCreate) {
      this.createGroup(formData);
    } else {
      formData.append('group_id', this.groupId);
      this.updateGroup(formData);
    }
  }

  createGroup(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.clientService.createGroup(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            this.groupTitle = '';
            this.isCreate = true;
            this.getGroupList();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  updateGroup(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.clientService.updateGroup(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            this.groupTitle = '';
            this.isCreate = true;
            this.getGroupList();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  openDeleteDialog(deleteFrom, item) {
    this.deleteFrom = deleteFrom;
    this.deleteItem = item;
    console.log(this.deleteFrom);
    $('#delete-dialog').modal('show');
  }

  deleteRecord() {
    if (this.deleteFrom == 'Goal') {
      this.deleteGoal();
    } else if (this.deleteFrom == 'Group') {
      this.deleteGroup();
    }
  }
  deleteGoal() {
    if (!this.requestMade) {
      this.requestMade = true;
      var formData: FormData = new FormData();
      formData.append('goal_id', this.deleteItem.id);
      this.loader.show();
      this.clientService.deleteGoal(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            this.getGoalList();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  deleteGroup() {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      var formData: FormData = new FormData();
      formData.append('group_id', this.deleteItem.id);
      this.clientService.deleteGroup(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            this.getGroupList();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

}

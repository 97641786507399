import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StickersApi, ClientApi } from 'src/app/const/app.url';

@Injectable({
  providedIn: 'root'
})
export class StickersService {

  constructor(
  	private http: HttpClient
  ) { }

  getStickersList(body?: any) {
    return this.http.post<any>(StickersApi.getAdminStickerList, body)
  }

  createSticker(body?: any) {
    return this.http.post<any>(StickersApi.createSticker, body)
  }

  updateSticker(body?: any) {
    return this.http.post<any>(StickersApi.editSticker, body)
  }

  deleteSticker(body?: any) {
    return this.http.post<any>(StickersApi.deleteSticker, body)
  }

  getClientList() {
    return this.http.get<any>(ClientApi.getOnlyClientList)
  }

  assignStickerToClient(body?: any) {
    return this.http.post<any>(StickersApi.assignStickerToClient, body)
  }

  getAllStickerList() {
    return this.http.get<any>(StickersApi.getAllStickerList)
  }
}

<div class="container-fluid main-content">
    <div class="row">
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
                <h4 class="my-2">Stickers</h4>
            </div>
        </div>
    </div>
    <div class="row pt-5">
        <div class="col-lg-2 d-none d-lg-block">
            <div class="d-flex align-items-center justify-content-between search-box">
                <input type="text" class="form-control" placeholder="Search..." autocomplete="off"
                    [(ngModel)]="searchText" (input)="getStickersList(1)">
                <div class="icon px-2"><i class="fa fa-search"></i></div>
            </div>
        </div>
        <div class="col-lg-10 text-right align-self-center">
            <ul class="table-feature">
                <li>
                    <div class="view-btn pointer">
                        <div [class.active]="viewActive" (click)="filterStickerList(true)">View Active</div>
                        <div>/</div>
                        <div [class.active]="!viewActive" (click)="filterStickerList(false)">View All</div>
                    </div>
                </li>
                <li>
                    <div class="pagination" *ngIf="pager.pages && pager.pages.length">
                        <ul class="page-number">
                            
                            <li (click)="setPage(pager.currentPage - 1)" [class.disabled]="pager.currentPage === 1">
                                <i class="fa fa-angle-left"></i>
                            </li>
                            <li *ngFor="let page of pager.pages" (click)="setPage(page)"
                                [class.active]="pager.currentPage === page">
                                {{ page }}
                            </li>
                            <li (click)="setPage(pager.currentPage + 1)"
                                [class.disabled]="pager.currentPage === pager.totalPages">
                                <i class="fa fa-angle-right"></i>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <div class="sort-by">
                        <select [(ngModel)]="pageSize" (change)="getStickersList(1)">
                            <option [value]="50">50/Pages</option>
                            <option [value]="100">100/Pages</option>
                            <option [value]="150">150/Pages</option>
                        </select>
                    </div>
                </li>
                <li>
                    <div class="sort-by">
                        <select [(ngModel)]="orderBy" (change)="getStickersList(1)">
                            <option [value]="''">Sort by</option>
                            <option [value]="'asc'">A To Z</option>
                            <option [value]="'desc'">Z To A</option>
                        </select>
                    </div>
                </li>
                <li>
                    <div class="primary-btn pointer" (click)="openAddUpdateStickerModal(1,null)">
                        Add Sticker <img src="assets/images/add-white.png" alt="images">
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="table-responsive table-list">
                <table class="table">
                    <thead class="thead-light">
                        <tr>
                            <th>Sticker Title</th>
                            <th>Description</th>
                            <th>Image Uncollected</th>
                            <th>Image Collected</th>
                            <th>Automated</th>
                            <th class="text-center">Active</th>
                            <th class="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of filteredStickerList">
                            <td>{{item.title}}</td>
                            <td>{{item.criteria}}</td>
                            <td>
                            	<div class="text-center">
                                    <img *ngIf="item.image_uncollected" src="assets/images/image.svg"
                                        class="image-btn pointer success-color" alt="images"
                                        (click)="openImageLink(item.image_uncollected)" />
                                </div>
                            </td>
                            <td>
                            	<div class="text-center">
                                    <img *ngIf="item.image_collected" src="assets/images/image.svg"
                                        class="image-btn pointer success-color" alt="images"
                                        (click)="openImageLink(item.image_collected)" />
                                </div>
                            </td>
                            <td>
                                <div class="text-center">
                                    <img *ngIf="item.automated" src="assets/images/check-square.png"
                                        class="image-btn pointer" alt="images" />
                                </div>
                            </td>
                            <td>
                                <div class="text-center">
                                    <img *ngIf="item.active" src="assets/images/check-square.png"
                                        class="image-btn pointer" alt="images" />
                                </div>
                            </td>
                            <td>
                                <div class="text-center">
                                    <img src="assets/images/add.png" class="image-btn pointer" (click)="openAssignStickerModal(item)" alt="images" />
                                    <img src="assets/images/edit.png" class="image-btn pointer pl-2" (click)="openAddUpdateStickerModal(0,item)" alt="images" />
                                    <img src="assets/images/delete.png" class="image-btn pointer pl-2" (click)="openDeleteDialog(item)" alt="images" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<div class="modal fade p-0" id="add-sticker" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered add-session-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header text-center">
                <div class="d-flex w-100">
                    <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
                    <div class="modal-title w-100">{{(isCreate)?'Add':'Update'}} Sticker</div>
                </div>
            </div>
            <div class="modal-body">
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Title:</div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control no-radius" placeholder="Enter sticker title"
                            [(ngModel)]="title">
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Description:</div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control no-radius" placeholder="Enter sticker description"
                            [(ngModel)]="description">
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4 pr-0">Image Uncollected:</div>
                    <div class="col-sm-8">
                        <div class="form-control form-file-control no-radius">
                            <label for="fileInput"
                                class="file-name">{{(img_uncollected)?img_uncollected.name:'Upload image'}}</label>
                            <label for="fileInput" class="file-btn">Browse</label>
                            <input type="file" class="d-none" id="fileInput" (change)="onFileChange($event, 'img_uncollected')" />
                        </div>
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Image Collected:</div>
                    <div class="col-sm-8">
	                    <div class="form-control form-file-control no-radius">
	                        <label for="fileInput1"
	                            class="file-name">{{(img_collected)?img_collected.name:'Upload image'}}</label>
	                        <label for="fileInput1" class="file-btn">Browse</label>
	                        <input type="file" class="d-none" id="fileInput1" (change)="onFileChange($event, 'img_collected')" />
	                    </div>
	                </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4 pr-0">Automated:</div>
                    <div class="col-sm-8">
                    	<div class="d-flex align-items-center">
                            <i *ngIf="!automated" (click)="automated=true;"
                                class="fa fa-square-o checkbox secondary-color inactive"></i>
                            <i *ngIf="automated" (click)="automated=false;"
                                class="fa fa-check-square-o checkbox success-color"></i>
                        </div>
                    </div>
                </div>
                <div class="row mb-3 align-items-center" *ngIf="automated">
                    <div class="col-sm-4 pr-0">Time:</div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control no-radius" placeholder="Enter time"
                            [(ngModel)]="time">
                    </div>
                </div>
                <div class="row mb-3 align-items-center" *ngIf="automated">
                    <div class="col-sm-4 pr-0">Select Query:</div>
                    <div class="col-sm-8">
                        <textarea class="form-control no-radius" placeholder="Enter query" [(ngModel)]="query"></textarea>
                    </div>
                </div>
                <div class="row mb-3 align-items-center" *ngIf="automated">
                    <div class="col-sm-4">Message Content:</div>
                    <div class="col-sm-8">
                    	<textarea class="form-control no-radius" placeholder="Enter message content" [(ngModel)]="content"></textarea>
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Active:</div>
                    <div class="col-sm-8">
                        <div class="d-flex align-items-center">
                            <i *ngIf="!active" (click)="active=true;"
                                class="fa fa-square-o checkbox secondary-color inactive"></i>
                            <i *ngIf="active" (click)="active=false;"
                                class="fa fa-check-square-o checkbox success-color"></i>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 text-center">
                    <div class="btn pointer no-radius mb-2 mx-2 secondary-bg text-white" (click)="clearData()">
                        Clear Form
                    </div>
                    <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="saveSticker()">
                        Save & Close
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade p-0" id="assign-sticker" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered add-session-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header text-center">
                <div class="d-flex w-100">
                    <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
                    <div class="modal-title w-100">Assign Sticker</div>
                </div>
            </div>
            <div class="modal-body">
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Sticker:</div>
                    <div class="col-sm-8">

                        <ng-select [items]="stickerDropdownList" placeholder="Select Sticker" bindValue="id"
                                    bindLabel="title" [clearable]="false" [(ngModel)]="assignStickersId">
                        </ng-select>
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Clients:</div>
                    <div class="col-sm-8">

                        <ng-select [multiple]="true" placeholder="Select Clients"
                                    [(ngModel)]="assignStickersClients">

                            <ng-option *ngFor="let client of clientList" [value]="client.id">{{client.first_name}} {{client.last_name}}</ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4 pr-0">Notification Message:</div>
                    <div class="col-sm-8">
                        <textarea class="form-control no-radius" placeholder="Enter Notification Message" [(ngModel)]="notificationMsg"></textarea>
                    </div>
                </div>
                <div class="col-lg-12 text-center">
                    <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="assignSticker()">
                        Assign Sticker
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-delete (onConfirm)="deleteSticker()"></app-delete>
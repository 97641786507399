<div class="container-fluid main-content">
    <div class="row">
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
                <h4 class="my-2">Bookings</h4>
                <img src="assets/images/setting.png" alt="images" class="setting-btn" data-toggle="modal"
                    data-target="#booking-settings" />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-8">
            <div class="d-flex align-items-center justify-content-between pb-3">
                <div class="m-0">
                    {{currentDateObj | date:'EEEE, dd/MM/yyyy'}}
                    {{selectedSession.start_time | date:'hh:mm aa'}}
                    - {{selectedSession.end_time | date:'hh:mm aa'}}
                </div>
                <div class="d-flex">
                    <div class="nav-btn ml-1 pointer" (click)="goToSession(-1)">
                        <i class="fa fa-chevron-left left"></i>
                    </div>
                    <div class="nav-btn ml-1 pointer" (click)="goToSession(1)">
                        <i class="fa fa-chevron-right right"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-6" *ngIf="filterdClientList.length==0">
            No Clients Booked
        </div>
        <div class="col-lg-3 col-md-3 col-sm-6" *ngFor="let item of filterdClientList">
            <div class="booking-card">
                <div class="row m-0 py-1 primary-bg pointer" [class.primary-bg]="item.status"
                    [class.secondary-bg]="!item.status" (click)="navigateToClientProfile(item, 'Profile')">
                    <div class="col-12 p-0 d-flex align-items-center">
                        <img class="profile-img mx-2" [src]="(item.avatar) ? baseURL + item.avatar: 'assets/avatar.png'"
                            onerror="this.onerror=null;this.src='assets/avatar.png';" />
                        <div class="p-0 px-2">
                            <div class="text-white">{{item.first_name + ' ' + item.last_name}}</div>
                        </div>
                    </div>
                </div>
                <div class="row p-3">
                    <div class="col-12 pb-3" (click)="navigateToClientProfile(item, 'FitnessLog')">
                        <div class="d-flex align-items-center justify-content-between pointer">
                            <div>Fitness Log</div>
                            <img src="assets/images/right-blue.png" alt="images" class="icon pr-1" />
                        </div>
                    </div>
                    <div class="col-12 pb-3" (click)="navigateToClientProfile(item, 'InjuryLog')">
                        <div class="d-flex align-items-center justify-content-between pointer">
                            <div>Injury Log</div>
                            <img *ngIf="item.injury==0" src="assets/images/smile.png" alt="images" class="icon" />
                            <img *ngIf="item.injury!=0" src="assets/images/warning.png" alt="images" class="icon" />
                        </div>
                    </div>
                    <div class="col-12" (click)="navigateToClientProfile(item, 'Memberships')">
                        <div class="d-flex align-items-center justify-content-between pointer">
                            <div>Payments</div>
                            <div *ngIf="item.overduecount>0" class="dollar-sign red">$</div>
                            <div *ngIf="item.duecount>0 && item.overduecount==0" class="dollar-sign yellow">$</div>
                            <div *ngIf="item.overduecount==0 && item.duecount==0" class="dollar-sign green">$</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row pt-5">
        <div class="col-lg-3 d-none d-lg-block">
            <div class="d-flex align-items-center justify-content-between search-box">
                <input type="text" class="form-control" placeholder="Search..." autocomplete="off"
                    [(ngModel)]="searchText" (input)="getBookingList(1)">
                <div class="icon px-2"><i class="fa fa-search"></i></div>
            </div>
        </div>
        <div class="col-lg-9 text-right align-self-center">
            <ul class="table-feature">
                <li>
                    <div class="pagination" *ngIf="pager.pages && pager.pages.length">
                        <ul class="page-number">
                            <li (click)="setPage(pager.currentPage - 1)" [class.disabled]="pager.currentPage === 1">
                                <i class="fa fa-angle-left"></i>
                            </li>
                            <li *ngFor="let page of pager.pages" (click)="setPage(page)"
                                [class.active]="pager.currentPage === page">
                                {{ page }}
                            </li>
                            <li (click)="setPage(pager.currentPage + 1)"
                                [class.disabled]="pager.currentPage === pager.totalPages">
                                <i class="fa fa-angle-right"></i>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <div class="sort-by">
                        <select [(ngModel)]="pageSize" (change)="getBookingList(1)">
                            <option [value]="50">50</option>
                            <option [value]="100">100</option>
                            <option [value]="150">150</option>
                        </select>
                    </div>
                </li>
                <!--
                <li>
                    <div class="sort-by">
                        <select [(ngModel)]="orderBy" (change)="getBookingList(1)">
                            <option [value]="''">Sort by</option>
                            <option [value]="'asc'">A To Z</option>
                            <option [value]="'desc'">Z To A</option>
                        </select>
                    </div>
                </li>
                -->
                <li>
                    <div class="view-btn pointer">
                        <div [class.active]="viewFuture" (click)="getBookingList(1)">View Future</div>
                        <div>/</div>
                        <div [class.active]="!viewFuture" (click)="openFilterDialog()">View Filter</div>
                    </div>
                </li>
                <li>
                    <div class="primary-btn pointer" (click)="openAddUpdateBookingModal()">
                        Book client <img src="assets/images/add-white.png" alt="images">
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="table-responsive table-list">
                <table class="table">
                    <thead class="thead-light">
                        <tr>
                            <th>#</th>
                            <th>Client Name</th>
                            <th>Session Date</th>
                            <th>Session Time</th>
                            <th>Session Title</th>
                            <th class="text-center">Status</th>
                            <th class="text-center px-3">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of bookingList;let i=index;">
                            <td>{{((pageIndex-1)*pageSize)+(i+1)}}</td>
                            <td>{{item.first_name + ' ' + item.last_name}}</td>
                            <td>{{item.session_date | date:'E, d MMM yyyy'}}</td>
                            <td>
                                {{item.start_time | date:'hh'}}:{{item.start_time | date:'mm'}}{{item.start_time |
                                date:'aa'}}
                                -
                                {{item.end_time | date:'hh'}}:{{item.end_time | date:'mm'}}{{item.end_time | date:'aa'}}
                            </td>
                            <td>{{item.name}}</td>
                            <td>
                                <div class="text-center">
                                    <img *ngIf="item.status==0" src="assets/images/close.svg" class="image-btn pointer"
                                        alt="Cancelled" />
                                    <img *ngIf="item.status==1 || item.status==2 || item.status==3"
                                        src="assets/images/check.png" class="image-btn pointer" alt="Booked" />
                                    <img *ngIf="item.status==4" src="assets/images/status.png" class="image-btn pointer"
                                        alt="Waitlist" />
                                    <img *ngIf="item.status==5" src="assets/images/icon-late-cancellation.svg"
                                        class="image-btn pointer" alt="Late Cancel" />
                                    <img *ngIf="item.status==6" src="assets/images/no-show.png"
                                        class="image-btn pointer" alt="No Show" />
                                    <img *ngIf="item.status==7" src="assets/images/hold.png" class="image-btn pointer"
                                        alt="Hold" />
                                </div>
                            </td>
                            <td>
                                <div class="text-center">
                                    <img [hidden]="item.status!=6" src="assets/images/no-show.png"
                                        class="image-btn pointer" alt="images" (click)="openReasonDialog(item)" />
                                    <img [hidden]="item.status==6" src="assets/images/show.png"
                                        class="image-btn pointer" alt="images" (click)="openConfirmDialog(item)" />
                                    <img src="assets/images/delete.png" class="image-btn pointer pl-2" alt="images"
                                        (click)="openDeleteDialog(item)" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<!-- #region Booking Setting -->
<div class="modal fade" id="booking-settings" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered booking-settings-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="d-flex w-100">
                    <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
                    <div class="modal-title w-100 text-center">Booking Cancellation Configurations</div>
                </div>
            </div>
            <div class="modal-body px-4">
                <div *ngFor="let item of bookingConfig" class="mb-3 d-flex align-items-center justify-content-between">
                    <div class="d-flex align-self-center">{{item.title}}:</div>
                    <div class="d-flex align-self-center">
                        <ng-select class="datetime" placeholder='HH' [items]="hours" bindLabel="value" bindValue="value"
                            [clearable]="false" [(ngModel)]="item.hour">
                        </ng-select>
                        <ng-select class="datetime" placeholder='MM' [items]="minutes" bindLabel="value"
                            bindValue="value" [clearable]="false" [(ngModel)]="item.minute">
                        </ng-select>
                        <div class="pl-2 d-flex align-self-center pointer meridiem">
                            <div [class.selected]="item.meridiem=='AM'" (click)="item.meridiem='AM';">
                                AM
                            </div>
                            <div [class.selected]="item.meridiem=='PM'" (click)="item.meridiem='PM';">
                                PM
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-lg-12 text-center">
                        <div class="btn pointer no-radius mb-2 mx-2 secondary-bg text-white" data-dismiss="modal">
                            Cancel
                        </div>
                        <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white"
                            (click)="updateBookingConfiguration();">
                            Save Settings
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- #endregion -->

<app-book-client (onRefresh)="onRefresh($event)"></app-book-client>

<app-filter [title]="'Show Historical Bookings'" [btnFilterText]="'Show Booking'"
    (onFilter)="closeFilterDialog($event)"></app-filter>

<app-delete (onConfirm)="deleteBooking()"></app-delete>

<app-confirm [title]="confirmationTitle" [description]="confirmationDescription" [confirmButtonText]="okText"
    [cancelButtonText]="cancelText" (onConfirm)="noShowBooking()"></app-confirm>
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ChallengeApi,ClientApi } from 'src/app/const/app.url';
@Injectable({
  providedIn: 'root'
})
export class ChallengeService {

  constructor(private http: HttpClient) { }
  getChallengeList(body?: any) {
    return this.http.post<any>(ChallengeApi.getChallengeList, body)
  }

  createChallenge(body?: any) {
    return this.http.post<any>(ChallengeApi.createChallenge, body)
  }

  updateChallenge(body?: any) {
    return this.http.post<any>(ChallengeApi.updateChallenge, body)
  }

  deleteChallenge(body?: any) {
    return this.http.post<any>(ChallengeApi.deleteChallenge, body)
  }

  getClientList() {
    return this.http.get<any>(ClientApi.getClientListWithoutPagination)
  }

  getAllChallengeList() {
    return this.http.get<any>(ClientApi.getAll_ChallengeList)
  }

  assignChallengeToClient(body?: any) {
    return this.http.post<any>(ChallengeApi.assignChallengeToClient, body)
  }
}

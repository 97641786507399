import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BookingApi, FitnessLogApi } from 'src/app/const/app.url';

@Injectable({
  providedIn: 'root'
})
export class BookingsService {

  constructor(private http: HttpClient) { }

  getTodayBookingList(body?: any) {
    return this.http.post<any>(BookingApi.getTodayBookingList, body)
  }

  getBookingList(body?: any) {
    return this.http.post<any>(BookingApi.getBookingList, body)
  }

  addBooking(body?: any) {
    return this.http.post<any>(BookingApi.addBooking, body)
  }

  updateBooking(body?: any) {
    return this.http.post<any>(BookingApi.updateBooking, body)
  }

  getBookingHistory(body?: any) {
    return this.http.post<any>(BookingApi.getBookingHistory, body)
  }

  getAuditBookingList(body?: any) {
    return this.http.post<any>(BookingApi.getAuditBookingList, body)
  }

  getAuditBookingHistory(body?: any) {
    return this.http.post<any>(BookingApi.getAuditBookingHistory, body)
  }

  getBookingConfiguration() {
    return this.http.get<any>(BookingApi.getBookingConfiguration)
  }

  addBookingConfiguration(body?: any) {
    return this.http.post<any>(BookingApi.addBookingConfiguration, body)
  }

  updateBookingConfiguration(body?: any) {
    return this.http.post<any>(BookingApi.updateBookingConfiguration, body)
  }

  getClientFitnessLogList(body?: any) {
    return this.http.post<any>(FitnessLogApi.getClientFitnessLogList, body)
  }

  getClientExerciseFitnessLogList(body?: any) {
    return this.http.post<any>(FitnessLogApi.getClientExerciseFitnessLogList, body)
  }

  addFitnessLog(body?: any) {
    return this.http.post<any>(FitnessLogApi.addFitnessLog, body)
  }

  updateFitnessLog(body?: any) {
    return this.http.post<any>(FitnessLogApi.updateFitnessLog, body)
  }

  deleteFitnessLog(body?: any) {
    return this.http.post<any>(FitnessLogApi.deleteFitnessLog, body)
  }

  getBookingCount(body?: any) {
    return this.http.post<any>(BookingApi.getBookingCount, body)
  }
}

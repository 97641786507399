<div class="container-fluid main-content d-flex align-items-center justify-content-center">
  <div class="h-100 w-100 row d-flex align-items-center justify-content-center">
    <div class="col-lg-3 p-0">
      <div class="row pb-5">
        <img src="assets/logo.png" class="logo">
      </div>
      <div class="row p-5 text-center">
        Please wait while you are redirected to 3-D secure authentication.
      </div>
      <div class="row">
        <div class="col text-center">
          {{progress}} %
          <div class="progress-bar">
            <div class="progress-bar-indicator" [ngStyle]="{'width':progress + '%'}"></div>
          </div>
        </div>
      </div>
      <!-- <h3>Credit Card</h3>
            <p>Please enter your payment details:</p>
            <div class="row mb-3">
                <div class="col-12">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fa fa-credit-card"></i></span>
                        </div>
                        <input type="text" id="card-number" class="input-field form-control" title="card number"
                            aria-label="enter your card number" placeholder="Card number" value="" tabindex="1"
                            readonly>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fa fa-user"></i></span>
                        </div>
                        <input type="text" id="cardholder-name" class="input-field form-control" title="cardholder name"
                            aria-label="enter name on card" placeholder="Card holder name" value="" tabindex="2"
                            readonly>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12">
                    <div class="input-group ">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                        </div>
                        <input type="text" id="expiry-month" class="input-field form-control" title="expiry month"
                            aria-label="two digit expiry month" placeholder="Two digit expiry month" value=""
                            tabindex="3" readonly>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                        </div>
                        <input type="text" id="expiry-year" class="input-field form-control" title="expiry year"
                            aria-label="two digit expiry year" placeholder="Two digit expiry year" value="" tabindex="4"
                            readonly>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fa fa-lock"></i></span>
                        </div>
                        <input type="text" id="security-code" class="input-field form-control" title="security code"
                            aria-label="three digit CCV security code" placeholder="Three digit CCV security code"
                            value="" tabindex="5" readonly>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="d-flex align-items-center mb-3">
                        <i *ngIf="!automatic" (click)="automatic=true;"
                            class="fa fa-square-o checkbox secondary-color inactive"></i>
                        <i *ngIf="automatic" (click)="automatic=false;"
                            class="fa fa-check-square-o checkbox success-color"></i>
                        <div class="pl-2"> Save my card for future payments </div>
                    </div>

                </div>
            </div>
            <div class="row mb-3 align-items-center">
                <div class="col-sm-12 text-center">
                    <button id="payButton" class="btn pointer no-radius mb-2 mx-2 primary-bg text-white"
                        (click)="pay();">Pay Now</button>
                </div>
            </div> -->
    </div>
  </div>
</div>

<div class="container-fluid main-content">
    <div class="row">
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
                <h4 class="my-2">Memberships</h4>
            </div>
        </div>
    </div>
    <div class="row pt-5">
        <div class="col-lg-2 d-none d-lg-block">
            <div class="d-flex align-items-center justify-content-between search-box">
                <input type="text" class="form-control" placeholder="Search..." autocomplete="off"
                    [(ngModel)]="searchText" (input)="getMembershipList(1)">
                <div class="icon px-2"><i class="fa fa-search"></i></div>
            </div>
        </div>
        <div class="col-lg-10 text-right align-self-center">
            <ul class="table-feature">
                <li>
                    <div class="view-btn pointer">
                        <div [class.active]="viewActive" (click)="filterMembershipList(true)">View Active</div>
                        <div>/</div>
                        <div [class.active]="!viewActive" (click)="filterMembershipList(false)">View All</div>
                    </div>
                </li>
                <li>
                    <div class="pagination" *ngIf="pager.pages && pager.pages.length">
                        <ul class="page-number">

                            <li (click)="setPage(pager.currentPage - 1)" [class.disabled]="pager.currentPage === 1">
                                <i class="fa fa-angle-left"></i>
                            </li>
                            <li *ngFor="let page of pager.pages" (click)="setPage(page)"
                                [class.active]="pager.currentPage === page">
                                {{ page }}
                            </li>
                            <li (click)="setPage(pager.currentPage + 1)"
                                [class.disabled]="pager.currentPage === pager.totalPages">
                                <i class="fa fa-angle-right"></i>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <div class="sort-by">
                        <select [(ngModel)]="pageSize" (change)="getMembershipList(1)">
                            <option [value]="50">50/Pages</option>
                            <option [value]="100">100/Pages</option>
                            <option [value]="150">150/Pages</option>
                        </select>
                    </div>
                </li>
                <li>
                    <div class="sort-by">
                        <select [(ngModel)]="orderBy" (change)="getMembershipList(1)">
                            <option [value]="''">Sort by</option>
                            <option [value]="'asc'">A To Z</option>
                            <option [value]="'desc'">Z To A</option>
                        </select>
                    </div>
                </li>
                <li>
                    <div class="primary-btn pointer" (click)="openAddUpdateMembershipModal(1,null)">
                        Add Membership <img src="assets/images/add-white.png" alt="images">
                    </div>
                </li>
                <li style="margin-left: -40px;">
                    <img src="assets/images/setting.png" alt="images" class="setting-btn" data-toggle="modal"
                        data-target="#booking-settings" (click)="openAddGlobalConfig()" style="width: 35%;" />

                </li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="table-responsive table-list">
                <table class="table">
                    <thead class="thead-light">
                        <tr>
                            <th>Membership Reference</th>
                            <th>Membership Title</th>
                            <th>Description</th>
                            <th>Type</th>
                            <th>Duration</th>
                            <th>Total Fee</th>
                            <th class="text-center">Active</th>
                            <th class="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of filteredMembershipList">
                            <td>{{item.reference}}</td>
                            <td>{{item.package_name}}</td>
                            <td>{{item.description}}</td>
                            <td>{{item.membership_type}}</td>
                            <td>{{item.duration}}</td>
                            <td>{{item.total_fee}}</td>
                            <td>
                                <div class="text-center">
                                    <img *ngIf="item.active" src="assets/images/check-square.png"
                                        class="image-btn pointer" alt="images" />
                                </div>
                            </td>
                            <td>
                                <div class="text-center">
                                    <img src="assets/images/edit.png" class="image-btn pointer" alt="images"
                                        (click)="openAddUpdateMembershipModal(0,item)" />
                                    <img src="assets/images/delete.png" class="image-btn pointer pl-2" alt="images"
                                        (click)="openDeleteDialog(item)" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="add-membership" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered add-membership-dialog" role="document" style="
    max-width: 1000px;">
        <div class="modal-content">
            <div class="modal-header text-center">
                <div class="d-flex w-100">
                    <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
                    <div class="modal-title w-100">{{(isCreate)?'Add':'Update'}} Membership</div>
                </div>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">Title:</div>
                            <div class="col-sm-8">
                                <input type="text" class="form-control no-radius" placeholder="Enter Membership Title"
                                    [(ngModel)]="title">
                            </div>
                        </div>

                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4 pr-0">Reference:</div>
                            <div class="col-sm-8">
                                <input type="text" class="form-control no-radius"
                                    placeholder="Enter Membership Reference" [(ngModel)]="reference">
                            </div>
                        </div>
                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4 pr-0">Description:</div>
                            <div class="col-sm-8">
                                <input type="text" class="form-control no-radius" placeholder="Enter description"
                                    [(ngModel)]="description">
                            </div>
                        </div>

                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4 pr-0">Type:</div>
                            <div class="col-sm-8">
                                <ng-select [items]="membershipTypeList" placeholder="Select Membership type"
                                    bindValue="id" bindLabel="title" [clearable]="false" [(ngModel)]="membershipType">
                                </ng-select>
                            </div>
                        </div>

                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">Locality:</div>
                            <div class="col-sm-8">
                                <ng-select [items]="membershipLocalityList" placeholder="Select Locality" bindValue="id"
                                    bindLabel="title" [clearable]="false" [(ngModel)]="locality">
                                </ng-select>
                            </div>
                        </div>

                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">Start Date:</div>
                            <div class="col-sm-8">
                                <input type="date" class="form-control no-radius" placeholder="Enter Start Date"
                                    [(ngModel)]="startDate">
                            </div>
                        </div>

                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">End Date:</div>
                            <div class="col-sm-8">
                                <input type="date" class="form-control no-radius" placeholder="Enter End Date"
                                    [(ngModel)]="endDate">
                            </div>
                        </div>

                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">Duration:</div>
                            <div class="col-sm-8">
                                <input type="text" class="form-control no-radius" placeholder="Enter Duration"
                                    [(ngModel)]="duration">
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">

                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">Session Type:</div>
                            <div class="col-sm-8">
                                <ng-select [multiple]="true" [items]="sessionTypeList" placeholder="Select session type"
                                    bindValue="id" bindLabel="title" [clearable]="false" [(ngModel)]="sessionType">
                                </ng-select>
                            </div>
                        </div>
                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">Credits:</div>
                            <div class="col-sm-8">
                                <input type="text" class="form-control no-radius" placeholder="Enter Membership Credits"
                                    [(ngModel)]="credits">
                            </div>
                        </div>

                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4 pr-0">Total Fee:</div>
                            <div class="col-sm-8">
                                <input type="text" class="form-control no-radius" placeholder="Enter Total Fee"
                                    [(ngModel)]="totalfee">
                            </div>
                        </div>

                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">First Month Fee:</div>
                            <div class="col-sm-8">
                                <input type="text" class="form-control no-radius" placeholder="Enter First Month Fee"
                                    [(ngModel)]="firstMonthFee">
                            </div>
                        </div>

                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">Subsequent Fees:</div>
                            <div class="col-sm-8">
                                <input type="text" class="form-control no-radius" placeholder="Enter Subsequent Fees"
                                    [(ngModel)]="subsequentFees">
                            </div>
                        </div>

                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">Client Group:</div>
                            <div class="col-sm-8">
                                <ng-select [multiple]="true" [items]="groupList" placeholder="Select Client Group"
                                    bindValue="id" bindLabel="name" [clearable]="false" [(ngModel)]="clientGroup">
                                </ng-select>
                            </div>
                        </div>

                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">Active:</div>
                            <div class="col-sm-8">
                                <div class="d-flex align-items-center">
                                    <i *ngIf="!active" (click)="active=true;"
                                        class="fa fa-square-o checkbox secondary-color inactive"></i>
                                    <i *ngIf="active" (click)="active=false;"
                                        class="fa fa-check-square-o checkbox success-color"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 text-center">
                        <div class="btn pointer no-radius mb-2 mx-2 secondary-bg text-white" (click)="clearData()">
                            Clear Form
                        </div>
                        <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="saveMembership()">
                            Save Membership
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="add-globalconfig" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered add-membership-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header text-center">
                <div class="d-flex w-100">
                    <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
                    <div class="modal-title w-100">Membership Settings</div>
                </div>
            </div>
            <div class="modal-body">

                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Recurring tolerance end:</div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control no-radius" placeholder="Enter recurring tolerance end"
                            [(ngModel)]="recurring_tolerance_end">
                    </div>
                </div>

                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Recurring tolerance start:</div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control no-radius" placeholder="Enter recurring tolerance start"
                            [(ngModel)]="recurring_tolerance_start">
                    </div>
                </div>
                <!--
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Recurring tolerance renew:</div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control no-radius" placeholder="Enter recurring tolerance renew"
                            [(ngModel)]="recurring_tolerance_renew">
                    </div>
                </div>
                -->
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Tolerance grace:</div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control no-radius" placeholder="Enter tolerance grace"
                            [(ngModel)]="tolerance_grace">
                    </div>
                </div>

                <div class="col-lg-12 text-center">
                    <div class="btn pointer no-radius mb-2 mx-2 secondary-bg text-white" (click)="clearGlobalConfig()">
                        Clear Form
                    </div>
                    <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white"
                        (click)="saveMembershipSetting()">
                        Save Settings
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-delete (onConfirm)="deleteMembership()"></app-delete>
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ExercisesService } from './exercises.service';
import { PagerService } from 'src/app/shared/pager.service';
import { environment } from 'src/environments/environment';
import { EquipmentsService } from '../equipments/equipments.service';
import { LoaderService } from 'src/app/shared/loader.service';
import { WorkoutService } from '../workout/workout.service';
declare var $: any;

@Component({
  selector: 'app-exercises',
  templateUrl: './exercises.component.html',
  styleUrls: ['./exercises.component.scss']
})
export class ExercisesComponent implements OnInit {

  requestMade = false;
  isCreate = false;

  currentDateObj = null;

  searchText = '';
  viewActive = true;
  iniFlag: any = 1;
  pager: any = {};
  rowCount = 0;
  pageSize = 50;
  pageIndex = 1;
  orderBy = 'asc';

  exerciseList = null;
  filteredExerciseList = null;

  exerciseTypeList = [
    { id: 1, name: 'Weight and Reps' },
    { id: 2, name: 'Reps Only' },
    { id: 3, name: 'Cardio' },
    { id: 4, name: 'Time Only' },
    { id: 5, name: 'Reps and Interval/Duration' },
  ];

  exerciseId = null;
  title = null;
  muscleGroup = null;
  movementType = null;
  exerciseType = null;
  description = null;
  imageURL = null;
  videoURL = null;
  active = null;
  clientFeedback = null;
  cues = null;

  fileInput = null;
  fileBase64 = null;
  fileExtension = null;

  deleteItem = null;
  equipmentList = [];
  equipments = null;

  alternateMovements: any;
  latExercise = null;
  regExercise = null;
  proExercise = null;

  alternateExerciseList = [];

  constructor(
    private toastr: ToastrService,
    private pagerService: PagerService,
    private exercisesService: ExercisesService,
    private equipmentsService: EquipmentsService,
    private loader: LoaderService,
    private workoutService: WorkoutService
  ) { }

  ngOnInit(): void {
    this.clearData();
    this.getExerciseList(1);
    this.getAllEquipment();
  }

  getAllEquipment() {
    this.equipmentsService.getEquipmentList().subscribe((response) => {
      this.equipmentList = response.data;
    })
  }

  getAllExercise(exercise_id?: number) {
    this.loader.show();
    this.workoutService.getAllExerciseList().subscribe(
      (response) => {
        if (response.status) {
          if (exercise_id) {
            this.alternateExerciseList = response.data.filter((exercise: any) => exercise.id !== exercise_id);
          } else {
            this.alternateExerciseList = response.data;
          }
        } else {
          this.alternateExerciseList = [];
          this.toastr.error(response.message);
        }
        this.loader.hide();
      },
      (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  getEquipmentName(equipmentId) {
    const equipment = this.equipmentList.find(eq => eq.id === equipmentId);
    return equipment ? equipment.name : '';
  }

  setPage(page: number) {
    if (page < 1) { return; }
    if (page > this.pager.totalPages) { return; }

    this.pageIndex = page;
    this.pager = this.pagerService.getPager(this.rowCount, this.pageSize, this.pageIndex);
    if (this.iniFlag === 0) {
      this.getExerciseList(0);
    }
  }

  getExerciseList(item) {
    this.loader.show();
    if (item === 1) {
      this.pageIndex = 1;
      this.iniFlag = 1;
    }
    var formData: FormData = new FormData();
    formData.append('searchname', this.searchText);
    formData.append('page_no', this.pageIndex.toString());
    formData.append('page_size', this.pageSize.toString());
    formData.append('order_by', this.orderBy.toString());
    this.exercisesService.getExerciseList(formData).subscribe(
      (response) => {
        if (response.data) {
          this.exerciseList = response.data;
          this.rowCount = response.totalrow;
          if (this.iniFlag === 1) {
            this.setPage(1);
            this.iniFlag = 0;
          }
          this.filterExerciseList(this.viewActive)
        } else {
          this.exerciseList = [];
          this.filteredExerciseList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  filterExerciseList(view): void {
    this.viewActive = view;
    this.filteredExerciseList = [];
    this.filteredExerciseList = (this.viewActive) ? this.exerciseList.filter((e) => e.active) : this.exerciseList;
    this.sortExerciseList();
  }

  sortExerciseList() {
    if (this.orderBy == 'asc') {
      this.filteredExerciseList.sort((a, b) => {
        if (a.title.toLowerCase() > b.title.toLowerCase()) { return 1; }
        if (a.title.toLowerCase() < b.title.toLowerCase()) { return -1; }
      });
    } else {
      this.filteredExerciseList.sort((a, b) => {
        if (a.title.toLowerCase() > b.title.toLowerCase()) { return -1; }
        if (a.title.toLowerCase() < b.title.toLowerCase()) { return 1; }
      });
    }
  }

  openImageLink(item) {
    window.open(environment.BASE_URL + item.image_url, '_blank');
  }

  openVideoLink(item) {
    window.open(item.video_url, '_blank');
  }

  openAddUpdateExerciseModal(isCreate, data) {
    this.clearData();
    this.isCreate = isCreate;
    if (!this.isCreate) {
      this.exerciseId = data.id;
      this.title = data.title;
      this.muscleGroup = data.muscle_group;
      this.movementType = data.movement_type;
      this.exerciseType = data.exercise_type;
      this.description = data.description;
      this.imageURL = data.image_url;
      this.videoURL = data.video_url;
      this.active = data.active;
      this.equipments = data.equipment_ids;
      this.clientFeedback = data.client_feedback;
      this.cues = data.cues;
      this.regExercise = data.alternates ? data.alternates.filter(alt => alt.alternate_type === 'reg').map(alt => alt.exercise_alternate_id) : '';
      this.latExercise = data.alternates ? data.alternates.filter(alt => alt.alternate_type === 'lat').map(alt => alt.exercise_alternate_id) : '';
      this.proExercise = data.alternates ? data.alternates.filter(alt => alt.alternate_type === 'pro').map(alt => alt.exercise_alternate_id) : '';
      this.getAllExercise(this.exerciseId);
    } else {
      this.getAllExercise();
    }
    $('#add-exercise').modal('show');
  }

  clearData(): void {
    this.exerciseId = null;
    this.title = null;
    this.muscleGroup = null;
    this.movementType = null;
    this.exerciseType = null;
    this.description = null;
    this.imageURL = null;
    this.videoURL = null;
    this.active = null;
    this.equipments = null;
    this.clientFeedback = null;
    this.cues = null;
    this.fileInput = null;
    this.regExercise = null;
    this.latExercise = null;
    this.proExercise = null;
  }

  onFileChange($event) {
    this.fileInput = $event.target.files[0];
    this.fileExtension = '.' + this.fileInput.name.split('.').pop();
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      this.fileBase64 = myReader.result;
    }
    myReader.readAsDataURL(this.fileInput);
  }

  createAlternateArray(): { alternate_id: number, alternate_type: string }[] {
    const alternates = [];

    if (this.regExercise) {
      this.regExercise.forEach(id => {
        alternates.push({ alternate_id: id, alternate_type: 'reg' });
      });
    }

    if (this.latExercise) {
      this.latExercise.forEach(id => {
        alternates.push({ alternate_id: id, alternate_type: 'lat' });
      });
    }

    if (this.proExercise) {
      this.proExercise.forEach(id => {
        alternates.push({ alternate_id: id, alternate_type: 'pro' });
      });
    }

    return alternates;
  }

  saveExercise() {
    if (!this.title) {
      this.toastr.error('Please enter title.');
      return;
    }
    if (!this.muscleGroup) {
      this.toastr.error('Please enter muscle group.');
      return;
    }
    if (!this.movementType) {
      this.toastr.error('Please enter movement type.');
      return;
    }
    if (!this.exerciseType) {
      this.toastr.error('Please select exercise type.');
      return;
    }
    var formData: FormData = new FormData();
    formData.append('title', this.title);
    formData.append('muscle_group', this.muscleGroup);
    formData.append('movement_type', this.movementType);
    formData.append('exercise_type', this.exerciseType);
    formData.append('description', (this.description) ? this.description : '');
    formData.append('video_url', (this.videoURL) ? this.videoURL : '');
    formData.append('active', (this.active) ? '1' : '0');
    if (this.equipments && this.equipments.length) {
      formData.append('equipment_ids', JSON.stringify(this.equipments));
    }
    formData.append('client_feedback', (this.clientFeedback) ? '1' : '0');
    formData.append('cues', (this.cues) ? this.cues : '');
    const alternatesArray: any = this.createAlternateArray();
    formData.append('alternates', JSON.stringify(alternatesArray));
    if (this.fileInput) {
      formData.append('image_url', this.fileBase64);
      formData.append('image_extension', this.fileExtension);
    }
    if (this.isCreate) {
      this.createExercise(formData);
    } else {
      formData.append('exercise_id', this.exerciseId);
      this.updateExercise(formData);
    }
  }

  createExercise(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.exercisesService.createExercise(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-exercise').modal('hide');
            this.getExerciseList(1);
            this.getAllEquipment();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  updateExercise(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.exercisesService.updateExercise(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-exercise').modal('hide');
            this.getExerciseList(1);
            this.getAllEquipment()
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  openDeleteDialog(item) {
    this.deleteItem = item;
    $('#delete-dialog').modal('show');
  }

  deleteExercise() {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      var formData: FormData = new FormData();
      formData.append('exercise_id', this.deleteItem.id);
      this.exercisesService.deleteExercise(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            this.getExerciseList(1);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { EquipmentsService } from './equipments.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from 'src/app/shared/loader.service';
declare var $: any;

@Component({
  selector: 'app-equipments',
  templateUrl: './equipments.component.html',
  styleUrls: ['./equipments.component.scss']
})
export class EquipmentsComponent implements OnInit {

  name = null;
  image = null;
  equipmentList = [];
  isCreate = false;
  deleteItem = null;
  equipmentId = null;
  imgExtension = null;
  imageBase64 = null;
  requestMade = false;
  fileInput = null;


  constructor(
    private equipmentsService: EquipmentsService,
    private toastr: ToastrService,
    private loader: LoaderService
  ) { }

  ngOnInit(): void {
    this.getEquipmentList();
  }

  getEquipmentList() {
    this.loader.show();
    this.equipmentsService.getEquipmentList().subscribe((response) => {
      if (response.data) {
        this.equipmentList = response.data;
      } else {
        this.equipmentList = [];
      }
      this.loader.hide();
    })
  }

  openAddUpdateEquipmentModal(isCreate, data) {
    this.clearData();
    this.isCreate = isCreate;
    if (!this.isCreate) {
      this.equipmentId = data.id;
      this.name = data.name;
      this.image = data.image;
    }
    $('#add-equipment').modal('show');
  }

  clearData(): void {
    this.equipmentId = null;
    this.name = null;
    this.image = null;
    this.fileInput = null;
  }


  openImageLink(item) {
    window.open(environment.BASE_URL + item, '_blank');
  }

  openDeleteDialog(item) {
    this.deleteItem = item;
    $('#delete-dialog').modal('show');
  }

  onFileChange($event) {
    this.fileInput = $event.target.files[0];
    this.imgExtension = '.' + this.fileInput.name.split('.').pop();
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      this.imageBase64 = myReader.result;
    }
    myReader.readAsDataURL(this.fileInput);
  }

  saveEquipment() {
    if (!this.name) {
      this.toastr.error('Please enter name.');
      return;
    }

    if (this.isCreate) {
      if (!this.imageBase64) {
        this.toastr.error('Please upload Image.');
        return;
      }
    }
    var formData: FormData = new FormData();
    formData.append('name', this.name);
    if (this.imageBase64) {
      formData.append('image', this.imageBase64);
      formData.append('image_extension', this.imgExtension);
    }
    if (this.isCreate) {
      this.createEquipment(formData);
    } else {
      formData.append('id', this.equipmentId);
      this.updateEquipment(formData);
    }
  }

  createEquipment(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;

      this.equipmentsService.addOrUpdateEquipment(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-equipment').modal('hide');
            this.getEquipmentList();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide()
        }, (err) => {
          this.requestMade = false;
          this.loader.hide()
          console.log(err);
        }
      );
    }
  }

  updateEquipment(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.equipmentsService.addOrUpdateEquipment(formData).subscribe(

        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-equipment').modal('hide');
            this.getEquipmentList();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  deleteEquipment() {

    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.equipmentsService.deleteEquipment(this.deleteItem.id).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            this.getEquipmentList();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }
}
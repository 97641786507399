<div class="container-fluid main-content">
    <div class="row">
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
                <h4 class="my-2">Dashboard</h4>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-3">
                    <div class="count-cards">
                        <h2>{{counts?.activeClient}}</h2>
                        <p>ACTIVE CLIENTS </p>
                        <div class="counter-btn py-3 pointer" (click)="goToClient(0)">
                            TOTAL: {{counts?.totalClient}}
                        </div>
                        <div class="add-btn pointer">
                            <img (click)="openAddUpdateClientModal(1,null)" src="assets/images/add.png" alt="images" />
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="count-cards">
                        <h2><span class="col-lg-6 text-center"
                                [class.warning-color]="counts?.dueCount>0">{{counts?.dueCount}}</span></h2>
                        <!-- <span style="clear: both;"><br></span> -->
                        <p><span [class.warning-color]="counts?.dueCount>0">DUE PAYMENTS</span></p>
                        <div class="counter-btn py-3 pointer" [class.warning-bg]="counts?.dueCount>0"
                            (click)="goToClient(1)">
                            REVIEW
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="count-cards">
                        <h2><span class="col-lg-6 text-center"
                                [class.danger-color]="counts?.overdueCount>0">{{counts?.overdueCount}}</span></h2>
                        <!-- <span style="clear: both;"><br></span> -->
                        <p><span [class.danger-color]="counts?.overdueCount>0">OVERDUE</span></p>
                        <div class="counter-btn py-3 pointer" [class.danger-bg]="counts?.overdueCount>0"
                            (click)="goToClient(1)">
                            REVIEW
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="count-cards ">
                        <h2>{{counts?.revenue}}</h2>
                        <p>REVENUE (BHD)</p>
                        <div class="counter-btn py-3 pointer" (click)="openAddBillDialog()">
                            ADD PAYMENT
                        </div>
                        <!-- <div class="counter-btn py-3 pointer" >
                            ADD NEW PAYMENT
                        </div>-->
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="table-responsive session-list">
                        <h2>{{date | uppercase}} SESSION LIST</h2>
                        <table class="table mb-0">
                            <tbody>
                                <tr *ngFor="let session of sessionList;">
                                    <td>{{session.start_time}} - {{session.end_time}}</td>
                                    <td>{{session.name}}</td>
                                    <td>{{session.first_name}} {{session.last_name}}</td>
                                    <td>{{session.booked}}/{{session.size}} Booked</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="view-all-btn">
                            <a class="counter-btn py-3 pointer" (click)="goToBooking()">
                                VIEW ALL
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--
        <div class="col-lg-4">
            <div class="p-4 activity-timeline">
                <h2>ACTIVITY TIMELINE</h2>
                <div class="py-3 timelinelist">
                    <ul class="verti-timeline list-unstyled">
        -->
        <!-- <li class="event-list">
                            <div class="event-timeline-dot">
                                <i class="fa fa-circle-o" aria-hidden="true"></i>
                            </div>
                            <div class="media">
                                <div class="media-body">
                                    <div>
                                        <h5 class="font-size-14">{{item.timestamp | date:'dd/MM/yyyy hh:mm a'}}</h5>
                                        <p class="text-muted">{{item.message}} </p>
                                    </div>
                                </div>
                            </div>
                        </li> -->
        <!--
                        <li class="event-list">
                            <div class="event-timeline-dot">
                                <i class="fa fa-circle-o" aria-hidden="true"></i>
                            </div>
                            <div class="media">
                                <div class="media-body">
                                    <div>
                                        <h5 class="font-size-14">Register account</h5>
                                        <p class="text-muted">New common language will be more
                                            simple and regular than the existing.</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="event-list">
                            <div class="event-timeline-dot">
                                <i class="fa fa-circle-o" aria-hidden="true"></i>
                            </div>
                            <div class="media">
                                <div class="media-body">
                                    <div>
                                        <h5 class="font-size-14">Register account</h5>
                                        <p class="text-muted">New common language will be more
                                            simple and regular than the existing.</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="event-list">
                            <div class="event-timeline-dot">
                                <i class="fa fa-circle-o" aria-hidden="true"></i>
                            </div>
                            <div class="media">
                                <div class="media-body">
                                    <div>
                                        <h5 class="font-size-14">Register account</h5>
                                        <p class="text-muted">New common language will be more
                                            simple and regular than the existing.</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="text-center">
                    <div class="px-4 py-3 view-all-btn" (click)="setPage(pager.currentPage + 1)"
                        [class.secondary-bg]="pager.currentPage === pager.totalPages">
                        VIEW MORE
                    </div>
                    <div class="px-4 py-3 view-all-btn" >
                        VIEW MORE
                    </div>
                </div>
            </div>
        </div>
        -->
    </div>
</div>

<!-- #region Add Client Bill -->
<div class="modal fade p-0" id="add-bill" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered add-session-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header text-center">
                <div class="d-flex w-100">
                    <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
                    <div class="modal-title w-100">
                        Add Bill
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Clients:</div>
                    <div class="col-sm-8">
                        <ng-select placeholder="Select Clients" [hideSelected] [clearable]="false"
                            [(ngModel)]="client_id">

                            <ng-option *ngFor="let client of clientList" [value]="client.id">{{client.first_name}}
                                {{client.last_name}}</ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Due Date:</div>
                    <div class="col-sm-8">
                        <input type="date" class="form-control no-radius" placeholder="Select start date"
                            [(ngModel)]="billStartDate">
                    </div>
                </div>

                <!--
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">End Date:</div>
                    <div class="col-sm-8">
                        <input type="date" class="form-control no-radius" placeholder="Select end date"
                            [(ngModel)]="billEndDate">
                    </div>
                </div>
                -->

                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Description:</div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control no-radius" placeholder="Enter description"
                            [(ngModel)]="billDescription">
                    </div>
                </div>

                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Amount:</div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control no-radius" placeholder="Enter bill amount in BD"
                            [(ngModel)]="billAmount">
                    </div>
                </div>



                <div class="col-lg-12 text-center">
                    <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="saveBill()">
                        Save
                    </div>

                    <!-- <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="saveBill()">
                        Pay Now
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- #endregion -->
<!-- Add Client -->
<div class="modal fade" id="add-client" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered add-client-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header text-center">
                <div class="d-flex w-100">
                    <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
                    <div class="modal-title w-100">{{(isCreate)?'Add':'Update'}} Client</div>
                </div>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">First Name:</div>
                            <div class="col-sm-8">
                                <input type="text" class="form-control no-radius" placeholder="Enter first Name"
                                    [(ngModel)]="userDetails.first_name">
                            </div>
                        </div>
                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">Last Name:</div>
                            <div class="col-sm-8">
                                <input type="text" class="form-control no-radius" placeholder="Enter last Name"
                                    [(ngModel)]="userDetails.last_name">
                            </div>
                        </div>
                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">Email:</div>
                            <div class="col-sm-8">
                                <input type="text" class="form-control no-radius" placeholder="Enter email"
                                    [(ngModel)]="userDetails.email">
                            </div>
                        </div>
                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">Phone:</div>
                            <div class="col-sm-8">
                                <input type="text" class="form-control no-radius" placeholder="Enter phone number"
                                    [(ngModel)]="userDetails.phone">
                            </div>
                        </div>
                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">Date of Birth:</div>
                            <div class="col-sm-8">
                                <input type="date" class="form-control no-radius" placeholder="Select date of bith"
                                    [(ngModel)]="userDetails.dob">
                            </div>
                        </div>
                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">Gender:</div>
                            <div class="col-sm-8">
                                <ng-select [items]="genderList" placeholder="Select gender" bindValue="value"
                                    bindLabel="value" [clearable]="false" [(ngModel)]="userDetails.gender">
                                </ng-select>
                            </div>
                        </div>
                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">Client Group:</div>
                            <div class="col-sm-8">
                                <ng-select [multiple]="true" [items]="clientGroupList" placeholder="Select client group"
                                    bindValue="id" bindLabel="name" [clearable]="false"
                                    [(ngModel)]="userDetails.groups">
                                </ng-select>


                            </div>
                        </div>
                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">User Type:</div>
                            <div class="col-sm-8">
                                <ng-select [items]="userTypeList" placeholder="Select user type" bindValue="value"
                                    bindLabel="value" [clearable]="false" [(ngModel)]="userDetails.user_type">
                                </ng-select>
                            </div>
                        </div>
                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">Client Status:</div>
                            <div class="col-sm-8">
                                <ng-select [items]="statusList" placeholder="Select status" bindValue="id"
                                    bindLabel="value" [clearable]="false" [(ngModel)]="userDetails.active">
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">New Password:</div>
                            <div class="col-sm-8">
                                <input type="password" class="form-control no-radius" placeholder="Enter new password"
                                    autocomplete="new-password" [(ngModel)]="newPassword">
                            </div>
                        </div>
                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">Confirm Password:</div>
                            <div class="col-sm-8">
                                <input type="password" class="form-control no-radius"
                                    placeholder="Enter confirm password" [(ngModel)]="confirmPassword">
                            </div>
                        </div>
                        <div class="row mb-3 align-items-center">
                            <div class="col-12">Limit access to different functions of the app:</div>
                        </div>
                        <div class="row mb-3 align-items-center">
                            <div class="col-12">
                                <ul class="functions_section">
                                    <li>
                                        <span>
                                            Video Library:
                                        </span>
                                        <span>
                                            <input type="checkbox" id="Library" switch="none"
                                                [(ngModel)]="userDetails.feature_video">
                                            <label for="Library"></label>
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            Workout Program:
                                        </span>
                                        <span>
                                            <input type="checkbox" id="Workout" switch="none"
                                                [(ngModel)]="userDetails.feature_program">
                                            <label for="Workout"></label>
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            Fitness Log:
                                        </span>
                                        <span>
                                            <input type="checkbox" id="Fitness" switch="none"
                                                [(ngModel)]="userDetails.feature_log">
                                            <label for="Fitness"></label>
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            Food Diary:
                                        </span>
                                        <span>
                                            <input type="checkbox" id="Diary" switch="none"
                                                [(ngModel)]="userDetails.feature_diary">
                                            <label for="Diary"></label>
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            Measurements:
                                        </span>
                                        <span>
                                            <input type="checkbox" id="Measurements" switch="none"
                                                [(ngModel)]="userDetails.feature_measurements">
                                            <label for="Measurements"></label>
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            Habits:
                                        </span>
                                        <span>
                                            <input type="checkbox" id="Habits" switch="none"
                                                [(ngModel)]="userDetails.feature_challenges">
                                            <label for="Habits"></label>
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            Stickers:
                                        </span>
                                        <span>
                                            <input type="checkbox" id="Stickers" switch="none"
                                                [(ngModel)]="userDetails.feature_stickers">
                                            <label for="Stickers"></label>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 text-center">
                    <div class="btn pointer no-radius mb-2 mx-2 secondary-bg text-white" (click)="clearData()">
                        Clear Form
                    </div>
                    <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="saveClient()">
                        Save & Close
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
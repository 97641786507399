<div class="container-fluid main-content">
    <div class="row">
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
                <h4 class="my-2">Override Sessions List</h4>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 text-right align-self-center">
            <ul class="table-feature">
                <li>
                    <div class="view-btn pointer">
                        <div [class.active]="viewActive" (click)="filterSessionOverrideList(true)">View Active</div>
                        <div>/</div>
                        <div [class.active]="!viewActive" (click)="filterSessionOverrideList(false)">View All</div>
                    </div>
                </li>
                <li>
                    <div class="primary-btn pointer" (click)="openAddUpdateOverrideSessionModal(1,null)">
                        Create override <img src="assets/images/add-white.png" alt="images">
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="table-responsive table-list">
                <table class="table">
                    <thead class="thead-light">
                        <tr>
                            <th>Date From</th>
                            <th>Date To</th>
                            <th>Override Description</th>
                            <th class="text-center">Active</th>
                            <th class="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of filteredSessionOverrideList">
                            <td>{{item.start_date | date:'dd/MM/yyyy'}}</td>
                            <td>{{item.end_date | date:'dd/MM/yyyy'}}</td>
                            <td>{{item.description}}</td>
                            <td>
                                <div class="text-center">
                                    <img *ngIf="item.active" src="assets/images/check-square.png"
                                        class="image-btn pointer" alt="images" />
                                </div>
                            </td>
                            <td>
                                <div class="text-center">
                                    <img src="assets/images/edit.png" class="image-btn pointer" alt="images"
                                        (click)="openAddUpdateOverrideSessionModal(0,item)" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<app-add-session *ngIf="isLoad" [isCreate]="isSessionCreate" [overrideId]="overrideId" [sessionData]="sessionData"
    (onRefresh)="onRefresh($event)">
</app-add-session>

<!-- #region Override Session -->
<div class="modal fade p-0" id="override-session" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered override-session-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="d-flex w-100">
                    <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
                    <div class="modal-title w-100 text-center">{{(isCreate)?'Add':'Update'}} Override Session</div>
                </div>
            </div>
            <div class="modal-body px-4">
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-2">Start Date:</div>
                    <div class="col-sm-4">
                        <input type="date" class="form-control no-radius" placeholder="Select start date"
                            [(ngModel)]="startDate" [disabled]="!isCreate">
                    </div>
                    <div class="col-sm-2">End Date:</div>
                    <div class="col-sm-4">
                        <input type="date" class="form-control no-radius" placeholder="Select end date"
                            [(ngModel)]="endDate" [disabled]="!isCreate">
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-2">Description:</div>
                    <div class="col-sm-10">
                        <input type="text" class="form-control no-radius" placeholder="Enter description"
                            [(ngModel)]="description">
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-2">Active:</div>
                    <div class="col-sm-2">
                        <div class="d-flex align-items-center">
                            <i *ngIf="!active" (click)="active=true;"
                                class="fa fa-square-o checkbox secondary-color inactive"></i>
                            <i *ngIf="active" (click)="active=false;"
                                class="fa fa-check-square-o checkbox success-color"></i>
                        </div>
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-12 text-center">
                        <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white"
                            (click)="saveOverrideSession()">
                            {{(isCreate)?'Create':'Update'}} Override
                        </div>
                    </div>
                </div>
                <div *ngIf="!isCreate" class="row">
                    <div class="col-lg-12 text-right">
                        <ul class="table-feature">
                            <li>
                                <div class="primary-btn pointer" (click)="openAddUpdateSessionModal(1,null)">
                                    Add session <img src="assets/images/add-white.png">
                                </div>
                            </li>
                            <li>
                                <div class="secondry-btn pointer" (click)="deactiveAllOverrideSession()">
                                    Deactive All
                                </div>
                            </li>
                            <li>
                                <div class="secondry-btn pointer" (click)="deleteAllOverrideSession()">
                                    Delete All
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div *ngIf="!isCreate" class="row">
                    <div class="col-lg-12">
                        <div class="table-responsive table-list max-height-300">
                            <table class="table">
                                <thead class="thead-light">
                                    <tr>
                                        <th>Session Time</th>
                                        <th>Session Name</th>
                                        <th>Size</th>
                                        <th>Days of Week</th>
                                        <!-- <th>Trainer</th> -->
                                        <th class="text-center">Active</th>
                                        <th class="text-center px-3">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of sessionList">
                                        <td>
                                            {{'1111-11-11 ' + item.start_time | date:'h'}}:{{'1111-11-11 ' + item.start_time | date:'mm'}}
                                            {{'1111-11-11 ' + item.start_time | date:'aa'}}
                                            -
                                            {{'1111-11-11 ' + item.end_time | date:'h'}}:{{'1111-11-11 ' + item.end_time | date:'mm'}}
                                            {{'1111-11-11 ' + item.end_time | date:'aa'}}
                                        </td>
                                        <td>{{item.name}}</td>
                                        <td>{{item.size}}</td>
                                        <td>{{item.daysOfWeek}}</td>
                                        <td>
                                            <div class="text-center">
                                                <img *ngIf="item.active" src="assets/images/check-square.png"
                                                    class="image-btn pointer" />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="text-center">
                                                <img src="assets/images/edit.png" class="image-btn pointer"
                                                    (click)="openAddUpdateSessionModal(0,item)" />
                                                <img src="assets/images/delete.png" class="image-btn pointer pl-2"
                                                    (click)="openDeleteDialog('session',item)" />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- #endregion -->

<app-delete (onConfirm)="deleteRecord()"></app-delete>

<div class="container-fluid main-content">
  <div class="row pt-2">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="my-2">{{clientDetails.user_type}} / {{clientDetails.first_name}} {{clientDetails.last_name}}
        </h4>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <ul class="nav nav-pills nav-fill my-2" role="tablist">
        <li class="nav-item">
          <a class="nav-link" [class.active]="selectedTab=='Profile'" id="Profile-tab" data-toggle="tab" href="#Profile"
            role="tab" aria-controls="Profile" aria-selected="true">Profile</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [class.active]="selectedTab=='Settings'" id="Settings-tab" data-toggle="tab"
            href="#Settings" role="tab" aria-controls="Settings" aria-selected="true">Settings</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [class.active]="selectedTab=='Memberships'" id="Memberships-tab" data-toggle="tab"
            href="#Memberships" role="tab" aria-controls="Memberships" aria-selected="false">Memberships</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [class.active]="selectedTab=='Bookings'" id="Bookings-tab" data-toggle="tab"
            href="#Bookings" role="tab" aria-controls="Bookings" aria-selected="false">Bookings</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [class.active]="selectedTab=='FitnessLog'" id="FitnessLog-tab" data-toggle="tab"
            href="#FitnessLog" role="tab" aria-controls="FitnessLog" aria-selected="false">Fitness Log</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [class.active]="selectedTab=='FoodDiary'" id="FoodDiary-tab" data-toggle="tab"
            href="#FoodDiary" role="tab" aria-controls="FoodDiary" aria-selected="false">Food Diary</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [class.active]="selectedTab=='Measurements'" id="Measurements-tab" data-toggle="tab"
            href="#Measurements" role="tab" aria-controls="Measurements" aria-selected="false">Measurements</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [class.active]="selectedTab=='Workout'" id="Workout-tab" data-toggle="tab" href="#Workout"
            role="tab" aria-controls="Workout" aria-selected="false">Workout Program</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [class.active]="selectedTab=='Equipments'" id="Equipments-tab" data-toggle="tab"
            href="#Equipments" role="tab" aria-controls="Equipments" aria-selected="false">Equipments</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [class.active]="selectedTab=='Habits'" id="Habits-tab" data-toggle="tab" href="#Habits"
            role="tab" aria-controls="Habits" aria-selected="false"> Habits </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [class.active]="selectedTab=='Stickers'" id="Stickers-tab" data-toggle="tab"
            href="#Stickers" role="tab" aria-controls="Stickers" aria-selected="false"> Stickers </a>
        </li>
      </ul>
      <div class="tab-content">
        <div class="tab-pane fade" [ngClass]="selectedTab=='Profile'?'active show':''" id="Profile" role="tabpanel"
          aria-labelledby="Profile-tab">
          <div class="row py-3">
            <div class="col-lg-12">
              <div class="mb-3 d-flex align-items-center justify-content-between">
                <h3 class="m-0">Personal Details</h3>
                <div class="btn pointer no-radius primary-bg text-white" (click)="saveClient();">
                  Save Changes
                </div>
              </div>
              <div class="row mb-3 align-items-center">
                <div class="col-sm-4">First Name:</div>
                <div class="col-sm-8">
                  <input type="text" class="form-control no-radius" placeholder="Enter first name"
                    [(ngModel)]="clientDetails.first_name">
                </div>
              </div>
              <div class="row mb-3 align-items-center">
                <div class="col-sm-4">Last Name:</div>
                <div class="col-sm-8">
                  <input type="text" class="form-control no-radius" placeholder="Enter last name"
                    [(ngModel)]="clientDetails.last_name">
                </div>
              </div>
              <div class="row mb-3 align-items-center">
                <div class="col-sm-4">Email:</div>
                <div class="col-sm-8">
                  <input type="text" class="form-control no-radius" placeholder="Enter email"
                    [(ngModel)]="clientDetails.email">
                </div>
              </div>
              <div class="row mb-3 align-items-center">
                <div class="col-sm-4">Phone:</div>
                <div class="col-sm-8">
                  <input type="text" class="form-control no-radius" placeholder="Enter phone"
                    [(ngModel)]="clientDetails.phone">
                </div>
              </div>
              <div class="row mb-3 align-items-center">
                <div class="col-sm-4">Date of Birth:</div>
                <div class="col-sm-8">
                  <input type="date" class="form-control no-radius" placeholder="Select date of bith"
                    [(ngModel)]="clientDetails.dob">
                </div>
              </div>
              <div class="row mb-3 align-items-center">
                <div class="col-sm-4">Gender:</div>
                <div class="col-sm-8">
                  <ng-select [items]="genderList" placeholder="Select gender" bindValue="value" bindLabel="value"
                    [clearable]="false" [(ngModel)]="clientDetails.gender">
                  </ng-select>
                </div>
              </div>
              <div class="row mb-3 align-items-center">
                <div class="col-sm-4">Client Group:</div>
                <div class="col-sm-8">
                  <ng-select [multiple]="true" [items]="clientGroupList" placeholder="Select client group"
                    bindValue="id" bindLabel="name" [clearable]="false" [(ngModel)]="clientDetails.groups">
                  </ng-select>

                  <!-- <ng-select [items]="clientGroupList" placeholder="Select client group" bindValue="id"
                                    bindLabel="name" [clearable]="false" [(ngModel)]="userDetails.groups">
                                </ng-select> -->
                </div>
              </div>
              <div class="row mb-3 align-items-center">
                <div class="col-sm-4">User Type:</div>
                <div class="col-sm-8">
                  <ng-select [items]="userTypeList" placeholder="Select user type" bindValue="value" bindLabel="value"
                    [clearable]="false" [(ngModel)]="clientDetails.user_type">
                  </ng-select>
                </div>
              </div>
              <div class="row mb-3 align-items-center">
                <div class="col-sm-4">Client Status:</div>
                <div class="col-sm-8">
                  <ng-select [items]="statusList" placeholder="Select status" bindValue="id" bindLabel="value"
                    [clearable]="false" [(ngModel)]="clientDetails.active">
                  </ng-select>
                </div>
              </div>
              <div class="row mb-3 align-items-center">
                <div class="col-sm-4">Height:</div>
                <div class="col-sm-8">
                  <input type="text" class="form-control no-radius" placeholder="Enter height"
                    [(ngModel)]="clientDetails.height">
                </div>
              </div>
              <div class="row mb-3 align-items-center">
                <div class="col-sm-4">Weight:</div>
                <div class="col-sm-8">
                  <input type="text" class="form-control no-radius" placeholder="Enter weight"
                    [(ngModel)]="clientDetails.weight">
                </div>
              </div>
              <div class="row mb-3 align-items-center">
                <div class="col-sm-4">Experience:</div>
                <div class="col-sm-8">
                  <ng-select [items]="experienceList" placeholder="Select experience" bindValue="id" bindLabel="value"
                    [clearable]="false" [(ngModel)]="clientDetails.experience_level">
                  </ng-select>
                </div>
              </div>
              <div class="row mb-3 align-items-center">
                <div class="col-sm-4">Health issues:</div>
                <div class="col-sm-8">
                  <textarea class="form-control no-radius" placeholder="Health issues, medications, injuries..."
                    [(ngModel)]="clientDetails.health_info">Enter health issues</textarea>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="row m-0">
                <h3 class="m-0">Client Goals</h3>
              </div>
              <div class="row mb-3 align-items-center">
                <div class="col-sm-4">Target Attendance:</div>
                <div class="col-sm-4">
                  <ng-select [items]="attendanceList" placeholder="Select target attendance per week" bindValue="id"
                    bindLabel="id" [clearable]="false" [(ngModel)]="attendance" (change)="updateAttendance()">
                  </ng-select>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  Listed in order of selection from the app:
                </div>
                <div class="col-12">
                  <ol class="goal-list">
                    <li *ngFor="let item of goalList">{{item.goals_title}}</li>
                  </ol>
                </div>
              </div>
              <div class="row pt-3">
                <div class="col-sm-12 text-right align-self-center">
                  <ul class="table-feature">
                    <li>
                      <div class="pagination" *ngIf="personalGoalPager.pages && personalGoalPager.pages.length">
                        <ul class="page-number">
                          <li (click)="setPersonalGoalPage(personalGoalPager.currentPage - 1)"
                            [class.disabled]="personalGoalPager.currentPage === 1">
                            <i class="fa fa-angle-left"></i>
                          </li>
                          <li *ngFor="let page of personalGoalPager.pages" (click)="setPersonalGoalPage(page)"
                            [class.active]="personalGoalPager.currentPage === page">
                            {{ page }}
                          </li>
                          <li (click)="setPersonalGoalPage(personalGoalPager.currentPage + 1)"
                            [class.disabled]="personalGoalPager.currentPage === personalGoalPager.totalPages">
                            <i class="fa fa-angle-right"></i>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <div class="primary-btn pointer" (click)="openAddUpdatePersonalGoalModal(1,null)">
                        Add Goal <img src="assets/images/add-white.png" alt="images">
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="col-lg-12">
                  <div class="table-responsive table-list">
                    <table class="table">
                      <thead class="thead-light">
                        <tr>
                          <th class="w-15">Date</th>
                          <th class="w-20">Title</th>
                          <th class="w-40">Description</th>
                          <th class="w-15">Achieved</th>
                          <th class="w-10 text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of personalGoalList">
                          <td>{{item.goal_date | date:'dd/MM/yyyy'}}</td>
                          <td>{{item.title}}</td>
                          <td>{{item.description}}</td>
                          <td>
                            {{(item.achieved_date=='')?'Active':item.achieved_date |
                            date:'dd/MM/yyyy'}}
                          </td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <img src="assets/images/edit.png" class="image-btn pointer" alt="images"
                                (click)="openAddUpdatePersonalGoalModal(0,item)" />
                              <img src="assets/images/delete.png" class="image-btn pointer pl-2" alt="images"
                                (click)="openDeleteDialog('client_personal_goals',item)" />
                            </div>
                          </td>
                        </tr>
                        <tr [hidden]="personalGoalList.length!=0">
                          <td colspan="5" class="text-center">No Records Found</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="row pt-3" id="clientinjury">
                <div class="col-sm-3 pr-0">
                  <h3 class="m-0">Client Injuries</h3>
                </div>
                <div class="col-sm-9 text-right align-self-center">
                  <ul class="table-feature">
                    <li>
                      <div class="pagination" *ngIf="injuryPager.pages && injuryPager.pages.length">
                        <ul class="page-number">
                          <li (click)="setInjuryPage(injuryPager.currentPage - 1)"
                            [class.disabled]="injuryPager.currentPage === 1">
                            <i class="fa fa-angle-left"></i>
                          </li>
                          <li *ngFor="let page of injuryPager.pages" (click)="setInjuryPage(page)"
                            [class.active]="injuryPager.currentPage === page">
                            {{ page }}
                          </li>
                          <li (click)="setInjuryPage(injuryPager.currentPage + 1)"
                            [class.disabled]="injuryPager.currentPage === injuryPager.totalPages">
                            <i class="fa fa-angle-right"></i>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <div class="primary-btn pointer" (click)="openAddUpdateInjuryModal(1,null)">
                        Add Injury <img src="assets/images/add-white.png" alt="images">
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="col-lg-12">
                  <div class="table-responsive table-list">
                    <table class="table">
                      <thead class="thead-light">
                        <tr>
                          <th class="w-15">Date</th>
                          <th class="w-20">Title</th>
                          <th class="w-40">Description</th>
                          <th class="w-15">Recovered</th>
                          <th class="w-10 text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of injuryList">
                          <td>{{item.injury_date | date:'dd/MM/yyyy'}}</td>
                          <td>{{item.title}}</td>
                          <td>{{item.description}}</td>
                          <td>
                            {{(item.recovered_date=='')?'Active':item.recovered_date |
                            date:'dd/MM/yyyy'}}
                          </td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <img src="assets/images/edit.png" class="image-btn pointer" alt="images"
                                (click)="openAddUpdateInjuryModal(0,item)" />
                              <img src="assets/images/delete.png" class="image-btn pointer pl-2" alt="images"
                                (click)="openDeleteDialog('client_injuries',item)" />
                            </div>
                          </td>
                        </tr>
                        <tr [hidden]="injuryList.length!=0">
                          <td colspan="5" class="text-center">No Records Found</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="row pt-3">
                <div class="col-sm-3 pr-0">
                  <h3 class="m-0">Client Notes</h3>
                </div>
                <div class="col-sm-9 text-right align-self-center">
                  <ul class="table-feature">
                    <li>
                      <div class="pagination" *ngIf="notePager.pages && notePager.pages.length">
                        <ul class="page-number">
                          <li (click)="setNotePage(notePager.currentPage - 1)"
                            [class.disabled]="notePager.currentPage === 1">
                            <i class="fa fa-angle-left"></i>
                          </li>
                          <li *ngFor="let page of notePager.pages" (click)="setNotePage(page)"
                            [class.active]="notePager.currentPage === page">
                            {{ page }}
                          </li>
                          <li (click)="setNotePage(notePager.currentPage + 1)"
                            [class.disabled]="notePager.currentPage === notePager.totalPages">
                            <i class="fa fa-angle-right"></i>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <div class="primary-btn pointer" (click)="openAddUpdateNoteModal(1,null)">
                        Add Note <img src="assets/images/add-white.png" alt="images">
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="col-lg-12">
                  <div class="table-responsive table-list">
                    <table class="table">
                      <thead class="thead-light">
                        <tr>
                          <th class="w-15">Date</th>
                          <th class="w-75">Notes</th>
                          <th class="w-10 text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of noteList">
                          <td>{{item.note_date | date:'dd/MM/yyyy'}}</td>
                          <td>{{item.note}}</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <img src="assets/images/edit.png" class="image-btn pointer" alt="images"
                                (click)="openAddUpdateNoteModal(0,item)" />
                              <img src="assets/images/delete.png" class="image-btn pointer pl-2" alt="images"
                                (click)="openDeleteDialog('client_notes',item)" />
                            </div>
                          </td>
                        </tr>
                        <tr [hidden]="noteList.length!=0">
                          <td colspan="3" class="text-center">No Records Found</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" [ngClass]="selectedTab=='Settings'?'active show':''" id="Settings" role="tabpanel"
          aria-labelledby="Settings-tab">
          <div class="row py-3">
            <div class="col-lg-6">
              <div class="mb-3 d-flex align-items-center justify-content-between">
                <h3 class="m-0">Reset Password</h3>
                <div class="btn pointer no-radius primary-bg text-white" (click)="replacePassword();">
                  Change Password
                </div>
              </div>
              <div class="row mb-3 align-items-center">
                <div class="col-sm-4">New Password:</div>
                <div class="col-sm-8">
                  <input type="password" autocomplete="new-password" class="form-control no-radius"
                    placeholder="Enter new password" [(ngModel)]="newPassword">
                </div>
              </div>
              <div class="row mb-3 align-items-center">
                <div class="col-sm-4">Confirm Password:</div>
                <div class="col-sm-8">
                  <input type="password" autocomplete="new-password" class="form-control no-radius"
                    placeholder="Enter confirm password" [(ngModel)]="confirmPassword">
                </div>
              </div>
              <div class="row mb-3 d-flex align-items-center justify-content-between">
                <h3 class="col-12 m-0">Access Rights</h3>
              </div>
              <div class="row mb-3 align-items-center">
                <div class="col-12">Limit access to different functions of the app:</div>
              </div>
              <div class="row mb-3 align-items-center">
                <div class="col-12">
                  <ul class="functions_section">
                    <li>
                      <span>
                        Video Library:
                      </span>
                      <span>
                        <input type="checkbox" id="LibraryFeature" switch="none"
                          [(ngModel)]="clientDetails.feature_video" (change)="updateFeatureAccess();">
                        <label for="LibraryFeature"></label>
                      </span>
                    </li>
                    <li>
                      <span>
                        Workout Program:
                      </span>
                      <span>
                        <input type="checkbox" id="WorkoutFeature" switch="none"
                          [(ngModel)]="clientDetails.feature_program" (change)="updateFeatureAccess();">
                        <label for="WorkoutFeature"></label>
                      </span>
                    </li>
                    <li>
                      <span>
                        Fitness Log:
                      </span>
                      <span>
                        <input type="checkbox" id="FitnessFeature" switch="none" [(ngModel)]="clientDetails.feature_log"
                          (change)="updateFeatureAccess();">
                        <label for="FitnessFeature"></label>
                      </span>
                    </li>
                    <li>
                      <span>
                        Food Diary:
                      </span>
                      <span>
                        <input type="checkbox" id="DiaryFeature" switch="none" [(ngModel)]="clientDetails.feature_diary"
                          (change)="updateFeatureAccess();">
                        <label for="DiaryFeature"></label>
                      </span>
                    </li>
                    <li>
                      <span>
                        Measurements:
                      </span>
                      <span>
                        <input type="checkbox" id="MeasurementFeature" switch="none"
                          [(ngModel)]="clientDetails.feature_measurements" (change)="updateFeatureAccess();">
                        <label for="MeasurementFeature"></label>
                      </span>
                    </li>
                    <li>
                      <span>
                        Habits:
                      </span>
                      <span>
                        <input type="checkbox" id="ChallengeFeature" switch="none"
                          [(ngModel)]="clientDetails.feature_challenges" (change)="updateFeatureAccess();">
                        <label for="ChallengeFeature"></label>
                      </span>
                    </li>
                    <li>
                      <span>
                        Stickers:
                      </span>
                      <span>
                        <input type="checkbox" id="StickerFeature" switch="none"
                          [(ngModel)]="clientDetails.feature_stickers" (change)="updateFeatureAccess();">
                        <label for="StickerFeature"></label>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="mb-3 d-flex align-items-center justify-content-between">
                <h3 class="m-0">Delete Account</h3>
                <div class="btn pointer no-radius primary-bg text-white" (click)="openDeleteDialog('client',null)">
                  Delete Client
                </div>
              </div>
              <div class="row mb-3 d-flex align-items-center justify-content-between">
                <h3 class="col-12 m-0">Notification Settings</h3>
              </div>
              <div class="row mb-3 align-items-center">
                <div class="col-12">
                  <ul class="functions_section">
                    <li>
                      <span>
                        Allow push notifications
                      </span>
                      <span>
                        <input type="checkbox" id="PushNotification" switch="none" [(ngModel)]="pushNotification"
                          (change)="changeNotificationConfig(1)">
                        <label for="PushNotification"></label>
                      </span>
                    </li>
                    <li>
                      <span>
                        General announcements
                      </span>
                      <span>
                        <input type="checkbox" id="GeneralNotification" switch="none" [(ngModel)]="generalNotification"
                          (change)="changeNotificationConfig(null)">
                        <label for="GeneralNotification"></label>
                      </span>
                    </li>
                    <li>
                      <span>
                        Booking reminder
                      </span>
                      <span>
                        <input type="checkbox" id="BookingNotification" switch="none" [(ngModel)]="bookingNotification"
                          (change)="changeNotificationConfig(null)">
                        <label for="BookingNotification"></label>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" [ngClass]="selectedTab=='Memberships'?'active show':''" id="Memberships"
          role="tabpanel" aria-labelledby="Memberships-tab">
          <div class="pt-5" *ngIf="clientDetails.client_state==1 || membershipPreTrialSessionList.length > 0 ">
            <!-- <div class="col-lg-12"> -->
            <div class="mb-3 d-flex align-items-center justify-content-between table-feature ">
              <h3 class="m-0">Pre trial 1 On 1 Sessions</h3>
              <div *ngIf="clientDetails.client_state==1" class="btn pointer no-radius primary-btn  text-white"
                (click)="openAssignOneOnOneMembershipDialog(null);">
                Assign 1 on 1 Time Memberships <img _ngcontent-cgm-c82="" src="assets/images/add-white.png"
                  alt="images">
              </div>

            </div>
            <!-- </div> -->
            <div class="table-responsive ss_client_table Memberships_table  table-list">

              <table class="table mb-0">
                <thead class="thead-light">
                  <tr>
                    <th>Locality</th>
                    <th>Start Date</th>
                    <th>Expiry Date</th>
                    <th>Start Time</th>
                    <th>Expiry Time</th>
                    <th>Description</th>
                    <th class="text-center">Action</th>
                    <th class="text-center">Active</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of membershipPreTrialSessionList">
                    <td scope="row">{{item.mType}}</td>
                    <td>{{item.start_date| date:'dd/MM/yyyy'}}</td>
                    <td>{{item.end_date| date:'dd/MM/yyyy'}}</td>
                    <td>{{item.start_time}}</td>
                    <td>{{item.end_time}}</td>
                    <td>{{item.description}}</td>
                    <td>
                      <div class="text-center">
                        <img src="assets/images/edit.png" class="image-btn pointer pl-2" alt="images"
                          (click)="openAssignOneOnOneMembershipDialog(item)" />

                        <img src="assets/images/delete.png" class="image-btn pointer pl-2" alt="images"
                          (click)="openDeleteDialog('client_pre_trial_session',item)" />

                      </div>
                    </td>
                    <td>
                      <div class="text-center">
                        <img *ngIf="item.active" src="assets/images/check-square.png" class="image-btn pointer"
                          alt="images" />
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="membershipPreTrialSessionList.length === 0">
                    <td colspan="8">
                      No data available
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>



          </div>
          <div class="" *ngIf="clientDetails.client_state>1 || clientDetails.client_state<1">
            <div class="filter_section row py-4 justify-content-end">
              <div class="col-lg-12">
                <div class="mb-3 d-flex align-items-center justify-content-between table-feature">

                  <h3 class="m-0">Memberships</h3>
                  <div class="btn pointer no-radius primary-btn  text-white" (click)="openAssignMembershipDialog();">
                    Assign Memberships <img _ngcontent-cgm-c82="" src="assets/images/add-white.png" alt="images">
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive ss_client_table Memberships_table  table-list">

              <table class="table mb-0">
                <thead class="thead-light">
                  <tr>
                    <th>Start Date</th>
                    <th>Expiry Date</th>
                    <th>Title</th>
                    <th>Action</th>
                    <th>Active</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of membershipList">
                    <td scope="row">{{item.start_date| date:'dd/MM/yyyy'}}</td>
                    <td>{{item.end_date| date:'dd/MM/yyyy'}}</td>
                    <td>{{item.package_name}}</td>
                    <td>
                      <div class="text-center">
                        <!-- <img *ngIf="item.membership_type=='Recurring' && item.status!=0"
                          src="assets/images/feather-pause-circle@3x.png" class="image-btn pointer pl-2" alt="images"
                          (click)="openPauseMembershipDialog(item)" /> -->

                        <!-- Removed upgrade / downgrade as does not meet requirements
                                                <img *ngIf="item.membership_type=='Recurring' && item.status!=0"
                                                    src="assets/images/Icon material-compare-arrows.png"
                                                    class="image-btn pointer pl-2" alt="images"
                                                    (click)="openUpgradeDowngradeMembershipDialog(item)" />
                                                -->
                        <img src="assets/images/edit.png" class="image-btn pointer pl-2" alt="images"
                          (click)="openEditClientMembershipDialog(item)" />

                        <img *ngIf="item.status!=0" src="assets/images/delete.png" class="image-btn pointer pl-2"
                          alt="images" (click)="openCancelMembershipDialog(item)" />
                      </div>
                    </td>
                    <td>
                      <ng-container *ngIf="item.status==0">Inactive</ng-container>
                      <ng-container *ngIf="item.status==1">Active</ng-container>
                      <ng-container *ngIf="item.status==2">Paused</ng-container>
                      <ng-container *ngIf="item.status==3">Cancellation Pending</ng-container>
                    </td>
                  </tr>
                  <tr *ngIf="membershipList.length === 0">
                    <td colspan="5">
                      No data available
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>


          <div class="" *ngIf="clientDetails.client_state>1 || clientDetails.client_state<1">
            <div class="filter_section row py-4 justify-content-end">
              <div class="col-lg-12">
                <div class="mb-3 d-flex align-items-center justify-content-between table-feature">

                  <h3 class="m-0">Billing & Credits</h3>
                  <div class="btn pointer no-radius primary-btn  text-white" (click)="openAddBillDialog(null)">
                    Add Bill <img _ngcontent-cgm-c82="" src="assets/images/add-white.png" alt="images">
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive table-list">

              <table class="table mb-0">
                <thead class="thead-light">
                  <tr>
                    <th>Bill Month</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Description</th>
                    <th>Credits</th>
                    <th>Bill Amount</th>
                    <th>Status</th>
                    <th>Action</th>
                    <th>Payment Required</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of billingCreditList">
                    <td scope="row">{{item.billing_month}}</td>
                    <td>{{item.start_date| date:'dd/MM/yyyy'}}</td>
                    <td>{{item.end_date| date:'dd/MM/yyyy'}}</td>
                    <td>{{item.description}}</td>
                    <td>{{item.credits_remaining}}</td>
                    <td>{{item.billing_amount}}</td>
                    <td>
                      <div class="text-center">
                        <img *ngIf="item.new_billing_status==0" src="assets/images/awesome-dollar-sign@3x-unpaid.png"
                          class="image-btn pointer" alt="images" />
                        <img *ngIf="item.new_billing_status==1" src="assets/images/awesome-dollar-sign@3x-paid.png"
                          class="image-btn pointer" alt="images" />
                        <img *ngIf="item.new_billing_status==2" src="assets/images/awesome-dollar-sign@3x-due.png"
                          class="image-btn pointer" alt="images" />
                        <img *ngIf="item.new_billing_status==3" src="assets/images/awesome-dollar-sign@3x-overdue.png"
                          class="image-btn pointer" alt="images" />
                        <ng-container *ngIf="item.new_billing_status==4">Pending</ng-container>
                      </div>
                      <!-- <ng-container *ngIf="item.new_billing_status==0">Unpaid</ng-container>
                                            <ng-container *ngIf="item.new_billing_status==1">Paid</ng-container>
                                            <ng-container *ngIf="item.new_billing_status==2">Due</ng-container>
                                            <ng-container *ngIf="item.new_billing_status==3">Over Due</ng-container> -->
                    </td>
                    <td>
                      <div class="text-center">
                        <img src="assets/images/edit.png" class="image-btn pointer" alt="images"
                          (click)="openEditBillingCreditModal(item)" />
                        <img src="assets/images/information.png" class="image-btn pointer pl-2" alt="images"
                          (click)="openClientPaymentDialog(item)" />
                      </div>
                    </td>
                    <td>
                      <ng-container
                        *ngIf="(item.new_billing_status==0 || item.new_billing_status==2 || item.new_billing_status==3) && item.billing_amount>0">
                        <div class="btn pointer no-radius primary-bg text-white" (click)="openPayNowDialog(item)">
                          Pay Now
                        </div>
                      </ng-container>
                    </td>
                  </tr>
                  <tr *ngIf="billingCreditList.length === 0">
                    <td colspan="9">
                      No data available
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" [ngClass]="selectedTab=='Bookings'?'active show':''" id="Bookings" role="tabpanel"
          aria-labelledby="Bookings-tab">
          <div class="row py-3">
            <div class="col-3">
              <h4 class="my-2">Booking Summary</h4>
            </div>
            <div class="col-lg-9 text-right align-self-center">
              <ul class="table-feature">
                <li>
                  <div class="view-btn d-flex justify-content-end pointer">
                    <div [class.active]="bookingSummeryView=='7'" (click)="getBookingSummary('7')">
                      Last 7 Days
                    </div>
                    <div>/</div>
                    <div [class.active]="bookingSummeryView=='30'" (click)="getBookingSummary('30')">
                      30 Days
                    </div>
                    <div>/</div>
                    <div [class.active]="bookingSummeryView=='60'" (click)="getBookingSummary('60')">
                      60 Days
                    </div>
                    <div>/</div>
                    <div [class.active]="bookingSummeryView=='90'" (click)="getBookingSummary('90')">
                      90 Days
                    </div>
                    <div>/</div>
                    <div [class.active]="bookingSummeryView=='0'" (click)="getBookingSummary('0')">
                      All Time
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-2 col-6">
              <div class="text-center summary-card primary-color">
                <div class="value">{{bookingSummeryData?.thisweekbooked}}</div>
                <div>Bookings</div>
                <div>THIS WEEK</div>
              </div>
            </div>
            <div class="col-lg-2 col-6">
              <div class="text-center summary-card primary-color">
                <div class="value">{{bookingSummeryData?.futurebooked}}</div>
                <div>Bookings</div>
                <div>ALL FUTURE</div>
              </div>
            </div>
            <div class="col-lg-2 col-6">
              <div class="text-center summary-card primary-color">
                <div class="value">{{bookingSummeryData?.allbooked}}</div>
                <div>Total Bookings</div>
                <div *ngIf="bookingSummeryView=='0'">ALL TIME</div>
                <div *ngIf="bookingSummeryView!='0'">Last {{bookingSummeryView}} days</div>
              </div>
            </div>
            <div class="col-lg-2 col-6">
              <div class="text-center summary-card warning-color">
                <div class="value">{{bookingSummeryData?.latecancel}}</div>
                <div>Late Cancel</div>
                <div *ngIf="bookingSummeryView=='0'">ALL TIME</div>
                <div *ngIf="bookingSummeryView!='0'">Last {{bookingSummeryView}} days</div>
              </div>
            </div>
            <div class="col-lg-2 col-6">
              <div class="text-center summary-card danger-color">
                <div class="value">{{bookingSummeryData?.noshow}}</div>
                <div>No Show</div>
                <div *ngIf="bookingSummeryView=='0'">ALL TIME</div>
                <div *ngIf="bookingSummeryView!='0'">Last {{bookingSummeryView}} days</div>
              </div>
            </div>
            <div class="col-lg-2 col-6">
              <div class="text-center summary-card primary-color">
                <div class="value">{{bookingSummeryData?.attendance}}</div>
                <div>Attendance %</div>
                <div *ngIf="bookingSummeryView=='0'">ALL TIME</div>
                <div *ngIf="bookingSummeryView!='0'">Last {{bookingSummeryView}} days</div>
              </div>
            </div>
          </div>
          <div class="row pt-5">
            <div class="col-lg-3 d-none d-lg-block">
              <h4 class="my-2">Booking List</h4>
            </div>
            <div class="col-lg-9 text-right align-self-center">
              <ul class="table-feature">
                <li>
                  <div class="pagination" *ngIf="bookingPager.pages && bookingPager.pages.length">
                    <ul class="page-number">
                      <li (click)="setPage(bookingPager.currentPage - 1)"
                        [class.disabled]="bookingPager.currentPage === 1">
                        <i class="fa fa-angle-left"></i>
                      </li>
                      <li *ngFor="let page of bookingPager.pages" (click)="setPage(page)"
                        [class.active]="bookingPager.currentPage === page">
                        {{ page }}
                      </li>
                      <li (click)="setPage(bookingPager.currentPage + 1)"
                        [class.disabled]="bookingPager.currentPage === bookingPager.totalPages">
                        <i class="fa fa-angle-right"></i>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div class="sort-by">
                    <select [(ngModel)]="bookingPageSize" (change)="setPage(bookingPageIndex)">
                      <option [value]="50">50</option>
                      <option [value]="100">100</option>
                      <option [value]="150">150</option>
                    </select>
                  </div>
                </li>
                <li>
                  <div class="view-btn pointer">
                    <div [class.active]="bookingViewFuture" (click)="getBookingList(1)">
                      View Future
                    </div>
                    <div>/</div>
                    <div [class.active]="!bookingViewFuture" (click)="openFilterDialog()">
                      View Filter
                    </div>
                  </div>
                </li>
                <li>
                  <div class="primary-btn pointer" (click)="openAddUpdateBookingModal()">
                    Book client <img src="assets/images/add-white.png" alt="images">
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="table-responsive table-list">
                <table class="table">
                  <thead class="thead-light">
                    <tr>
                      <th>#</th>
                      <th>Update Time</th>
                      <th>Session Date</th>
                      <th>Session Time</th>
                      <th>Session Title</th>
                      <th>Action</th>
                      <th>User</th>
                      <th class="text-center">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of bookingList;let i=index;">
                      <td>{{((bookingPageIndex-1)*bookingPageSize)+(i+1)}}</td>
                      <td>{{item.audit_timestamp | date:'E, d MMM yyyy hh:mm:ss aa'}}</td>
                      <td>{{item.session_date | date:'E, d MMM yyyy'}}</td>
                      <td>
                        {{item.start_time | date:'hh'}}:{{item.start_time |
                        date:'mm'}}{{item.start_time | date:'aa'}}
                        -
                        {{item.end_time | date:'hh'}}:{{item.end_time |
                        date:'mm'}}{{item.end_time | date:'aa'}}
                      </td>
                      <td>{{item.name}}</td>
                      <td>
                        <ng-container *ngIf="item.status==0">Cancelled</ng-container>
                        <ng-container *ngIf="item.status==1">Booked</ng-container>
                        <ng-container *ngIf="item.status==2">Booked waitlist</ng-container>
                        <ng-container *ngIf="item.status==3">Booked waitlist late</ng-container>
                        <ng-container *ngIf="item.status==4">Waitlist position
                          {{item.wait_position}}</ng-container>
                        <ng-container *ngIf="item.status==5">Late cancellation</ng-container>
                        <ng-container *ngIf="item.status==6">No Show</ng-container>
                        <ng-container *ngIf="item.status==7">Hold</ng-container>
                      </td>
                      <td>{{(item.created_first_name)?(item.created_first_name + ' ' +
                        item.created_last_name):'Unknown'}}
                      </td>
                      <td>
                        <div class="text-center">
                          <img *ngIf="item.status==0" src="assets/images/close.svg" class="image-btn pointer"
                            alt="Cancelled" />
                          <img *ngIf="item.status==1 || item.status==2 || item.status==3" src="assets/images/check.png"
                            class="image-btn pointer" alt="Booked" />
                          <img *ngIf="item.status==4" src="assets/images/status.png" class="image-btn pointer"
                            alt="Waitlist" />
                          <img *ngIf="item.status==5" src="assets/images/icon-late-cancellation.svg"
                            class="image-btn pointer" alt="Late Cancel" />
                          <img *ngIf="item.status==6" src="assets/images/no-show.png" class="image-btn pointer"
                            alt="No Show" />
                          <img *ngIf="item.status==7" src="assets/images/hold.png" class="image-btn pointer"
                            alt="Hold" />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" [ngClass]="selectedTab=='FitnessLog'?'active show':''" id="FitnessLog"
          role="tabpanel" aria-labelledby="FitnessLog-tab">
          <div class="row py-3">
            <div class="col-12">
              <h4 class="my-2">Fitness Log</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 d-none d-lg-block">
              <div class="d-flex align-items-center justify-content-between search-box">
                <input type="text" class="form-control" placeholder="Search..." autocomplete="off"
                  [(ngModel)]="searchText" (input)="filterClientFitnessLogList()">
                <div class="icon px-2"><i class="fa fa-search"></i></div>
              </div>
            </div>
            <div class="col-lg-9 text-right align-self-center">
              <ul class="table-feature">
                <li>
                  <div class="primary-btn pointer" (click)="openAddUpdateFitnessLogDialog(null)">
                    Add Log <img src="assets/images/add-white.png" alt="images">
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="table-responsive table-list">
                <table class="table mb-0">
                  <thead class="thead-light">
                    <tr>
                      <th>Date</th>
                      <th>Movement</th>
                      <th>Log Details</th>
                      <th>Notes</th>
                      <th class="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of filterFitnessLogList">
                      <td>{{item.fitness_date | date:'dd/MM/yyyy'}}</td>
                      <td>{{item.title}}</td>
                      <td>
                        <ng-container *ngIf="item.exercise_type==1">
                          {{item.weight + ' ' + item.weight_unit}} x {{item.reps}} REPS
                        </ng-container>
                        <ng-container *ngIf="item.exercise_type==2">
                          {{item.reps}} REPS
                        </ng-container>
                        <ng-container *ngIf="item.exercise_type==3">
                          {{item.duration + ' ' + item.duration_unit}} x
                          {{item.distance + ' ' + item.distance_unit}} x
                          {{item.calorie + ' KCAL'}} x
                          {{item.speed + ' ' + item.speed_unit}}
                        </ng-container>
                        <ng-container *ngIf="item.exercise_type==4">
                          {{item.duration + ' ' + item.duration_unit}}
                        </ng-container>
                        <ng-container *ngIf="item.exercise_type==5">
                          {{item.duration + ' ' + item.duration_unit}} x {{item.reps}}
                          REPS
                        </ng-container>
                      </td>
                      <td>{{item.notes}}</td>
                      <td>
                        <div class="text-center">
                          <img class="image-btn pointer" src="assets/images/history.png" alt="images"
                            (click)="getHistoryFitnessLogList(item.exercise_id,1)">
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" [ngClass]="selectedTab=='FoodDiary'?'active show':''" id="FoodDiary" role="tabpanel"
          aria-labelledby="FoodDiary-tab">
          <div class="row py-3">
            <div class="col-12">
              <h4 class="my-2">Food Diary</h4>
            </div>
          </div>
          <div class="row pb-3">
            <div class="col-lg-5">
              <div class="calendar-container">
                <div class="calendar-header d-flex align-items-center justify-content-between">
                  <div class="pl-3 pointer" (click)="goToLastMonth()">
                    <i class="fa fa-chevron-left" aria-hidden="true"></i>
                  </div>
                  <div class="text-uppercase ls-2">{{monthNames[selectedMonth]}} {{selectedYear}}
                  </div>
                  <div class="pr-3 pointer" (click)="goToNextMonth()">
                    <i class="fa fa-chevron-right" aria-hidden="true"></i>
                  </div>
                </div>
                <div class="calendar-body">
                  <div class="calendar-table">
                    <div class="row calendar-table-header py-3 m-0">
                      <div class="col box" *ngFor="let day of dayNames">
                        {{day}}
                      </div>
                    </div>
                    <div class="row calendar-date">
                      <div *ngFor="let lastDay of daysInLastMonth" class="col box last-month">
                        <div class="date-box" (click)="setLastMonthDate(lastDay)">{{lastDay}}
                        </div>
                      </div>
                      <div *ngFor="let day of daysInThisMonth" class="col box">
                        <div class="date-box"
                          [class.current-date]="currentDate === day && currentMonth == selectedMonth && currentYear == selectedYear"
                          [class.selected-date]="selectedDate === day" (click)="setSelectedDate(day)">
                          <div>{{day}}</div>
                          <div class="event-bullet" [ngClass]="getEventType(day)"></div>
                        </div>
                      </div>
                      <div *ngFor="let nextDay of daysInNextMonth" class="col box next-month">
                        <div class="date-box" (click)="setNextMonthDate(nextDay)">{{nextDay}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="border">
                <h5 class="text-center p-3">
                  {{dayFullNames[selectedDay]}}, {{selectedDate}} {{monthNames[selectedMonth]}}
                  {{selectedYear}}
                </h5>
                <div class="row m-0">
                  <div class="col-lg-6 p-0" *ngFor="let item of filterFoodDiaryLogList">
                    <div class="image-slot">
                      <img src="{{imagePath + item.image}}">
                      <div class="time-slot p-1">{{item.log_date_time | date:'hh:mm aa'}}</div>
                      <div class="delete-btn pointer" (click)="openDeleteDialog('food_dairy',item)">
                        <i class="fa fa-trash"></i>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 pb-3 text-center" *ngIf="filterFoodDiaryLogList.length==0">
                    No food logs
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" [ngClass]="selectedTab=='Measurements'?'active show':''" id="Measurements"
          role="tabpanel" aria-labelledby="Measurements-tab">
          <div class="row py-3">
            <div class="col-12">
              <h4 class="my-2">Measurement Log</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 d-none d-lg-block">
              <div class="d-flex align-items-center justify-content-between search-box">
                <input type="text" class="form-control" placeholder="Search..." autocomplete="off"
                  [(ngModel)]="searchText" (input)="filterClientMeasurementLogList()">
                <div class="icon px-2"><i class="fa fa-search"></i></div>
              </div>
            </div>
            <div class="col-lg-9 text-right align-self-center">
              <ul class="table-feature">
                <li>
                  <div class="primary-btn pointer" (click)="openAddUpdateMeasurementLogDialog(null)">
                    Add Log <img src="assets/images/add-white.png" alt="images">
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="table-responsive table-list">
                <table class="table mb-0">
                  <thead class="thead-light">
                    <tr>
                      <th>Date</th>
                      <th>Measurement</th>
                      <th>Log Details</th>
                      <th>Notes</th>
                      <th class="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of filterMeasurementLogList">
                      <td>{{item.measurement_date | date:'dd/MM/yyyy'}}</td>
                      <td>{{item.title}}</td>
                      <td>{{item.value + ' ' + item.value_unit}} </td>
                      <td>{{item.notes}}</td>
                      <td>
                        <div class="text-center">
                          <img class="image-btn pointer" src="assets/images/history.png" alt="images"
                            (click)="getHistoryMeasurementLogList(item.measurement_id,1)">
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="tab-pane fade" [ngClass]="selectedTab=='Workout'?'active show':''" id="Workout" role="tabpanel"
          aria-labelledby="Workout-tab">

          <div class="row py-3">
            <div class="col-12">
              <h4 class="m-0">Workout Program</h4>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3 d-none d-lg-block">
              <div class="d-flex align-items-center justify-content-between search-box">
                <input type="text" class="form-control" placeholder="Search..." autocomplete="off"
                  [(ngModel)]="searchText" (input)="getClientWorkoutList()">
                <div class="icon px-2">
                  <i class="fa fa-search"></i>
                </div>
              </div>
            </div>
            <div class="col-lg-9 text-right align-self-center">
              <ul class="table-feature">
                <li>
                  <div class="primary-btn pointer" (click)="openAddUpdateWorkoutDialog(null)">
                    Assign Program
                    <img src="assets/images/add-white.png" alt="images">
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12">
              <div class="table-responsive table-list">
                <table class="table mb-0">
                  <thead class="thead-light">
                    <tr>
                      <th>Program Title</th>
                      <th>Date Assigned</th>
                      <th>Start Date</th>
                      <th>Trainer</th>
                      <th>Active</th>
                      <th class="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of filterworkoutList">

                      <td>{{item.title}}</td>
                      <td>{{item.timestamp | date:'dd/MM/yyyy'}}</td>
                      <td>{{item.start_date | date:'dd/MM/yyyy'}}</td>
                      <td>{{getTrainerNameById(item.trainer_id)}}</td>
                      <td>
                        <div class="text-center">
                          <img *ngIf="item.workout_status" src="assets/images/check-square.png"
                            class="image-btn pointer" alt="images" />
                        </div>
                      </td>

                      <td>
                        <div class="text-center">
                          <img src="assets/images/setting.png" class="image-btn pointer pl-2" alt="images"
                            (click)="openAddUpdateWorkoutDialog(item)" />

                          <img src="assets/images/delete.png" class="image-btn pointer pl-2" alt="images"
                            (click)="openDeleteDialog('workout', item)" />

                          <img src="assets/images/edit.png" class="image-btn pointer pl-2" alt="images"
                            (click)="openWorkoutProgramModal(0,item)" />
                        </div>
                      </td>

                    </tr>

                    <tr *ngIf="filterworkoutList.length === 0">
                      <td colspan="7">
                        No data available
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="filter_section row py-4 justify-content-end">
            <div class="col-lg-12">
              <div class="mb-3 d-flex align-items-center justify-content-between table-feature">
                <h3 class="m-0">Workout Program Log</h3>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12">
              <div class="table-responsive table-list">
                <table class="table mb-0">
                  <thead class="thead-light">
                    <tr>
                      <th>Program Title</th>
                      <th>Workout Week</th>
                      <th>Workout Title</th>
                      <th>Start Date Logged</th>
                      <th>End Date Logged</th>
                      <th>Action</th>
                      <!-- <th class="text-center">Action</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of workoutLogList">

                      <td>{{item.program_name}}</td>
                      <td>Week {{item.week}}</td>
                      <td>{{item.workout_name}}</td>
                      <td>{{item.start_datetime | date:'dd/MM/yyyy'}} {{ moment(item.start_datetime).format('hh:mm A')
                        }}</td>
                      <td>{{item.end_datetime | date:'dd/MM/yyyy'}} {{ item.end_datetime ?
                        moment(item.end_datetime).format('hh:mm A') : null }}</td>
                      <td>
                        <div class="text-center">
                          <img class="image-btn pointer px-3" src="assets/images/history.png" alt="logs"
                            (click)="openWorkoutLogDialog(item.id, true)" />
                        </div>
                      </td>
                      <!-- <td>
                                                <div class="text-center">
                                                    <img src="assets/images/delete.png" class="image-btn pointer pl-2"
                                                        alt="images" (click)="openDeleteDialog('workoutlog', item)" />
                                                </div>
                                            </td> -->

                    </tr>

                    <tr *ngIf="workoutLogList.length === 0">
                      <td colspan="7">
                        No data available
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>

        <div class="tab-pane fade" [ngClass]="selectedTab=='Equipments'?'active show':''" id="Equipments"
          role="tabpanel" aria-labelledby="Equipments-tab">
          <div class="row py-3">
            <div class="col-12">
              <h4 class="my-2">Equipments</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 d-none d-lg-block">
              <div class="d-flex align-items-center justify-content-between search-box">
                <input type="text" class="form-control" placeholder="Search..." autocomplete="off"
                  [(ngModel)]="searchText" (input)="filterClientMeasurementLogList()">
                <div class="icon px-2"><i class="fa fa-search"></i></div>
              </div>
            </div>
            <div class="col-lg-9 text-right align-self-center">
              <ul class="table-feature">
                <li>
                  <div class="primary-btn pointer" (click)="openAddUpdateEquipmentDialog()">
                    Add Equipments <img src="assets/images/add-white.png" alt="images">
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="table-responsive table-list">
                <table class="table mb-0">
                  <thead class="thead-light">
                    <tr>
                      <th>Equipment</th>
                      <th>Image</th>
                      <th class="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of equipmentList">
                      <td>{{item.name}} </td>
                      <td>
                        <div class="text-center">
                          <img *ngIf="item.image" src="assets/images/image.svg" class="image-btn pointer success-color"
                            alt="images" (click)="openImageLink(item.image)" />
                        </div>
                      </td>
                      <td>
                        <div class="text-center">
                          <img src="assets/images/delete.png" class="image-btn pointer pl-2" alt="images"
                            (click)="openDeleteDialog('equipment',item)" />
                        </div>
                      </td>
                    </tr>
                    <tr *ngIf="equipmentList?.length === 0">
                      <td colspan="8" class="text-center">
                        No Equipments
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" [ngClass]="selectedTab=='Habits'?'active show':''" id="Habits" role="tabpanel"
          aria-labelledby="Habits-tab">
          <div class="row py-3">
            <div class="col-12">
              <h4 class="m-0">Habits</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 d-none d-lg-block">
              <div class="d-flex align-items-center justify-content-between search-box">
                <input type="text" class="form-control" placeholder="Search..." autocomplete="off"
                  [(ngModel)]="searchText" (input)="filterClientChallengeList()">
                <div class="icon px-2">
                  <i class="fa fa-search"></i>
                </div>
              </div>
            </div>
            <div class="col-lg-9 text-right align-self-center">
              <ul class="table-feature">
                <li>
                  <div class="primary-btn pointer" (click)="openAddUpdateChallengeDialog(null)">
                    Assign Habit
                    <img src="assets/images/add-white.png" alt="images">
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="table-responsive table-list">
                <table class="table mb-0">
                  <thead class="thead-light">
                    <tr>
                      <th>Habit Title</th>
                      <th>Frequency</th>
                      <th>Frequency Type</th>
                      <th>Overall %</th>
                      <th>Active</th>
                      <th class="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of filterChallengeList">

                      <td>{{item.title}}</td>
                      <td>{{item.frequency}}</td>
                      <td>{{item.frequency_type}}</td>
                      <td>{{item.statistics.overall}}%</td>
                      <td>
                        <div class="text-center">
                          <img *ngIf="item.challenge_status == 1" src="assets/images/check-square.png"
                            class="image-btn pointer" alt="images" />
                        </div>
                      </td>

                      <td>
                        <div class="text-center">
                          <img src="assets/images/history.png" class="image-btn pointer pl-2" alt="images"
                            (click)="openChallengeLogListDialog(item)" />
                          <img src="assets/images/edit.png" class="image-btn pointer pl-2" alt="images"
                            (click)="openAddUpdateChallengeDialog(item)" />

                          <img src="assets/images/delete.png" class="image-btn pointer pl-2" alt="images"
                            (click)="openDeleteDialog('challenge', item)" />
                        </div>
                      </td>

                    </tr>

                    <tr *ngIf="filterChallengeList.length === 0">
                      <td colspan="7">
                        No data available
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" [ngClass]="selectedTab=='Stickers'?'active show':''" id="Stickers" role="tabpanel"
          aria-labelledby="Stickers-tab">
          <div class="row py-3">
            <div class="col-12">
              <h4 class="my-2">Stickers</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 d-none d-lg-block">
              <div class="d-flex align-items-center justify-content-between search-box">
                <input type="text" class="form-control" placeholder="Search..." autocomplete="off"
                  [(ngModel)]="searchText" (input)="filterClientStickerList()">
                <div class="icon px-2">
                  <i class="fa fa-search"></i>
                </div>
              </div>
            </div>
            <div class="col-lg-9 text-right align-self-center">
              <ul class="table-feature">
                <li>
                  <div class="primary-btn pointer" (click)="openAddUpdateStickerDialog(null)">
                    Assign Sticker
                    <img src="assets/images/add-white.png" alt="images">
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="table-responsive table-list">
                <table class="table mb-0">
                  <thead class="thead-light">
                    <tr>
                      <th>Sticker Title</th>
                      <th>Date Collected</th>
                      <th>Active</th>
                      <th class="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of filterstickerLogList">
                      <td>{{item.title}}</td>
                      <td>{{item.collected_date | date:'dd/MM/yyyy'}}</td>
                      <td>
                        <div class="text-center">
                          <img *ngIf="item.sticker_status" src="assets/images/check-square.png"
                            class="image-btn pointer" alt="images" />
                        </div>
                      </td>
                      <td>

                        <div class="text-center">
                          <img src="assets/images/edit.png" class="image-btn pointer pl-2" alt="images"
                            (click)="openAddUpdateStickerDialog(item)" />

                          <img src="assets/images/delete.png" class="image-btn pointer pl-2" alt="images"
                            (click)="openDeleteDialog('sticker',item)" />
                        </div>
                      </td>
                    </tr>
                    <tr *ngIf="filterstickerLogList.length === 0">
                      <td colspan="4" class="text-center">
                        No data available
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-delete (onConfirm)="deleteRecord()"></app-delete>
<!-- #region Profile -->
<!-- #region Add Update Personal Goal -->
<div class="modal fade" id="add-update-personal-goal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="d-flex w-100">
          <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
          <div class="modal-title w-100 text-center">{{(isCreate)?'Add':'Update'}} Client Goal
          </div>
        </div>
      </div>
      <div class="modal-body px-4">
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Start Date:</div>
          <div class="col-sm-8">
            <input type="date" class="form-control no-radius" placeholder="Select start date" [(ngModel)]="logDate">
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Title:</div>
          <div class="col-sm-8">
            <input type="text" class="form-control no-radius" placeholder="Enter title" [(ngModel)]="title">
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Description:</div>
          <div class="col-sm-8">
            <textarea rows="3" class="form-control no-radius" placeholder="Enter description"
              [(ngModel)]="description"></textarea>
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Achieved Date:</div>
          <div class="col-sm-8">
            <input type="date" class="form-control no-radius" placeholder="Select start date"
              [(ngModel)]="achievedDate">
          </div>
        </div>
        <div class="col-lg-12 text-center">
          <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="savePersonalGoal()">
            {{(isCreate)?'Add':'Update'}} Goal
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- #endregion -->

<!-- #region Add Update Injury -->
<div class="modal fade" id="add-update-injury" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="d-flex w-100">
          <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
          <div class="modal-title w-100 text-center">{{(isCreate)?'Add':'Update'}} Client Injury
          </div>
        </div>
      </div>
      <div class="modal-body px-4">
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Injury Date:</div>
          <div class="col-sm-8">
            <input type="date" class="form-control no-radius" placeholder="Select start date" [(ngModel)]="logDate">
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Title:</div>
          <div class="col-sm-8">
            <input type="text" class="form-control no-radius" placeholder="Enter title" [(ngModel)]="title">
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Description:</div>
          <div class="col-sm-8">
            <textarea rows="3" class="form-control no-radius" placeholder="Enter description"
              [(ngModel)]="description"></textarea>
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Recovered Date:</div>
          <div class="col-sm-8">
            <input type="date" class="form-control no-radius" placeholder="Select start date"
              [(ngModel)]="recoveredDate">
          </div>
        </div>
        <div class="col-lg-12 text-center">
          <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="saveInjury()">
            {{(isCreate)?'Add':'Update'}} Injury
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- #endregion -->

<!-- #region Add Update Notes -->
<div class="modal fade" id="add-update-note" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="d-flex w-100">
          <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
          <div class="modal-title w-100 text-center">{{(isCreate)?'Add':'Update'}} Client Note
          </div>
        </div>
      </div>
      <div class="modal-body px-4">
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Date:</div>
          <div class="col-sm-8">
            <input type="date" class="form-control no-radius" placeholder="Select start date" [(ngModel)]="logDate">
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Note:</div>
          <div class="col-sm-8">
            <textarea rows="3" class="form-control no-radius" placeholder="Enter note" [(ngModel)]="note"></textarea>
          </div>
        </div>
        <div class="col-lg-12 text-center">
          <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="saveNote()">
            {{(isCreate)?'Add':'Update'}} Note
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- #endregion -->

<!-- #endregion -->

<!-- #region Membership -->

<!-- #region Add Client Bill -->
<div class="modal fade p-0" id="add-bill" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered add-session-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <div class="d-flex w-100">
          <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
          <div class="modal-title w-100">
            Add Bill
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Due Date:</div>
          <div class="col-sm-8">
            <input type="date" class="form-control no-radius" placeholder="Select start date"
              [(ngModel)]="billStartDate">
          </div>
        </div>

        <!--
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">End Date:</div>
                    <div class="col-sm-8">
                        <input type="date" class="form-control no-radius" placeholder="Select end date"
                            [(ngModel)]="billEndDate">
                    </div>
                </div>
                -->

        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Description:</div>
          <div class="col-sm-8">
            <input type="text" class="form-control no-radius" placeholder="Enter description"
              [(ngModel)]="billDescription">
          </div>
        </div>

        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Amount:</div>
          <div class="col-sm-8">
            <input type="text" class="form-control no-radius" placeholder="Enter bill amount in BD"
              [(ngModel)]="billAmount">
          </div>
        </div>



        <div class="col-lg-12 text-center">
          <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="saveBill()">
            Save
          </div>

          <!-- <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="saveBill()">
                        Pay Now
                    </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
<!-- #endregion -->

<!-- #region Pay now -->
<div class="modal fade p-0" id="pay-now" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered add-session-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <div class="d-flex w-100">
          <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
          <div class="modal-title w-100">
            Add Offline Payment
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Payment Date:</div>
          <div class="col-sm-8">
            <input type="date" class="form-control no-radius" placeholder="Select start date" [(ngModel)]="paymentDate">
          </div>
        </div>

        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Amount:</div>
          <div class="col-sm-8">
            <input type="text" class="form-control no-radius" placeholder="Amount" [(ngModel)]="paymentAmount"
              [disabled]="true">
          </div>
        </div>

        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Payment Method:</div>
          <div class="col-sm-8">
            <ng-select [items]="paymentMethodList" placeholder="Select Payment Method" bindValue="id" bindLabel="title"
              [clearable]="false" [(ngModel)]="paymentMethod">
            </ng-select>

          </div>
        </div>
        <input type="hidden" class="form-control no-radius" placeholder="Select start date"
          [(ngModel)]="billingCreditsId">
        <div class="col-lg-12 text-center">
          <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="savePayNow()">
            Pay Now
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- #endregion -->

<!-- #region Pause Membership -->
<div class="modal fade p-0" id="pause-membership" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered add-session-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <div class="d-flex w-100">
          <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
          <div class="modal-title w-100">
            Pause Membership
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Start Date:</div>
          <div class="col-sm-8">
            <input type="date" class="form-control no-radius" placeholder="Select effective start date"
              [(ngModel)]="pauseStartDate">
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">End Date:</div>
          <div class="col-sm-8">
            <input type="date" class="form-control no-radius" placeholder="Select inclusive end date"
              [(ngModel)]="pauseEndDate">
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Description:</div>
          <div class="col-sm-8">
            <input type="text" class="form-control no-radius" placeholder="Enter pause reason"
              [(ngModel)]="pauseDescription">
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Amount:</div>
          <div class="col-sm-8">
            <input type="text" class="form-control no-radius" placeholder="Enter in BD [optional]"
              [(ngModel)]="pauseAmount">
          </div>
        </div>


        <input type="hidden" class="form-control no-radius" [(ngModel)]="clientMembershipId">
        <div class="col-lg-12 text-center">
          <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="pauseMembership()">
            Pause Membership
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- #endregion -->

<!-- #region Upgrade Downgrade Membership -->
<div class="modal fade p-0" id="upgrade-downgrade-membership" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered add-session-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <div class="d-flex w-100">
          <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
          <div class="modal-title w-100">
            Upgrade Downgrade Membership
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Upgrade / Downgrade:</div>
          <div class="col-sm-8">
            <ng-select [items]="ugActionList" placeholder="Select Upgrade / Downgrade" bindValue="id" bindLabel="title"
              [clearable]="false" [(ngModel)]="udAction">
            </ng-select>
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Duration:</div>
          <div class="col-sm-8">
            <input type="number" class="form-control no-radius" placeholder="Select effective start date"
              [(ngModel)]="udDuration">
          </div>
        </div>

        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">First Month Extra Fee:</div>
          <div class="col-sm-8">
            <input type="text" class="form-control no-radius" placeholder="Enter in BD [optional]"
              [(ngModel)]="udFirstMonthFee">
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Description:</div>
          <div class="col-sm-8">
            <input type="text" class="form-control no-radius" placeholder="Enter description"
              [(ngModel)]="udDescription">
          </div>
        </div>

        <input type="hidden" class="form-control no-radius" [(ngModel)]="clientMembershipId">
        <div class="col-lg-12 text-center">
          <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="upgradeDowngradeMembership()">
            Change Membership
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- #endregion -->

<!-- #region Cancel Membership -->
<div class="modal fade p-0" id="cancel-membership" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered add-session-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <div class="d-flex w-100">
          <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
          <div class="modal-title w-100 text-center">
            Cancel Membership
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div class="row mb-3 align-items-center">
          <div class="col-sm-12">Are you sure you want to cancel this membership?
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-6">Cancellation Amount:</div>
          <div class="col-sm-6">
            <input type="text" class="form-control no-radius" placeholder="Enter in BD [optional]"
              [(ngModel)]="cancelAmount">
          </div>
        </div>
        <input type="hidden" class="form-control no-radius" [(ngModel)]="clientMembershipId">
        <div class="col-lg-12 text-center">
          <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="cancelMembership()">
            Cancel Membership
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- #endregion -->

<!-- #region Edit Billing Credit -->
<div class="modal fade p-0" id="edit-billing-credit" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered add-session-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <div class="d-flex w-100">
          <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
          <div class="modal-title w-100 text-center">
            Edit Billing Credit Data
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Start Date:</div>
          <div class="col-sm-8">
            <input type="date" class="form-control no-radius" placeholder="Select effective start date"
              [(ngModel)]="bcStartDate">
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">End Date:</div>
          <div class="col-sm-8">
            <input type="date" class="form-control no-radius" placeholder="Select inclusive end date"
              [(ngModel)]="bcEndDate">
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Description:</div>
          <div class="col-sm-8">
            <input type="text" class="form-control no-radius" placeholder="Enter description"
              [(ngModel)]="bcDescription">
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Amount:</div>
          <div class="col-sm-8">
            <input type="text" class="form-control no-radius" placeholder="Enter amount in BD" [(ngModel)]="bcAmount">
          </div>
        </div>

        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Credits:</div>
          <div class="col-sm-8">
            <input type="number" class="form-control no-radius" placeholder="Enter credits" [(ngModel)]="bcCredits">
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Billing Status:</div>
          <div class="col-sm-8">
            <ng-select [items]="bcStatusList" placeholder="Select billing status" bindValue="id" bindLabel="title"
              [clearable]="false" [(ngModel)]="bcStatus">
            </ng-select>
          </div>
        </div>
        <input type="hidden" class="form-control no-radius" [(ngModel)]="billingCreditId">
        <div class="col-lg-12 text-center">
          <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="saveUpdateBillingCreditData()">
            Update Billing & Credit Data
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- #endregion -->

<!-- #region Client Payment -->
<div class="modal fade p-0" id="client-payment" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered client-payment-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <div class="d-flex w-100">
          <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
          <div class="modal-title w-100 text-center">
            Client Payments
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div class="table-responsive table-list">
          <table class="table mb-0">
            <thead class="thead-light">
              <tr>
                <th width="20%">Transaction ID</th>
                <th width="20%">Payment Date Time</th>
                <th width="10%">Method</th>
                <th width="10%">Amount</th>
                <th width="20%">Description</th>
                <th width="10%">Status</th>
                <th width="10%">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of clientPaymentList">
                <td scope="row">{{item.payment_gateway_payment_id}}</td>
                <td>{{item.payment_date_time| date:'dd/MM/yyyy hh:mm aa'}}</td>
                <td>{{item.payment_method_name}}</td>
                <td>{{item.amount}} {{item.currency}}</td>
                <td class="text-break">{{item.description}}</td>
                <td>{{paymentStatus[item.status]}}</td>
                <td>
                  <ng-container *ngIf="item.status==2">
                    <div class="btn pointer no-radius primary-bg text-white" (click)="checkPaymentStatus(item)">
                      Check Status
                    </div>
                  </ng-container>
                </td>
              </tr>
              <tr *ngIf="clientPaymentList.length === 0">
                <td colspan="20" class="text-center">
                  No data available
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- #endregion -->

<!-- #region Edit Client Membership -->
<div class="modal fade p-0" id="edit-client-membership" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered add-session-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <div class="d-flex w-100">
          <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
          <div class="modal-title w-100 text-center">
            Edit Client Membership Data
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Start Date:</div>
          <div class="col-sm-8">
            <input type="date" class="form-control no-radius" placeholder="Select effective start date"
              [(ngModel)]="cmStartDate">
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">End Date:</div>
          <div class="col-sm-8">
            <input type="date" class="form-control no-radius" placeholder="Select inclusive end date"
              [(ngModel)]="cmEndDate">
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Status:</div>
          <div class="col-sm-8">
            <ng-select [items]="cmStatusList" placeholder="Select status" bindValue="id" bindLabel="title"
              [clearable]="false" [(ngModel)]="cmStatus">
            </ng-select>
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Automatic Payments:</div>
          <div class="col-sm-8">
            <ng-select [items]="cmAutomaticPaymentList" placeholder="Select Automatic Payments" bindValue="id"
              bindLabel="title" [clearable]="false" [(ngModel)]="cmAutomaticPayment">
            </ng-select>
          </div>
        </div>
        <input type="hidden" class="form-control no-radius" [(ngModel)]="clientMembershipId">
        <div class="col-lg-12 text-center">
          <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="saveClientMembershipData()">
            Update Client Membership Data
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- #endregion -->

<!-- #region Pre trial one on one Membership -->
<div class="modal fade p-0" id="assign-one-on-one-membership" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered add-session-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <div class="d-flex w-100">
          <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
          <div class="modal-title w-100 text-center">
            Assign Pre trial one on one Membership
          </div>
        </div>
      </div>

      <div class="modal-body">
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Membership Locality:</div>
          <div class="col-sm-8">
            <ng-select [items]="membershipLocalityList" placeholder="Select Locality" bindValue="id" bindLabel="title"
              [clearable]="false" [(ngModel)]="prMembershipLocalityId">
            </ng-select>
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Start Date:</div>
          <div class="col-sm-8">
            <input type="date" class="form-control no-radius" placeholder="Select effective start date"
              [(ngModel)]="prStartDate">
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">End Date:</div>
          <div class="col-sm-8">
            <input type="date" class="form-control no-radius" placeholder="Select inclusive end date"
              [(ngModel)]="prEndDate">
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Start Time:</div>
          <div class="col-sm-8">
            <input type="time" class="form-control no-radius" placeholder="Select effective start time"
              [(ngModel)]="prStartTime">
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">End Time:</div>
          <div class="col-sm-8">
            <input type="time" class="form-control no-radius" placeholder="Select inclusive end time"
              [(ngModel)]="prEndTime">
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Description:</div>
          <div class="col-sm-8">
            <input type="text" class="form-control no-radius" placeholder="Enter description"
              [(ngModel)]="prDescription">
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Status:</div>
          <div class="col-sm-8">
            <ng-select [items]="cmStatusList" placeholder="Select status" bindValue="id" bindLabel="title"
              [clearable]="false" [(ngModel)]="prStatus">
            </ng-select>
          </div>
        </div>

        <input type="hidden" class="form-control no-radius" [(ngModel)]="prId">
        <div class="col-lg-12 text-center">
          <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="SavePreTrialOneOnOneSession()">
            Save pre trial one on one session
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- #endregion -->
<!-- #region Assign Membership -->
<div class="modal fade p-0" id="assign-membership" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered add-session-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <div class="d-flex w-100">
          <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
          <div class="modal-title w-100 text-center">
            Assign Membership
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Membership:</div>
          <div class="col-sm-8">
            <ng-select [items]="membershipListing" placeholder="Select Membership" bindValue="id"
              bindLabel="package_name" [clearable]="false" [(ngModel)]="puMembershipId" (change)="getMembershipData()">
            </ng-select>
          </div>
        </div>
        <div class="row mb-3 align-items-center" style="display: none;">
          <div class="col-sm-4">Membership Type:</div>
          <div class="col-sm-8">
            <ng-select [items]="membershipTypeList" placeholder="Select Membership Type" bindValue="id"
              bindLabel="title" [clearable]="false" [disabled]="true" [(ngModel)]="puMembershipTypeId">
            </ng-select>
          </div>
        </div>
        <div class="row mb-3 align-items-center" style="display: none;">
          <div class="col-sm-4">Membership Locality:</div>
          <div class="col-sm-8">
            <ng-select [items]="membershipLocalityList" placeholder="Select Locality" bindValue="id" bindLabel="title"
              [clearable]="false" [disabled]="true" [(ngModel)]="puMembershipLocalityId">
            </ng-select>
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Start Date:</div>
          <div class="col-sm-8">
            <input type="date" class="form-control no-radius" placeholder="Effective start date of membership"
              [disabled]="!puMembershipTypeId || puMembershipTypeId==6" [(ngModel)]="puStartDate"
              (change)="getMembershipData()">
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">End Date:</div>
          <div class="col-sm-8">
            <input type="date" class="form-control no-radius" placeholder="Inclusive end date of membership"
              [disabled]="!puMembershipTypeId || puMembershipTypeId==2 || puMembershipTypeId==4 || puMembershipTypeId==6"
              [(ngModel)]="puEndDate" (change)="getMembershipData()">
          </div>
        </div>
        <div class="row mb-3 align-items-center" style="display: none;">
          <div class="col-sm-4">Duration:</div>
          <div class="col-sm-8">
            <input type="text" class="form-control no-radius" placeholder="Duration of selected membership in month"
              [disabled]="true" [(ngModel)]="puDuration">
          </div>
        </div>
        <div class="row mb-3 align-items-center" style="display: none;">
          <div class="col-sm-4">Credits:</div>
          <div class="col-sm-8">
            <input type="text" class="form-control no-radius" placeholder="Credits available" [disabled]="true"
              [(ngModel)]="puCredits">
          </div>
        </div>
        <div class="row mb-3 align-items-center" style="display: none;">
          <div class="col-sm-4">Aggregate Credits:</div>
          <div class="col-sm-8">
            <input type="text" class="form-control no-radius" placeholder="Aggregate Credits" [disabled]="true"
              [(ngModel)]="puPartialCredits">
          </div>
        </div>
        <div class="row mb-3 align-items-center" style="display: none;">
          <div class="col-sm-4">First month fee:</div>
          <div class="col-sm-8">
            <input type="text" class="form-control no-radius" placeholder="First month fee" [disabled]="true"
              [(ngModel)]="puFirstMonthFee">
          </div>
        </div>
        <div class="row mb-3 align-items-center" style="display: none;">
          <div class="col-sm-4">Subsequent Month Fee:</div>
          <div class="col-sm-8">
            <input type="text" class="form-control no-radius" placeholder="Subsequent Month Fee" [disabled]="true"
              [(ngModel)]="puSubsequentMonthFee">
          </div>
        </div>
        <div class="row mb-3 align-items-center" style="display: none;">
          <div class="col-sm-4">Aggregate Fee:</div>
          <div class="col-sm-8">
            <input type="text" class="form-control no-radius" placeholder="Aggregate Fee" [disabled]="true"
              [(ngModel)]="puPartialFee">
          </div>
        </div>
        <div class="row mb-3 align-items-center" style="display: none;">
          <div class="col-sm-4">Total Fee:</div>
          <div class="col-sm-8">
            <input type="text" class="form-control no-radius" placeholder="Total Fee" [disabled]="true"
              [(ngModel)]="puTotalFee">
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Active:</div>
          <div class="col-sm-8">
            <div class="d-flex align-items-center">
              <i *ngIf="!puActive" (click)="puActive=true;"
                class="fa fa-square-o checkbox secondary-color inactive"></i>
              <i *ngIf="puActive" (click)="puActive=false;" class="fa fa-check-square-o checkbox success-color"></i>
            </div>
          </div>
        </div>
        <!-- <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Description:</div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control no-radius" placeholder="Description"
                            [(ngModel)]="puDescription">
                    </div>
                </div> -->
        <input type="hidden" class="form-control no-radius" [(ngModel)]="puBillStartDate">
        <input type="hidden" class="form-control no-radius" [(ngModel)]="puBillEndDate">
        <div class="col-lg-12 text-center">
          <div class="btn pointer no-radius mb-2 mx-2 secondary-bg text-white"
            (click)="clearAssignMembershipDialogue()">
            Clear Form
          </div>
          <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="assignMembershipToClient()">
            Assign Membership
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- #endregion -->

<!-- #endregion -->

<!-- #region Booking -->
<app-book-client (onRefresh)="onBookingRefresh($event)" [clientId]="clientId"></app-book-client>

<app-filter [title]="'Show Historical Bookings'" [btnFilterText]="'Show Booking'"
  (onFilter)="closeFilterDialog($event)"></app-filter>
<!-- #endregion -->

<!-- #region Fitness Log -->

<!-- #region Fitness History Log -->
<div class="modal fade" id="fitness-log-dialog" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="d-flex w-100">
          <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
          <div class="modal-title w-100 text-center">Fitness Log</div>
        </div>
      </div>
      <div class="modal-body px-4">
        <div class="table-responsive table-list">
          <table class="table mb-0">
            <thead class="thead-light">
              <tr>
                <th>Date</th>
                <th>Movement</th>
                <th>Log Details</th>
                <th>Notes</th>
                <th class="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of historyFitnessLogList">
                <td>{{item.fitness_date | date:'dd/MM/yyyy'}}</td>
                <td>{{item.title}}</td>
                <td>
                  <ng-container *ngIf="item.exercise_type==1">
                    {{item.weight + ' ' + item.weight_unit}} x {{item.reps}} REPS
                  </ng-container>
                  <ng-container *ngIf="item.exercise_type==2">
                    {{item.reps}} REPS
                  </ng-container>
                  <ng-container *ngIf="item.exercise_type==3">
                    {{item.duration + ' ' + item.duration_unit}} x
                    {{item.distance + ' ' + item.distance_unit}} x
                    {{item.calorie + ' KCAL'}} x
                    {{item.speed + ' ' + item.speed_unit}}
                  </ng-container>
                  <ng-container *ngIf="item.exercise_type==4">
                    {{item.duration + ' ' + item.duration_unit}}
                  </ng-container>
                  <ng-container *ngIf="item.exercise_type==5">
                    {{item.duration + ' ' + item.duration_unit}} x {{item.reps}} REPS
                  </ng-container>
                </td>
                <td>{{item.notes}}</td>
                <td>
                  <div class="text-center">
                    <img class="image-btn pointer px-3" src="assets/images/edit.png"
                      (click)="openAddUpdateFitnessLogDialog(item)">
                    <img class="image-btn pointer" src="assets/images/delete.png" (click)="deleteFitnessLog(item)">
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- #endregion -->

<!-- #region Add Update Fitness Log -->
<div class="modal fade" id="add-update-fitness-log" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="d-flex w-100">
          <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
          <div class="modal-title w-100 text-center">{{(isNewFitnessLog)?'Add':'Update'}} Fitness Log</div>
        </div>
      </div>
      <div class="modal-body px-4">
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Movement:</div>
          <div class="col-sm-8">
            <ng-select [items]="exerciseList" placeholder="Select Movement" bindValue="id" bindLabel="title"
              [clearable]="false" [disabled]="!isNewFitnessLog" [(ngModel)]="fitnessLogDetails.exercise_id"
              (change)="setExerciseType()">
            </ng-select>
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Date:</div>
          <div class="col-sm-8">
            <input type="date" class="form-control no-radius" placeholder="Select date of bith"
              [(ngModel)]="fitnessLogDetails.fitness_date">
          </div>
        </div>

        <ng-container *ngIf="exerciseType=='1'">
          <div class="row mb-3 align-items-center">
            <div class="col-sm-4">Weight:</div>
            <div class="col-sm-8">
              <div class="border form-control no-radius d-flex align-items-center">
                <input type="text" class="w-50 border-0 no-outline" placeholder="Enter weight"
                  [(ngModel)]="fitnessLogDetails.weight">
                <div class="w-50 text-right">
                  <span class="pointer" [class.selected]="fitnessLogDetails.weight_unit=='KG'"
                    (click)="fitnessLogDetails.weight_unit='KG'">KG</span>
                  <span> / </span>
                  <span class="pointer" [class.selected]="fitnessLogDetails.weight_unit=='LBS'"
                    (click)="fitnessLogDetails.weight_unit='LBS'">LBS</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3 align-items-center">
            <div class="col-sm-4">Reps:</div>
            <div class="col-sm-8">
              <input type="text" class="form-control no-radius" placeholder="Enter reps"
                [(ngModel)]="fitnessLogDetails.reps">
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="exerciseType=='2'">
          <div class="row mb-3 align-items-center">
            <div class="col-sm-4">Reps:</div>
            <div class="col-sm-8">
              <input type="text" class="form-control no-radius" placeholder="Enter reps"
                [(ngModel)]="fitnessLogDetails.reps">
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="exerciseType=='3'">
          <div class="row mb-3 align-items-center">
            <div class="col-sm-4">Duration:</div>
            <div class="col-sm-8">
              <div class="border form-control no-radius d-flex align-items-center">
                <input type="text" class="w-50 border-0 no-outline" placeholder="Enter duration"
                  [(ngModel)]="fitnessLogDetails.duration">
                <div class="w-50 text-right">
                  <span class="pointer" [class.selected]="fitnessLogDetails.duration_unit=='SEC'"
                    (click)="fitnessLogDetails.duration_unit='SEC'">SEC</span>
                  <span> / </span>
                  <span class="pointer" [class.selected]="fitnessLogDetails.duration_unit=='MIN'"
                    (click)="fitnessLogDetails.duration_unit='MIN'">MIN</span>
                  <span> / </span>
                  <span class="pointer" [class.selected]="fitnessLogDetails.duration_unit=='HR'"
                    (click)="fitnessLogDetails.duration_unit='HR'">HR</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3 align-items-center">
            <div class="col-sm-4">Calorie:</div>
            <div class="col-sm-8">
              <input type="number" class="form-control no-radius" placeholder="Enter calories in kcal"
                [(ngModel)]="fitnessLogDetails.calorie">
            </div>
          </div>
          <div class="row mb-3 align-items-center">
            <div class="col-sm-4">Distance:</div>
            <div class="col-sm-8">
              <div class="border form-control no-radius d-flex align-items-center">
                <input type="text" class="w-50 border-0 no-outline" placeholder="Enter distance"
                  [(ngModel)]="fitnessLogDetails.distance">
                <div class="w-50 text-right">
                  <span class="pointer" [class.selected]="fitnessLogDetails.distance_unit=='M'"
                    (click)="fitnessLogDetails.distance_unit='M'">M</span>
                  <span> / </span>
                  <span class="pointer" [class.selected]="fitnessLogDetails.distance_unit=='KM'"
                    (click)="fitnessLogDetails.distance_unit='KM'">KM</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3 align-items-center">
            <div class="col-sm-4">Speed:</div>
            <div class="col-sm-8">
              <div class="border form-control no-radius d-flex align-items-center">
                <input type="text" class="w-50 border-0 no-outline" placeholder="Enter speed"
                  [(ngModel)]="fitnessLogDetails.speed">
                <div class="w-50 text-right">
                  <span class="pointer" [class.selected]="fitnessLogDetails.speed_unit=='M/S'"
                    (click)="fitnessLogDetails.speed_unit='M/S'">M/S</span>
                  <span> / </span>
                  <span class="pointer" [class.selected]="fitnessLogDetails.speed_unit=='KM/HR'"
                    (click)="fitnessLogDetails.speed_unit='KM/HR'">KM/HR</span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="exerciseType=='4'">
          <div class="row mb-3 align-items-center">
            <div class="col-sm-4">Duration:</div>
            <div class="col-sm-8">
              <div class="border form-control no-radius d-flex align-items-center">
                <input type="text" class="w-50 border-0 no-outline" placeholder="Enter duration"
                  [(ngModel)]="fitnessLogDetails.duration">
                <div class="w-50 text-right">
                  <span class="pointer" [class.selected]="fitnessLogDetails.duration_unit=='SEC'"
                    (click)="fitnessLogDetails.duration_unit='SEC'">SEC</span>
                  <span> / </span>
                  <span class="pointer" [class.selected]="fitnessLogDetails.duration_unit=='MIN'"
                    (click)="fitnessLogDetails.duration_unit='MIN'">MIN</span>
                  <span> / </span>
                  <span class="pointer" [class.selected]="fitnessLogDetails.duration_unit=='HR'"
                    (click)="fitnessLogDetails.duration_unit='HR'">HR</span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="exerciseType=='5'">
          <div class="row mb-3 align-items-center">
            <div class="col-sm-4">Reps:</div>
            <div class="col-sm-8">
              <input type="text" class="form-control no-radius" placeholder="Enter reps"
                [(ngModel)]="fitnessLogDetails.reps">
            </div>
          </div>
        </ng-container>

        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Notes:</div>
          <div class="col-sm-8">
            <input type="text" class="form-control no-radius" placeholder="Enter notes"
              [(ngModel)]="fitnessLogDetails.notes">
          </div>
        </div>

        <div class="col-lg-12 text-center">
          <div *ngIf="isNewFitnessLog" class="btn pointer no-radius mb-2 mx-2 secondary-bg text-white"
            (click)="addUpdateFitnessLog(0)">
            Save & New
          </div>
          <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="addUpdateFitnessLog(1)">
            Save
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- #endregion -->

<!-- #endregion -->

<!-- #region Measurement Log -->

<!-- #region Measurement History Log -->
<div class="modal fade" id="measurement-history-log-dialog" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="d-flex w-100">
          <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
          <div class="modal-title w-100 text-center">Measurement Log</div>
        </div>
      </div>
      <div class="modal-body px-4">
        <div class="table-responsive table-list">
          <table class="table mb-0">
            <thead class="thead-light">
              <tr>
                <th>Date</th>
                <th>Measurement</th>
                <th>Log Details</th>
                <th>Notes</th>
                <th class="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of historyMeasurementLogList">
                <td>{{item.measurement_date | date:'dd/MM/yyyy'}}</td>
                <td>{{item.title}}</td>
                <td>{{item.value + ' ' + item.value_unit}} </td>
                <td>{{item.notes}}</td>
                <td>
                  <div class="text-center">
                    <img class="image-btn pointer px-3" src="assets/images/edit.png"
                      (click)="openAddUpdateMeasurementLogDialog(item)">
                    <img class="image-btn pointer" src="assets/images/delete.png" (click)="deleteMeasurementLog(item)">
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- #endregion -->

<!-- #region Workout Fitness History Log -->
<div class="modal fade" id="workout-fitness-log-dialog" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="d-flex w-100">
          <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
          <div class="modal-title w-100 text-center">Workout Fitness Log</div>
        </div>
      </div>
      <div class="modal-body px-4">
        <div class="table-responsive table-list">
          <table class="table mb-0">
            <thead class="thead-light">
              <tr>
                <th>Date</th>
                <th>Movement</th>
                <th>Log Details</th>
                <th>Notes</th>
                <th class="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of workoutFitnessLogList">
                <td>{{item.fitness_date | date:'dd/MM/yyyy'}}</td>
                <td>{{item.title}}</td>
                <td>
                  <ng-container *ngIf="item.exercise_type==1">
                    {{item.weight + ' ' + item.weight_unit}} x {{item.reps}} REPS
                  </ng-container>
                  <ng-container *ngIf="item.exercise_type==2">
                    {{item.reps}} REPS
                  </ng-container>
                  <ng-container *ngIf="item.exercise_type==3">
                    {{item.duration + ' ' + item.duration_unit}} x
                    {{item.distance + ' ' + item.distance_unit}} x
                    {{item.calorie + ' KCAL'}} x
                    {{item.speed + ' ' + item.speed_unit}}
                  </ng-container>
                  <ng-container *ngIf="item.exercise_type==4">
                    {{item.duration + ' ' + item.duration_unit}}
                  </ng-container>
                  <ng-container *ngIf="item.exercise_type==5">
                    {{item.duration + ' ' + item.duration_unit}} x {{item.reps}} REPS
                  </ng-container>
                </td>
                <td>{{item.notes}}</td>
                <td>
                  <div class="text-center">
                    <img class="image-btn pointer px-3" src="assets/images/edit.png"
                      (click)="openAddUpdateFitnessLogDialog(item)">
                    <img class="image-btn pointer" src="assets/images/delete.png"
                      (click)="deleteFitnessLog(item, true)">
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- #endregion -->

<!-- #region Add Update Measurement Log -->
<div class="modal fade" id="add-update-measurement-log" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="d-flex w-100">
          <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
          <div class="modal-title w-100 text-center">{{(isNewMeasurementLog)?'Add':'Update'}} Measurement Log
          </div>
        </div>
      </div>
      <div class="modal-body px-4">
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Measurement:</div>
          <div class="col-sm-8">
            <ng-select [items]="measurementList" placeholder="Select Measurement" bindValue="id" bindLabel="title"
              [clearable]="false" [disabled]="!isNewMeasurementLog" [(ngModel)]="measurementLogDetails.measurement_id"
              (change)="setMeasurementType()">
            </ng-select>
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Date:</div>
          <div class="col-sm-8">
            <input type="date" class="form-control no-radius" placeholder="Select date of bith"
              [(ngModel)]="measurementLogDetails.measurement_date">
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Value:</div>
          <div class="col-sm-8">
            <div class="border form-control no-radius d-flex align-items-center">
              <input type="text" [disabled]="!measurementLogDetails.measurement_id" class="w-50 border-0 no-outline"
                placeholder="Enter value" [(ngModel)]="measurementLogDetails.value">
              <div class="w-50 text-right">
                <ng-container *ngFor="let item of valueUnits;let last=last;">
                  <span class="pointer" [class.selected]="measurementLogDetails.value_unit==item"
                    (click)="measurementLogDetails.value_unit=item">{{item}}</span>
                  <span *ngIf="!last"> / </span>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Notes:</div>
          <div class="col-sm-8">
            <input type="text" class="form-control no-radius" placeholder="Enter notes"
              [(ngModel)]="measurementLogDetails.notes">
          </div>
        </div>
        <div class="col-lg-12 text-center">
          <div *ngIf="isNewMeasurementLog" class="btn pointer no-radius mb-2 mx-2 secondary-bg text-white"
            (click)="addUpdateMeasurementLog(0)">
            Save & New
          </div>
          <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="addUpdateMeasurementLog(1)">
            Save
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- #endregion -->

<!-- #endregion -->

<!-- #region Challenge -->
<div class="modal fade p-0" id="assign-challenge" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered add-session-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <div class="d-flex w-100">
          <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
          <div class="modal-title w-100">Assign Habit</div>
        </div>
      </div>
      <div class="modal-body">
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Habit:</div>
          <div class="col-sm-8">
            <ng-select [items]="challengeDropdownList" placeholder="Select Habit" bindValue="id" bindLabel="title"
              [clearable]="false" [(ngModel)]="challenge_id" [disabled]="!isNewChallenge">
            </ng-select>
          </div>
        </div>

        <div class="row mb-3 align-items-center" *ngIf="!isNewChallenge">
          <div class="col-sm-4 pr-0">Active:</div>
          <div class="col-sm-8">
            <div class="d-flex align-items-center">
              <i *ngIf="!challenge_active" (click)="challenge_active=true;"
                class="fa fa-square-o checkbox secondary-color inactive"></i>
              <i *ngIf="challenge_active" (click)="challenge_active=false;"
                class="fa fa-check-square-o checkbox success-color"></i>
            </div>
          </div>
        </div>

        <div class="row mb-3 align-items-center" *ngIf="isNewChallenge">
          <div class="col-sm-4">Notification:</div>
          <div class="col-sm-8">
            <input type="text" class="form-control no-radius" placeholder="Enter Notification Message"
              [(ngModel)]="challenge_notification">
          </div>
        </div>

        <div class="col-lg-12 text-center">
          <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="assignChallenge()">
            Save & Close
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- #endregion -->

<!-- #region Sticker -->
<div class="modal fade p-0" id="assign-sticker" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered add-session-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <div class="d-flex w-100">
          <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
          <div class="modal-title w-100">
            {{(isNewSticker) ? '' : 'Update'}} Assign Sticker
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Sticker:</div>
          <div class="col-sm-8">

            <ng-select placeholder="Select Sticker" [clearable]="false" [(ngModel)]="assignStickersId"
              [disabled]="!isNewSticker">
              <ng-option *ngFor="let sticker of stickerDropdownList" [value]="sticker.id">
                {{sticker.title}}</ng-option>
            </ng-select>
          </div>
        </div>

        <div class="row mb-3 align-items-center" *ngIf="!isNewSticker">
          <div class="col-sm-4">Collected Date:</div>
          <div class="col-sm-8">
            <input type="date" class="form-control no-radius" placeholder="Select collected date"
              [(ngModel)]="stickersCollectedDate">
          </div>
        </div>

        <div class="row mb-3 align-items-center" *ngIf="!isNewSticker">
          <div class="col-sm-4 pr-0">Active:</div>
          <div class="col-sm-8">
            <div class="d-flex align-items-center">
              <i *ngIf="!stickersStatus" (click)="stickersStatus=true;"
                class="fa fa-square-o checkbox secondary-color inactive"></i>
              <i *ngIf="stickersStatus" (click)="stickersStatus=false;"
                class="fa fa-check-square-o checkbox success-color"></i>
            </div>
          </div>
        </div>

        <div class="row mb-3 align-items-center" *ngIf="isNewSticker">
          <div class="col-sm-4 pr-0">Notification Message:</div>
          <div class="col-sm-8">
            <textarea class="form-control no-radius" placeholder="Enter Notification Message"
              [(ngModel)]="stickerNotificationMsg"></textarea>
          </div>
        </div>
        <div class="col-lg-12 text-center">
          <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="assignSticker()">
            {{(isNewSticker) ? 'Assign' : 'Update'}} Sticker
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- #endregion -->

<!-- #region Workout -->
<div class="modal fade p-0" id="assign-workout" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered add-session-dialog" role="document">
    <div class="modal-content" *ngIf="!warningModal">
      <div class="modal-header text-center">
        <div class="d-flex w-100">
          <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
          <div class="modal-title w-100">
            {{(isNewWorkout) ? '' : 'Update'}} Assign Workout Program
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Workout:</div>
          <div class="col-sm-8">
            <ng-select [items]="workoutDropdownList" placeholder="Select workout" bindValue="id" bindLabel="title"
              [clearable]="false" [(ngModel)]="assignWorkoutId" [disabled]="!isNewWorkout"
              (change)="changeWorkout($event)">
            </ng-select>
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4 pr-0">Start Date:</div>
          <div class="col-sm-8">
            <input type="date" class="form-control no-radius" placeholder="Enter Start Date"
              [(ngModel)]="workoutStartDate">
          </div>
        </div>
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Trainer:</div>
          <div class="col-sm-8">
            <ng-select [multiple]="true" [items]="trainerList" placeholder="Select Trainer" bindValue="id"
              bindLabel="name" [clearable]="false" [(ngModel)]="assignClientTrainerId">
            </ng-select>
          </div>
        </div>

        <div class="row mb-3 align-items-center" *ngIf="!isNewWorkout">
          <div class="col-sm-4 pr-0">Active:</div>
          <div class="col-sm-8">
            <div class="d-flex align-items-center">
              <i *ngIf="!workoutStatus" (click)="workoutStatus=true;"
                class="fa fa-square-o checkbox secondary-color inactive"></i>
              <i *ngIf="workoutStatus" (click)="workoutStatus=false;"
                class="fa fa-check-square-o checkbox success-color"></i>
            </div>
          </div>
        </div>

        <div class="row mb-3 align-items-center" *ngIf="isNewWorkout">
          <div class="col-sm-4 pr-0">Notification Message:</div>
          <div class="col-sm-8">
            <textarea class="form-control no-radius" placeholder="Enter Notification Message"
              [(ngModel)]="workoutNotificationMsg"></textarea>
          </div>
        </div>
        <div class="col-lg-12 text-center">
          <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="checkEquipments()">
            {{(isNewWorkout) ? 'Assign' : 'Update'}} Workout
          </div>
        </div>
      </div>
    </div>
    <div class="modal-content" *ngIf="warningModal">
      <div class="modal-header text-center">
        <div class="d-flex w-100">
          <img src="assets/images/back.png" class="modal-back-btn pointer" (click)="warningModal = false" />
          <div class="modal-title w-100">Client Equipments</div>
        </div>
      </div>
      <div class="modal-body">
        <div class="row mb-3 align-items-center">
          <div class="col-sm-12">
            {{ clientDetails.first_name }} {{ clientDetails.last_name }} does not have
            <ng-container *ngFor="let exercise of missingEquipmentNames">
              <ng-container *ngFor="let equipment of exercise.missingEquipmentNames">
                {{ equipment }}
              </ng-container>
              for {{ exercise.exerciseName }}.
            </ng-container>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-6 text-center">
            <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white">
              Edit
            </div>
          </div>
          <div class="col-lg-6 text-center">
            <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="assignWorkoutProgram()">
              Continue
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- #region Workout program -->
<app-workout-program [isCreate]="isCreate" [type]="'clientProfile'" [selectedWeekId]="commonService.selectedWeekId"
  [exerciseList]="commonService.exerciseList" [weeks]="commonService.weeks" [activeWeekId]="commonService.activeWeekId"
  [workoutProgram]="commonService.workoutProgram" (onModalHidden)="handleModalHidden(true)">
</app-workout-program>

<!-- #region Equipment -->
<div class="modal fade p-0" id="assign-equipment" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered add-session-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <div class="d-flex w-100">
          <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
          <div class="modal-title w-100">
            {{(isNewWorkout) ? '' : 'Update'}} Assign Equipment
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div class="row mb-3 align-items-center">
          <div class="col-sm-4">Equipment:</div>
          <div class="col-sm-8">

            <ng-select [items]="equipmentDropdownList" placeholder="Select Equipment" bindValue="id" bindLabel="name"
              [clearable]="false" [(ngModel)]="assignEquipmentId" [disabled]="!isNewEquipment">
            </ng-select>
          </div>
        </div>

        <div class="col-lg-12 text-center">
          <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="assignEquipment()">
            {{(isNewWorkout) ? 'Assign' : 'Update'}} Equipment
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- #endregion -->

<div class="modal fade" id="challenge-log" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered add-booking-dialog" role="document">
    <div class="modal-content ">
      <div class="modal-header text-center">
        <div class="d-flex align-items-center w-100">
          <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
          <div class="modal-title w-100">{{challengeTitle}}</div>
        </div>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 p-0">
            <div class="calendar-container">
              <div class="calendar-header d-flex align-items-center justify-content-between">
                <div class="pl-3" (click)="goToLastMonth()">
                  <i class="fa fa-chevron-left pointer" aria-hidden="true"></i>
                </div>
                <div class="ion-text-uppercase ls-2">{{monthNames[selectedMonth]}} {{selectedYear}}
                </div>
                <div class="pr-3" (click)="goToNextMonth()">
                  <i class="fa fa-chevron-right pointer" aria-hidden="true"></i>
                </div>
              </div>
              <div class="habits-calendar-body">
                <div class="calendar-table">
                  <div class="row calendar-table-header py-3 m-0">
                    <div class="col box" *ngFor="let day of dayNames">
                      {{day}}
                    </div>
                  </div>
                  <div class="row calendar-date">
                    <div *ngFor="let lastDay of daysInLastMonth" class="col box last-month">
                      <div class="date-box pointer" (click)="setLastMonthDate(lastDay)">
                        {{lastDay}}</div>
                    </div>
                    <div *ngFor="let day of daysInThisMonth" class="col box">
                      <div class="date-box pointer"
                        [class.current-date]="currentDate === day && currentMonth === selectedMonth && currentYear === selectedYear"
                        [ngClass]="getChallengeLogType(day)"
                        (click)="setSelectedDate(day)">
                        <div>{{day}}</div>
                        <span *ngIf="isChallengeNoteExist(day)" class="challenge-note-indicator">
                          ...
                        </span>
                      </div>
                    </div>
                    <div *ngFor="let nextDay of daysInNextMonth" class="col box next-month">
                      <div class="date-box pointer" (click)="setNextMonthDate(nextDay)">
                        {{nextDay}}</div>
                    </div>
                  </div>
                    <div *ngFor="let logs of monthHabitsNotes();">
                      <div >{{logs.challenge_log_date | date: 'd' }}. {{logs.challenge_note}}</div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</div>
<!-- #endregion -->
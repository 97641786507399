import { Component, OnInit } from '@angular/core';
import { PagerService } from 'src/app/shared/pager.service';
import { ToastrService } from 'ngx-toastr';
import { NotificationsService } from './notifications.service';
import { LoaderService } from 'src/app/shared/loader.service';
declare var $: any;

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  requestMade = false;
  isCreate = false;

  searchText = '';
  viewActive = true;
  iniFlag: any = 1;
  pager: any = {};
  rowCount = 0;
  pageSize = 50;
  pageIndex = 1;
  orderBy = 'asc';
  clientList = null;

  notificationTypeList = null;
  filteredNotificationTypeList = null;

  typeList = [];

  notificationTypeId = null;
  title = null;
  description = null;
  type = null;
  configurable = null;
  time = null;
  query = null;
  content = null;
  active = null;

  deleteItem = null;

  sendtoall = false;
  clientId = null;
  messageContent = null;

  constructor(
    private toastr: ToastrService,
    private pagerService: PagerService,
    private notificationService: NotificationsService,
    private loader: LoaderService
  ) { }

  ngOnInit(): void {
    this.clearData();
    this.getTypeList();
    this.getNotificationTypeList(1);
    this.getClientList();
  }

  setPage(page: number) {
    if (page < 1) { return; }
    if (page > this.pager.totalPages) { return; }

    this.pageIndex = page;
    this.pager = this.pagerService.getPager(this.rowCount, this.pageSize, this.pageIndex);
    if (this.iniFlag === 0) {
      this.getNotificationTypeList(0);
    }
  }

  getNotificationTypeList(item) {
    this.loader.show();
    if (item === 1) {
      this.pageIndex = 1;
      this.iniFlag = 1;
    }
    var formData: FormData = new FormData();
    formData.append('title', this.searchText);
    formData.append('page_no', this.pageIndex.toString());
    formData.append('page_size', this.pageSize.toString());
    formData.append('order_by', this.orderBy.toString());
    this.notificationService.getNotificationTypeList(formData).subscribe(
      (response) => {
        if (response.data) {
          this.notificationTypeList = response.data;
          this.rowCount = response.totalrow;
          if (this.iniFlag === 1) {
            this.setPage(1);
            this.iniFlag = 0;
          }
          this.filterNotificationTypeList(this.viewActive)
        } else {
          this.notificationTypeList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  filterNotificationTypeList(view): void {
    this.viewActive = view;
    this.filteredNotificationTypeList = [];
    this.filteredNotificationTypeList = (this.viewActive) ? this.notificationTypeList.filter((e) => e.is_active) : this.notificationTypeList;
    this.sortNotificationTypeList();
  }

  sortNotificationTypeList() {
    if (this.orderBy == 'asc') {
      this.filteredNotificationTypeList.sort((a, b) => {
        if (a.title.toLowerCase() > b.title.toLowerCase()) { return 1; }
        if (a.title.toLowerCase() < b.title.toLowerCase()) { return -1; }
      });
    } else {
      this.filteredNotificationTypeList.sort((a, b) => {
        if (a.title.toLowerCase() > b.title.toLowerCase()) { return -1; }
        if (a.title.toLowerCase() < b.title.toLowerCase()) { return 1; }
      });
    }
  }


  getTypeList() {
    this.typeList = [];
    this.typeList.push({ value: 'Booking' });
    this.typeList.push({ value: 'General' });
  }

  openAddUpdateNotificationTypeModal(isCreate, data) {
    this.clearData();
    this.isCreate = isCreate;
    if (!this.isCreate) {
      this.notificationTypeId = data.id;
      this.title = data.title;
      this.description = data.description;
      this.type = data.type;
      this.configurable = data.is_configurable;
      this.content = data.content;
      this.time = data.notification_time;
      this.query = data.notification_query
      this.active = data.is_active;
    }
    $('#add-update-notification-types').modal('show');
  }

  openSendNotificationModal() {
    this.sendtoall = false;
    this.clientId = null;
    this.messageContent = null;

    $('#send-notification').modal('show');
  }

  getClientList() {
    this.loader.show();
    this.notificationService.getClientList().subscribe(
      (response) => {
        this.requestMade = false;
        if (response.status) {
          this.clientList = response.data;
        } else {
          this.toastr.error(response.message);
        }
        this.loader.hide();
      }, (err) => {
        this.requestMade = false;
        this.loader.hide();
        console.log(err);
      }
    );
  }

  selectAllClients(val) {
    if (val) {
      const selected = this.clientList.map(item => item.id);
      this.clientId = selected;
    } else {
      this.clientId = null;
    }
  }

  sendNotification() {

    if (!this.clientId) {
      this.toastr.error('Please select client.');
      return;
    }
    if (!this.messageContent) {
      this.toastr.error('Please enter Notification message.');
      return;
    }

    var formData: FormData = new FormData();
    formData.append('clientId', this.clientId);
    formData.append('message', this.messageContent);

    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.notificationService.sendNotification(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#send-notification').modal('hide');

            this.sendtoall = false;
            this.clientId = null;
            this.messageContent = null;
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.loader.hide();
          this.requestMade = false;
        }
      );
    }
  }

  clearData(): void {
    this.title = null;
    this.description = null;
    this.type = null;
    this.configurable = null;
    this.content = null;
    this.active = null;
  }


  saveExercise() {
    if (!this.title) {
      this.toastr.error('Please enter title.');
      return;
    }
    if (!this.description) {
      this.toastr.error('Please enter description.');
      return;
    }
    if (!this.type) {
      this.toastr.error('Please select type.');
      return;
    }
    if (this.configurable && !this.time) {
      this.toastr.error('Please enter time.');
      return;
    }
    if (this.configurable && this.time) {
      let time = this.time.split(' ');
      time = time.filter((e) => e);
      if (time.length != 5) {
        this.toastr.error('Invalid time string.');
        return;
      }
      if (time[0] > 59) {
        this.toastr.error('Invalid time string.');
        return;
      }
      if (time[1] > 23) {
        this.toastr.error('Invalid time string.');
        return;
      }
      if (time[2] < 1 || time[2] > 31) {
        this.toastr.error('Invalid time string.');
        return;
      }
      if (time[3] < 1 || time[3] > 12) {
        this.toastr.error('Invalid time string.');
        return;
      }
      if (time[4] > 6) {
        this.toastr.error('Invalid time string.');
        return;
      }
    }
    if (this.configurable && !this.query) {
      this.toastr.error('Please enter query.');
      return;
    }
    if (this.configurable && !this.content) {
      this.toastr.error('Please enter content.');
      return;
    }
    var formData: FormData = new FormData();
    formData.append('title', this.title);
    formData.append('description', (this.description) ? this.description : '');
    formData.append('type', this.type);
    formData.append('is_configurable', (this.configurable) ? '1' : '0');
    formData.append('content', (this.content) ? this.content : '');
    // formData.append('notification_trigger', (this.configurable && this.query) ? '' : '');
    formData.append('notification_time', (this.configurable && this.time) ? this.time : '');
    formData.append('notification_query', (this.configurable && this.query) ? this.query : '');
    formData.append('is_active', (this.active) ? '1' : '0');

    if (this.isCreate) {
      this.createNotificationType(formData);
    } else {
      formData.append('notificationtype_id', this.notificationTypeId);
      this.updateNotificationType(formData);
    }
  }

  createNotificationType(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.notificationService.createNotificationType(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-update-notification-types').modal('hide');
            this.getNotificationTypeList(1);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  updateNotificationType(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.notificationService.updateNotificationType(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-update-notification-types').modal('hide');
            this.getNotificationTypeList(1);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  openDeleteDialog(item) {
    this.deleteItem = item;
    $('#delete-dialog').modal('show');
  }

  deleteNotificationType() {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      var formData: FormData = new FormData();
      formData.append('notitype_id', this.deleteItem.id);
      this.notificationService.deleteNotificationType(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            this.getNotificationTypeList(1);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  @Input() title: string;
  @Input() btnFilterText: string;

  @Output() onFilter: EventEmitter<any> = new EventEmitter<any>();

  fromDate = null;
  toDate = null;

  constructor() { }

  ngOnInit(): void {
  }

  filter() {
    this.onFilter.emit({
      fromDate: this.fromDate,
      toDate: this.toDate
    });
  }
}

<div class="modal fade" id="book-clients" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered add-booking-dialog" role="document">
        <div class="modal-content ">
            <div class="modal-header">
                <div class="d-flex align-items-center w-100">
                    <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
                    <ng-select class="w-100 ml-3" placeholder="Select client" [items]="clientList" bindValue="id"
                        bindLabel="name" [clearable]="false" [readonly]="clientId" [(ngModel)]="selectedClient"
                        (change)="getDateWiseSessionList()">
                    </ng-select>
                </div>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-12 p-0">
                        <div class="calendar-container">
                            <div class="calendar-header d-flex align-items-center justify-content-between">
                                <div class="pl-3" (click)="goToLastMonth()">
                                    <i class="fa fa-chevron-left pointer" aria-hidden="true"></i>
                                </div>
                                <div class="ion-text-uppercase ls-2">{{monthNames[selectedMonth]}} {{selectedYear}}
                                </div>
                                <div class="pr-3" (click)="goToNextMonth()">
                                    <i class="fa fa-chevron-right pointer" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div class="calendar-body">
                                <div class="calendar-table">
                                    <div class="row calendar-table-header py-3 m-0">
                                        <div class="col box" *ngFor="let day of dayNames">
                                            {{day}}
                                        </div>
                                    </div>
                                    <div class="row calendar-date">
                                        <div *ngFor="let lastDay of daysInLastMonth" class="col box last-month">
                                            <div class="date-box pointer" (click)="setLastMonthDate(lastDay)">
                                                {{lastDay}}</div>
                                        </div>
                                        <div *ngFor="let day of daysInThisMonth" class="col box">
                                            <div class="date-box pointer"
                                                [class.current-date]="currentDate === day && currentMonth == selectedMonth && currentYear == selectedYear"
                                                [class.selected-date]="selectedDate === day"
                                                (click)="setSelectedDate(day)">
                                                <div>{{day}}</div>
                                            </div>
                                        </div>
                                        <div *ngFor="let nextDay of daysInNextMonth" class="col box next-month">
                                            <div class="date-box pointer" (click)="setNextMonthDate(nextDay)">
                                                {{nextDay}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="px-5 py-3">
                    <div class="row tabs">
                        <div class="col-6 p-1 text-center pointer" [class.selected]="selectedTab==1"
                            (click)="setSelectedTab(1)">Training
                        </div>
                        <div class="col-6 p-1 text-center pointer" [class.selected]="selectedTab==2"
                            (click)="setSelectedTab(2)">Consultation
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col time-slot-header">
                        <div> {{dayFullNames[selectedDay]}}, {{selectedDate}} {{monthNames[selectedMonth]}}
                            {{selectedYear}}</div>
                    </div>
                </div>
                <div [hidden]="selectedTab==2">
                    <div class="row py-2 border-bottom" *ngFor="let item of bookingSessionList">
                        <div class="col-3 align-self-center pr-0">
                            <div class="d-flex text-center">
                                <div>{{item.start_time | date:'hh'}}:{{item.start_time | date:'mm'}}
                                    <br>{{item.start_time | date:'aa'}}
                                </div>
                                <div class="d-flex align-items-center px-1">-</div>
                                <div>{{item.end_time | date:'hh'}}:{{item.end_time | date:'mm'}}
                                    <br>{{item.end_time | date:'aa'}}
                                </div>
                            </div>
                        </div>
                        <div class="col-4 align-self-center">
                            <div class="fs-16 text-ellipsis">{{item.name}}</div>
                            <div class="secondary-color text-ellipsis">{{item.first_name}} {{item.last_name}}</div>
                        </div>
                        <div class="col-2 align-self-center">
                            <div class="text-center desc">
                                <div class="fs-16">{{item.bookedcount}}/{{item.size}}</div>
                                <div class="fs-12 secondary-color">Booked</div>
                            </div>
                        </div>
                        <div class="col-3 align-self-center">
                            <div *ngIf="item.size>item.bookedcount" (click)="addBooking(item.id)"
                                class="btn pointer no-radius w-100 primary-bg text-white">
                                Book
                            </div>
                            <div *ngIf="item.waitlist && item.size==item.bookedcount && item.waitlist_size > item.waitlistcount"
                                (click)="addBooking(item.id)"
                                class="btn pointer no-radius w-100 secondary-bg text-white">
                                Waitlist
                            </div>
                            <div *ngIf="(!item.waitlist && item.size==item.bookedcount ) || (item.waitlist && item.waitlist_size == item.waitlistcount)"
                                class="btn pointer no-radius w-100 danger-bg text-white">
                                Full
                            </div>
                        </div>
                    </div>
                </div>
                <div [hidden]="selectedTab==1">
                    <div class="row py-2 border-bottom" *ngFor="let item of consultationSessionList">
                        <div class="col-3 align-self-center pr-0">
                            <div class="d-flex text-center">
                                <div>{{item.start_time | date:'hh'}}:{{item.start_time | date:'mm'}}
                                    <br>{{item.start_time | date:'aa'}}
                                </div>
                                <div class="d-flex align-items-center px-1">-</div>
                                <div>{{item.end_time | date:'hh'}}:{{item.end_time | date:'mm'}}
                                    <br>{{item.end_time | date:'aa'}}
                                </div>
                            </div>
                        </div>
                        <div class="col-4 align-self-center">
                            <div class="fs-16 text-ellipsis">{{item.name}}</div>
                            <div class="secondary-color text-ellipsis">{{item.first_name}} {{item.last_name}}</div>
                        </div>
                        <div class="col-2 align-self-center">
                            <div class="text-center desc">
                                <div class="fs-16">{{item.bookedcount}}/{{item.size}}</div>
                                <div class="fs-12 secondary-color">Booked</div>
                            </div>
                        </div>
                        <div class="col-3 align-self-center">
                            <div *ngIf="item.size>item.bookedcount" (click)="addBooking(item.id)"
                                class="btn pointer no-radius w-100 primary-bg text-white">
                                Book
                            </div>
                            <div *ngIf="item.waitlist && item.size==item.bookedcount && item.waitlist_size > item.waitlistcount"
                                (click)="addBooking(item.id)"
                                class="btn pointer no-radius w-100 secondary-bg text-white">
                                Waitlist
                            </div>
                            <div *ngIf="(!item.waitlist && item.size==item.bookedcount ) || (item.waitlist && item.waitlist_size == item.waitlistcount)"
                                class="btn pointer no-radius w-100 danger-bg text-white">
                                Full
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { GuiService } from 'src/app/shared/gui.service';
import { StorageService } from 'src/app/shared/storage.service';
import { UserInfo } from 'src/app/model/user-profile';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {

  userDetails: UserInfo = null;

  constructor(public guiService: GuiService, public storageService: StorageService) {

  }

  ngOnInit(): void {
    this.userDetails = this.storageService.loggedInUser;
  }

  toggleNav() {
    this.guiService.showNavbar = !this.guiService.showNavbar;
    this.guiService.navbarToggled.next(true);
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GuiService } from 'src/app/shared/gui.service';
import { ToastrService } from 'ngx-toastr';
import { ResetPasswordService } from './reset-password.service';
import { LoaderService } from 'src/app/shared/loader.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  requestMade = false;
  isValid = false;
  message = '';

  email = null;
  newPassword = null;
  confirmPassword = null;
  token = null;

  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public guiService: GuiService,
    private resetPasswordService: ResetPasswordService,
    private loader: LoaderService
  ) { }

  ngOnInit(): void {
    this.guiService.showNavbar = false;
    this.guiService.showToggleMenu = false;
    this.guiService.showProfileMenu = false;
    this.email = this.route.snapshot.queryParamMap.get('email');
    this.token = this.route.snapshot.queryParamMap.get('token');
    if (this.email && this.token) {
      this.isValid = true;
    } else {
      this.isValid = false;
      this.message = 'Invalid link.'
    }
  }

  resetPassword() {
    if (!this.email) {
      this.toastr.error('Please enter email.');
      return;
    }
    if (!this.newPassword) {
      this.toastr.error('Please enter password.');
      return;
    }
    if (this.newPassword != this.confirmPassword) {
      this.toastr.error('Password and confirm password not match.');
      return;
    }
    var formData: FormData = new FormData();
    formData.append('email', this.email);
    formData.append('token', this.token);
    formData.append('newpassword', this.newPassword);
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.resetPasswordService.resetPassword(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.isValid = false;
            this.message = 'Your password has been changed. Please try to login with your new password.'
          } else {
            this.isValid = false;
            this.message = response.message;
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }
}

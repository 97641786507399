<div class="container-fluid main-content">
    <div class="row pt-2">
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
                <h4 class="my-2">Settings </h4>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <ul class="nav nav-pills my-2" role="tablist">
                <!--
                <li class="nav-item">
                    <a class="nav-link" [class.active]="selectedTab=='General'" id="General-tab" data-toggle="tab"
                        href="#General" role="tab" aria-controls="General" aria-selected="true">General</a>
                </li>
                -->
                <li class="nav-item">
                    <a class="nav-link" [class.active]="selectedTab=='ASCTeam'" id="ASCTeam-tab" data-toggle="tab"
                        href="#ASCTeam" role="tab" aria-controls="ASCTeam" aria-selected="true">ASC Team</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [class.active]="selectedTab=='Documents'" id="Documents-tab" data-toggle="tab"
                        href="#Documents" role="tab" aria-controls="Documents" aria-selected="false">Documents</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [class.active]="selectedTab=='Blogs'" id="Blog-tab" data-toggle="tab"
                        href="#Blogs" role="tab" aria-controls="Blogs" aria-selected="false">Blogs</a>
                </li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane fade" [ngClass]="selectedTab=='General'?'active show':''" id="General"
                    role="tabpanel" aria-labelledby="General-tab">
                    <div class="row py-3">
                        <div class="col-lg-4">
                            <div class="mb-3 d-flex align-items-center justify-content-between">
                                <h3 class="m-0">General Settings</h3>
                                <div class="btn pointer no-radius primary-bg text-white">
                                    Save Changes
                                </div>
                            </div>
                            <div class="row mb-3 align-items-center">
                                <div class="col-sm-4">Company Name:</div>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control no-radius" placeholder="Enter company name"
                                        [(ngModel)]="generalSetting.first_name">
                                </div>
                            </div>
                            <div class="row mb-3 align-items-center">
                                <div class="col-sm-4">Phone Number:</div>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control no-radius" placeholder="Enter phone number"
                                        [(ngModel)]="generalSetting.last_name">
                                </div>
                            </div>
                            <div class="row mb-3 align-items-center">
                                <div class="col-sm-4">Email:</div>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control no-radius" placeholder="Enter email"
                                        [(ngModel)]="generalSetting.email">
                                </div>
                            </div>
                            <div class="row mb-3 align-items-center">
                                <div class="col-sm-4">Website:</div>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control no-radius" placeholder="Enter website"
                                        [(ngModel)]="generalSetting.phone">
                                </div>
                            </div>
                            <div class="row mb-3 align-items-center">
                                <div class="col-sm-4">Address:</div>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control no-radius" placeholder="Enter address"
                                        [(ngModel)]="generalSetting.dob">
                                </div>
                            </div>
                            <div class="row mb-3 align-items-center">
                                <div class="col-sm-4">Google Maps:</div>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control no-radius" placeholder="Enter google maps"
                                        [(ngModel)]="generalSetting.height">
                                </div>
                            </div>
                            <div class="row mb-3 align-items-center">
                                <div class="col-sm-4">Mailing List:</div>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control no-radius" placeholder="Enter mailing list"
                                        [(ngModel)]="generalSetting.height">
                                </div>
                            </div>
                            <div class="row mb-3 align-items-center">
                                <div class="col-sm-4">App Version:</div>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control no-radius" placeholder="Enter app version"
                                        [(ngModel)]="generalSetting.height">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="row">
                                <div class="col-sm-6">
                                    <h3 class="m-0">Link Settings</h3>
                                </div>
                                <div class="col-sm-6 text-right align-self-center">
                                    <ul class="table-feature">
                                        <li>
                                            <div class="primary-btn pointer">
                                                Add Link <img src="assets/images/add-white.png" alt="images">
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-lg-12">
                                    <div class="table-responsive table-list">
                                        <table class="table">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th>Description</th>
                                                    <th>URL Link</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Instagram</td>
                                                    <td>https://www.www.com</td>
                                                    <td>
                                                        <div class="text-center">
                                                            <img src="assets/images/edit.png" class="image-btn pointer"
                                                                alt="images" />
                                                            <img src="assets/images/delete.png"
                                                                class="image-btn pointer pl-2" alt="images" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Facebook</td>
                                                    <td>https://www.www.com</td>
                                                    <td>
                                                        <div class="text-center">
                                                            <img src="assets/images/edit.png" class="image-btn pointer"
                                                                alt="images" />
                                                            <img src="assets/images/delete.png"
                                                                class="image-btn pointer pl-2" alt="images" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Youtube</td>
                                                    <td>https://www.www.com</td>
                                                    <td>
                                                        <div class="text-center">
                                                            <img src="assets/images/edit.png" class="image-btn pointer"
                                                                alt="images" />
                                                            <img src="assets/images/delete.png"
                                                                class="image-btn pointer pl-2" alt="images" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" [ngClass]="selectedTab=='ASCTeam'?'active show':''" id="ASCTeam"
                    role="tabpanel" aria-labelledby="ASCTeam-tab">
                    <div class="row pt-3">
                        <div class="col-sm-6">
                            <h3 class="m-0">ASC Team</h3>
                        </div>
                        <div class="col-sm-6 text-right align-self-center">
                            <ul class="table-feature">
                                <li>
                                    <div class="primary-btn pointer" (click)="openAddUpdateTeamMemberModal(1,null)">
                                        Add Team <img src="assets/images/add-white.png" alt="images">
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="table-responsive table-list">
                                <table class="table">
                                    <thead class="thead-light">
                                        <tr>
                                            <th>Name</th>
                                            <th>Title</th>
                                            <th>Description</th>
                                            <th>Position</th>
                                            <th>Active</th>
                                            <th class="px-3">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of teamMemberList">
                                            <td>{{item.name}}</td>
                                            <td>{{item.designation}}</td>
                                            <td>{{item.description}}</td>
                                            <td class="text-center">{{item.position}}</td>
                                            <td>
                                                <div class="d-flex justify-content-center">
                                                    <img *ngIf="item.is_active" src="assets/images/check-square.png"
                                                        class="image-btn pointer" alt="images" />
                                                </div>
                                            </td>
                                            <td>
                                                <div class="d-flex justify-content-center">
                                                    <img src="assets/images/edit.png" class="image-btn pointer"
                                                        alt="images" (click)="openAddUpdateTeamMemberModal(0,item)" />
                                                    <img src="assets/images/delete.png" class="image-btn pointer pl-2"
                                                        alt="images" (click)="openDeleteDialog('team',item)" />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" [ngClass]="selectedTab=='Documents'?'active show':''" id="Documents"
                    role="tabpanel" aria-labelledby="Documents-tab">
                    <div class="row pt-3">
                        <div class="col-sm-6">
                            <h3 class="m-0">Support Documents</h3>
                        </div>
                        <div class="col-sm-6 text-right align-self-center">
                            <ul class="table-feature">
                                <li>
                                    <div class="primary-btn pointer" (click)="openAddUpdateDocumentModal(1,null)">
                                        Add Docs <img src="assets/images/add-white.png" alt="images">
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="table-responsive table-list">
                                <table class="table">
                                    <thead class="thead-light">
                                        <tr>
                                            <th class="text-left">Document Title</th>
                                            <th class="text-left">Position</th>
                                            <th class="text-center">Agreement</th>
                                            <th class="text-center">Active</th>
                                            <th class="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of documentList">
                                            <td>{{item.title}}</td>
                                            <td class="text-center">{{item.position}}</td>
                                            <td class="text-center">
                                                <img *ngIf="item.agreement" src="assets/images/check-square.png"
                                                    class="image-btn pointer" alt="images" />
                                            </td>
                                            <td class="text-center">
                                                <img *ngIf="item.active" src="assets/images/check-square.png"
                                                    class="image-btn pointer" alt="images" />
                                            </td>
                                            <td>
                                                <div class="text-center">
                                                    <img src="assets/images/edit.png" class="image-btn pointer"
                                                        alt="images" (click)="openAddUpdateDocumentModal(0,item)" />
                                                    <img src="assets/images/delete.png" class="image-btn pointer pl-2"
                                                        alt="images" (click)="openDeleteDialog('document',item)" />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" [ngClass]="selectedTab=='Blogs'?'active show':''" id="Blogs" role="tabpanel"
                    aria-labelledby="Blogs-tab">
                    <div class="row pt-3">
                        <div class="col-sm-6">
                            <h3 class="m-0">Blogs</h3>
                        </div>
                        <div class="col-sm-6 text-right align-self-center">
                            <ul class="table-feature">
                                <li>
                                    <div class="primary-btn pointer" (click)="openAddUpdateBlogModal(1,null)">
                                        Add Blog <img src="assets/images/add-white.png" alt="images">
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="table-responsive table-list">
                                <table class="table">
                                    <thead class="thead-light">
                                        <tr>
                                            <th class="text-left">Blog Title</th>
                                            <th class="text-left">URL</th>
                                            <th class="text-center">Date</th>
                                            <th class="text-center">Image</th>
                                            <th class="text-center">Active</th>
                                            <th class="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of blogList">
                                            <td>{{item.title}}</td>
                                            <td class="text-left"><a href="{{item.url_link}}"
                                                    target="_blank">{{item.url_link}}</a></td>
                                            <td class="text-center">{{item.blog_date| date:'dd/MM/yyyy'}}</td>
                                            <td class="text-center">
                                                <div class="text-center">
                                                    <img *ngIf="item.blog_image" src="assets/images/image.svg"
                                                        class="image-btn pointer success-color" alt="images"
                                                        (click)="openImageLink(item.blog_image)" />
                                                </div>
                                            </td>
                                            <td class="text-center">
                                                <img *ngIf="item.active" src="assets/images/check-square.png"
                                                    class="image-btn pointer" alt="images" />
                                            </td>
                                            <td>
                                                <div class="text-center">
                                                    <img src="assets/images/edit.png" class="image-btn pointer"
                                                        alt="images" (click)="openAddUpdateBlogModal(0,item)" />
                                                    <img src="assets/images/delete.png" class="image-btn pointer pl-2"
                                                        alt="images" (click)="openDeleteDialog('blog',item)" />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="blogList.length === 0">
                                            <td colspan="6">
                                                No data available
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade p-0" id="add-team-member" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered add-session-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header text-center">
                <div class="d-flex w-100">
                    <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
                    <div class="modal-title w-100">{{(isCreate)?'Add':'Update'}} Team Member</div>
                </div>
            </div>
            <div class="modal-body">
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Name:</div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control no-radius" placeholder="Enter name"
                            [(ngModel)]="memberName">
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Designation:</div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control no-radius" placeholder="Enter designation"
                            [(ngModel)]="memberDesignation">
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Description:</div>
                    <div class="col-sm-8">
                        <textarea rows="3" class="form-control no-radius" placeholder="Enter description"
                            [(ngModel)]="memberDescription"></textarea>
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Position:</div>
                    <div class="col-sm-8">
                        <input type="number" class="form-control no-radius" placeholder="Enter position"
                            [(ngModel)]="memberPosition">
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4 pr-0">Image:</div>
                    <div class="col-sm-8">
                        <div class="form-control form-file-control no-radius">
                            <label for="fileInput"
                                class="file-name">{{(fileInput)?fileInput.name:'Upload image'}}</label>
                            <label for="fileInput" class="file-btn">Browse</label>
                            <input type="file" class="d-none" id="fileInput" (change)="onFileChange($event)" />
                        </div>
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Active:</div>
                    <div class="col-sm-8">
                        <div class="d-flex align-items-center">
                            <i *ngIf="!memberActive" (click)="memberActive=true;"
                                class="fa fa-square-o checkbox secondary-color inactive"></i>
                            <i *ngIf="memberActive" (click)="memberActive=false;"
                                class="fa fa-check-square-o checkbox success-color"></i>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 text-center">
                    <div class="btn pointer no-radius mb-2 mx-2 secondary-bg text-white" (click)="clearData()">
                        Clear Form
                    </div>
                    <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="saveTeamMember()">
                        Save & Close
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade p-0" id="add-document" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg modal-dialog-centered add-document" role="document">
        <div class="modal-content">
            <div class="modal-header text-center">
                <div class="d-flex w-100">
                    <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
                    <div class="modal-title w-100">{{(isCreate)?'Add':'Update'}} Document</div>
                </div>
            </div>
            <div class="modal-body">
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-2">Title:</div>
                    <div class="col-sm-4">
                        <input type="text" class="form-control no-radius" placeholder="Enter title"
                            [(ngModel)]="documentTitle">
                    </div>

                    <div class="col-sm-2 text-right">Position:</div>
                    <div class="col-sm-4">
                        <input type="number" class="form-control no-radius" placeholder="Enter position"
                            [(ngModel)]="documentPosition">
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-sm-2">Content:</div>
                    <div class="col-sm-10">
                        <textarea rows="15" class="form-control no-radius" placeholder="Enter content"
                            [(ngModel)]="documentContent"></textarea>
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Require Agreement:</div>
                    <div class="col-sm-2">
                        <div class="d-flex align-items-center">
                            <i *ngIf="!documentAgreement" (click)="documentAgreement=true;"
                                class="fa fa-square-o checkbox secondary-color inactive"></i>
                            <i *ngIf="documentAgreement" (click)="documentAgreement=false;"
                                class="fa fa-check-square-o checkbox success-color"></i>
                        </div>
                    </div>
                    <div class="col-sm-4">Active:</div>
                    <div class="col-sm-2">
                        <div class="d-flex align-items-center">
                            <i *ngIf="!documentActive" (click)="documentActive=true;"
                                class="fa fa-square-o checkbox secondary-color inactive"></i>
                            <i *ngIf="documentActive" (click)="documentActive=false;"
                                class="fa fa-check-square-o checkbox success-color"></i>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 text-center">
                    <div class="btn pointer no-radius mb-2 mx-2 secondary-bg text-white" (click)="clearDocumentData()">
                        Clear Form
                    </div>
                    <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="saveDocument()">
                        Save & Close
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade p-0" id="add-blog" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered add-session-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header text-center">
                <div class="d-flex w-100">
                    <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
                    <div class="modal-title w-100">{{(isCreate)?'Add':'Update'}} Blog</div>
                </div>
            </div>
            <div class="modal-body">
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Title:</div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control no-radius" placeholder="Enter title"
                            [(ngModel)]="blogTitle">
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">URL:</div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control no-radius" placeholder="Enter URL"
                            [(ngModel)]="blogUrlLink">
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Date:</div>
                    <div class="col-sm-8">
                        <input type="date" class="form-control no-radius" placeholder="Enter date"
                            [(ngModel)]="blogDate">

                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4 pr-0">Image:</div>
                    <div class="col-sm-8">
                        <div class="form-control form-file-control no-radius">
                            <label for="fileInput"
                                class="file-name">{{(fileInput)?fileInput.name:'Upload image'}}</label>
                            <label for="fileInput" class="file-btn">Browse</label>
                            <input type="file" class="d-none" id="fileInput" (change)="onFileChange($event)" />
                        </div>
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Active:</div>
                    <div class="col-sm-8">
                        <div class="d-flex align-items-center">
                            <i *ngIf="!blogActive" (click)="blogActive=true;"
                                class="fa fa-square-o checkbox secondary-color inactive"></i>
                            <i *ngIf="blogActive" (click)="blogActive=false;"
                                class="fa fa-check-square-o checkbox success-color"></i>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 text-center">
                    <div class="btn pointer no-radius mb-2 mx-2 secondary-bg text-white" (click)="clearData()">
                        Clear Form
                    </div>
                    <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="saveBlog()">
                        Save & Close
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-delete (onConfirm)="deleteRecord()"></app-delete>
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DocumentApi, TeamMemberApi, BlogApi } from 'src/app/const/app.url';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpClient) { }

  getTeamMemberList() {
    return this.http.get<any>(TeamMemberApi.getTeamMemberList)
  }

  addTeamMember(body?: any) {
    return this.http.post<any>(TeamMemberApi.addTeamMember, body)
  }

  updateTeamMember(body?: any) {
    return this.http.post<any>(TeamMemberApi.updateTeamMember, body)
  }

  deleteTeamMember(body?: any) {
    return this.http.post<any>(TeamMemberApi.deleteTeamMember, body)
  }

  getDocumentList() {
    return this.http.get<any>(DocumentApi.getDocumentList)
  }

  addDocument(body?: any) {
    return this.http.post<any>(DocumentApi.addDocument, body)
  }

  updateDocument(body?: any) {
    return this.http.post<any>(DocumentApi.updateDocument, body)
  }

  deleteDocument(body?: any) {
    return this.http.post<any>(DocumentApi.deleteDocument, body)
  }


  getBlogList(body?: any) {
    return this.http.post<any>(BlogApi.getAdminBlogList, body)
  }

  addBlog(body?: any) {
    return this.http.post<any>(BlogApi.createBlog, body)
  }

  updateBlog(body?: any) {
    return this.http.post<any>(BlogApi.editBlog, body)
  }

  deleteBlog(body?: any) {
    return this.http.post<any>(BlogApi.deleteBlog, body)
  }
}

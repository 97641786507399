import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {
  Exercise,
  Section,
  Workout,
  WorkoutProgram,
} from 'src/app/model/workout.model';
import { LoaderService } from 'src/app/shared/loader.service';
import { StorageService } from 'src/app/shared/storage.service';
import { WorkoutService } from '../workout/workout.service';
import { CommonService } from 'src/app/shared/common.service';
declare var $: any;

@Component({
  selector: 'app-workout-program',
  templateUrl: './workout-program.component.html',
  styleUrls: ['./workout-program.component.scss'],
})
export class WorkoutProgramComponent implements OnInit {
  @Output() onModalShown = new EventEmitter<void>();
  @Output() onModalHidden = new EventEmitter<void>();
  @Input() workoutProgram: WorkoutProgram;
  @Input() isCreate: boolean = false;
  @Input() workoutProgramId: any;
  @Input() activeWeekId: number;
  @Input() selectedWeekId: number;
  @Input() weeks = [];
  @Input() exerciseList = [];
  @Input() type: string = 'clientProfile' || 'workout';
  exerciseHistory: any[] = [];
  weekNotifications: { [key: number]: boolean } = {};  // only for add workout modal

  constructor(
    private toastr: ToastrService,
    private storageService: StorageService,
    private loader: LoaderService,
    private workoutService: WorkoutService,
    private el: ElementRef,
    public commonService: CommonService
  ) { }

  async openModal() {
    if (!this.isCreate && this.workoutProgramId) {
      await this.commonService.getWorkoutDetailById(this.workoutProgramId);
      this.workoutProgram = this.commonService.workoutProgram;
    }
      $(this.el.nativeElement).find('#add-workout').modal('show');
  }

  closeModal(isData: any) {
    $(this.el.nativeElement).find('#add-workout').modal('hide').on('hidden.bs.modal', () => {
      this.onModalHidden.emit(isData);
    });
  }

  ngOnInit() {}

  isWeekNotificationActive(weekId: number): boolean {
    const workoutsInWeek = this.workoutProgram.workout.filter(workout => workout.week === weekId);
    if (workoutsInWeek.length) {
      return workoutsInWeek.length > 0 && workoutsInWeek.every(workout => workout.week_notification === 1);
    } else {
      return !!this.weekNotifications[weekId];
    }
  }

  toggleWeekNotification(weekId: number, value: number): void {
    const workoutsInWeek = this.workoutProgram.workout.filter(workout => workout.week === weekId);
    if (workoutsInWeek.length){
      workoutsInWeek.forEach(workout => {
        workout.week_notification = value;
      });
    } else {
      this.weekNotifications[weekId] = value === 1;
    }
  }

  removeExercise(workoutIndex: number, sectionIndex: number, index: number) {
    const exercise = this.workoutProgram.workout[workoutIndex].section[sectionIndex].exercise[index];
  
    // If the exercise does not have an id, it is considered newly added and can be removed directly
    if (!exercise.id || this.isCreate) {
      this.workoutProgram.workout[workoutIndex].section[sectionIndex].exercise.splice(index, 1);
    } else {
      // Otherwise, mark it as deleted
      exercise.is_delete = 1;
    }
  }

  saveWorkoutProgram(dismissModal = false) {
    if (!this.workoutProgram.program_title) {
      this.toastr.error('Please enter program title.');
      return;
    } else if (!this.workoutProgram.program_description) {
      this.toastr.error('Please enter program description.');
      return;
    } else if (
      !this.workoutProgram.image &&
      !this.workoutProgram.program_image
    ) {
      this.toastr.error('Please select program image.');
      return;
    } else if (this.workoutProgram.workout.length === 0) {
      this.toastr.error('Workout day required.');
      return;
    }
    for (const workout of this.workoutProgram.workout) {
      if (workout.is_delete === 1) {
        continue;
      }
      if (!workout.workout_title) {
        this.toastr.error('Please enter workout title.');
        return;
      } else if (!workout.workout_description) {
        this.toastr.error('Please enter workout description.');
        return;
      } else if (workout.section.length === 0) {
        this.toastr.error('Section required.');
        return;
      }
      if (!workout.workout_image && !workout.image) {
        this.toastr.error('Please select workout image.');
        return;
      }
      for (const section of workout.section) {
        if (section.is_delete === 1) {
          continue;
        }
        if (!section.section_title) {
          this.toastr.error('Please enter section title.');
          return;
        } else if (!section.section_position) {
          this.toastr.error('Please enter section position.');
          return;
        } else if (section.exercise.length === 0) {
          this.toastr.error('Exercise required.');
          return;
        }
        for (const exercise of section.exercise) {
          if (exercise.is_delete === 1) {
            continue;
          }
          if (!exercise.exercise_id) {
            this.toastr.error('Please select exercise.');
            return;
          } else if (
            typeof exercise.exercise_sets !== 'number' ||
            exercise.exercise_sets < 1
          ) {
            this.toastr.error('Please enter exercise sets.');
            return;
          } else if (
            typeof exercise.target_min !== 'number' ||
            exercise.target_min < 1
          ) {
            this.toastr.error('Please enter exercise min reps/time.');
            return;
          } else if (
            typeof exercise.target_max !== 'number' ||
            exercise.target_max < 1
          ) {
            this.toastr.error('Please enter exercise max reps/time.');
            return;
          } else if (
            typeof exercise.exercise_rest !== 'number' ||
            exercise.exercise_rest < 0
          ) {
            this.toastr.error('Please enter valid exercise rest time.');
            return;
          } else if (
            typeof exercise.exercise_position !== 'number' ||
            exercise.exercise_position < 1
          ) {
            this.toastr.error('Please enter valid exercise position.');
            return;
          }
        }
      }
    }

    const formData = new FormData();
    const client_id = this.storageService.loggedInUser.id;
    formData.append('client_id', client_id.toString());
    formData.append('workout_program', JSON.stringify(this.workoutProgram));
    if (this.isCreate) {
      this.createExercise(formData, dismissModal);
    } else {
      this.updateExercise(formData, dismissModal);
    }
  }

  openHistoryExerciseModal(exerciseId) {
    this.loader.show();
    this.workoutService
      .getUpdatedExercise({ workout_exercise_id: exerciseId })
      .subscribe(
        (response) => {
          if (response.data) {
            this.exerciseHistory = response.data;
          } else {
            this.exerciseHistory = [];
          }
        },
        (err) => {
          console.log(err);
        }
      );
      const $historyDialog = $('#history-exercise-dialog');
  
      $historyDialog.modal('show');
      this.loader.hide();

    // Add event listener for modal hidden event
    const onHiddenHandler = () => {
      if ($('#add-workout').hasClass('show')) {
        $('body').addClass('modal-open');
      }
      // Remove the event listener after it's been triggered
      $historyDialog.off('hidden.bs.modal', onHiddenHandler);
    };

    $historyDialog.on('hidden.bs.modal', onHiddenHandler);
  }

  createExercise(formData, dismissModal) {
      this.loader.show();
      this.workoutService.createWorkout(formData).subscribe(
        (response) => {
          if (response.status) {
            this.toastr.success(response.message);
            if (dismissModal) {
              this.closeModal(true);
            }
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        },
        (err) => {
          this.loader.hide();
          console.log(err);
        }
      );
  }

  updateExercise(formData, dismissModal) {
      this.loader.show();
      this.workoutService.updateWorkout(formData).subscribe(
        (response) => {
          if (response.status) {
            if(response.data.workout_program) {
              this.commonService.workoutProgram = response.data.workout_program;
              this.commonService.getWeeks();
            }
            this.toastr.success(response.message);
            if (dismissModal) {
              this.closeModal(true);
            }
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        },
        (err) => {
          this.toastr.error('Failed to save workout program');
          this.loader.hide();
          console.log(err);
        }
      );
  }

  setAsWeek() {
    // selectedWeekId must be a number
    this.selectedWeekId = Number(this.selectedWeekId);

    if (this.selectedWeekId === this.activeWeekId) {
      return; // No change needed if it's already the active week
    }

    const currentActiveWeekId = this.activeWeekId;

    const currentIndex = this.weeks.indexOf(currentActiveWeekId);
    const selectedIndex = this.weeks.indexOf(this.selectedWeekId);

    // If selectedWeekId doesn't exist in this.weeks, return
    if (selectedIndex === -1) {
      return;
    }

    // Determine if selected week is before or after current active week
    const isBeforeCurrent = selectedIndex < currentIndex;

    // Update workoutProgram.workout based on the new active week position
    this.workoutProgram.workout.forEach((workout) => {
      if (isBeforeCurrent) {
        // Increment week values for weeks between selectedWeekId and currentActiveWeekId
        if (
          workout.week >= this.selectedWeekId &&
          workout.week < currentActiveWeekId
        ) {
          workout.week += 1;
        } else if (workout.week === currentActiveWeekId) {
          workout.week = this.selectedWeekId;
        }
      } else {
        // Decrement week values for weeks between currentActiveWeekId and selectedWeekId
        if (
          workout.week <= this.selectedWeekId &&
          workout.week > currentActiveWeekId
        ) {
          workout.week -= 1;
        } else if (workout.week === currentActiveWeekId) {
          workout.week = this.selectedWeekId;
        }
      }
    });

    // Update the weeks array accordingly
    if (isBeforeCurrent) {
      this.weeks = this.weeks.map((week) => {
        if (week >= this.selectedWeekId && week < currentActiveWeekId) {
          return week + 1;
        } else if (week === currentActiveWeekId) {
          return this.selectedWeekId;
        }
        return week;
      });
    } else {
      this.weeks = this.weeks.map((week) => {
        if (week <= this.selectedWeekId && week > currentActiveWeekId) {
          return week - 1;
        } else if (week === currentActiveWeekId) {
          return this.selectedWeekId;
        }
        return week;
      });
    }

    // ReIndex weeks array to represent in sequence
    this.weeks.sort((a, b) => a - b);

    // Update activeWeekId to the selected week
    this.activeWeekId = this.selectedWeekId;

    // Reindex workoutProgram.workout week numbers to match sequential week order
    const weekMapping = {};
    this.weeks.forEach((week, index) => {
      weekMapping[week] = index + 1;
    });

    this.workoutProgram.workout.forEach((workout) => {
      workout.week = weekMapping[workout.week];
    });

    // Update the weeks array to reflect new sequential order
    this.weeks = this.weeks.map((week) => weekMapping[week]);
  }

  deleteWeek() {
    let indexToDelete = this.weeks.indexOf(this.activeWeekId);

    if (indexToDelete !== -1) {
      // Use splice to remove the week at the found index
      this.weeks.splice(indexToDelete, 1);
      const deletedWeekId = this.activeWeekId;

      // Remove or mark as deleted workouts for the deleted week
      if (this.isCreate) {
        this.workoutProgram.workout = this.workoutProgram.workout.filter(
          (workout) => workout.week !== deletedWeekId
        );
      } else {
        this.workoutProgram.workout = this.workoutProgram.workout.map(
          (workout) => {
            if (workout.week === deletedWeekId) {
              return { ...workout, is_delete: 1 };
            }
            return workout;
          }
        );
      }

      // Re-index the weeks to maintain sequence
      this.reindexWeeks();

      // Adjust the week numbers in workoutProgram.workout
      this.workoutProgram.workout.forEach((workout) => {
        if (workout.week > deletedWeekId) {
          workout.week -= 1;
        }
      });

      // Update active week to the previous or next week if available
      if (this.weeks.length > 0) {
        const newActiveIndex = indexToDelete === 0 ? 0 : indexToDelete - 1;
        this.selectWeek(this.weeks[newActiveIndex]);
      } else {
        // If no weeks are left, set activeWeekId to null
        this.activeWeekId = null;
      }
    }
  }

  reindexWeeks() {
    this.weeks.forEach((week, index) => {
      const newId = index + 1;
      if (week !== newId) {
        this.weeks[index] = newId;
      }
    });

    // Updated the activeWeekId, if the active week was re-indexed
    if (this.activeWeekId && this.weeks.indexOf(this.activeWeekId) === -1) {
      this.activeWeekId = null; // If the active week is deleted, set it to null
    }
  }

  addSection(index: number) {
    this.workoutProgram.workout[index].section.push(new Section());
  }

  removeSection(workoutIndex: number, index: number) {
    const section = this.workoutProgram.workout[workoutIndex].section[index];
  
    // If the section does not have an id, it is considered newly added and can be removed directly
    if (!section.id || this.isCreate) {
      this.workoutProgram.workout[workoutIndex].section.splice(index, 1);
    } else {
      // Otherwise, mark it as deleted
      section.is_delete = 1;
    }
  }

  addExercise(workoutIndex: number, sectionIndex: number) {
    this.workoutProgram.workout[workoutIndex].section[
      sectionIndex
    ].exercise.push(new Exercise());
  }

  addWorkout() {
    const workout = new Workout();
    workout.week = this.activeWeekId;
    const existingWorkoutsInWeek = this.workoutProgram.workout.filter(w => w.week === this.activeWeekId);
    if (existingWorkoutsInWeek.length) {
      // For existing workouts, inherit the notification value from existing workouts if available
      workout.week_notification = existingWorkoutsInWeek.length > 0 ? existingWorkoutsInWeek[0].week_notification : 0;
    } else {
      // For new workouts, use the tracked week notification value
      workout.week_notification = this.weekNotifications[this.activeWeekId] ? 1 : 0;
    }
    this.workoutProgram.workout.push(workout);
  }

  removeWorkout(index: number) {
    const workout = this.workoutProgram.workout[index];
  
    // If the workout does not have an id, it is considered newly added and can be removed directly
    if (!workout.id || this.isCreate) {
      this.workoutProgram.workout.splice(index, 1);
    } else {
      // Otherwise, mark it as deleted
      workout.is_delete = 1;
    }
  }

  duplicateWeek() {
    if (this.activeWeekId) {
      // Find the active week to duplicate
      const newWeekNumber = Math.max(...this.weeks) + 1;

      if (newWeekNumber) {
        this.weeks.push(newWeekNumber);

        const newWorkouts = this.workoutProgram.workout
          .filter((workout) => workout.week === this.activeWeekId)
          .map((workout) => {
            const newWorkout = {
              isDuplicate: true,
              ...workout,
              week: newWeekNumber,
              section: workout.section.map((section) => ({
                ...section,
                id: undefined,
                exercise: section.exercise.map((exercise) => ({
                  ...exercise,
                  id: undefined,
                })),
              })),
            };

            newWorkout.id = undefined; // Skip copying 'id' from workout
            return newWorkout;
          });

        this.workoutProgram.workout.push(...newWorkouts);

        // Select the new week
        this.selectWeek(newWeekNumber);
      }
    }
  }

  selectWeek(weekId: number) {
    this.activeWeekId = weekId;
    this.selectedWeekId = weekId;
  }

  onFileChange($event, type, index) {
    const fileInput = $event.target.files[0];
    const fileName = fileInput.name;
    const fileExtension = fileInput.name.split('.').pop();

    var fileReader: FileReader = new FileReader();
    fileReader.onloadend = (e) => {
      if (type === 'program') {
        this.workoutProgram.program_image = fileReader.result.toString();
        this.workoutProgram.program_image_name = fileName;
        this.workoutProgram.program_image_ext = fileExtension;
      } else {
        this.workoutProgram.workout[index].workout_image =
          fileReader.result.toString();
        this.workoutProgram.workout[index].workout_image_name = fileName;
        this.workoutProgram.workout[index].workout_image_ext = fileExtension;
      }
    };
    fileReader.readAsDataURL(fileInput);
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GuiService } from '../../shared/gui.service';
import { CheckoutService } from './checkout.service';
import { environment } from 'src/environments/environment';
import { LoaderService } from 'src/app/shared/loader.service';
declare var ThreeDS: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  merchantId = 'E12724950';
  redirectURL = environment.API_URL + 'processPayment';
  tokenId = '';
  sessionId = '';
  orderId = '';
  transactionId = '';
  amount = 0;
  showProgress = true;
  totalProcess = 5;
  finishedProcess = 0;
  progress = 0;

  constructor(
    private route: ActivatedRoute,
    private guiService: GuiService,
    private checkoutService: CheckoutService,
    private loader: LoaderService
  ) { }

  ngOnInit(): void {
    this.guiService.showNavbar = false;
    this.guiService.showToggleMenu = false;
    this.guiService.showProfileMenu = false;
    this.finishedProcess = 0;
    const data = this.route.snapshot.queryParamMap.get('data');
    this.getClientCheckoutData(data);
  }

  getClientCheckoutData(data) {
    this.loader.show();
    var formData: FormData = new FormData();
    formData.append('data', data);
    this.checkoutService.getClientCheckoutData(formData).subscribe(
      (response) => {
        console.log(response);
        const data = response.data;
        this.tokenId = data.tokenId;
        this.sessionId = data.payment_gateway;
        this.orderId = data.payment_gateway_payment_id;
        this.transactionId = data.payment_gateway_payment_id;
        this.amount = data.amount;
        this.calculateProgress();
        this.configureThreeDS();
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  configureThreeDS() {
    ThreeDS.configure({
      merchantId: this.merchantId,
      sessionId: this.sessionId,
      containerId: "3DSUI",
      callback: () => {
        if (ThreeDS.isConfigured()) {
          console.log("Done with configure");
          console.log("ThreeDS JS API Version : " + ThreeDS.version);
          this.calculateProgress();
          this.initiateAuthentication();
        }
      },
      configuration: {
        userLanguage: "en-AU", //Optional parameter
        wsVersion: 58
      }
    });
  }

  initiateAuthentication() {
    var optionalParams = {
      sourceOfFunds: {
        type: "CARD"
      },
      authentication: {
        acceptVersions: "3DS1,3DS2"
      },
    };
    ThreeDS.initiateAuthentication(this.orderId, this.transactionId, (data) => {
      if (data && data.error) {
        var error = data.error;

        //Something bad happened, the error value will match what is returned by the Authentication API
        console.error("error.code : ", error.code);
        console.error("error.msg : ", error.msg);
        console.error("error.result : ", error.result);
        console.error("error.status : ", error.status);
      } else {
        console.log("After Initiate 3DS ", data);

        //data.response will contain information like gatewayRecommendation, authentication version, etc.
        console.log("REST API raw response ", data.restApiResponse);
        console.log("Correlation Id", data.correlationId);
        console.log("Gateway Recommendation", data.gatewayRecommendation);
        console.log("HTML Redirect Code", data.htmlRedirectCode);
        console.log("Authentication Version", data.authenticationVersion);
        switch (data.gatewayRecommendation) {
          case "PROCEED":
            this.calculateProgress();
            this.authenticatePayer();//merchant's method
            break;
          case "DO_NOT_PROCEED":
            // this.displayReceipt(data);//merchant's method, you can offer the payer the option to try another payment method.
            break;
        }
      }
    }, optionalParams);
  }

  authenticatePayer() {
    var optionalParams = {
      fullScreenRedirect: true,
      billing: {
        address: {
          city: "Manama",
          country: "BHR"
        }
      }
    };

    const retryDelayMs = 2000;

    console.log('Authenticate Payer Attempt 1');
    setTimeout(() => {
      ThreeDS.authenticatePayer(this.orderId, this.transactionId, (data: any) => {
        console.log(data);
        if (!data.error) {
          //data.response will contain all the response payload from the AUTHENTICATE_PAYER call.
          console.log("REST API response ", data.restApiResponse);
          console.log("HTML redirect code", data.htmlRedirectCode);
          // this.displayReceipt(data);
        }
        else {
          this.calculateProgress();
          console.log('Authenticate Payer Attempt 2');
          setTimeout(() => {
            ThreeDS.authenticatePayer(this.orderId, this.transactionId, (data: any) => {
              console.log(data);
              if (!data.error) {
                //data.response will contain all the response payload from the AUTHENTICATE_PAYER call.
                console.log("REST API response ", data.restApiResponse);
                console.log("HTML redirect code", data.htmlRedirectCode);
                // this.displayReceipt(data);
              }
              else {
                this.calculateProgress();
                console.log('Authenticate Payer Attempt 3');
                setTimeout(() => {
                  ThreeDS.authenticatePayer(this.orderId, this.transactionId, (data: any) => {
                    console.log(data);
                    if (!data.error) {
                      //data.response will contain all the response payload from the AUTHENTICATE_PAYER call.
                      console.log("REST API response ", data.restApiResponse);
                      console.log("HTML redirect code", data.htmlRedirectCode);
                      // this.displayReceipt(data);
                    }
                    else {
                      this.calculateProgress();
                      console.log('Authenticate Payer Attempt 4');
                      setTimeout(() => {
                        ThreeDS.authenticatePayer(this.orderId, this.transactionId, (data: any) => {
                          console.log(data);
                          if (!data.error) {
                            //data.response will contain all the response payload from the AUTHENTICATE_PAYER call.
                            console.log("REST API response ", data.restApiResponse);
                            console.log("HTML redirect code", data.htmlRedirectCode);
                            // this.displayReceipt(data);
                          }
                          else {
                            this.calculateProgress();
                            console.log('Authenticate Payer Attempt 5');
                            setTimeout(() => {
                              ThreeDS.authenticatePayer(this.orderId, this.transactionId, (data: any) => {
                                console.log(data);
                                if (!data.error) {
                                  //data.response will contain all the response payload from the AUTHENTICATE_PAYER call.
                                  console.log("REST API response ", data.restApiResponse);
                                  console.log("HTML redirect code", data.htmlRedirectCode);
                                  // this.displayReceipt(data);
                                }
                              }, optionalParams);
                            }, retryDelayMs);
                          }
                        }, optionalParams);
                      }, retryDelayMs);
                    }
                  }, optionalParams);
                }, retryDelayMs);
              }
            }, optionalParams);
          }, retryDelayMs);
        }
      }, optionalParams);
    }, retryDelayMs);
  }

  calculateProgress() {
    this.finishedProcess++;
    const progress = Math.round((this.finishedProcess * 100) / this.totalProcess);
    this.progress = (progress > 100) ? 100 : progress;
  }
}

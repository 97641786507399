import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientApi } from 'src/app/const/app.url';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  getDashboardCounts() {
    return this.http.get<any>(ClientApi.dashboardCounts)
  }

  todaySessionList() {
    return this.http.get<any>(ClientApi.todaySessionList)
  }

  getClientList() {
    return this.http.get<any>(ClientApi.getClientListWithoutPagination)
  }

  getGroupList() {
    return this.http.get<any>(ClientApi.groupList)
  }

  activityTimeline(body?: any) {
    return this.http.post<any>(ClientApi.activityTimeline,body)
  }
}

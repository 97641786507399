import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MembershipService } from './membership.service';
import { PagerService } from 'src/app/shared/pager.service';
declare var $: any;
import * as moment from 'moment';
import { LoaderService } from 'src/app/shared/loader.service';

@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.scss']
})
export class MembershipComponent implements OnInit {

  membershipList: any;
  membershipTypeList: any;
  sessionTypeList: any;
  membershipLocalityList: any;
  groupList: any;
  filteredMembershipList = null;

  requestMade = false;
  isCreate = false;
  deleteItem = null;

  currentDateObj = null;

  recurring_tolerance_end = null;
  recurring_tolerance_start = null;
  recurring_tolerance_renew = null;
  tolerance_grace = null;

  searchText = '';
  viewActive = true;
  iniFlag: any = 1;
  pager: any = {};
  rowCount = 0;
  pageSize = 50;
  pageIndex = 1;
  orderBy = 'asc';

  membershipId = null;
  title = null;
  sessionType = null;
  reference = null;
  credits = null;
  description = null;
  totalfee = null;
  membershipType = null;
  firstMonthFee = null;
  locality = null;
  subsequentFees = null;
  startDate = null;
  clientGroup = null;
  endDate = null;
  active = null;
  duration = null;

  constructor(
    private toastr: ToastrService,
    private membershipService: MembershipService,
    private pagerService: PagerService,
    private loader: LoaderService
  ) { }

  ngOnInit(): void {
    this.clearData();
    this.getMembershipList(1);
    this.getsessionTypeList();
    this.getmembershipTypeList();
    this.getMembershipLocalityList();
    this.getGroupList();
  }

  getMembershipList(item) {
    this.loader.show();
    if (item === 1) {
      this.pageIndex = 1;
      this.iniFlag = 1;
    }

    var formData: FormData = new FormData();
    formData.append('searchname', this.searchText);
    formData.append('page_no', this.pageIndex.toString());
    formData.append('page_size', this.pageSize.toString());
    formData.append('order_by', this.orderBy.toString());

    this.membershipService.getMembershipList(formData).subscribe(
      (response) => {
        if (response.data) {
          this.membershipList = response.data;
          this.rowCount = response.totalrow;
          if (this.iniFlag === 1) {
            this.setPage(1);
            this.iniFlag = 0;
          }
          this.filterMembershipList(this.viewActive)
        } else {
          this.membershipList = [];
          this.filteredMembershipList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  openAddGlobalConfig() {
    $('#add-globalconfig').modal('show');
    this.clearGlobalConfig();
    this.loader.show();
    this.membershipService.getMembershipGlobalConfigData().subscribe(
      (response) => {
        if (response.data) {
          if (response.data.length > 0) {
            this.recurring_tolerance_end = response.data[0].recurring_tolerance_end;
            this.recurring_tolerance_start = response.data[0].recurring_tolerance_start;
            this.recurring_tolerance_renew = response.data[0].recurring_tolerance_renew;
            this.tolerance_grace = response.data[0].tolerance_grace;
          }
        } else {
          this.sessionTypeList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  clearGlobalConfig() {
    this.recurring_tolerance_end = null;
    this.recurring_tolerance_start = null;
    this.recurring_tolerance_renew = null;
    this.tolerance_grace = null;
  }

  saveMembershipSetting() {

    if (!this.recurring_tolerance_end) {
      this.toastr.error('Please recurring tolerance end.');
      return;
    } if (!this.recurring_tolerance_start) {
      this.toastr.error('Please recurring tolerance start.');
      return;
    } if (!this.recurring_tolerance_renew) {
      this.toastr.error('Please recurring tolerance renew.');
      return;
    } if (!this.tolerance_grace) {
      this.toastr.error('Please tolerance grace.');
      return;
    }

    var formData: FormData = new FormData();
    formData.append('recurring_tolerance_end', this.recurring_tolerance_end);
    formData.append('recurring_tolerance_start', this.recurring_tolerance_start);
    formData.append('recurring_tolerance_renew', this.recurring_tolerance_renew);
    formData.append('tolerance_grace', this.tolerance_grace);

    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.membershipService.saveMembershipSetting(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-globalconfig').modal('hide');
            this.clearGlobalConfig();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  getsessionTypeList() {
    this.loader.show();
    this.membershipService.getsessionTypeList().subscribe(
      (response) => {
        if (response.data) {
          this.sessionTypeList = response.data;
        } else {
          this.sessionTypeList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  getmembershipTypeList() {
    this.loader.show();
    this.membershipService.getmembershipTypeList().subscribe(
      (response) => {
        if (response.data) {
          this.membershipTypeList = response.data;
        } else {
          this.membershipTypeList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  getMembershipLocalityList() {
    this.loader.show();
    this.membershipService.getMembershipLocalityList().subscribe(
      (response) => {
        if (response.data) {
          this.membershipLocalityList = response.data;
        } else {
          this.membershipLocalityList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  getGroupList() {
    this.loader.show();
    this.membershipService.getGroupList().subscribe(
      (response) => {
        if (response.data) {
          this.groupList = response.data;
        } else {
          this.groupList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  setPage(page: number) {
    if (page < 1) { return; }
    if (page > this.pager.totalPages) { return; }

    this.pageIndex = page;
    this.pager = this.pagerService.getPager(this.rowCount, this.pageSize, this.pageIndex);
    if (this.iniFlag === 0) {
      this.getMembershipList(0);
    }
  }

  filterMembershipList(view): void {
    this.viewActive = view;
    this.filteredMembershipList = [];
    this.filteredMembershipList = (this.viewActive) ? this.membershipList.filter((e) => e.active) : this.membershipList;
    //this.sortMembershipList();
  }

  sortMembershipList() {
    if (this.orderBy == 'asc') {
      this.filteredMembershipList.sort((a, b) => {
        if (a.title.toLowerCase() > b.title.toLowerCase()) { return 1; }
        if (a.title.toLowerCase() < b.title.toLowerCase()) { return -1; }
      });
    } else {
      this.filteredMembershipList.sort((a, b) => {
        if (a.title.toLowerCase() > b.title.toLowerCase()) { return -1; }
        if (a.title.toLowerCase() < b.title.toLowerCase()) { return 1; }
      });
    }
  }

  clearData(): void {
    this.membershipId = null;
    this.title = null;
    this.sessionType = null;
    this.reference = null;
    this.credits = null;
    this.description = null;
    this.totalfee = null;
    this.membershipType = null;
    this.firstMonthFee = null;
    this.locality = null;
    this.subsequentFees = null;
    this.startDate = null;
    this.clientGroup = null;
    this.endDate = null;
    this.active = null;
    this.duration = null;
  }

  saveMembership() {

    if (!this.title) {
      this.toastr.error('Please enter title.');
      return;
    }
    if (!this.sessionType) {
      this.toastr.error('Please select session type.');
      return;
    }
    if (!this.reference) {
      this.toastr.error('Please enter reference.');
      return;
    }
    if (!this.description) {
      this.toastr.error('Please enter description.');
      return;
    }
    if (!this.membershipType) {
      this.toastr.error('Please select Membership Type.');
      return;
    }
    if (!this.locality) {
      this.toastr.error('Please select locality.');
      return;
    }
    if (this.membershipType == '6' || this.membershipType == 6) {
      if (!this.startDate || this.startDate == 'Invalid date') {
        this.toastr.error('Please enter Start Date.');
        return;
      }
      if (!this.endDate || this.endDate == 'Invalid date') {
        this.toastr.error('Please enter End Date.');
        return;
      }
    }
    // if (!this.startDate) {
    //   this.toastr.error('Please enter Start Date.');
    //   return;
    // }
    if (!this.clientGroup) {
      this.toastr.error('Please select Client Group.');
      return;
    }
    // if (!this.endDate) {
    //   this.toastr.error('Please enter End Date.');
    //   return;
    // }
    if (this.duration !== "") {
      if (isNaN(this.duration)) {
        this.toastr.error('Please enter valid duration.');
        return;
      }
    }

    if (this.firstMonthFee !== "") {
      if (isNaN(this.firstMonthFee)) {
        this.toastr.error('Please enter valid First Month Fee.');
        return;
      }
    }
    if (this.subsequentFees !== "") {
      if (isNaN(this.subsequentFees)) {
        this.toastr.error('Please enter valid Subsequent Fees.');
        return;
      }
    }
    if (this.credits === "") {
      this.toastr.error('Please enter credits.');
      return;
    } else {
      if (isNaN(this.credits)) {
        this.toastr.error('Please enter valid credits.');
        return;
      }
    }

    if (this.totalfee !== "") {
      if (isNaN(this.totalfee)) {
        this.toastr.error('Please enter valid total fee.');
        return;
      }
    }

    var formData: FormData = new FormData();
    formData.append('package_name', this.title);
    formData.append('description', this.description);
    formData.append('membership_type_id', this.membershipType);
    formData.append('membership_locality_id', this.locality);
    formData.append('start_date', this.startDate);
    formData.append('end_date', this.endDate);
    formData.append('duration', this.duration);
    formData.append('session_type_ids', this.sessionType);
    formData.append('active', (this.active) ? '1' : '0');
    formData.append('group_ids', this.clientGroup);
    formData.append('reference', this.reference);

    formData.append('credits', this.credits);
    if (this.subsequentFees !== null) {
      formData.append('subsequent_month_fee', this.subsequentFees);
    }
    if (this.firstMonthFee !== null) {
      formData.append('first_month_fee', this.firstMonthFee);
    }
    if (this.totalfee !== null) {
      formData.append('total_fee', this.totalfee);
    } else {
      formData.append('total_fee', "0");
    }

    if (this.isCreate) {
      this.createMembership(formData);
    } else {
      formData.append('membership_id', this.membershipId);
      this.updateMembership(formData);
    }
  }

  openAddUpdateMembershipModal(isCreate, data) {
    this.clearData();
    this.isCreate = isCreate;
    if (!this.isCreate) {
      this.membershipId = data.id;
      this.title = data.package_name;
      this.sessionType = data.session_type_ids.split(',').map(i => Number(i));
      this.reference = data.reference;
      this.credits = data.credits;
      this.description = data.description;
      this.totalfee = data.total_fee;
      this.membershipType = data.membership_type_id;
      this.firstMonthFee = data.first_month_fee;
      this.locality = data.membership_locality_id;
      this.subsequentFees = data.subsequent_month_fee;
      this.startDate = (data.start_date) ? moment(data.start_date).format('YYYY-MM-DD') : '';
      this.clientGroup = data.group_ids.split(',').map(i => Number(i));
      this.endDate = (data.end_date) ? moment(data.end_date).format('YYYY-MM-DD') : '';
      this.active = data.active;
      this.duration = data.duration;
      console.log(data);
    }
    $('#add-membership').modal('show');
  }


  createMembership(formData) {

    if (!this.requestMade) {
      this.requestMade = true;
      this.loader.show();
      this.membershipService.createMembership(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-membership').modal('hide');
            this.getMembershipList(1);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  updateMembership(formData) {

    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.membershipService.updateMembership(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-membership').modal('hide');
            this.getMembershipList(1);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  openDeleteDialog(item) {
    this.deleteItem = item;
    $('#delete-dialog').modal('show');
  }

  deleteMembership() {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      var formData: FormData = new FormData();
      formData.append('membership_id', this.deleteItem.id);
      this.membershipService.deleteMembership(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            this.getMembershipList(1);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }
}

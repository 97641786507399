import { Injectable } from '@angular/core';
import { UserInfo } from '../model/user-profile';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private _loggedInUser: UserInfo;
  public get loggedInUser() {
    return this._loggedInUser;
  }
  public set loggedInUser(value) {
    this._loggedInUser = value;
    if (value) {
      this.setProfileImage();
      this.setMenu();
      localStorage.setItem('LoggedInUser', JSON.stringify(value));
    } else {
      localStorage.removeItem('LoggedInUser');
    }
  }

  private _menus: any;
  public get menus() {
    return this._menus;
  }

  public profileImage = ''

  public data: any;

  constructor() {
    const loggedInUser = JSON.parse(localStorage.getItem('LoggedInUser'));
    if (loggedInUser) {
      this._loggedInUser = loggedInUser;
      this.setProfileImage();
      this.setMenu();
    }
  }

  setProfileImage() {
    const avatar = this._loggedInUser.avatar;
    this.profileImage = environment.BASE_URL + avatar;
  }

  setMenu() {
    this._menus = [];
    this._menus.push({ title: 'Dashboard', url: 'dashboard', isOpened: false, hasSubMenu: false, subMenu: [] });
    this._menus.push({ title: 'Bookings', url: 'bookings', isOpened: false, hasSubMenu: false, subMenu: [] });
    this._menus.push({
      title: 'Sessions', url: '', isOpened: false, hasSubMenu: true, subMenu: [{
        title: 'Default Sessions',
        url: 'sessions',
      },
      {
        title: 'Override Sessions',
        url: 'override-sessions',
      }]
    });
    this._menus.push({ title: 'Clients', url: 'clients', isOpened: false, hasSubMenu: false, subMenu: [] });
    this._menus.push({ title: 'Exercises', url: 'exercises', isOpened: false, hasSubMenu: false, subMenu: [] });
    this._menus.push({ title: 'Equipments', url: 'equipments', isOpened: false, hasSubMenu: false, subMenu: [] });
    this._menus.push({ title: 'Measurements', url: 'measurements', isOpened: false, hasSubMenu: false, subMenu: [] });
    this._menus.push({ title: 'Workout Program', url: 'workout', isOpened: false, hasSubMenu: false, subMenu: [] });
    this._menus.push({ title: 'Membership', url: 'membership', isOpened: false, hasSubMenu: false, subMenu: [] });
    this._menus.push({ title: 'Habits', url: 'challenge', isOpened: false, hasSubMenu: false, subMenu: [] });
    this._menus.push({ title: 'Stickers', url: 'stickers', isOpened: false, hasSubMenu: false, subMenu: [] });
    this._menus.push({ title: 'Notifications', url: 'notifications', isOpened: false, hasSubMenu: false, subMenu: [] });
    this._menus.push({ title: 'Reports', url: 'report', isOpened: false, hasSubMenu: false, subMenu: [] });
    this._menus.push({ title: 'Settings', url: 'settings', isOpened: false, hasSubMenu: false, subMenu: [] });
    this._menus.push({ title: 'Logout', url: 'log-out', isOpened: false, hasSubMenu: false, subMenu: [] });
  }

  clearStorage() {
    this.loggedInUser = null;
  }
}

<div class="container-fluid main-content">
    <div class="row">
        <div class="col-lg-12">
            <div class="d-flex align-items-center justify-content-between">
                <h4 class="my-2">Client Dashboard</h4>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-5 pr-0">
            <div class="d-flex align-items-center justify-content-between search-box">
                <!-- <input type="text" class="form-control" placeholder="Search..." autocomplete="off"
                    [(ngModel)]="searchText" (input)="getClientList(1)"> -->
                <input class="form-control" [(ngModel)]="searchName" type="text" name="searchName" id="searchId"
                    (ngModelChange)="this.searchUpdate.next($event)">
                <div class="icon px-2"><i class="fa fa-search"></i></div>
            </div>
        </div>
        <div class="col-7 text-right pl-0">
            <ul class="table-feature">
                <li>
                    <div class="view-btn pointer">
                        <div class="p-0" [class.active]="viewActive" (click)="filterClientList(true)">Active</div>
                        <div>/</div>
                        <div class="p-0" [class.active]="!viewActive" (click)="filterClientList(false)">All</div>
                    </div>
                </li>
                <!-- <li>
                    <div class="pagination" *ngIf="pager.pages && pager.pages.length">
                        <ul class="page-number"> -->
                <!-- <li>
                                <div (click)="setPage(1)" [ngClass]="{ disabled: pager.currentPage === 1 }">
                                    <i class="fa fa-angle-double-left"></i>
                                </div>
                            </li> -->
                <!-- <li (click)="setPage(pager.currentPage - 1)" [class.disabled]="pager.currentPage === 1">
                                <i class="fa fa-angle-left"></i>
                            </li>
                            <li *ngFor="let page of pager.pages" (click)="setPage(page)"
                                [class.active]="pager.currentPage === page">
                                {{ page }}
                            </li>
                            <li (click)="setPage(pager.currentPage + 1)"
                                [class.disabled]="pager.currentPage === pager.totalPages">
                                <i class="fa fa-angle-right"></i>
                            </li> -->
                <!-- <li>
                                <div (click)="setPage(pager.totalPages)"
                                    [ngClass]="{ disabled: pager.currentPage === pager.totalPages }">
                                    <i class="fa fa-angle-double-right"></i>
                                </div>
                            </li> -->
                <!-- </ul>
                    </div>
                </li> -->
                <!-- <li>
                    <div class="sort-by">
                        <select [(ngModel)]="pageSize" (change)="getClientList(1)">
                            <option [value]="50">50/Pages</option>
                            <option [value]="100">100/Pages</option>
                            <option [value]="150">150/Pages</option>
                        </select>
                    </div>
                </li>

                <li>
                    <div class="sort-by">
                        <select [(ngModel)]="orderBy" (change)="getClientList(1)">
                            <option [value]="''">Sort by</option>
                            <option [value]="'asc'">A To Z</option>
                            <option [value]="'desc'">Z To A</option>
                        </select>
                    </div>
                </li> -->
                <li class="ml-2">
                    <div class="primary-btn pointer pl-1 pr-2" (click)="openAddUpdateClientModal(1,null)"><img
                            src="assets/images/add-white.png" alt="images"></div>
                </li>
                <li class="ml-1">
                    <div class="image-btn pointer" (click)="openAddUpdateGoalDialog();">
                        <img src="assets/images/setting.png" class="image-btn" />
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="col" *ngFor="let item of filteredClientList">
            <!-- <div class="client-card" (click)="openAddUpdateClientModal(0,item)"> -->
            <div class="client-card">
                <div class="row m-0 py-1 pointer" (click)="goToProfile(item)" [class.primary-bg]="item.active"
                    [class.secondary-bg]="!item.active" [class.danger-bg]="item.overduecount>0"
                    [class.warning-bg]="item.duecount>0">
                    <div class="col-12 p-0 d-flex align-items-center">
                        <img class="profile-img mx-2" [src]="(item.avatar) ? baseURL + item.avatar: 'assets/avatar.png'"
                            onerror="this.onerror=null;this.src='assets/avatar.png';" />
                        <div class="p-0 px-2">
                            <div class="text-white">{{item.first_name}} {{item.last_name}}</div>
                        </div>
                    </div>
                </div>
                <div class="row m-0 py-1 px-3">
                    <div class="col-12 p-0 py-2 d-flex align-items-center justify-content-between border-bottom phone">
                        <img src="assets/images/feather-phone@3x.png" class="icon-img" alt="images">
                        <div> {{item.phone}} </div>
                    </div>
                </div>
                <div class="row m-0 py-1 px-3 ">
                    <div class="col-7 p-0 border-bottom">
                        <div>Last trained</div>
                        <div>{{(item.last_trained) ? (item.last_trained | date:'dd/MM/yyyy'): '-'}}</div>
                    </div>
                    <div class="col-5 p-0 border-bottom d-flex justify-content-end">
                        <div class="date pr-1"> {{ getDaysAgo(item.last_trained) }} </div>
                        <div class="text-center"> DAYS <br>AGO </div>
                    </div>
                </div>
                <div class="row p-3">
                    <div class="col-12">
                        <div class="d-flex align-items-center justify-content-between">
                            <div>Booked sessions</div>
                            <div>{{item.bookedcount || 0}}</div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="d-flex align-items-center justify-content-between">
                            <div>Payments</div>
                            <div *ngIf="item.overduecount>0" class="dollar-sign red">$</div>
                            <div *ngIf="item.duecount>0 && item.overduecount==0" class="dollar-sign yellow">$</div>
                            <div *ngIf="item.overduecount==0 && item.duecount==0" class="dollar-sign green">$</div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="d-flex align-items-center justify-content-between">
                            <div>Client status</div>
                            <div>{{(item.active)?'Active':'Inactive'}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="add-client" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered add-client-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header text-center">
                <div class="d-flex w-100">
                    <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
                    <div class="modal-title w-100">{{(isCreate)?'Add':'Update'}} Client</div>
                </div>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">First Name:</div>
                            <div class="col-sm-8">
                                <input type="text" class="form-control no-radius" placeholder="Enter first Name"
                                    [(ngModel)]="userDetails.first_name">
                            </div>
                        </div>
                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">Last Name:</div>
                            <div class="col-sm-8">
                                <input type="text" class="form-control no-radius" placeholder="Enter last Name"
                                    [(ngModel)]="userDetails.last_name">
                            </div>
                        </div>
                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">Email:</div>
                            <div class="col-sm-8">
                                <input type="text" class="form-control no-radius" placeholder="Enter email"
                                    [(ngModel)]="userDetails.email">
                            </div>
                        </div>
                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">Phone:</div>
                            <div class="col-sm-8">
                                <input type="text" class="form-control no-radius" placeholder="Enter phone number"
                                    [(ngModel)]="userDetails.phone">
                            </div>
                        </div>
                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">Date of Birth:</div>
                            <div class="col-sm-8">
                                <input type="date" class="form-control no-radius" placeholder="Select date of bith"
                                    [(ngModel)]="userDetails.dob">
                            </div>
                        </div>
                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">Gender:</div>
                            <div class="col-sm-8">
                                <ng-select [items]="genderList" placeholder="Select gender" bindValue="value"
                                    bindLabel="value" [clearable]="false" [(ngModel)]="userDetails.gender">
                                </ng-select>
                            </div>
                        </div>
                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">Client Group:</div>
                            <div class="col-sm-8">
                                <ng-select [multiple]="true" [items]="groupList" placeholder="Select client group"
                                    bindValue="id" bindLabel="name" [clearable]="false"
                                    [(ngModel)]="userDetails.groups">
                                </ng-select>
                            </div>
                        </div>
                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">User Type:</div>
                            <div class="col-sm-8">
                                <ng-select [items]="userTypeList" placeholder="Select user type" bindValue="value"
                                    bindLabel="value" [clearable]="false" [(ngModel)]="userDetails.user_type">
                                </ng-select>
                            </div>
                        </div>
                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">Client Status:</div>
                            <div class="col-sm-8">
                                <ng-select [items]="statusList" placeholder="Select status" bindValue="id"
                                    bindLabel="value" [clearable]="false" [(ngModel)]="userDetails.active">
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">New Password:</div>
                            <div class="col-sm-8">
                                <input type="password" class="form-control no-radius" placeholder="Enter new password"
                                    autocomplete="new-password" [(ngModel)]="newPassword">
                            </div>
                        </div>
                        <div class="row mb-3 align-items-center">
                            <div class="col-sm-4">Confirm Password:</div>
                            <div class="col-sm-8">
                                <input type="password" class="form-control no-radius"
                                    placeholder="Enter confirm password" [(ngModel)]="confirmPassword">
                            </div>
                        </div>
                        <div class="row mb-3 align-items-center">
                            <div class="col-12">Limit access to different functions of the app:</div>
                        </div>
                        <div class="row mb-3 align-items-center">
                            <div class="col-12">
                                <ul class="functions_section">
                                    <li>
                                        <span>
                                            Video Library:
                                        </span>
                                        <span>
                                            <input type="checkbox" id="Library" switch="none"
                                                [(ngModel)]="userDetails.feature_video">
                                            <label for="Library"></label>
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            Workout Program:
                                        </span>
                                        <span>
                                            <input type="checkbox" id="Workout" switch="none"
                                                [(ngModel)]="userDetails.feature_program">
                                            <label for="Workout"></label>
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            Fitness Log:
                                        </span>
                                        <span>
                                            <input type="checkbox" id="Fitness" switch="none"
                                                [(ngModel)]="userDetails.feature_log">
                                            <label for="Fitness"></label>
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            Food Diary:
                                        </span>
                                        <span>
                                            <input type="checkbox" id="Diary" switch="none"
                                                [(ngModel)]="userDetails.feature_diary">
                                            <label for="Diary"></label>
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            Measurements:
                                        </span>
                                        <span>
                                            <input type="checkbox" id="Measurements" switch="none"
                                                [(ngModel)]="userDetails.feature_measurements">
                                            <label for="Measurements"></label>
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            Habits:
                                        </span>
                                        <span>
                                            <input type="checkbox" id="Habits" switch="none"
                                                [(ngModel)]="userDetails.feature_challenges">
                                            <label for="Habits"></label>
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            Stickers:
                                        </span>
                                        <span>
                                            <input type="checkbox" id="Stickers" switch="none"
                                                [(ngModel)]="userDetails.feature_stickers">
                                            <label for="Stickers"></label>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 text-center">
                    <div class="btn pointer no-radius mb-2 mx-2 secondary-bg text-white" (click)="clearData()">
                        Clear Form
                    </div>
                    <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="saveClient()">
                        Save & Close
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- #region Goal -->
<div class="modal fade" id="add-update-goal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered booking-settings-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="d-flex w-100">
                    <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
                    <!-- <div class="modal-title w-100 text-center">Client Goals Configurations</div> -->
                    <ul class="nav nav-pills nav-fill my-2 w-100 text-center" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link" [class.active]="selectedTab=='Goals'" id="Goals-tab" data-toggle="tab"
                                href="#Goals" role="tab" aria-controls="Goals" aria-selected="true">Client Goals</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" [class.active]="selectedTab=='Groups'" id="Groups-tab" data-toggle="tab"
                                href="#Groups" role="tab" aria-controls="Groups" aria-selected="false">Client Group</a>
                        </li>

                    </ul>
                </div>
            </div>
            <div class="modal-body px-4">
                <div class="tab-content">
                    <div class="tab-pane fade" [ngClass]="selectedTab=='Goals'?'active show':''" id="Goals"
                        role="tabpanel" aria-labelledby="Goals-tab">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="table-responsive table-list">
                                    <table class="table">
                                        <thead class="thead-light">
                                            <tr>
                                                <th style="width: 80%;">Goal</th>
                                                <th style="width: 20%;" class="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="p-0 align-middle">
                                                    <input type="text" class="form-control border-0 no-outline"
                                                        placeholder="Enter goal title" [(ngModel)]="goalTitle">
                                                </td>
                                                <td class="p-0">
                                                    <div class="text-center primary-bg text-white py-2 m-1 pointer"
                                                        (click)="addUpdateGoal();"> {{(isCreate)?'Add':'Update'}} Goal
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr *ngFor="let item of goalList;">
                                                <td>{{item.goals_title}}</td>
                                                <td>
                                                    <div class="text-center">
                                                        <img src="assets/images/edit.png" class="image-btn pointer"
                                                            alt="images" (click)="setUpdateGoal(item);" />
                                                        <img src="assets/images/delete.png"
                                                            class="image-btn pointer pl-2" alt="images"
                                                            (click)="openDeleteDialog('Goal',item);" />
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" [ngClass]="selectedTab=='Groups'?'active show':''" id="Groups"
                        role="tabpanel" aria-labelledby="Groups-tab">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="table-responsive table-list">
                                    <table class="table">
                                        <thead class="thead-light">
                                            <tr>
                                                <th style="width: 80%;">Group</th>
                                                <th style="width: 20%;" class="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="p-0 align-middle">
                                                    <input type="text" class="form-control border-0 no-outline"
                                                        placeholder="Enter group title" [(ngModel)]="groupTitle">
                                                </td>
                                                <td class="p-0">
                                                    <div class="text-center primary-bg text-white py-2 m-1 pointer"
                                                        (click)="addUpdateGroup();"> {{(isCreate)?'Add':'Update'}} Group
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr *ngFor="let item of groupList;">
                                                <td>{{item.name}}</td>
                                                <td>
                                                    <div class="text-center">
                                                        <img src="assets/images/edit.png" class="image-btn pointer"
                                                            alt="images" (click)="setUpdateGroup(item);" />
                                                        <img src="assets/images/delete.png"
                                                            class="image-btn pointer pl-2" alt="images"
                                                            (click)="openDeleteDialog('Group',item);" />
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="row mb-3 align-items-center">
                    <div class="col-lg-12 text-center">
                        <div class="btn pointer no-radius mb-2 mx-2 secondary-bg text-white">
                            Cancel
                        </div>
                        <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white">
                            Save Settings
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>
<!-- #endregion -->

<app-delete (onConfirm)="deleteRecord()"></app-delete>
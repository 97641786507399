<div class="modal fade" id="filter-dailog" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered time-picker-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="d-flex w-100">
                    <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
                    <div class="modal-title w-100 text-center">{{title}}</div>
                </div>
            </div>
            <div class="modal-body px-4">
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">From:</div>
                    <div class="col-sm-8">
                        <input type="date" class="form-control no-radius" placeholder="Select from date"
                            [(ngModel)]="fromDate">
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">To:</div>
                    <div class="col-sm-8">
                        <input type="date" class="form-control no-radius" placeholder="Select to date"
                            [(ngModel)]="toDate">
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-lg-12 text-center">
                        <div class="btn pointer no-radius mx-2 primary-bg text-white" (click)="filter();">
                            {{btnFilterText}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit, ViewChild } from '@angular/core';
import { BookingsService } from '../bookings/bookings.service';
import { PagerService } from 'src/app/shared/pager.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ExercisesService } from '../exercises/exercises.service';
import { ClientsService } from '../clients/clients.service';
import { WorkoutService } from '../workout/workout.service';
import { ClientProfileService } from './client-profile.service';
import { MeasurementsService } from '../measurements/measurements.service';

import * as moment from 'moment';
import { MembershipService } from '../membership/membership.service';
import { EquipmentsService } from '../equipments/equipments.service';
import { environment } from 'src/environments/environment';
import { LoaderService } from 'src/app/shared/loader.service';
import { SessionsService } from '../sessions/sessions.service';
import { WorkoutProgramComponent } from '../workout-program/workout-program.component';
import { CommonService } from 'src/app/shared/common.service';
import { StorageService } from 'src/app/shared/storage.service';
declare var $: any;

@Component({
  selector: 'app-client-profile',
  templateUrl: './client-profile.component.html',
  styleUrls: ['./client-profile.component.scss']
})
export class ClientProfileComponent implements OnInit {
  @ViewChild(WorkoutProgramComponent) workoutProgramComp: WorkoutProgramComponent;
  moment: any = moment;
  requestMade = false;
  isCreate = false;
  clientId = 0;
  selectedTab = '';

  searchText = '';
  deleteItem = null;
  deleteFrom = '';

  // Profile
  genderList = null;
  clientGroupList = null;
  userTypeList = null;
  statusList = null;
  experienceList = null;
  attendanceList = [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }];
  clientDetails: any = {};
  attendance = 0;
  goalList = [];

  logId = null;
  logDate = null;
  title = null;
  description = null;
  achievedDate = null;
  recoveredDate = null;
  note = null;

  personalGoalInitFlag: any = 1;
  personalGoalPager: any = {};
  personalGoalRowCount = 0;
  personalGoalPageSize = 3;
  personalGoalPageIndex = 1;
  personalGoalList = [];

  injuryInitFlag: any = 1;
  injuryPager: any = {};
  injuryRowCount = 0;
  injuryPageSize = 3;
  injuryPageIndex = 1;
  injuryList = [];

  noteInitFlag: any = 1;
  notePager: any = {};
  noteRowCount = 0;
  notePageSize = 3;
  notePageIndex = 1;
  noteList = [];

  //Setting
  newPassword = '';
  confirmPassword = '';
  notification = 0;
  pushNotification = false;
  generalNotification = false;
  bookingNotification = false;

  // Monali's code start
  // Membership
  membershipList = [];

  //Add Bill
  billingCreditList = [];
  billStartDate: any = new Date();
  billEndDate: any = new Date();
  billStatus: any;
  billAmount: any;
  billDescription: any;

  clientPaymentList = [];
  paymentStatus = ['Failed', 'Success', 'Pending'];

  //Pay Now
  paymentDate: any = new Date();
  paymentAmount: null;
  paymentMethod: null;
  billingCreditsId: null;
  paymentMethodList = [];

  // Pause Membership
  pauseStartDate = null;
  pauseEndDate = null;
  pauseDescription = null;
  pauseAmount = null;
  clientMembershipId = null;
  alreadyPaused = null;
  alreadyPausedStartDate = null;
  alreadyPausedEndDate = null;

  // Upgrade Downgrade Membership
  udAction = null;
  udDuration = null;
  udFirstMonthFee = null;
  udDescription = null;
  ugActionList = [{ id: 1, title: 'Upgrade' }, { id: 2, title: 'Downgrade' }];

  // Cancel Membership
  cancelAmount = null;

  // Update Billing Credit Data
  bcStatusList = [{ id: 0, title: 'Unpaid' }, { id: 1, title: 'Paid' }, { id: 2, title: 'Due' }, { id: 3, title: 'OverDue' }];
  bcStartDate = null;
  bcEndDate = null;
  bcDescription = null;
  bcAmount = null;
  bcCredits = null;
  bcStatus = null;
  billingCreditId = null;

  // Update client membership data
  cmStatusList = [{ id: 0, title: 'Inactive' }, { id: 1, title: 'Active' }];
  cmAutomaticPaymentList = [{ id: 0, title: 'Disable' }, { id: 1, title: 'Enable' }];
  cmStartDate = null;
  cmEndDate = null;
  cmStatus = null;
  cmAutomaticPayment = null;

  // Assign membership
  membershipListing = [];
  membershipTypeList: [];
  membershipLocalityList: [];
  puMembershipId = null;
  puMembershipTypeId = null;
  puMembershipLocalityId = null;
  puStartDate = null;
  puEndDate = null;
  puDuration = null;
  puCredits = null;
  puPartialCredits = null;
  puFirstMonthFee = null;
  puSubsequentMonthFee = null;
  puPartialFee = null;
  puTotalFee = null;
  puDescription = null;
  puBillStartDate = null;
  puBillEndDate = null;
  puActive = true;
  // Pre Trial
  membershipPreTrialSessionList = [];
  prMembershipLocalityId = null;
  prStartDate = null;
  prEndDate = null;
  prStartTime = null;
  prEndTime = null;
  prDescription = null;
  prStatus = null;
  prId = null;

  challengeTitle = null;
  challengeLogDateArray = [];
  // Monali's code end


  // Booking
  bookingSummeryView = '0';
  bookingSummeryData = null;
  bookingViewFuture = true;
  bookingInitFlag: any = 1;
  bookingPager: any = {};
  bookingRowCount = 0;
  bookingPageSize = 50;
  bookingPageIndex = 1;
  bookingOrderBy = 'desc';
  bookingList = [];

  fromDate = null;
  toDate = null;

  // Fitness Log
  fitnessLogList = [];
  filterFitnessLogList = [];
  historyFitnessLogList = [];
  isNewFitnessLog = true;
  fitnessLogId = 0;
  fitnessLogDetails = {
    id: null,
    client_id: null,
    exercise_id: null,
    fitness_date: null,
    reps: null,
    calorie: null,
    weight: null,
    weight_unit: 'KG',
    duration: null,
    duration_unit: 'SEC',
    distance: null,
    distance_unit: 'M',
    speed: null,
    speed_unit: 'M/S',
    notes: null,
  }
  exerciseList = [];
  selectedExercise = null;
  exerciseType = '';
  workoutFitnessLogList = [];

  // Food Diary
  currentDateObj = null;
  date: any = new Date();
  daysInThisMonth = null;
  daysInLastMonth = null;
  daysInNextMonth = null;
  dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  dayFullNames = ['Sunday', 'Monday', 'Tueday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  currentDate = null;
  currentMonth = null;
  currentYear = null;
  selectedDateObj = null;
  selectedMonth = null;
  selectedYear = null;
  selectedDate = null;
  selectedDay = null;

  imagePath = '';
  foodDiaryLogList = [];
  filterFoodDiaryLogList = [];

  // Measurement Log
  measurementLogList = [];
  filterMeasurementLogList = [];
  historyMeasurementLogList = [];
  isNewMeasurementLog = true;
  measurementLogId = 0;
  measurementLogDetails = {
    id: null,
    client_id: null,
    measurement_id: null,
    measurement_date: null,
    value: null,
    value_unit: null,
    notes: null,
  }
  measurementList = [];
  selectedMeasurement = null;
  valueUnits = [];


  // Challenge
  challengeList = [];
  filterChallengeList = [];
  challengeDetails = null;
  isNewChallenge = true;
  challengeDropdownList = [];
  challenge_id = null;
  challenge_notification = null;
  challenge_active = null;
  challengeNotes = [];

  // Sticker
  stickerList = [];
  filterstickerLogList = [];
  assignStickersId: any;
  stickersCollectedDate: any;
  stickerNotificationMsg: any;
  stickersStatus: any;
  isNewSticker = true;
  stickerDetails = null;
  stickerDropdownList = [];

  // Workout
  workoutList = [];
  filterworkoutList = [];
  assignWorkoutId: any;
  workoutNotificationMsg: any;
  workoutStatus: any;
  isNewWorkout = true;
  workoutDetails = null;
  workoutDropdownList = [];
  assignWorkoutClients: any;
  workoutLogList = [];
  filterworkoutLogList = [];
  workoutStartDate = null;
  assignClientTrainerId: any;
  trainerList = [];

  // Equipment
  EquipmentDetails = null;
  isNewEquipment = true;
  assignEquipmentId: any;
  equipmentDropdownList = [];
  equipmentList: any;
  warningModal = false;
  missingEquipmentNames: any;
  selectecMonthChallengeNotes: any;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private pagerService: PagerService,
    private membershipService: MembershipService,
    private bookingsService: BookingsService,
    private exercisesService: ExercisesService,
    private clientsService: ClientsService,
    private workoutService: WorkoutService,
    private clientProfileService: ClientProfileService,
    private measurementsService: MeasurementsService,
    private equipmentsService: EquipmentsService,
    private loader: LoaderService,
    private sessionsService: SessionsService,
    public commonService: CommonService,
    private storageService: StorageService
  ) { }

  ngOnInit(): void {
    const { client_id } = window.history.state;
    let { tab } = window.history.state;
    if (client_id) {
      this.clientId = client_id;
      if (tab == 'InjuryLog') {
        setTimeout(() => {
          var element = document.getElementById("clientinjury");
          element.scrollIntoView(true);
        }, 500);
        tab = '';
      }
      this.selectedTab = tab ? tab : 'Profile';
    } else {
      this.router.navigate(['dashboard']);
    }

    this.currentDateObj = new Date();
    this.date = new Date();
    this.currentDate = this.date.getDate();
    this.currentMonth = this.date.getMonth();
    this.currentYear = this.date.getFullYear();
    this.selectedDate = this.date.getDate();
    this.selectedMonth = this.date.getMonth();
    this.selectedYear = this.date.getFullYear();

    this.getGenderList();
    this.getClientGroupList();
    this.getUserTypeList();
    this.getStatusList();
    this.getExperienceList();
    this.getUserProfileDetails();
    this.getClientGoalList();
    this.getAttendance();
    this.getPersonalGoalList(1);
    this.getInjuryList(1);
    this.getNoteList(1);

    this.getPurchasedMembershipListByClientId();
    this.getBillingCreditListByClientId();
    this.getAllPaymentMethod();
    this.getmembershipTypeList();
    this.getMembershipLocalityList();
    this.getClientPreTrialMembershipSessionListAdmin();

    this.getBookingSummary('30');
    this.getBookingList(1);

    this.getExerciseList();
    this.getFitnessLogList();

    this.getDaysOfMonth();
    this.getClientFoodDiaryList();

    this.getMeasurementList();
    this.getMeasurementLogList();


    this.getClientChallengesList();
    this.getAllChallenge();

    this.getClientStickerList();
    this.getAllStickerList();

    this.getClientWorkoutList();
    this.getAllWorkoutList();
    this.getClientWorkoutLogList();
    this.getAllEquipmentList();
    this.getClientEquipmentList();
    this.getTrainerList();
    this.commonService.getExerciseList();
  }

  //#region Profile

  getGenderList() {
    this.genderList = [];
    this.genderList.push({ value: 'Male' });
    this.genderList.push({ value: 'Female' });
  }

  getClientGroupList() {
    this.loader.show();
    this.clientsService.getGroupList().subscribe(
      (response) => {
        if (response.data) {
          this.clientGroupList = response.data;
        } else {
          this.clientGroupList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  getUserTypeList() {
    this.userTypeList = [];
    this.userTypeList.push({ value: 'Admin' });
    this.userTypeList.push({ value: 'Trainer' });
    this.userTypeList.push({ value: 'Client' });
  }

  getStatusList() {
    this.statusList = [];
    this.statusList.push({ id: 1, value: 'Active' });
    this.statusList.push({ id: 0, value: 'Inactive' });
  }

  getExperienceList() {
    this.experienceList = [];
    this.experienceList.push({ id: 'None', value: 'None' });
    this.experienceList.push({ id: 'Beginner', value: 'Beginner' });
    this.experienceList.push({ id: 'Intermediate', value: 'Intermediate' });
    this.experienceList.push({ id: 'Advanced', value: 'Advanced' });
  }

  getUserProfileDetails() {
    this.loader.show()
    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    this.clientsService.getClientDetails(formData).subscribe(
      (response) => {
        if (response.status) {
          if (response.data) {
            this.clientDetails = response.data[0];
            this.clientDetails.groups = (this.clientDetails.groups != '' && this.clientDetails.groups != null) ? this.clientDetails.groups.split(',').map(i => Number(i)) : '';
            this.clientDetails.dob = moment(this.clientDetails.dob).format('YYYY-MM-DD');
            this.pushNotification = (this.clientDetails.notification == 1) ? true : false;
            this.generalNotification = (this.clientDetails.notification && (this.clientDetails.notification == 1 || this.clientDetails.notification == 2)) ? true : false;
            this.bookingNotification = (this.clientDetails.notification && (this.clientDetails.notification == 1 || this.clientDetails.notification == 3)) ? true : false;;
          }
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  getTrainerList(): void {
    this.sessionsService.getTrainerList().subscribe(
      (response) => {
        if (response.data) {
          this.trainerList = response.data.map((e) => { return { id: e.id, name: e.first_name + ' ' + e.last_name } });
        } else {
          this.trainerList = [];
        }
      }, (err) => {
        console.log(err);
      }
    );
  }
  getTrainerNameById(trainerIds: string) {
    const trainerIdArray = trainerIds.split(',').map(id => parseInt(id.trim()));

    const trainerNames = trainerIdArray.map(trainerId => {
      const trainer = this.trainerList.find(t => t.id === trainerId);
      return trainer ? trainer.name : '';
    });

    return trainerNames.join(', ');
  }

  saveClient() {
    if (!this.clientDetails.first_name) {
      this.toastr.error('Please enter first name.');
      return;
    }
    if (!this.clientDetails.last_name) {
      this.toastr.error('Please enter last name.');
      return;
    }
    if (!this.clientDetails.email) {
      this.toastr.error('Please enter email.');
      return;
    }
    if (!this.clientDetails.phone) {
      this.toastr.error('Please enter phone.');
      return;
    }
    if (!this.clientDetails.dob) {
      this.toastr.error('Please select date of birth.');
      return;
    }
    if (!this.clientDetails.gender) {
      this.toastr.error('Please select gender.');
      return;
    }
    if (!this.clientDetails.user_type) {
      this.toastr.error('Please select user type.');
      return;
    }
    if (this.clientDetails.active == null) {
      this.toastr.error('Please select status.');
      return;
    }

    var formData: FormData = new FormData();
    formData.append('first_name', this.clientDetails.first_name);
    formData.append('last_name', this.clientDetails.last_name);
    formData.append('email', this.clientDetails.email);
    formData.append('phone', this.clientDetails.phone);
    formData.append('dob', this.clientDetails.dob);
    formData.append('gender', this.clientDetails.gender);
    formData.append('groups', this.clientDetails.groups);
    formData.append('user_type', this.clientDetails.user_type);
    formData.append('active', this.clientDetails.active);
    formData.append('height', this.clientDetails.height);
    formData.append('weight', this.clientDetails.weight);
    formData.append('experience_level', this.clientDetails.experience_level);
    formData.append('health_info', this.clientDetails.health_info);
    formData.append('client_id', this.clientId.toString());

    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.clientsService.updateClient(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide()
        }, (err) => {
          this.requestMade = false;
          this.loader.hide()
          console.log(err);
        }
      );
    }
  }

  getAttendance() {
    this.loader.show();
    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    this.clientsService.getAttendance(formData).subscribe(
      (response) => {
        if (response.status) {
          this.attendance = response.data[0].value;
        } else {
          this.attendance = 0;
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  updateAttendance() {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      var formData: FormData = new FormData();
      formData.append('client_id', this.clientId.toString());
      formData.append('value', this.attendance.toString());
      this.clientsService.updateAttendance(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  getClientGoalList() {
    this.loader.show();
    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    this.clientsService.getClientGoalList(formData).subscribe(
      (response) => {
        if (response.status) {
          this.goalList = response.data;
        } else {
          this.toastr.error(response.message);
        }
        this.loader.hide()
      }, (err) => {
        this.loader.hide()
        console.log(err);
      }
    );
  }

  clearLogData(): void {
    this.logId = null;
    this.logDate = moment().format('YYYY-MM-DD');;
    this.title = null;
    this.description = null;
    this.achievedDate = null;
    this.recoveredDate = null;
    this.note = null;
  }

  // Personal Goal
  setPersonalGoalPage(page: number) {
    if (page < 1) { return; }
    if (page > this.personalGoalPager.totalPages) { return; }

    this.personalGoalPageIndex = page;
    this.personalGoalPager = this.pagerService.getPager(this.personalGoalRowCount, this.personalGoalPageSize, this.personalGoalPageIndex);
    if (this.personalGoalInitFlag === 0) {
      this.getPersonalGoalList(0);
    }
  }

  getPersonalGoalList(item) {
    this.loader.show();
    if (item === 1) {
      this.personalGoalPageIndex = 1;
      this.personalGoalInitFlag = 1;
    }
    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    formData.append('searchname', this.searchText);
    formData.append('page_no', this.personalGoalPageIndex.toString());
    formData.append('page_size', this.personalGoalPageSize.toString());
    formData.append('order_by', 'asc');
    this.clientsService.getPersonalGoalList(formData).subscribe(
      (response) => {
        if (response.data) {
          this.personalGoalList = response.data;
          this.personalGoalRowCount = response.totalrow;
          if (this.personalGoalInitFlag === 1) {
            this.setPersonalGoalPage(1);
            this.personalGoalInitFlag = 0;
          }
        } else {
          this.personalGoalList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  openAddUpdatePersonalGoalModal(isCreate, data) {
    this.clearLogData();
    this.isCreate = isCreate;
    if (!this.isCreate) {
      this.logId = data.id;
      this.logDate = moment(data.goal_date).format('YYYY-MM-DD');;
      this.title = data.title;
      this.description = data.description;
      this.achievedDate = (data.achieved_date) ? moment(data.achieved_date).format('YYYY-MM-DD') : '';
    }
    $('#add-update-personal-goal').modal('show');
  }

  savePersonalGoal() {
    if (!this.logDate) {
      this.toastr.error('Please select date.');
      return;
    }
    if (!this.title) {
      this.toastr.error('Please enter title.');
      return;
    }
    if (!this.description) {
      this.toastr.error('Please enter description.');
      return;
    }

    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    formData.append('goal_date', (this.logDate) ? this.logDate : '');
    formData.append('title', this.title);
    formData.append('description', (this.description) ? this.description : '');
    formData.append('achieved_date', (this.achievedDate) ? this.achievedDate : '');
    if (this.isCreate) {
      this.createPersonalGoal(formData);
    } else {
      formData.append('goal_id', this.logId);
      this.updatePersonalGoal(formData);
    }
  }

  createPersonalGoal(formData) {
    if (!this.requestMade) {
      this.loader.show()
      this.requestMade = true;
      this.clientsService.createPersonalGoal(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-update-personal-goal').modal('hide');
            this.getPersonalGoalList(1);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  updatePersonalGoal(formData) {
    if (!this.requestMade) {
      this.loader.show()
      this.requestMade = true;
      this.clientsService.updatePersonalGoal(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-update-personal-goal').modal('hide');
            this.getPersonalGoalList(1);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  deletePersonalGoal() {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      var formData: FormData = new FormData();
      formData.append('goal_id', this.deleteItem.id);
      this.clientsService.deletePersonalGoal(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            this.getPersonalGoalList(1);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  // Injury log
  setInjuryPage(page: number) {
    if (page < 1) { return; }
    if (page > this.injuryPager.totalPages) { return; }

    this.injuryPageIndex = page;
    this.injuryPager = this.pagerService.getPager(this.injuryRowCount, this.injuryPageSize, this.injuryPageIndex);
    if (this.injuryInitFlag === 0) {
      this.getInjuryList(0);
    }
  }

  getInjuryList(item) {
    this.loader.show();
    if (item === 1) {
      this.injuryPageIndex = 1;
      this.injuryInitFlag = 1;
    }
    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    formData.append('searchname', this.searchText);
    formData.append('page_no', this.injuryPageIndex.toString());
    formData.append('page_size', this.injuryPageSize.toString());
    formData.append('order_by', 'desc');
    this.clientsService.getInjuryList(formData).subscribe(
      (response) => {
        if (response.data) {
          this.injuryList = response.data;
          this.injuryRowCount = response.totalrow;
          if (this.injuryInitFlag === 1) {
            this.setInjuryPage(1);
            this.injuryInitFlag = 0;
          }
        } else {
          this.injuryList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  openAddUpdateInjuryModal(isCreate, data) {
    this.clearLogData();
    this.isCreate = isCreate;
    if (!this.isCreate) {
      this.logId = data.id;
      this.logDate = moment(data.injury_date).format('YYYY-MM-DD');
      this.title = data.title;
      this.description = data.description;
      this.recoveredDate = (data.recovered_date) ? moment(data.recovered_date).format('YYYY-MM-DD') : '';
    }
    $('#add-update-injury').modal('show');
  }

  saveInjury() {
    if (!this.logDate) {
      this.toastr.error('Please select date.');
      return;
    }
    if (!this.title) {
      this.toastr.error('Please enter title.');
      return;
    }
    if (!this.description) {
      this.toastr.error('Please enter description.');
      return;
    }

    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    formData.append('injury_date', (this.logDate) ? this.logDate : '');
    formData.append('title', this.title);
    formData.append('description', (this.description) ? this.description : '');
    formData.append('recovered_date', (this.recoveredDate) ? this.recoveredDate : '');
    if (this.isCreate) {
      this.createInjury(formData);
    } else {
      formData.append('injurie_id', this.logId);
      this.updateInjury(formData);
    }
  }

  createInjury(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.clientsService.createInjury(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-update-injury').modal('hide');
            this.getInjuryList(1);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  updateInjury(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.clientsService.updateInjury(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-update-injury').modal('hide');
            this.getInjuryList(1);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  deleteInjury() {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      var formData: FormData = new FormData();
      formData.append('injurie_id', this.deleteItem.id);
      this.clientsService.deleteInjury(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            this.getInjuryList(1);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  // Notes log
  setNotePage(page: number) {
    if (page < 1) { return; }
    if (page > this.notePager.totalPages) { return; }

    this.notePageIndex = page;
    this.notePager = this.pagerService.getPager(this.noteRowCount, this.notePageSize, this.notePageIndex);
    if (this.noteInitFlag === 0) {
      this.getNoteList(0);
    }
  }

  getNoteList(item) {
    this.loader.show();
    if (item === 1) {
      this.notePageIndex = 1;
      this.noteInitFlag = 1;
    }
    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    formData.append('searchname', this.searchText);
    formData.append('page_no', this.notePageIndex.toString());
    formData.append('page_size', this.notePageSize.toString());
    formData.append('order_by', 'desc');
    this.clientsService.getNoteList(formData).subscribe(
      (response) => {
        if (response.data) {
          this.noteList = response.data;
          this.noteRowCount = response.totalrow;
          if (this.noteInitFlag === 1) {
            this.setNotePage(1);
            this.noteInitFlag = 0;
          }
        } else {
          this.noteList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  openAddUpdateNoteModal(isCreate, data) {
    this.clearLogData();
    this.isCreate = isCreate;
    if (!this.isCreate) {
      this.logId = data.id;
      this.logDate = moment(data.note_date).format('YYYY-MM-DD');
      this.note = data.note;
    }
    $('#add-update-note').modal('show');
  }

  saveNote() {
    if (!this.logDate) {
      this.toastr.error('Please select date.');
      return;
    }

    if (!this.note) {
      this.toastr.error('Please enter note.');
      return;
    }

    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    formData.append('note_date', (this.logDate) ? this.logDate : '');
    formData.append('note', this.note);
    if (this.isCreate) {
      this.createNote(formData);
    } else {
      formData.append('note_id', this.logId);
      this.updateNote(formData);
    }
  }

  createNote(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.clientsService.createNote(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-update-note').modal('hide');
            this.getNoteList(1);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  updateNote(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.clientsService.updateNote(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-update-note').modal('hide');
            this.getNoteList(1);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  deleteNote() {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      var formData: FormData = new FormData();
      formData.append('note_id', this.deleteItem.id);
      this.clientsService.deleteNote(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            this.getNoteList(1);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  //#endregion

  //#region Setting

  replacePassword() {
    if (!this.newPassword) {
      this.toastr.error('Please enter new password.')
      return;
    }
    if (!this.confirmPassword) {
      this.toastr.error('Please enter confirm password.')
      return;
    }
    if (this.newPassword !== this.confirmPassword) {
      this.toastr.error('Password and confirm password doesn\'t match.')
      return;
    }
    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    formData.append('newpassword', this.newPassword);
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.clientsService.replacePassword(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success('Password saved.');
            this.newPassword = '';
            this.confirmPassword = '';
            this.getUserProfileDetails();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
        }
      );
    }
  }

  updateFeatureAccess() {
    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    formData.append('feature_video', (this.clientDetails.feature_video) ? '1' : '0');
    formData.append('feature_program', (this.clientDetails.feature_program) ? '1' : '0');
    formData.append('feature_log', (this.clientDetails.feature_log) ? '1' : '0');
    formData.append('feature_diary', (this.clientDetails.feature_diary) ? '1' : '0');
    formData.append('feature_measurements', (this.clientDetails.feature_measurements) ? '1' : '0');
    formData.append('feature_challenges', (this.clientDetails.feature_challenges) ? '1' : '0');
    formData.append('feature_stickers', (this.clientDetails.feature_stickers) ? '1' : '0');
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.clientsService.updateClient(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            this.getUserProfileDetails();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  deleteClient() {
    this.loader.show();
    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    this.clientsService.deleteClient(formData).subscribe(
      (response) => {
        if (response.status) {
          this.toastr.success(response.message);
          this.router.navigate(['clients']);
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  setNotificationConfig() {
    if (this.notification) {
      this.pushNotification = true;
      if (this.notification == 3) {
        this.generalNotification = true;
      } else if (this.notification == 2) {
        this.bookingNotification = true;
      } else if (this.notification == 1) {
        this.generalNotification = true;
        this.bookingNotification = true;
      }
    }
  }

  changeNotificationConfig(main) {
    if (main) {
      this.generalNotification = this.pushNotification;
      this.bookingNotification = this.pushNotification;
    }
    if (this.generalNotification || this.bookingNotification) {
      this.pushNotification = true;
    }
    if (this.generalNotification || this.bookingNotification) {
      this.pushNotification = true;
    }
    let value = 0;
    if (this.pushNotification && this.generalNotification && this.bookingNotification) {
      value = 1;
    } else if (this.pushNotification && this.generalNotification) {
      value = 2;
    } else if (this.pushNotification && this.bookingNotification) {
      value = 3;
    }
    this.updateNotificationConfig(value);
  }

  updateNotificationConfig(value) {
    this.loader.show();
    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    formData.append('notification_id', value);
    this.clientProfileService.updateNotificationConfig(formData).subscribe(
      (response) => {
        if (response.status) {
          this.toastr.success(response.message);
          this.getUserProfileDetails();
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  //#endregion

  //#region Booking

  getBookingSummary(days: string) {
    this.loader.show();
    this.bookingSummeryView = days;
    let startDate = '';
    let endDate = '';
    if (this.bookingSummeryView != '0') {
      startDate = moment().subtract(parseInt(days, 10) - 1, "days").format("YYYY-MM-DD");
      endDate = moment().format('YYYY-MM-DD')
    }
    var formData: FormData = new FormData();
    formData.append('start_date', startDate);
    formData.append('end_date', endDate);
    formData.append('client_id', this.clientId.toString());
    this.bookingsService.getBookingCount(formData).subscribe(
      (response) => {
        if (response.status) {
          if (response.data) {
            this.bookingSummeryData = response.data[0];
          }
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  setPage(page: number) {
    if (page < 1) { return; }
    if (page > this.bookingPager.totalPages) { return; }

    this.bookingPageIndex = page;
    this.bookingPager = this.pagerService.getPager(this.bookingRowCount, this.bookingPageSize, this.bookingPageIndex);
    if (this.bookingInitFlag === 0) {
      if (this.bookingViewFuture) {
        this.getBookingList(0);
      } else {
        this.getBookingHistory(0);
      }
    }
  }

  getBookingList(item) {
    this.loader.show();
    if (item === 1) {
      this.bookingPageIndex = 1;
      this.bookingInitFlag = 1;
    }
    this.bookingViewFuture = true;
    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    formData.append('searchname', this.searchText);
    formData.append('page_no', this.bookingPageIndex.toString());
    formData.append('page_size', this.bookingPageSize.toString());
    formData.append('order_by', this.bookingOrderBy.toString());
    this.bookingsService.getAuditBookingList(formData).subscribe(
      (response) => {
        if (response.status && response.data) {
          this.bookingList = [];
          this.bookingList = response.data;
          this.bookingList.map((e) => {
            e.start_time = new Date(e.session_date.substring(0, 10).replace(/-/g, '/') + ' ' + e.start_time),
              e.end_time = new Date(e.session_date.substring(0, 10).replace(/-/g, '/') + ' ' + e.end_time)
          });
          this.bookingRowCount = response.totalrow;
          if (this.bookingInitFlag === 1) {
            this.setPage(1);
            this.bookingInitFlag = 0;
          }
        } else {
          this.bookingList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  openFilterDialog() {
    $('#filter-dailog').modal('show');
  }

  closeFilterDialog(data) {
    if (data) {
      this.fromDate = data.fromDate;
      this.toDate = data.toDate;
      this.getBookingHistory(1);
    }
  }

  getBookingHistory(item) {
    this.loader.show();
    if (item === 1) {
      this.bookingPageIndex = 1;
      this.bookingInitFlag = 1;
    }
    this.bookingViewFuture = false;
    $('#filter-dailog').modal('hide');
    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    formData.append('start_date', this.fromDate);
    formData.append('end_date', this.toDate);
    formData.append('searchname', this.searchText);
    formData.append('page_no', this.bookingPageIndex.toString());
    formData.append('page_size', this.bookingPageSize.toString());
    formData.append('order_by', this.bookingOrderBy.toString());
    this.bookingsService.getAuditBookingHistory(formData).subscribe(
      (response) => {
        if (response.status && response.data) {
          this.bookingList = response.data;
          this.bookingList.map((e) => {
            e.start_time = new Date(e.session_date.substring(0, 10).replace(/-/g, '/') + ' ' + e.start_time),
              e.end_time = new Date(e.session_date.substring(0, 10).replace(/-/g, '/') + ' ' + e.end_time)
          });
          this.bookingRowCount = response.totalrow;
          if (this.bookingInitFlag === 1) {
            this.setPage(1);
            this.bookingInitFlag = 0;
          }
        } else {
          this.bookingList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  openAddUpdateBookingModal() {
    $('#book-clients').modal('show');
  }

  onBookingRefresh(data) {
    this.getBookingList(1);
  }
  //#endregion

  //#region Fitness Log
  getExerciseList() {
    this.loader.show();
    var formData: FormData = new FormData();
    formData.append('page_no', '1');
    formData.append('page_size', '1000');
    formData.append('order_by', 'asc');
    this.exercisesService.getExerciseList(formData).subscribe(
      (response) => {
        if (response.status) {
          if (response.data) {
            const exerciseList = response.data;
            this.exerciseList = exerciseList.filter((e) => e.active && !e.is_delete);
          } else {
            this.exerciseList = [];
          }
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  getFitnessLogList() {
    this.loader.show();
    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    this.bookingsService.getClientFitnessLogList(formData).subscribe(
      (response) => {
        if (response.status) {
          this.fitnessLogList = response.data;
          this.filterClientFitnessLogList();
        } else {
          this.fitnessLogList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  filterClientFitnessLogList() {
    this.filterFitnessLogList = [];
    this.filterFitnessLogList = (this.searchText) ? this.fitnessLogList.filter((e) => e.title.toLowerCase().indexOf(this.searchText) > -1) : this.fitnessLogList;
    this.filterFitnessLogList.sort((a, b) => {
      if (new Date(a.fitness_date).getTime() > new Date(b.fitness_date).getTime()) return -1;
      if (new Date(a.fitness_date).getTime() < new Date(b.fitness_date).getTime()) return 1;
      if (a.title.toLowerCase() > b.title.toLowerCase()) { return 1; }
      if (a.title.toLowerCase() < b.title.toLowerCase()) { return -1; }
    });
  }

  getHistoryFitnessLogList(exerciseId, open) {
    this.loader.show();
    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    formData.append('exercise_id', exerciseId.toString());
    this.bookingsService.getClientExerciseFitnessLogList(formData).subscribe(
      (response) => {
        if (response.status) {
          this.historyFitnessLogList = response.data;
          this.historyFitnessLogList.sort((a, b) => {
            if (new Date(a.fitness_date).getTime() > new Date(b.fitness_date).getTime()) return -1;
            if (new Date(a.fitness_date).getTime() < new Date(b.fitness_date).getTime()) return 1;
            if (new Date(a.creation_date_time).getTime() > new Date(b.creation_date_time).getTime()) return -1;
            if (new Date(a.creation_date_time).getTime() < new Date(b.creation_date_time).getTime()) return 1;
          });
          if (open) {
            $('#fitness-log-dialog').modal('show');
          }
        } else {
          this.historyFitnessLogList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  clearFitnessLog() {
    this.fitnessLogDetails = {
      id: null,
      client_id: null,
      exercise_id: null,
      fitness_date: null,
      reps: null,
      calorie: null,
      weight: null,
      weight_unit: 'KG',
      duration: null,
      duration_unit: 'SEC',
      distance: null,
      distance_unit: 'M',
      speed: null,
      speed_unit: 'M/S',
      notes: null,
    };
    this.exerciseType = null;
  }

  openAddUpdateFitnessLogDialog(item) {
    this.clearFitnessLog();
    if (item) {
      this.fitnessLogDetails = item;
      this.isNewFitnessLog = false;
      this.fitnessLogDetails.fitness_date = moment(item.fitness_date).format('YYYY-MM-DD');
      this.setExerciseType();
    } else {
      this.isNewFitnessLog = true;
      this.fitnessLogDetails.fitness_date = moment().format('YYYY-MM-DD');
    }
    const $addUpdateFitnessDialog = $('#add-update-fitness-log');

    $addUpdateFitnessDialog.modal('show');
    this.loader.hide();

    // Add event listener for modal hidden event
    const onHiddenHandler = () => {
      if ($('#fitness-log-dialog').hasClass('show')) {
        $('body').addClass('modal-open');
      }
      if ($('#workout-fitness-log-dialog').hasClass('show')) {
        $('body').addClass('modal-open');
      }
      // Remove the event listener after it's been triggered
      $addUpdateFitnessDialog.off('hidden.bs.modal', onHiddenHandler);
    };

    $addUpdateFitnessDialog.on('hidden.bs.modal', onHiddenHandler);
  }

  setExerciseType() {
    const exercise = this.exerciseList.find((e) => e.id == this.fitnessLogDetails.exercise_id);
    this.exerciseType = (exercise) ? exercise.exercise_type : 0;
  }

  addUpdateFitnessLog(close) {
    if (!this.fitnessLogDetails.exercise_id) {
      this.toastr.error('Please select movement.');
      return;
    }
    if (!this.fitnessLogDetails.fitness_date) {
      this.toastr.error('Please select date.');
      return;
    }
    if (this.fitnessLogDetails.reps) {
      if (this.fitnessLogDetails.reps > 99) {
        this.toastr.error('Please enter reps below 100.');
        this.fitnessLogDetails.reps = 1;
        return;
      }
    }
    if (this.fitnessLogDetails.calorie) {
      if (this.fitnessLogDetails.calorie > 9999) {
        this.toastr.error('Please enter calories below 10000.');
        this.fitnessLogDetails.calorie = 1;
        return;
      }
    }
    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    formData.append('exercise_id', this.fitnessLogDetails.exercise_id);
    formData.append('fitness_date', new Date(this.fitnessLogDetails.fitness_date).toJSON().substring(0, 10));
    formData.append('reps', (this.fitnessLogDetails.reps) ? this.fitnessLogDetails.reps.toString() : '');
    formData.append('calorie', (this.fitnessLogDetails.calorie) ? this.fitnessLogDetails.calorie.toString() : '');
    formData.append('notes', (this.fitnessLogDetails.notes) ? this.fitnessLogDetails.notes.toString() : '');
    formData.append('weight', (this.fitnessLogDetails.weight) ? this.fitnessLogDetails.weight.toString() : '');
    formData.append('weight_unit', (this.fitnessLogDetails.weight_unit) ? this.fitnessLogDetails.weight_unit.toString() : '');
    formData.append('duration', (this.fitnessLogDetails.duration) ? this.fitnessLogDetails.duration.toString() : '');
    formData.append('duration_unit', (this.fitnessLogDetails.duration_unit) ? this.fitnessLogDetails.duration_unit.toString() : '');
    formData.append('distance', (this.fitnessLogDetails.distance) ? this.fitnessLogDetails.distance.toString() : '');
    formData.append('distance_unit', (this.fitnessLogDetails.distance_unit) ? this.fitnessLogDetails.distance_unit.toString() : '');
    formData.append('speed', (this.fitnessLogDetails.speed) ? this.fitnessLogDetails.speed.toString() : '');
    formData.append('speed_unit', (this.fitnessLogDetails.speed_unit) ? this.fitnessLogDetails.speed_unit.toString() : '');

    if (this.isNewFitnessLog) {
      this.addFitnessLog(formData, close, this.fitnessLogDetails.exercise_id);
    } else {
      formData.append('fitness_id', this.fitnessLogDetails.id);
      this.updateFitnessLog(formData, this.fitnessLogDetails.exercise_id);
    }
  }

  addFitnessLog(formData, close, exerciseId) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.bookingsService.addFitnessLog(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            if (close) {
              $('#add-update-fitness-log').modal('hide');
              this.getFitnessLogList();
              this.getHistoryFitnessLogList(exerciseId, 0);
            }
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  updateFitnessLog(formData, exerciseId) {
    this.loader.show();
    this.bookingsService.updateFitnessLog(formData).subscribe(
      (response) => {
        if (response.status) {
          this.toastr.success(response.message);
          $('#add-update-fitness-log').modal('hide');
          this.getFitnessLogList();
          this.getHistoryFitnessLogList(exerciseId, 0);
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  deleteFitnessLog(item, workoutFitnessModal = false) {
    const id = item.id;
    const exerciseId = item.exercise_id;
    if (id) {
      this.loader.show();
      var formData: FormData = new FormData();
      formData.append('fitness_id', id);
      this.bookingsService.deleteFitnessLog(formData).subscribe(
        (response) => {
          if (response.status) {
            if (workoutFitnessModal) {
              const deletedIndex = this.workoutFitnessLogList.findIndex((log) => log.id == id);
              this.workoutFitnessLogList.splice(deletedIndex, 1);
            } else {
              this.getFitnessLogList();
              this.getHistoryFitnessLogList(exerciseId, 1);
            }
          }
          this.loader.hide();
        }, (err) => {
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  //#endregion

  //#region Food Diary

  getDaysOfMonth() {
    this.daysInThisMonth = [];
    this.daysInLastMonth = [];
    this.daysInNextMonth = [];
    this.selectedYear = this.date.getFullYear();
    this.selectedMonth = this.date.getMonth();
   this.monthHabitsNotes();
    const selectedDate = new Date(this.selectedYear, this.selectedMonth, this.selectedDate);
    this.selectedDay = selectedDate.getDay();
    this.selectedDateObj = selectedDate;

    var thisMonthFirstDay = new Date(this.date.getFullYear(), this.date.getMonth(), 1).getDay();
    var prevMonthDays = new Date(this.date.getFullYear(), this.date.getMonth(), 0).getDate();
    for (var i = prevMonthDays - (thisMonthFirstDay - 1); i <= prevMonthDays; i++) {
      this.daysInLastMonth.push(i);
    }

    var thisMonthDays = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0).getDate();
    for (var i = 0; i < thisMonthDays; i++) {
      this.daysInThisMonth.push(i + 1);
    }

    var thisMonthLastDay = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0).getDay();
    for (var i = 1; i < (7 - thisMonthLastDay); i++) {
      this.daysInNextMonth.push(i);
    }

    var totalDays = this.daysInLastMonth.length + this.daysInThisMonth.length + this.daysInNextMonth.length;
    if (totalDays < 35) {
      for (var i = (7 - thisMonthLastDay); i < ((7 - thisMonthLastDay) + 7); i++) {
        this.daysInNextMonth.push(i);
      }
    }
  }
  monthHabitsNotes() :any {
   return this.selectecMonthChallengeNotes = this.challengeNotes.filter(log => {
      const logDate = new Date(log.challenge_log_date);
      return logDate.getMonth() === this.selectedMonth && logDate.getFullYear() === this.selectedYear;
    });
  }

  setLastMonthDate(day) {
    this.selectedDate = day;
    this.goToLastMonth();
  }

  goToLastMonth() {
    this.date = new Date(this.date.getFullYear(), this.date.getMonth(), 0);
    this.getDaysOfMonth();
    this.getClientFoodDiaryList();
  }

  setNextMonthDate(day) {
    this.selectedDate = day;
    this.goToNextMonth();
  }

  goToNextMonth() {
    this.date = new Date(this.date.getFullYear(), this.date.getMonth() + 2, 0);
    this.getDaysOfMonth();
    this.getClientFoodDiaryList();
  }

  setSelectedDate(day) {
    if (this.selectedDate != day) {
      this.selectedDate = day;
      const selectedDate = new Date(this.selectedYear, this.selectedMonth, this.selectedDate);
      this.selectedDay = selectedDate.getDay();
      this.filterClientFoodDiaryLogList();
    }
  }

  getEventType(date) {
    const foodDiaryLogList = this.foodDiaryLogList.find((e) => new Date(e.log_date_time).getDate() == date && new Date(e.log_date_time).getMonth() == (this.selectedMonth));
    return (foodDiaryLogList) ? 'past-booked' : 'default';
  }

  getClientFoodDiaryList() {
    this.loader.show();
    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    formData.append('log_date', this.date.toJSON());
    this.clientProfileService.getFoodDiaryList(formData).subscribe(
      (response) => {
        if (response.status) {
          this.imagePath = response.imagepath;
          this.foodDiaryLogList = response.data;
          this.filterClientFoodDiaryLogList();
        } else {
          this.foodDiaryLogList = [];
          this.filterFoodDiaryLogList = [];
        }
        this.loader.hide()
      }, (err) => {
        this.loader.hide()
        console.log(err);
      }
    );
  }

  filterClientFoodDiaryLogList() {
    this.filterFoodDiaryLogList = this.foodDiaryLogList.filter((e) => new Date(e.log_date_time).getDate() == this.selectedDate);
    this.filterFoodDiaryLogList.sort((a, b) => new Date(a.log_date_time).getTime() - new Date(b.log_date_time).getTime());
  }

  deleteFoodDiaryLog() {
    this.loader.show();
    var formData: FormData = new FormData();
    formData.append('fooddiary_id', this.deleteItem.id);
    this.clientProfileService.deleteFoodDiary(formData).subscribe(
      (response) => {
        if (response.status) {
          this.toastr.success(response.message);
          this.getClientFoodDiaryList();
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  //#endregion

  //#region Measurement Log
  getMeasurementList() {
    this.loader.show();
    var formData: FormData = new FormData();
    formData.append('page_no', '1');
    formData.append('page_size', '1000');
    formData.append('order_by', 'asc');
    this.measurementsService.getMeasurementList(formData).subscribe(
      (response) => {
        if (response.status) {
          if (response.data) {
            const measurementList = response.data;
            this.measurementList = measurementList.filter((e) => e.active && !e.is_delete);
          } else {
            this.measurementList = [];
          }
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  getMeasurementLogList() {
    this.loader.show();
    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    this.measurementsService.getClientMeasurementLogList(formData).subscribe(
      (response) => {
        if (response.status) {
          this.measurementLogList = response.data;
          this.filterClientMeasurementLogList();
        } else {
          this.measurementLogList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  filterClientMeasurementLogList() {
    this.filterMeasurementLogList = [];
    this.filterMeasurementLogList = (this.searchText) ? this.measurementLogList.filter((e) => e.title.toLowerCase().indexOf(this.searchText) > -1) : this.measurementLogList;
    this.filterMeasurementLogList.sort((a, b) => {
      if (new Date(a.measurement_date).getTime() > new Date(b.measurement_date).getTime()) return -1;
      if (new Date(a.measurement_date).getTime() < new Date(b.measurement_date).getTime()) return 1;
      if (a.title.toLowerCase() > b.title.toLowerCase()) { return 1; }
      if (a.title.toLowerCase() < b.title.toLowerCase()) { return -1; }
    });
  }

  getHistoryMeasurementLogList(measurementId, open) {
    this.loader.show();
    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    formData.append('measurement_id', measurementId.toString());
    this.measurementsService.getClientExerciseMeasurementLogList(formData).subscribe(
      (response) => {
        if (response.status) {
          this.historyMeasurementLogList = response.data;
          this.historyMeasurementLogList.sort((a, b) => {
            if (new Date(a.measurement_date).getTime() > new Date(b.measurement_date).getTime()) return -1;
            if (new Date(a.measurement_date).getTime() < new Date(b.measurement_date).getTime()) return 1;
            if (new Date(a.creation_date_time).getTime() > new Date(b.creation_date_time).getTime()) return -1;
            if (new Date(a.creation_date_time).getTime() < new Date(b.creation_date_time).getTime()) return 1;
          });
          if (open) {
            $('#measurement-history-log-dialog').modal('show');
          }
        } else {
          this.historyMeasurementLogList = [];
          $('#measurement-history-log-dialog').modal('hide');
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  clearMeasurementLog() {
    this.measurementLogDetails = {
      id: null,
      client_id: null,
      measurement_id: null,
      measurement_date: null,
      value: null,
      value_unit: '',
      notes: null,
    };
  }

  openAddUpdateMeasurementLogDialog(item) {
    this.clearMeasurementLog();
    if (item) {
      this.measurementLogDetails = item;
      this.isNewMeasurementLog = false;
      this.measurementLogDetails.measurement_date = moment(item.measurement_date).format('YYYY-MM-DD');
    } else {
      this.isNewMeasurementLog = true;
      this.measurementLogDetails.measurement_date = moment().format('YYYY-MM-DD');
    }
    $('#add-update-measurement-log').modal('show');
  }

  setMeasurementType() {
    const measurement = this.measurementList.find((e) => e.id == this.measurementLogDetails.measurement_id);
    this.valueUnits = (measurement) ? measurement.unit.split(',') : [];
    this.measurementLogDetails.value_unit = (this.valueUnits.length > 0) ? this.valueUnits[0] : '';
  }

  addUpdateMeasurementLog(close) {
    if (!this.measurementLogDetails.measurement_id) {
      this.toastr.error('Please select measurement.');
      return;
    }
    if (!this.measurementLogDetails.measurement_date) {
      this.toastr.error('Please select date.');
      return;
    }
    if (!this.measurementLogDetails.value) {
      this.toastr.error('Please enter value.');
      return;
    }
    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    formData.append('measurement_id', this.measurementLogDetails.measurement_id);
    formData.append('measurement_date', new Date(this.measurementLogDetails.measurement_date).toJSON().substring(0, 10));
    formData.append('value', (this.measurementLogDetails.value) ? this.measurementLogDetails.value.toString() : '');
    formData.append('value_unit', (this.measurementLogDetails.value_unit) ? this.measurementLogDetails.value_unit.toString() : '');
    formData.append('notes', (this.measurementLogDetails.notes) ? this.measurementLogDetails.notes.toString() : '');
    if (this.isNewMeasurementLog) {
      this.addMeasurementLog(formData, close, this.measurementLogDetails.measurement_id);
    } else {
      formData.append('measurementlog_id', this.measurementLogDetails.id);
      this.updateMeasurementLog(formData, this.measurementLogDetails.measurement_id);
    }
  }

  addMeasurementLog(formData, close, measurement_id) {
    if (!this.requestMade) {
      this.requestMade = true;
      this.loader.show();
      this.measurementsService.addMeasurementLog(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            if (close) {
              $('#add-update-measurement-log').modal('hide');
              this.getMeasurementLogList();
              this.getHistoryMeasurementLogList(measurement_id, 0);
            }
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  updateMeasurementLog(formData, measurement_id) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.measurementsService.updateMeasurementLog(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-update-measurement-log').modal('hide');
            this.getMeasurementLogList();
            this.getHistoryMeasurementLogList(measurement_id, 0);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  deleteMeasurementLog(item) {
    const id = item.id;
    const measurementId = item.measurement_id;
    if (id) {
      this.loader.show();
      var formData: FormData = new FormData();
      formData.append('measurementlog_id', id);
      this.measurementsService.deleteMeasurementLog(formData).subscribe(
        (response) => {
          if (response.status) {
            this.getMeasurementLogList();
            this.getHistoryMeasurementLogList(measurementId, 1);
          }
          this.loader.hide();
        }, (err) => {
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  //#endregion

  //#region Client Sticker

  getClientStickerList() {
    this.loader.show()
    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    this.clientProfileService.getClientStickerList(formData).subscribe(
      (response) => {
        if (response.status) {
          this.stickerList = response.data;
          this.filterClientStickerList();
        } else {
          this.stickerList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  getAllStickerList() {
    this.loader.show()
    this.clientProfileService.getAllStickerList().subscribe(
      (response) => {
        if (response.status) {
          this.stickerDropdownList = response.data;
        } else {
          this.stickerDropdownList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }


  filterClientStickerList() {

    this.filterstickerLogList = [];
    this.filterstickerLogList = (this.searchText) ? this.stickerList.filter((e) => e.title.toLowerCase().indexOf(this.searchText) > -1) : this.stickerList;
  }

  deleteSticker() {
    this.loader.show();
    var formData: FormData = new FormData();
    formData.append('id', this.deleteItem.assign_id);
    this.clientProfileService.deleteClientSticker(formData).subscribe(
      (response) => {
        if (response.status) {
          this.toastr.success(response.message);
          this.getClientStickerList();
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  openAddUpdateStickerDialog(item) {
    this.clearSticker();
    if (item) {
      this.stickerDetails = item;
      this.assignStickersId = item.id;
      this.stickersCollectedDate = moment(item.collected_date).format('YYYY-MM-DD');
      this.stickersStatus = item.sticker_status;
      this.isNewSticker = false;
      // this.setExerciseType();
    }
    else {
      this.isNewSticker = true;
    }
    $('#assign-sticker').modal('show');
  }

  clearSticker() {
    this.stickerDetails = null;
    this.assignStickersId = null;
    this.stickersCollectedDate = null;
    this.stickersStatus = null;
  }

  assignSticker() {

    if (this.isNewSticker) {
      if (!this.assignStickersId) {
        this.toastr.error('Please select sticker.');
        return;
      }
      if (!this.stickerNotificationMsg) {
        this.toastr.error('Please enter notification message.');
        return;
      }
    }

    var formData: FormData = new FormData();

    if (this.isNewSticker) {
      formData.append('sticker_id', this.assignStickersId);
      formData.append('client_id', this.clientId.toString());
      formData.append('message', this.stickerNotificationMsg);

      this.assignStickerToClient(formData);
    } else {
      formData.append('id', this.stickerDetails.assign_id);
      formData.append('active', (this.stickersStatus) ? '1' : '0');
      formData.append('collected_date', new Date(this.stickersCollectedDate).toJSON().substring(0, 10));

      this.updateassignSticker11(formData);
    }

  }

  assignStickerToClient(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.clientProfileService.assignStickerToClient(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            this.getClientStickerList();
            $('#assign-sticker').modal('hide');
            this.assignStickersId = null;
            this.stickerNotificationMsg = null;
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  updateassignSticker11(formData) {
    if (!this.requestMade) {
      this.loader.show
      this.requestMade = true;
      this.clientProfileService.updateAssignedSticker(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            this.getClientStickerList();
            $('#assign-sticker').modal('hide');
            this.assignStickersId = null;
            this.stickerNotificationMsg = null;
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  //#endregion


  //#region Client Workout

  getClientWorkoutList() {
    this.loader.show();
    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    formData.append('searchname', this.searchText);
    this.clientProfileService.getClientWorkoutList(formData).subscribe(
      (response) => {
        if (response.status) {
          this.workoutList = response.data;
          this.filterClientWorkoutList();
        } else {
          this.workoutList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }


  getClientWorkoutLogList() {
    this.loader.show();
    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    this.clientProfileService.getClientWorkoutLogList(formData).subscribe(
      (response) => {
        if (response.status) {
          this.workoutLogList = response.data;
        } else {
          this.workoutLogList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }


  filterClientWorkoutList() {
    this.filterworkoutList = [];
    this.filterworkoutList = (this.searchText) ? this.workoutList.filter((e) => e.title.toLowerCase().indexOf(this.searchText) > -1) : this.workoutList;
  }

  filterClientWorkoutLogList() {

    // let aa = this.workoutLogList[0].start_datelogged;
    // console.log(aa);
    // console.log(moment(aa).format('hh:mm A'));
    // console.log(moment(aa).format('DD/MM/YYYY'));

    this.filterworkoutLogList = [];
    this.filterworkoutLogList = (this.searchText) ? this.workoutLogList.filter((e) => e.title.toLowerCase().indexOf(this.searchText) > -1) : this.workoutLogList;
  }

  openWorkoutLogDialog(workoutLogId, open = false) {
    this.loader.show();
    var formData: FormData = new FormData();
    formData.append('workout_log_id', workoutLogId.toString());
    this.clientProfileService.getWorkoutLogsList(formData).subscribe(
      (response) => {
        if (response.status) {
          this.workoutFitnessLogList = response.data;
          if (open) {
            $('#workout-fitness-log-dialog').modal('show');
          }
        } else {
          this.workoutFitnessLogList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }


  deleteClientWorkout() {
    this.loader.show();
    var formData: FormData = new FormData();
    formData.append('client_id', this.deleteItem.client_id);
    formData.append('workout_program_id', this.deleteItem.workout_program_id);
    this.clientProfileService.deleteClientWorkout(formData).subscribe(
      (response) => {
        if (response.status) {
          this.toastr.success(response.message);
          this.getClientWorkoutList();
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }


  deleteClientWorkoutLog() {
    this.loader.show();
    var formData: FormData = new FormData();
    formData.append('client_workout_log_id', this.deleteItem.id);
    this.clientProfileService.deleteClientWorkoutLog(formData).subscribe(
      (response) => {
        if (response.status) {
          this.toastr.success(response.message);
          this.getClientWorkoutLogList();
          this.getClientWorkoutList();
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  getAllWorkoutList() {
    this.loader.show();
    this.workoutService.getAllWorkoutList().subscribe(
      (response) => {
        if (response.status) {
          this.workoutDropdownList = response.data;
        } else {
          this.workoutDropdownList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  assignWorkoutProgram() {
    var formData: FormData = new FormData();
    formData.append('admin_id', this.storageService.loggedInUser.id.toString());
    formData.append('workout_program_id', this.assignWorkoutId);
    formData.append('client_id', this.clientId.toString());
    formData.append('trainer_id', this.assignClientTrainerId);
    formData.append('start_date', this.workoutStartDate);
    if (this.isNewWorkout) {
      formData.append('notification_message', this.workoutNotificationMsg);
      this.assignWorkoutToClient(formData);
    } else {
      formData.append('id', this.workoutDetails.id);
      formData.append('active', (this.workoutStatus) ? '1' : '0');
      this.updateassignWorkout(formData);
    }
  }

  assignWorkoutToClient(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.workoutService.assignWorkoutProgramToClient(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            this.getClientWorkoutList();
            $('#assign-workout').modal('hide');
            this.warningModal = false;

            this.clearWorkout();

          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }


  updateassignWorkout(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.workoutService.UpdateAssignWorkout(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            this.getClientWorkoutList();
            $('#assign-workout').modal('hide');
            this.warningModal = false;
            this.clearWorkout();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  openAddUpdateWorkoutDialog(item) {

    this.clearWorkout();
    if (item) {
      this.workoutDetails = item;
      this.assignWorkoutId = item.workout_program_id;
      this.workoutStatus = item.workout_status;
      this.assignClientTrainerId = item.trainer_id.split(',').map((id: any) => parseInt(id.trim()));
      this.workoutStartDate = item.start_date ? moment(item.start_date).format('YYYY-MM-DD') : '';
      this.isNewWorkout = false;
    } else {
      this.isNewWorkout = true;
    }
    $('#assign-workout').modal('show');
  }

  clearWorkout() {
    this.workoutDetails = null;
    this.assignWorkoutId = null;
    this.workoutNotificationMsg = null;
    this.isNewWorkout = null;
    this.assignClientTrainerId = null;
    this.workoutStartDate = null;
  }

  //#endregion

  //#region Equipment

  getAllEquipmentList() {
    this.loader.show();
    this.equipmentsService.getEquipmentList().subscribe((response) => {
      if (response) {
        this.equipmentDropdownList = response.data;
      } else {
        this.equipmentDropdownList = [];
      }
      this.loader.hide();
    }, (err) => {
      this.loader.hide();
      console.log(err);
    })
  }
  openAddUpdateEquipmentDialog() {
    this.clearEquipment();
    this.isNewEquipment = true;
    $('#assign-equipment').modal('show');
  }

  assignEquipment() {
    if (this.isNewEquipment) {
      if (!this.assignEquipmentId) {
        this.toastr.error('Please select Equipment.');
        return;
      }
    }

    var formData: FormData = new FormData();
    if (this.isNewEquipment) {
      formData.append('equipment_id', this.assignEquipmentId);
      formData.append('client_id', this.clientId.toString());
      this.assignEquipmentToClient(formData);
    } else {
      formData.append('equipment_id', this.assignEquipmentId);
      this.updateAssignEquipment(formData);
    }
  }

  assignEquipmentToClient(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.equipmentsService.assignEquipmentToClient(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            this.getClientEquipmentList();
            $('#assign-equipment').modal('hide');
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  async checkEquipments() {
    await this.getClientEquipmentList();
    if (this.isNewWorkout) {
      if (!this.assignWorkoutId) {
        this.toastr.error('Please select workout.');
        return;
      }
      if (!this.workoutNotificationMsg) {
        this.toastr.error('Please enter notification message.');
        return;
      }
      if (!this.workoutStartDate) {
        this.toastr.error('Please select Date.');
        return;
      }
      if (!this.assignClientTrainerId) {
        this.toastr.error('Please select Trainer.');
        return;
      }

      const exerciseIds = await this.getUniqueExerciseIds();

      try {
        const response = await this.workoutService.getExerciseEquipment({ exercise_ids: exerciseIds }).toPromise();
        let exerciseEquipments: { [exerciseId: string]: number[] } = {};

        if (response && response.data) {
          exerciseEquipments = response.data;
        }

        const getExerciseNameById = (exerciseId: number): string => {
          const exercise = this.exerciseList.find(ex => ex.id === exerciseId);
          return exercise ? exercise.title : '';
        };

        const getEquipmentNameById = (equipmentId: number): string => {
          const equipment = this.equipmentDropdownList.find(eq => eq.id === equipmentId);
          return equipment ? equipment.name : '';
        };
        const missingEquipments: any[] = [];
        const equipmentIds = this.equipmentList.map(item => item.id);
        for (const [exerciseId, exerciseEquipment] of Object.entries(exerciseEquipments)) {
          const exerciseEquipmentIds = exerciseEquipment as number[];
          const missing = exerciseEquipmentIds.filter(equipmentId => !equipmentIds.includes((equipmentId)));

          if (missing.length > 0) {
            const missingEquipmentNames = missing.map(equipmentId => getEquipmentNameById(equipmentId));
            const exerciseName = getExerciseNameById(Number(exerciseId));
            missingEquipments.push({
              exerciseName,
              missingEquipmentNames
            });
          }
        }
        this.missingEquipmentNames = missingEquipments;
        if (missingEquipments.length > 0) {
          this.warningModal = true;
        } else {
          this.assignWorkoutProgram();
        }
      } catch (err) {
        console.error(err);
        this.toastr.error('An error occurred while fetching exercise equipment.');
      }
    } else {
      this.assignWorkoutProgram();
    }
  }

  changeWorkout(event) {
    this.commonService.getWorkoutDetailById(event.id);
  }

  async getUniqueExerciseIds() {
    const exerciseIds = new Set<number>();
    if (this.commonService.workoutProgram.workout) {
      this.commonService.workoutProgram.workout.forEach(workout => {
        workout.section.forEach(section => {
          section.exercise.forEach(exercise => {
            exerciseIds.add(exercise.exercise_id);
          });
        });
      });
    }
    return Array.from(exerciseIds);
  }

  getClientEquipmentList() {
    this.loader.show()
    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    this.clientProfileService.getClientEquipmentList(this.clientId).subscribe(
      (response) => {
        if (response) {
          this.equipmentList = response.data;
        } else {
          this.equipmentList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  updateAssignEquipment(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.equipmentsService.assignEquipmentToClient(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response) {
            this.toastr.success(response.message);
            this.getClientEquipmentList();
            $('#assign-equipment').modal('hide');
            this.clearWorkout();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  clearEquipment() {
    this.assignEquipmentId = null;
    this.isNewEquipment = null;
  }

  openImageLink(item) {
    window.open(environment.BASE_URL + item, '_blank');
  }

  deleteClientEquipment() {
    this.loader.show();
    var formData: FormData = new FormData();
    formData.append('equipment_id', this.deleteItem.id);
    formData.append('client_id', this.clientId.toString());
    this.clientProfileService.deleteClientEquipment(formData).subscribe(
      (response) => {
        if (response) {
          this.toastr.success(response.message);
          this.getClientEquipmentList();
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }
  //#endregion

  //#region Client Challenges

  getClientChallengesList() {
    this.loader.show();
    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    this.clientProfileService.getClientChallengesList(formData).subscribe(
      (response) => {
        if (response.status) {
          this.challengeList = response.data;
          this.filterClientChallengeList();
        } else {
          this.challengeList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }


  getAllChallenge() {
    this.loader.show();
    this.clientProfileService.getAllChallengeList().subscribe(
      (response) => {
        if (response.status) {
          this.challengeDropdownList = response.data;
        } else {
          this.challengeDropdownList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  filterClientChallengeList() {

    this.filterChallengeList = [];
    this.filterChallengeList = (this.searchText) ? this.challengeList.filter((e) => e.title.toLowerCase().indexOf(this.searchText) > -1) : this.challengeList;
  }

  deleteClientChallenge() {
    this.loader.show();
    var formData: FormData = new FormData();
    formData.append('id', this.deleteItem.client_challenge_id);
    this.clientProfileService.deleteClientChallenge(formData).subscribe(
      (response) => {
        if (response.status) {
          this.toastr.success(response.message);
          this.getClientChallengesList();
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  openAddUpdateChallengeDialog(item) {
    this.clearChallenge();
    if (item) {
      this.challengeDetails = item;
      this.challenge_id = item.challenge_id;
      this.challenge_active = item.challenge_status;
      this.isNewChallenge = false;
    } else {
      this.isNewChallenge = true;
    }
    $('#assign-challenge').modal('show');
  }

  clearChallenge() {
    this.challengeDetails = null;
    this.challenge_id = null;
    this.challenge_notification = null;
    this.challenge_active = null;
  }

  assignChallenge() {

    if (this.isNewChallenge) {
      if (!this.challenge_id) {
        this.toastr.error('Please select challenge.');
        return;
      }
      if (!this.challenge_notification) {
        this.toastr.error('Please enter notification message.');
        return;
      }
    }

    var formData: FormData = new FormData();
    formData.append('challenge_id', this.challenge_id);
    formData.append('client_id', this.clientId.toString());
    
    if (this.isNewChallenge) {
      formData.append('challenge_status', '1');
      formData.append('notification_message', this.challenge_notification);
    } else {
      formData.append('challenge_status', (this.challenge_active) ? '1' : '0');
    }
    this.assignChallengeToClient(formData);

  }


  assignChallengeToClient(formData) {
    this.loader.show();
    this.clientProfileService.assignChallengeToClient(formData).subscribe(
      (response) => {
        if (response.status) {
          this.toastr.success(response.message);
          this.getClientChallengesList();
          $('#assign-challenge').modal('hide');
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }
  // Monali's code start
  getPurchasedMembershipListByClientId() {
    this.loader.show();
    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    this.clientProfileService.getPurchasedMembershipListByClientId(formData).subscribe(
      (response) => {
        if (response.status) {
          this.membershipList = response.data;
        } else {
          this.membershipList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  getBillingCreditListByClientId() {
    this.loader.show();
    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    this.clientProfileService.getBillingCreditListByClientId(formData).subscribe(
      (response) => {
        if (response.status) {
          this.billingCreditList = response.data;
        } else {
          this.billingCreditList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }
  // Open client bill modal
  openAddBillDialog(item) {
    this.clearAddBill();

    $('#add-bill').modal('show');
  }
  clearAddBill() {
    this.billStartDate = moment(new Date()).format('YYYY-MM-DD');
    this.billEndDate = moment(new Date()).format('YYYY-MM-DD');
    this.billDescription = null;
    this.billAmount = null;
  }
  saveBill() {
    if (!this.billStartDate) {
      this.toastr.error('Please select bill start date.');
      return;
    }
    if (!this.billEndDate) {
      this.toastr.error('Please select bill end date.');
      return;
    }
    if (!this.billDescription) {
      this.toastr.error('Please enter bill description.');
      return;
    }
    if (!this.billAmount) {
      this.toastr.error('Please enter bill amount.');
      return;
    }

    var formData: FormData = new FormData();
    formData.append('billing_amount', this.billAmount);
    formData.append('billing_status', '0');
    formData.append('description', this.billDescription);
    formData.append('start_date', this.billStartDate);
    formData.append('end_date', this.billStartDate);

    formData.append('client_id', this.clientId.toString());
    this.assignBillToClient(formData);
  }

  assignBillToClient(formData) {
    this.loader.show();
    this.clientProfileService.assignBillToClient(formData).subscribe(
      (response) => {
        if (response.status) {
          this.toastr.success(response.message);
          this.getBillingCreditListByClientId();
          $('#add-bill').modal('hide');
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }
  openPayNowDialog(item) {
    this.clearPayNow();
    if (item) {
      this.paymentAmount = item.billing_amount;
      this.billingCreditsId = item.id;
    }
    $('#pay-now').modal('show');
  }
  clearPayNow() {
    this.paymentDate = moment(new Date()).format('YYYY-MM-DD');
    this.paymentAmount = null;
    this.paymentMethod = null;
    this.billingCreditsId = null;
  }
  getAllPaymentMethod() {
    this.loader.show();
    this.clientProfileService.getPaymentMethodList().subscribe(
      (response) => {
        if (response.status) {
          this.paymentMethodList = response.data;
        } else {
          this.paymentMethodList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }
  getmembershipTypeList() {
    this.loader.show();
    this.membershipService.getmembershipTypeList().subscribe(
      (response) => {
        if (response.data) {
          this.membershipTypeList = response.data;
        } else {
          this.membershipTypeList = [];
        }
        this.loader.hide()
      }, (err) => {
        this.loader.hide()
        console.log(err);
      }
    );
  }

  getMembershipLocalityList() {
    this.loader.show();
    this.membershipService.getMembershipLocalityList().subscribe(
      (response) => {
        if (response.data) {
          this.membershipLocalityList = response.data;
        } else {
          this.membershipLocalityList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  getAllMembershipListing() {
    this.loader.show();
    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    this.clientProfileService.getMembershipListForUserGroup(formData).subscribe(
      (response) => {
        if (response.status) {
          this.membershipListing = response.data;
        } else {
          this.membershipListing = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }
  savePayNow() {
    if (!this.paymentDate) {
      this.toastr.error('Please select payment date.');
      return;
    }
    if (!this.paymentMethod) {
      this.toastr.error('Please select payment method.');
      return;
    }
    var formData: FormData = new FormData();
    formData.append('payment_method_id', this.paymentMethod);
    formData.append('billing_status', '1');
    formData.append('payment_date_time', this.paymentDate + ' ' + moment(new Date()).format('HH:mm:ss'));
    formData.append('billing_credits_id', this.billingCreditsId);
    formData.append('currency', 'BHD');
    formData.append('client_id', this.clientId.toString());
    this.updateBillingCreditData(formData);
  }

  updateBillingCreditData(formData) {
    this.loader.show();
    this.clientProfileService.updateBillingCreditData(formData).subscribe(
      (response) => {
        if (response.status) {
          this.toastr.success(response.message);
          this.getPurchasedMembershipListByClientId();
          this.getBillingCreditListByClientId();
          $('#pay-now').modal('hide');
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  openPauseMembershipDialog(item) {
    this.clearPauseMembership();
    if (item) {
      this.loader.show();
      this.clientMembershipId = item.id;
      var formData: FormData = new FormData();
      formData.append('client_membership_id', this.clientMembershipId.toString());
      this.clientProfileService.getPausedMembershipDetail(formData).subscribe(
        (response) => {
          if (response.status) {
            if (response.data[0]) {
              this.pauseStartDate = response.data[0].start_date;
              this.pauseEndDate = response.data[0].end_date;
              this.pauseDescription = response.data[0].description;
              this.pauseAmount = response.data[0].amount;
              this.alreadyPaused = 1;
              this.alreadyPausedStartDate = response.data[0].start_date;
              this.alreadyPausedEndDate = response.data[0].end_date;
              $('#pause-membership').modal('show');
            }
            else {
              this.alreadyPaused = 0;
              $('#pause-membership').modal('show');
            }
          }
          else {
            $('#pause-membership').modal('show');
          }
          this.loader.hide();
        }, (err) => {
          this.loader.hide();
          console.log(err);
        }
      );
    }

  }
  clearPauseMembership() {
    this.pauseStartDate = moment(new Date()).format('YYYY-MM-DD');
    this.pauseEndDate = moment(new Date()).format('YYYY-MM-DD');
    this.pauseDescription = null;
    this.pauseAmount = null;
    this.alreadyPaused = 0;
    this.clientMembershipId = null;
    this.alreadyPausedStartDate = null;
    this.alreadyPausedStartDate = null;
  }
  pauseMembership() {
    if (!this.pauseStartDate) {
      this.toastr.error('Please select effective pause start date.');
      return;
    }
    if (!this.pauseEndDate) {
      this.toastr.error('Please select inclusive pause end date.');
      return;
    }
    if (!this.pauseDescription) {
      this.toastr.error('Please enter pause reason.');
      return;
    }
    var formData: FormData = new FormData();
    formData.append('start_date', this.pauseStartDate);
    formData.append('end_date', this.pauseEndDate);
    formData.append('description', this.pauseDescription);

    if (!this.pauseAmount) {
      formData.append('fee_amount', '0');
    }
    else {
      formData.append('fee_amount', this.pauseAmount);
    }
    formData.append('status', '1');
    formData.append('client_membership_id', this.clientMembershipId.toString());
    if (this.alreadyPausedStartDate != this.pauseStartDate || this.alreadyPaused === 0) {
      this.clientRequestToPauseMembership(formData);
    }
    else {
      this.resumeMembershipManually(formData);
    }
  }
  clientRequestToPauseMembership(formData) {
    this.loader.show();
    this.clientProfileService.clientRequestToPauseMembership(formData).subscribe(
      (response) => {
        if (response.status) {
          this.toastr.success(response.message);
          this.getPurchasedMembershipListByClientId();
          this.getBillingCreditListByClientId();
          $('#pause-membership').modal('hide');
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  resumeMembershipManually(formData) {
    this.loader.show();
    this.clientProfileService.resumeMembershipManually(formData).subscribe(
      (response) => {
        if (response.status) {
          this.toastr.success(response.message);
          this.getPurchasedMembershipListByClientId();
          this.getBillingCreditListByClientId();
          $('#pause-membership').modal('hide');
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  openUpgradeDowngradeMembershipDialog(item) {
    this.clearUpgradeDowngradeMembership();
    if (item) {
      this.clientMembershipId = item.id;
    }
    $('#upgrade-downgrade-membership').modal('show');
  }
  clearUpgradeDowngradeMembership() {
    this.udAction = null;
    this.udDuration = null;
    this.udDescription = null;
    this.udFirstMonthFee = null;
  }
  upgradeDowngradeMembership() {
    if (!this.udAction) {
      this.toastr.error('Please select upgrade / downgrade membership.');
      return;
    }
    if (!this.udDuration) {
      this.toastr.error('Please duration that how many month to upgrade / downgrade.');
      return;
    }

    var formData: FormData = new FormData();
    formData.append('action', this.udAction);
    formData.append('duration', this.udDuration);
    formData.append('description', this.udDescription);

    if (!this.udFirstMonthFee) {
      formData.append('first_month_extra_fee', '0');
    }
    else {
      formData.append('first_month_extra_fee', this.udFirstMonthFee);
    }
    formData.append('cm_id', this.clientMembershipId.toString());
    this.saveUpgradeDowngradeMembership(formData);
  }
  saveUpgradeDowngradeMembership(formData) {
    this.loader.show();
    this.clientProfileService.upgradeDowngradeMembership(formData).subscribe(
      (response) => {
        if (response.status) {
          this.toastr.success(response.message);
          this.getPurchasedMembershipListByClientId();
          this.getBillingCreditListByClientId();
          $('#upgrade-downgrade-membership').modal('hide');
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }
  openCancelMembershipDialog(item) {
    this.clearCancelMembership();
    if (item) {
      this.clientMembershipId = item.id;
    }
    $('#cancel-membership').modal('show');
  }
  clearCancelMembership() {
    this.cancelAmount = null;
  }

  cancelMembership() {
    this.loader.show();
    var formData: FormData = new FormData();
    if (!this.cancelAmount) {
      formData.append('cancel_amount', '0');
    }
    else {
      formData.append('cancel_amount', this.cancelAmount);
    }
    formData.append('client_membership_id', this.clientMembershipId.toString());
    formData.append('client_id', this.clientId.toString());
    this.clientProfileService.cancelMembership(formData).subscribe(

      (response) => {
        if (response.status) {
          this.toastr.success(response.message);
          this.getPurchasedMembershipListByClientId();
          this.getBillingCreditListByClientId();
          $('#cancel-membership').modal('hide');
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }
  openEditBillingCreditModal(item) {
    this.clearBillingCreditsData();
    if (item) {
      console.log(item);
      this.bcStartDate = item.start_date;
      this.bcEndDate = item.end_date;
      this.bcDescription = item.description;
      this.bcAmount = item.billing_amount;
      this.bcCredits = item.credits_remaining;
      this.bcStatus = item.new_billing_status;
      this.billingCreditId = item.id;
    }
    $('#edit-billing-credit').modal('show');
  }
  clearBillingCreditsData() {
    this.bcStartDate = null;
    this.bcEndDate = null;
    this.bcDescription = null;
    this.bcAmount = null;
    this.bcCredits = null;
    this.bcStatus = null;
    this.billingCreditId = null;
  }
  saveUpdateBillingCreditData() {
    if (!this.bcStartDate) {
      this.toastr.error('Please select effective start date.');
      return;
    }
    if (!this.bcEndDate) {
      this.toastr.error('Please select inclusive end date.');
      return;
    }
    var formData: FormData = new FormData();
    formData.append('start_date', this.bcStartDate);
    formData.append('end_date', this.bcEndDate);
    formData.append('billing_amount', (this.bcAmount) ? this.bcAmount : 0);
    formData.append('credits', (this.bcCredits) ? this.bcCredits : 0);
    formData.append('billing_status', this.bcStatus);
    formData.append('billing_description', this.bcDescription);
    formData.append('billing_credits_id', this.billingCreditId.toString());
    this.saveChangedBillingCreditData(formData);
  }

  saveChangedBillingCreditData(formData) {
    this.loader.show();
    this.clientProfileService.updateBillingCreditData(formData).subscribe(
      (response) => {
        if (response.status) {
          this.toastr.success(response.message);
          this.getPurchasedMembershipListByClientId();
          this.getBillingCreditListByClientId();
          $('#edit-billing-credit').modal('hide');
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  openClientPaymentDialog(item) {
    this.loader.show();
    const id = item.billing_credits_id || item.id;
    var formData: FormData = new FormData();
    formData.append('billing_credits_id', id.toString());
    this.clientProfileService.getClientPaymentList(formData).subscribe(
      (response) => {
        this.clientPaymentList = [];
        if (response.status) {
          this.clientPaymentList = response.data;
          $('#client-payment').modal('show');
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  checkPaymentStatus(item) {
    if (item.payment_method_id == '2') {
      this.checkCreditCardPaymentStatus(item);
    } else {
      this.checkBenefitPaymentStatus(item);
    }
  }

  checkCreditCardPaymentStatus(item) {
    this.loader.show();
    const id = item.billing_credits_id || item.id;
    var formData: FormData = new FormData();
    formData.append('billingCreditsId', id.toString());
    this.clientProfileService.checkCreditCardPaymentStatus(formData).subscribe(
      (response) => {
        if (response.status) {
          this.toastr.success(response.message);
          this.refreshPaymentData(item);
        }
        else {
          this.toastr.error(response.message);
          this.refreshPaymentData(item);
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  checkBenefitPaymentStatus(item) {
    this.loader.show();
    const id = item.billing_credits_id || item.id;
    var formData: FormData = new FormData();
    formData.append('billingCreditsId', id.toString());
    this.clientProfileService.checkBenefitPaymentStatus(formData).subscribe(
      (response) => {
        if (response.status) {
          this.toastr.success(response.message);
          this.refreshPaymentData(item);
        }
        else {
          this.toastr.error(response.message);
          this.refreshPaymentData(item);
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  refreshPaymentData(item) {
    this.getPurchasedMembershipListByClientId();
    this.getBillingCreditListByClientId();
    this.openClientPaymentDialog(item);
  }

  updateClientPaymentData(item, description, status, billingStatus) {
    this.loader.show();
    var formData: FormData = new FormData();
    formData.append('billing_credits_id', item.billing_credits_id.toString());
    formData.append('description', description);
    formData.append('status', status);
    formData.append('billing_status', billingStatus);
    this.clientProfileService.updateClientPaymentData(formData).subscribe(
      (response) => {
        if (response.status) {
          this.getPurchasedMembershipListByClientId();
          this.getBillingCreditListByClientId();
          this.openClientPaymentDialog(item);
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        const error_msg = 'Payment ERROR getting hash: ' + err;
        this.toastr.error(error_msg);
      }
    );
  }

  openEditClientMembershipDialog(item) {
    this.clearEditClientMembershipDialog();
    if (item) {
      this.clientMembershipId = item.id;
      this.cmStartDate = item.start_date;
      this.cmEndDate = item.end_date;
      this.cmStatus = item.status;
      this.cmAutomaticPayment = item.automatic_payments;
    }
    $('#edit-client-membership').modal('show');


  }
  clearEditClientMembershipDialog() {
    this.clientMembershipId = null;
    this.cmStartDate = null;
    this.cmEndDate = null;
    this.cmStatus = null;
    this.cmAutomaticPayment = null;
  }
  saveClientMembershipData() {
    if (!this.cmStartDate) {
      this.toastr.error('Please select effective start date.');
      return;
    }
    if (!this.cmEndDate) {
      this.toastr.error('Please select inclusive end date.');
      return;
    }

    var formData: FormData = new FormData();
    formData.append('start_date', this.cmStartDate);
    formData.append('end_date', this.cmEndDate);
    formData.append('status', this.cmStatus);
    formData.append('automatic_payments', this.cmAutomaticPayment);

    formData.append('client_membership_id', this.clientMembershipId.toString());
    this.updateClientMembershipData(formData);
  }
  updateClientMembershipData(formData) {
    this.loader.show();
    this.clientProfileService.updateClientMembershipData(formData).subscribe(
      (response) => {
        if (response.status) {
          this.toastr.success(response.message);
          this.getPurchasedMembershipListByClientId();
          $('#edit-client-membership').modal('hide');
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }
  openAssignMembershipDialog() {
    this.clearAssignMembershipDialogue();
    this.getAllMembershipListing();
    $('#assign-membership').modal('show');
  }
  clearAssignMembershipDialogue() {
    this.puMembershipId = null;
    this.puMembershipTypeId = null;
    this.puMembershipLocalityId = null;
    this.puStartDate = moment(new Date()).format('YYYY-MM-DD');
    this.puEndDate = moment(new Date()).format('YYYY-MM-DD');
    this.puDuration = null;
    this.puCredits = null;
    this.puPartialCredits = null;
    this.puFirstMonthFee = null;
    this.puSubsequentMonthFee = null;
    this.puPartialFee = null;
    this.puTotalFee = null;
    this.puDescription = null;
    this.puBillStartDate = null;
    this.puBillEndDate = null;
    this.puActive = true;
  }

  getMembershipData() {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      var formData: FormData = new FormData();
      formData.append('client_id', this.clientId.toString());
      formData.append('start_date', this.puStartDate);
      formData.append('end_date', this.puEndDate);
      formData.append('membership_id', this.puMembershipId.toString());
      this.clientProfileService.purchaseMembership(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            //this.toastr.success(response.message);

            this.puMembershipTypeId = response.data.membership_type_id;
            this.puMembershipLocalityId = response.data.membership_locality_id;
            this.puStartDate = response.data.start_date;
            this.puEndDate = response.data.end_date;
            this.puDuration = response.data.duration;
            this.puCredits = response.data.credits;
            this.puPartialCredits = response.data.aggregateCredit;
            this.puFirstMonthFee = response.data.first_month_fee;
            this.puSubsequentMonthFee = response.data.subsequent_month_fee;
            this.puPartialFee = response.data.aggregateFee;
            this.puTotalFee = response.data.total_fee;
            this.puDescription = response.data.description;
            this.puBillStartDate = response.data.bill_start_date;
            this.puBillEndDate = response.data.bill_end_date;
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }
  assignMembershipToClient() {
    if (!this.puMembershipId) {
      this.toastr.error('Please select membership to assign.');
      return;
    }

    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    formData.append('membership_id', this.puMembershipId);
    formData.append('membership_type_id', this.puMembershipTypeId);
    formData.append('start_date', this.puStartDate);
    formData.append('end_date', this.puEndDate);
    formData.append('bill_start_date', this.puBillStartDate);
    formData.append('bill_end_date', this.puBillEndDate);
    formData.append('status', (this.puActive) ? '1' : '0');

    //formData.append('description', this.puDescription);
    formData.append('description', '');
    if (this.puPartialCredits > 0) {
      formData.append('credits', this.puPartialCredits);
      formData.append('billing_amount', this.puPartialFee);
    }
    else {
      formData.append('credits', this.puCredits);
      formData.append('billing_amount', this.puTotalFee);
    }
    formData.append('automatic_payments', this.cmAutomaticPayment);


    this.addPurchasedMembershipData(formData);
  }
  addPurchasedMembershipData(formData) {
    this.loader.show();
    this.clientProfileService.addPurchasedMembershipData(formData).subscribe(
      (response) => {
        if (response.status) {
          this.toastr.success(response.message);
          this.getPurchasedMembershipListByClientId();
          this.getBillingCreditListByClientId();
          $('#assign-membership').modal('hide');
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }
  getClientPreTrialMembershipSessionListAdmin() {
    this.loader.show();
    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());

    this.clientProfileService.getClientPreTrialMembershipSessionListAdmin(formData).subscribe(

      (response) => {
        if (response.status) {

          this.membershipPreTrialSessionList = response.data;
        } else {
          this.membershipPreTrialSessionList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }
  openAssignOneOnOneMembershipDialog(item) {
    this.clearAssignOneOnOneMembershipDialog();
    if (item && item != null) {
      console.log(item);
      this.prMembershipLocalityId = item.membership_locality_id;
      this.prStartDate = item.start_date;
      this.prEndDate = item.end_date;
      this.prStartTime = item.start_time;
      this.prEndTime = item.end_time;
      this.prDescription = item.description;
      this.prStatus = item.active;
      this.prId = item.id;
    }
    else {
      this.prId = 0;
    }
    $('#assign-one-on-one-membership').modal('show');
  }
  clearAssignOneOnOneMembershipDialog() {
    this.prMembershipLocalityId = null;
    this.prStartDate = null;
    this.prEndDate = null;
    this.prStartTime = null;
    this.prEndTime = null;
    this.prDescription = null;
    this.prStatus = null;
    this.prId = null;
  }
  SavePreTrialOneOnOneSession() {
    if (!this.prStartDate) {
      this.toastr.error('Please select effective start date.');
      return;
    }
    if (!this.prEndDate) {
      this.toastr.error('Please select inclusive end date.');
      return;
    }
    if (!this.prStartTime) {
      this.toastr.error('Please select effective start time.');
      return;
    }
    if (!this.prEndTime) {
      this.toastr.error('Please select inclusive end time.');
      return;
    }


    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    formData.append('id', this.prId);
    formData.append('membership_locality_id', this.prMembershipLocalityId);

    formData.append('start_date', this.prStartDate);
    formData.append('end_date', this.prEndDate);
    formData.append('start_time', this.prStartTime);
    formData.append('end_time', this.prEndTime);
    formData.append('description', this.prDescription);
    formData.append('active', this.prStatus);
    this.addPreTrialOneOnOneSession(formData);
  }
  addPreTrialOneOnOneSession(formData) {
    this.loader.show();
    this.clientProfileService.addUpdatePreTrialOneOnOneSession(formData).subscribe(
      (response) => {
        if (response.status) {
          this.toastr.success(response.message);
          this.getClientPreTrialMembershipSessionListAdmin();
          this.clientDetails.client_state = response.client_state;
          $('#assign-one-on-one-membership').modal('hide');
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }
  deletePreTrialOneOnOneSession() {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      var formData: FormData = new FormData();
      formData.append('id', this.deleteItem.id);
      this.clientProfileService.deletePreTrialOneOnOneSession(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            this.getClientPreTrialMembershipSessionListAdmin();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }
  openChallengeLogListDialog(item) {
    this.loader.show();
    var formData: FormData = new FormData();
    formData.append('client_id', this.clientId.toString());
    formData.append('challenge_id', item.challenge_id);
    formData.append('client_challenge_id', item.client_challenge_id);
    this.clientProfileService.getClientChallengeLogList(formData).subscribe(
      (response) => {
        if (response.status) {
          this.challengeTitle = item.title;
          this.challengeLogDateArray = response.data;
          this.challengeNotes = this.challengeLogDateArray
            .filter((log: any) => log.challenge_note.trim().length > 0)
            .sort((a, b) => new Date(a.challenge_log_date).getTime() - new Date(b.challenge_log_date).getTime());
          $('#challenge-log').modal('show');
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }
  getChallengeLogType(date) {
    const formattedDate = ("0" + date).slice(-2) + '-' + ("0" + (this.selectedMonth + 1)).slice(-2) + '-' + this.selectedYear;
  
    const challengeLog = this.challengeLogDateArray.find(e =>
      new Date(e.challenge_log_date).toLocaleString('en-GB').slice(0, 10).replace(/\//g, "-") === formattedDate
    );
  
    if (!challengeLog) {
      return 'empty-challenge';
    }
  
    switch (challengeLog.challenge_log_status) {
      case 0:
        return 'missed-challenge';
      case 1:
        return 'complete-challenge';
      case 2:
        return 'skipped-challenge';
      default:
        return 'empty-challenge';
    }
  }
  isChallengeNoteExist(date:any) :boolean {
    const formattedDate = ("0" + date).slice(-2) + '-' + ("0" + (this.selectedMonth + 1)).slice(-2) + '-' + this.selectedYear;
    const challengeLog = this.challengeLogDateArray.find(e =>
      new Date(e.challenge_log_date).toLocaleString('en-GB').slice(0, 10).replace(/\//g, "-") === formattedDate
    );
  
    if (!challengeLog) {
      return false;
    }
    return challengeLog.challenge_note.length!=0;
  }
  
  ////  Monali's code end

  openDeleteDialog(deleteFrom, item) {
    this.deleteFrom = deleteFrom;
    this.deleteItem = item;
    $('#delete-dialog').modal('show');
  }

  deleteRecord() {
    if (this.deleteFrom == 'client') {
      this.deleteClient();
    } else if (this.deleteFrom == 'client_personal_goals') {
      this.deletePersonalGoal();
    } else if (this.deleteFrom == 'client_injuries') {
      this.deleteInjury();
    } else if (this.deleteFrom == 'client_notes') {
      this.deleteNote();
    } else if (this.deleteFrom == 'food_dairy') {
      this.deleteFoodDiaryLog();
    } else if (this.deleteFrom == 'client_pre_trial_session') {
      this.deletePreTrialOneOnOneSession();
    } else if (this.deleteFrom === 'sticker') {
      this.deleteSticker();
    } else if (this.deleteFrom === 'challenge') {
      this.deleteClientChallenge();
    } else if (this.deleteFrom === 'workout') {
      this.deleteClientWorkout();
    } else if (this.deleteFrom === 'workoutlog') {
      this.deleteClientWorkoutLog();
    } else if (this.deleteFrom === 'equipment') {
      this.deleteClientEquipment();
    }
  }

  handleModalHidden(data: any) {
    if (data) {
      this.getClientWorkoutList();
    }
  }

  async openWorkoutProgramModal(isCreate: any, item: any) {
    this.commonService.clearData();
    this.isCreate = isCreate;
    if (!this.isCreate && item) {
      this.workoutProgramComp.workoutProgramId = item.workout_program_id;
    }
    this.commonService.selectedWeekId = this.commonService.activeWeekId;
    this.workoutProgramComp.openModal();
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MembershipApi, SessionApi } from 'src/app/const/app.url';

@Injectable({
  providedIn: 'root'
})
export class MembershipService {

  constructor(private http: HttpClient) { }

  getMembershipList(body?: any) {
    return this.http.post<any>(MembershipApi.getMembershipList, body)
  }

  getsessionTypeList() {
    return this.http.get<any>(SessionApi.sessionTypeList)
  }

  getmembershipTypeList() {
    return this.http.get<any>(MembershipApi.getMembershipTypeList)
  }

  getMembershipLocalityList() {
    return this.http.get<any>(MembershipApi.getMembershipLocalityList)
  }

  getGroupList() {
    return this.http.get<any>(MembershipApi.groupList)
  }

  createMembership(body?: any) {
    return this.http.post<any>(MembershipApi.createMembership, body)
  }

  updateMembership(body?: any) {
    return this.http.post<any>(MembershipApi.editMembership, body)
  }

  deleteMembership(body?: any) {
    return this.http.post<any>(MembershipApi.deleteMembership, body)
  }

  saveMembershipSetting(body?: any) {
    return this.http.post<any>(MembershipApi.saveMembershipSetting, body)
  }

  getMembershipGlobalConfigData(body?: any) {
    return this.http.post<any>(MembershipApi.getMembershipGlobalConfigData, body)
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PagerService } from 'src/app/shared/pager.service';
import { WorkoutService } from './workout.service';
import { WorkoutProgram } from 'src/app/model/workout.model';
import { EquipmentsService } from '../equipments/equipments.service';
import { LoaderService } from 'src/app/shared/loader.service';
import { SessionsService } from '../sessions/sessions.service';
import { WorkoutProgramComponent } from '../workout-program/workout-program.component';
import { CommonService } from 'src/app/shared/common.service';
import { StorageService } from 'src/app/shared/storage.service';

declare var $: any;

@Component({
  selector: 'app-workout',
  templateUrl: './workout.component.html',
  styleUrls: ['./workout.component.scss'],
})
export class WorkoutComponent implements OnInit {
  @ViewChild(WorkoutProgramComponent)
  workoutProgramComp: WorkoutProgramComponent;
  isCreate = false;

  searchText = '';
  viewActive = true;
  iniFlag: any = 1;
  pager: any = {};
  rowCount = 0;
  pageSize = 50;
  pageIndex = 1;
  orderBy = 'asc';

  workoutList: any;
  filterWorkoutList = null;
  workoutDropdownList = [];

  clientList = [];

  assignWorkoutId: any;
  assignWorkoutClients: any;
  notificationMsg: any;

  clientEquipments: any[] = [];
  warningModal = false;

  deleteItem = null;
  equipmentList = [];
  startDate = null;
  assignClientTrainer = null;
  trainerList = null;

  constructor(
    private toastr: ToastrService,
    private pagerService: PagerService,
    private workoutService: WorkoutService,
    private equipmentsService: EquipmentsService,
    private loader: LoaderService,
    private sessionsService: SessionsService,
    public commonService: CommonService,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.commonService.clearData();
    this.getWorkoutList(1);
    this.commonService.getExerciseList();
    this.getClientList();
    this.getAllWorkoutList();
    this.getAllEquipment();
    this.getTrainerList();
  }

  setPage(page: number) {
    if (page < 1) {
      return;
    }
    if (page > this.pager.totalPages) {
      return;
    }

    this.pageIndex = page;
    this.pager = this.pagerService.getPager(
      this.rowCount,
      this.pageSize,
      this.pageIndex
    );
    if (this.iniFlag === 0) {
      this.getWorkoutList(0);
    }
  }

  getWorkoutList(item) {
    this.loader.show();
    if (item === 1) {
      this.pageIndex = 1;
      this.iniFlag = 1;
    }

    var formData: FormData = new FormData();
    formData.append('searchname', this.searchText);
    formData.append('page_no', this.pageIndex.toString());
    formData.append('page_size', this.pageSize.toString());
    formData.append('order_by', this.orderBy.toString());

    this.workoutService.getWorkoutList(formData).subscribe(
      (response) => {
        if (response.data) {
          this.workoutList = response.data;
          this.rowCount = response.totalrow;
          if (this.iniFlag === 1) {
            this.setPage(1);
            this.iniFlag = 0;
          }
          this.filterWorkoutListData(this.viewActive);
        } else {
          this.workoutList = [];
          this.filterWorkoutList = [];
        }
        this.loader.hide();
      },
      (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  filterWorkoutListData(view): void {
    this.viewActive = view;
    this.filterWorkoutList = [];
    this.filterWorkoutList = this.viewActive
    ? this.workoutList.filter((e) => e.active)
    : this.workoutList;
  }

  async openAddUpdateWorkoutModal(isCreate, item) {
    this.commonService.clearData();
    this.isCreate = isCreate;
    if(!this.isCreate){
      await this.commonService.getWorkoutDetailById(item.id);
    }
    this.commonService.selectedWeekId = this.commonService.activeWeekId;
    this.workoutProgramComp.openModal();
  }

  handleModalHidden(data: any) {
    if(data){
      this.getWorkoutList(1);
      this.getAllWorkoutList();
    }
  }

  changeWorkout(event) {
    this.commonService.getWorkoutDetailById(event.id);
  }

  createFormData(
    object: Object,
    form?: FormData,
    namespace?: string
  ): FormData {
    const formData = form || new FormData();
    for (let property in object) {
      if (!object.hasOwnProperty(property) || !object[property]) {
        continue;
      }
      const formKey = namespace ? `${namespace}[${property}]` : property;
      if (object[property] instanceof Date) {
        formData.append(formKey, object[property].toISOString());
      } else if (
        typeof object[property] === 'object' &&
        !(object[property] instanceof File)
      ) {
        this.createFormData(object[property], formData, formKey);
      } else {
        formData.append(formKey, object[property]);
      }
    }

    return formData;
  }

  deleteWorkout() {
      this.loader.show();
      var formData: FormData = new FormData();
      formData.append('program_id', this.deleteItem.id);
      this.workoutService.deleteWorkout(formData).subscribe(
        (response) => {
          if (response.status) {
            this.toastr.success(response.message);
            this.getWorkoutList(1);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        },
        (err) => {
          this.loader.hide();
          console.log(err);
        }
      );
  }

  getClientName(id: any) {
    const client = this.clientList.find((client: any) => client.id == id);
    return client.first_name + client.last_name;
  }

  openAssignWorkoutModal(data) {
    this.assignWorkoutId = data.id;
    this.commonService.getWorkoutDetailById(data.id);
    this.warningModal = false;
    $('#assign-workout').modal('show');
  }

  getClientList() {
    this.loader.show();
    this.workoutService.getClientList().subscribe(
      (response) => {
        if (response.status) {
          this.clientList = response.data;
        } else {
          this.toastr.error(response.message);
        }
        this.loader.hide();
      },
      (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  getTrainerList(): void {
    this.sessionsService.getTrainerList().subscribe(
      (response) => {
        if (response.data) {
          this.trainerList = response.data.map((e) => {
            return {
              id: e.id.toString(),
              name: e.first_name + ' ' + e.last_name,
            };
          });
        } else {
          this.trainerList = [];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getAllWorkoutList() {
    this.loader.show();
    this.workoutService.getAllWorkoutList().subscribe(
      (response) => {
        if (response.status) {
          this.workoutDropdownList = response.data;
        } else {
          this.workoutDropdownList = [];
        }
        this.loader.hide();
      },
      (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  getAllEquipment() {
    this.equipmentsService.getEquipmentList().subscribe((response) => {
      this.equipmentList = response.data;
    });
  }

  async getClientEquipmentList() {
    if (!this.assignWorkoutId) {
      this.toastr.error('Please select workout.');
      return;
    }
    if (!this.assignWorkoutClients) {
      this.toastr.error('Please select clients.');
      return;
    }
    if (!this.notificationMsg) {
      this.toastr.error('Please enter notification message.');
      return;
    }
    if (!this.startDate) {
      this.toastr.error('Please Select Date.');
      return;
    }
    if (!this.assignClientTrainer) {
      this.toastr.error('Please Select Trainer.');
      return;
    }

    const exerciseIds = await this.getUniqueExerciseIds();
    interface ClientEquipment {
      clientName: any;
      equipments: any;
      missingEquipments?: {
        exerciseName: string;
        missingEquipmentNames: string[];
      }[];
    }

    try {
      const response = await this.workoutService
        .getExerciseEquipment({ exercise_ids: exerciseIds })
        .toPromise();
      let exerciseEquipments: { [exerciseId: string]: number[] } = {};
      this.clientEquipments = [];
      if (response.data) {
        exerciseEquipments = response.data;
      }

      const getExerciseNameById = (exerciseId: number) => {
        const exercise = this.commonService.exerciseList.find((ex) => ex.id === exerciseId);
        return exercise ? exercise.title : '';
      };

      const getEquipmentNameById = (equipmentId: number) => {
        const equipment = this.equipmentList.find(
          (eq) => eq.id === equipmentId
        );
        return equipment ? equipment.name : '';
      };

      const clientEquipmentPromises = this.assignWorkoutClients.map(
        async (client_id: any) => {
          try {
            const equipmentPromise = this.workoutService
              .getClientEquipmentList(client_id.toString())
              .toPromise();
            const clientNamePromise = this.getClientName(client_id);
            const [equipmentResponse, clientName] = await Promise.all([
              equipmentPromise,
              clientNamePromise,
            ]);
            const equipmentIds = equipmentResponse.data.map((item) => item.id);
            const clientEquipment: ClientEquipment = {
              clientName,
              equipments: equipmentIds,
              missingEquipments: [],
            };
            // Iterate over each exercise ID
            for (const [exerciseId, exerciseEquipment] of Object.entries(
              exerciseEquipments
            )) {
              const exerciseEquipmentIds = exerciseEquipment as number[];
              const missing = exerciseEquipmentIds.filter(
                (equipmentId) =>
                  !clientEquipment.equipments.includes(Number(equipmentId))
              );

              if (missing.length > 0) {
                const missingEquipmentNames = await Promise.all(
                  missing.map((equipmentId) =>
                    getEquipmentNameById(equipmentId)
                  )
                );
                const exerciseName = getExerciseNameById(Number(exerciseId));
                clientEquipment.missingEquipments.push({
                  exerciseName,
                  missingEquipmentNames,
                });
              }
            }
            return clientEquipment;
          } catch (err) {
            const clientName = await this.getClientName(client_id);
            return {
              clientName,
              equipments: null,
              missingEquipments: [],
            };
          }
        }
      );
      this.clientEquipments = await Promise.all(clientEquipmentPromises);
      const hasMissingEquipments = this.clientEquipments.some(
        (clientEquipment) => clientEquipment.missingEquipments.length > 0
      );
      if (hasMissingEquipments) {
        this.warningModal = true;
      } else {
        this.assignWorkoutProgram();
      }
    } catch (err) {
      this.toastr.error('An error occurred while fetching exercise equipment.');
    }
  }

  async getUniqueExerciseIds() {
    const exerciseIds = new Set<number>();
    if (this.commonService.workoutProgram.workout) {
      this.commonService.workoutProgram.workout.forEach((workout) => {
        workout.section.forEach((section) => {
          section.exercise.forEach((exercise) => {
            exerciseIds.add(exercise.exercise_id);
          });
        });
      });
    }
    return Array.from(exerciseIds);
  }

  assignWorkoutProgram() {
    this.warningModal = false;
      this.loader.show();
      var formData: FormData = new FormData();
      formData.append('admin_id', this.storageService.loggedInUser.id.toString());
      formData.append('workout_program_id', this.assignWorkoutId);
      formData.append('client_id', this.assignWorkoutClients);
      formData.append('notification_message', this.notificationMsg);
      formData.append('trainer_id', this.assignClientTrainer);
      formData.append('start_date', this.startDate);

      this.workoutService.assignWorkoutProgramToClient(formData).subscribe(
        (response) => {
          if (response.status) {
            this.toastr.success(response.message);
            this.assignWorkoutId = null;
            this.assignWorkoutClients = null;
            this.notificationMsg = null;
            this.assignClientTrainer = null;
            this.startDate = null;
            this.getWorkoutList(1);
            $('#assign-workout').modal('hide');
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        },
        (err) => {
          this.loader.hide();
          console.log(err);
        }
      );
  }

  openDeleteDialog(item) {
    this.deleteItem = item;
    $('#delete-dialog').modal('show');
  }

  duplicateWorkoutProgram(duplicateWorkoutId) {
    var formData: FormData = new FormData();
    formData.append('admin_id', this.storageService.loggedInUser.id.toString());
    formData.append('workout_program_id', duplicateWorkoutId);

    this.loader.show();
    this.workoutService.assignWorkoutProgramToClient(formData).subscribe(
      (response) => {
        if (response.status) {
          this.toastr.success("Workout Program Duplicated Successfully");
          this.getWorkoutList(1);
        } else {
          this.toastr.error(response.message);
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }
}

<div class="container-fluid main-content">
    <div class="row">
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
                <h4 class="my-2">Equipments</h4>
            </div>
        </div>
    </div>
    <div class="row pt-5">
        <div class="col-lg-2 d-none d-lg-block">
        </div>
        <div class="col-lg-10 text-right align-self-right col-lg-2 d-none d-lg-block">
            <ul class="table-feature">
                <li>
                    <div class="primary-btn pointer" (click)="openAddUpdateEquipmentModal(1,null)">
                        Add Equipment <img src="assets/images/add-white.png" alt="images">
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="table-responsive table-list">
                <table class="table">
                    <thead class="thead-light">
                        <tr>
                            <th>Equipment Name</th>
                            <th>Equipment Image</th>
                            <th class="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of equipmentList">
                            <td>{{item.name}}</td>
                            <td>
                                <div class="text-center">
                                    <img *ngIf="item.image" src="assets/images/image.svg"
                                        class="image-btn pointer success-color" alt="images"
                                        (click)="openImageLink(item.image)" />
                                </div>
                            </td>
                            <td>
                                <div class="text-center">
                                    <img src="assets/images/edit.png" class="image-btn pointer" alt="images"
                                        (click)="openAddUpdateEquipmentModal(0,item)" />
                                    <img src="assets/images/delete.png" class="image-btn pointer pl-2" alt="images"
                                        (click)="openDeleteDialog(item)" />
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="equipmentList.length === 0">
                            <td colspan="8" class="text-center">
                                No Equipments
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="modal fade p-0" id="add-equipment" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered add-session-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header text-center">
                <div class="d-flex w-100">
                    <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
                    <div class="modal-title w-100">{{(isCreate)?'Add':'Update'}} Equipment</div>
                </div>
            </div>
            <div class="modal-body">
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Name:</div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control no-radius" placeholder="Enter equipment name"
                            [(ngModel)]="name">
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4 pr-0">Image:</div>
                    <div class="col-sm-8">
                        <div class="form-control form-file-control no-radius">
                            <label for="fileInput" class="file-name">{{(fileInput) ? fileInput.name : 'Upload
                                image'}}</label>
                            <label for="fileInput" class="file-btn">Browse</label>
                            <input type="file" class="d-none" id="fileInput" (change)="onFileChange($event)" />
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 text-center">
                    <div class="btn pointer no-radius mb-2 mx-2 secondary-bg text-white" (click)="clearData()">
                        Clear Form
                    </div>
                    <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="saveEquipment()">
                        Save & Close
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-delete (onConfirm)="deleteEquipment()"></app-delete>
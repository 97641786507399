import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SessionApi } from 'src/app/const/app.url';

@Injectable({
  providedIn: 'root'
})
export class SessionsService {

  constructor(private http: HttpClient) { }

  getSessionList() {
    return this.http.get<any>(SessionApi.getSessionList)
  }

  getDateWiseSessionList(body?: any) {
    return this.http.post<any>(SessionApi.getDateWiseSessionList, body)
  }

  createSession(body?: any) {
    return this.http.post<any>(SessionApi.createSession, body)
  }

  updateSession(body?: any) {
    return this.http.post<any>(SessionApi.updateSession, body)
  }

  deleteSession(body?: any) {
    return this.http.post<any>(SessionApi.deleteSession, body)
  }

  getTrainerList() {
    return this.http.get<any>(SessionApi.getTrainerList)
  }

  getSessionTypeList(body?: any) {
    return this.http.post<any>(SessionApi.getSessionTypeList, body)
  }

  createSessionType(body?: any) {
    return this.http.post<any>(SessionApi.createSessionType, body)
  }

  updateSessionType(body?: any) {
    return this.http.post<any>(SessionApi.updateSessionType, body)
  }

  deleteSessionType(body?: any) {
    return this.http.post<any>(SessionApi.deleteSessionType, body)
  }

  getSessionOverrideList() {
    return this.http.get<any>(SessionApi.getSessionOverrideList)
  }

  createOverrideSession(body?: any) {
    return this.http.post<any>(SessionApi.createOverrideSession, body)
  }

  updateOverrideSession(body?: any) {
    return this.http.post<any>(SessionApi.updateOverrideSession, body)
  }

  getOverrideSessionList(body?: any) {
    return this.http.post<any>(SessionApi.getOverrideSessionList, body)
  }

  deactiveAllOverrideSession(body?: any) {
    return this.http.post<any>(SessionApi.deactiveAllOverrideSession, body)
  }
  
  deleteAllOverrideSession(body?: any) {
    return this.http.post<any>(SessionApi.deleteAllOverrideSession, body)
  }
}

import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PagerService } from 'src/app/shared/pager.service';
import { MeasurementsService } from './measurements.service';
import { LoaderService } from 'src/app/shared/loader.service';
declare var $: any;

@Component({
  selector: 'app-measurements',
  templateUrl: './measurements.component.html',
  styleUrls: ['./measurements.component.scss']
})
export class MeasurementsComponent implements OnInit {

  requestMade = false;
  isCreate = false;

  searchText = '';
  viewActive = true;
  iniFlag: any = 1;
  pager: any = {};
  rowCount = 0;
  pageSize = 50;
  pageIndex = 1;
  orderBy = 'asc';

  measurementList = null;
  filteredMeasurementList = null;

  allowPosition = false;
  positionList = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 },
  { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 }];

  measurementId = null;
  title = null;
  unit = null;
  position = null;
  active = null;

  deleteItem = null;

  constructor(
    private toastr: ToastrService,
    private pagerService: PagerService,
    private measurementsService: MeasurementsService,
    private loader: LoaderService
  ) { }

  ngOnInit(): void {
    this.clearData();
    this.getMeasurementList(1);
  }

  setPage(page: number) {
    if (page < 1) { return; }
    if (page > this.pager.totalPages) { return; }

    this.pageIndex = page;
    this.pager = this.pagerService.getPager(this.rowCount, this.pageSize, this.pageIndex);
    if (this.iniFlag === 0) {
      this.getMeasurementList(0);
    }
  }

  getMeasurementList(item) {
    this.loader.show();
    if (item === 1) {
      this.pageIndex = 1;
      this.iniFlag = 1;
    }
    var formData: FormData = new FormData();
    formData.append('title', this.searchText);
    formData.append('page_no', this.pageIndex.toString());
    formData.append('page_size', this.pageSize.toString());
    formData.append('order_by', this.orderBy.toString());
    this.measurementsService.getMeasurementList(formData).subscribe(
      (response) => {
        if (response.data) {
          this.measurementList = response.data;
          this.rowCount = response.totalrow;
          if (this.iniFlag === 1) {
            this.setPage(1);
            this.iniFlag = 0;
          }
          this.filterMeasurementList(this.viewActive)
        } else {
          this.measurementList = [];
          this.filteredMeasurementList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  filterMeasurementList(view): void {
    this.viewActive = view;
    this.filteredMeasurementList = [];
    this.filteredMeasurementList = (this.viewActive) ? this.measurementList.filter((e) => e.active) : this.measurementList;
    this.sortMeasurementList();
  }

  sortMeasurementList() {
   if (this.orderBy == 'asc') {
      this.filteredMeasurementList.sort((a, b) => {
        if (a.title.toLowerCase() > b.title.toLowerCase()) { return 1; }
        if (a.title.toLowerCase() < b.title.toLowerCase()) { return -1; }
      });
    } else {
      this.filteredMeasurementList.sort((a, b) => {
        if (a.title.toLowerCase() > b.title.toLowerCase()) { return -1; }
        if (a.title.toLowerCase() < b.title.toLowerCase()) { return 1; }
      });
    }
  }

  openAddUpdateMeasurementModal(isCreate, data) {
    this.clearData();
    this.isCreate = isCreate;
    if (!this.isCreate) {
      this.measurementId = data.id;
      this.title = data.title;
      this.unit = data.unit;
      this.position = data.position;
      this.allowPosition = (this.position) ? true : false;
      this.active = data.active;
    }
    $('#add-measurement').modal('show');
  }

  clearData(): void {
    this.measurementId = null;
    this.title = null;
    this.unit = null;
    this.position = null;
    this.active = null;
    this.allowPosition = false;
  }

  saveMeasurement() {
    if (!this.title) {
      this.toastr.error('Please enter title.');
      return;
    }
    const title = this.measurementList.find((e) => e.title.toLowerCase() === this.title.toLowerCase());
    if (title && title.id != this.measurementId) {
      this.toastr.error(this.title + ' already exist' + ((title.position) ? ' in position ' + title.position : '' + '.'));
      return;
    }
    if (!this.unit) {
      this.toastr.error('Please enter unit.');
      return;
    }
    if (this.allowPosition) {
      if (!this.position) {
        this.toastr.error('Please select position.');
        return;
      }
      const position = this.measurementList.find((e) => e.position == this.position);
      if (position && position.id != this.measurementId) {
        this.toastr.error(position.title + ' already exist' + ((position.position) ? ' in position ' + position.position : '' + '.'));
        return;
      }
    }
    var formData: FormData = new FormData();
    formData.append('title', this.title);
    formData.append('unit', this.unit);
    formData.append('position', (this.allowPosition) ? this.position : 0);
    formData.append('active', (this.active) ? '1' : '0');
    if (this.isCreate) {
      this.createMeasurement(formData);
    } else {
      formData.append('measurement_id', this.measurementId);
      this.updateMeasurement(formData);
    }
  }

  createMeasurement(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.measurementsService.createMeasurement(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-measurement').modal('hide');
            this.getMeasurementList(1);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  updateMeasurement(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.measurementsService.updateMeasurement(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-measurement').modal('hide');
            this.getMeasurementList(1);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.loader.hide();
          this.requestMade = false;
          console.log(err);
        }
      );
    }
  }

  openDeleteDialog(item) {
    this.deleteItem = item;
    $('#delete-dialog').modal('show');
  }

  deleteMeasurement() {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      var formData: FormData = new FormData();
      formData.append('measurement_id', this.deleteItem.id);
      this.measurementsService.deleteMeasurement(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            this.getMeasurementList(1);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MeasurementApi } from 'src/app/const/app.url';

@Injectable({
  providedIn: 'root'
})
export class MeasurementsService {

  constructor(private http: HttpClient) { }

  getMeasurementList(body?: any) {
    return this.http.post<any>(MeasurementApi.getMeasurementList, body)
  }

  createMeasurement(body?: any) {
    return this.http.post<any>(MeasurementApi.createMeasurement, body)
  }

  updateMeasurement(body?: any) {
    return this.http.post<any>(MeasurementApi.updateMeasurement, body)
  }

  deleteMeasurement(body?: any) {
    return this.http.post<any>(MeasurementApi.deleteMeasurement, body)
  }

  getClientMeasurementLogList(body?: any) {
    return this.http.post<any>(MeasurementApi.getClientMeasurementLogList, body)
  }

  getClientExerciseMeasurementLogList(body?: any) {
    return this.http.post<any>(MeasurementApi.getClientExerciseMeasurementLogList, body)
  }

  getMeasurementLogList(body?: any) {
    return this.http.post<any>(MeasurementApi.getMeasurementLogList, body)
  }

  addMeasurementLog(body?: any) {
    return this.http.post<any>(MeasurementApi.addMeasurementLog, body)
  }

  updateMeasurementLog(body?: any) {
    return this.http.post<any>(MeasurementApi.updateMeasurementLog, body)
  }

  deleteMeasurementLog(body?: any) {
    return this.http.post<any>(MeasurementApi.deleteMeasurementLog, body)
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotificationTypeApi, ClientApi } from 'src/app/const/app.url';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private http: HttpClient) { }

  getNotificationTypeList(body?: any) {
    return this.http.post<any>(NotificationTypeApi.getNotificationTypes, body)
  }

  createNotificationType(body?: any) {
    return this.http.post<any>(NotificationTypeApi.createNotificationType, body)
  }

  updateNotificationType(body?: any) {
    return this.http.post<any>(NotificationTypeApi.updateNotificationType, body)
  }

  deleteNotificationType(body?: any) {
    return this.http.post<any>(NotificationTypeApi.deleteNotificationType, body)
  }

  sendNotification(body?: any) {
    return this.http.post<any>(NotificationTypeApi.sendNotificationToUsers, body)
  }

  getClientList() {
    return this.http.get<any>(ClientApi.getOnlyClientList)
  }
}

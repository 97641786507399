<div class="container-fluid main-content">
    <div class="row">
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
                <h4 class="my-2">Habits</h4>
            </div>
        </div>
    </div>
    <div class="row pt-5">
        <div class="col-lg-2 d-none d-lg-block">
            <div class="d-flex align-items-center justify-content-between search-box">
                <input type="text" class="form-control" placeholder="Search..." autocomplete="off"
                    [(ngModel)]="searchText" (input)="getChallengeList(1)">
                <div class="icon px-2"><i class="fa fa-search"></i></div>
            </div>
        </div>
        <div class="col-lg-10 text-right align-self-center">
            <ul class="table-feature">
                <li>
                    <div class="view-btn pointer">
                        <div [class.active]="viewActive" (click)="filterChallengeList(true)">View Active</div>
                        <div>/</div>
                        <div [class.active]="!viewActive" (click)="filterChallengeList(false)">View All</div>
                    </div>
                </li>
                <li>
                    <div class="pagination" *ngIf="pager.pages && pager.pages.length">
                        <ul class="page-number">
                            <!-- <li>
                                <div (click)="setPage(1)" [ngClass]="{ disabled: pager.currentPage === 1 }">
                                    <i class="fa fa-angle-double-left"></i>
                                </div>
                            </li> -->
                            <li (click)="setPage(pager.currentPage - 1)" [class.disabled]="pager.currentPage === 1">
                                <i class="fa fa-angle-left"></i>
                            </li>
                            <li *ngFor="let page of pager.pages" (click)="setPage(page)"
                                [class.active]="pager.currentPage === page">
                                {{ page }}
                            </li>
                            <li (click)="setPage(pager.currentPage + 1)"
                                [class.disabled]="pager.currentPage === pager.totalPages">
                                <i class="fa fa-angle-right"></i>
                            </li>
                            <!-- <li>
                                <div (click)="setPage(pager.totalPages)"
                                    [ngClass]="{ disabled: pager.currentPage === pager.totalPages }">
                                    <i class="fa fa-angle-double-right"></i>
                                </div>
                            </li> -->
                        </ul>
                    </div>
                </li>
                <li>
                    <div class="sort-by">
                        <select [(ngModel)]="pageSize" (change)="getChallengeList(1)">
                            <option [value]="50">50/Pages</option>
                            <option [value]="100">100/Pages</option>
                            <option [value]="150">150/Pages</option>
                        </select>
                    </div>
                </li>
                <li>
                    <div class="sort-by">
                        <select [(ngModel)]="orderBy" (change)="getChallengeList(1)">
                            <option [value]="''">Sort by</option>
                            <option [value]="'asc'">A To Z</option>
                            <option [value]="'desc'">Z To A</option>
                        </select>
                    </div>
                </li>
                <li>
                    <div class="primary-btn pointer" (click)="openAddUpdateChallengeModal(1,null)">
                        Add Habit <img src="assets/images/add-white.png" alt="images">
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="table-responsive table-list">
                <table class="table">
                    <thead class="thead-light">
                        <tr>
                            <th>Habit Title</th>
                            <th>Description</th>
                            <th>Frequency</th>
                            <th>Frequency Type</th>
                            <th class="text-center">Active</th>
                            <th class="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of filteredChallengeList">
                            <td>{{item.title}}</td>
                            <td>{{item.description}} </td>
                            <td>{{item.frequency}}</td>
                            <td>{{item.frequency_type}}</td>
                            <td>
                                <div class="text-center">
                                    <img *ngIf="item.active" src="assets/images/check-square.png"
                                        class="image-btn pointer" alt="images" />
                                </div>
                            </td>
                            <td>
                                <div class="text-center">
                                    <img src="assets/images/add.png" class="image-btn pointer"
                                        (click)="openAssignChallengeModal(item)" alt="images">

                                    <img src="assets/images/edit.png" class="image-btn pointer pl-2" alt="images"
                                        (click)="openAddUpdateChallengeModal(0,item)" />
                                    <img src="assets/images/delete.png" class="image-btn pointer pl-2" alt="images"
                                        (click)="openDeleteDialog(item)" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<div class="modal fade p-0" id="add-challenge" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered add-session-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header text-center">
                <div class="d-flex w-100">
                    <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
                    <div class="modal-title w-100">{{(isCreate)?'Add':'Update'}} Habit</div>
                </div>
            </div>
            <div class="modal-body">
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Habit Title:</div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control no-radius" placeholder="Enter Habit Title"
                            [(ngModel)]="title">
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Description:</div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control no-radius" placeholder="Enter Description"
                            [(ngModel)]="description">
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Frequency Type:</div>
                    <div class="col-sm-8">
                        <ng-select [items]="frequencyTypeList" placeholder="Select Frequency Type" bindValue="value"
                            bindLabel="value" [clearable]="false" [(ngModel)]="frequencyType" (change)="onFrequencyTypeChange()">
                        </ng-select>
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4 pr-0">Frequency:</div>
                    <div class="col-sm-8" *ngIf="frequencyType == 'days'">
                        <div *ngIf="frequencyType === 'days'" class="week-selector">
                            <button *ngFor="let day of weekDays" 
                                    [class.selected]="selectedDays.includes(day)"
                                    (click)="toggleDay(day)">
                              {{day}}
                            </button>
                        </div>
                    </div>
                    <div class="col-sm-8" *ngIf="frequencyType != 'days'">
                        <input type="number" class="form-control no-radius" placeholder="Enter Frequency"
                            [(ngModel)]="frequency" min="1" step="1" oninput="this.value = this.value.replace(/[^0-9]/g, '')">
                    </div>
                </div>
              


                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Active:</div>
                    <div class="col-sm-8">
                        <div class="d-flex align-items-center">
                            <i *ngIf="!active" (click)="active=true;"
                                class="fa fa-square-o checkbox secondary-color inactive"></i>
                            <i *ngIf="active" (click)="active=false;"
                                class="fa fa-check-square-o checkbox success-color"></i>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 text-center">
                    <div class="btn pointer no-radius mb-2 mx-2 secondary-bg text-white" (click)="clearData()">
                        Clear Form
                    </div>
                    <!-- <div class="btn pointer no-radius mb-2 mx-2 secondary-bg text-white">
                        Save & Duplicate
                    </div> -->
                    <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="saveChallenge()">
                        Save & Close
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade p-0" id="assign-challenge" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered add-session-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header text-center">
                <div class="d-flex w-100">
                    <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
                    <div class="modal-title w-100">Assign Habit</div>
                </div>
            </div>
            <div class="modal-body">
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Habit:</div>
                    <div class="col-sm-8">
                        <ng-select [items]="challengeListDropdown" placeholder="Select Habit" bindValue="id"
                            bindLabel="title" [clearable]="false" [(ngModel)]="challenge_id">
                        </ng-select>
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Clients:</div>
                    <div class="col-sm-8">
                        <ng-select placeholder="Select Clients" [hideSelected] [isOpen] [multiple]="true"
                            [clearable]="false" [(ngModel)]="client_id">

                            <ng-option *ngFor="let client of clientList" [value]="client.id">{{client.first_name}}
                                {{client.last_name}}</ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Notification:</div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control no-radius" placeholder="Enter Notification Message"
                            [(ngModel)]="notification_message">
                    </div>
                </div>




                <div class="col-lg-12 text-center">
                    <div class="btn pointer no-radius mb-2 mx-2 secondary-bg text-white" (click)="clearData()">
                        Clear Form
                    </div>
                    <!-- <div class="btn pointer no-radius mb-2 mx-2 secondary-bg text-white">
                        Save & Duplicate
                    </div> -->
                    <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="assignChallenge()">
                        Save & Close
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-delete (onConfirm)="deleteChallenge()"></app-delete>
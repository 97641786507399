import { Component, OnInit } from '@angular/core';
import { BookingsService } from './bookings.service';
import { BookingStatus } from 'src/app/enum/booking-status.enum';
import { ToastrService } from 'ngx-toastr';
import { PagerService } from 'src/app/shared/pager.service';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/shared/storage.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { LoaderService } from 'src/app/shared/loader.service';
declare var $: any;

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss']
})
export class BookingsComponent implements OnInit {

  requestMade = false;
  baseURL = '';
  currentDateObj = null;

  hours = [];
  minutes = [];
  bookingConfig = [];

  todaySessionList = null;
  todayClientList = null;
  filterdClientList = [];
  selectedSession: any = {};
  selectedSessionIndex = 0;

  fromDate = null;
  toDate = null;

  searchText = '';
  viewFuture = true;
  iniFlag: any = 1;
  pager: any = {};
  rowCount = 0;
  pageSize = 50;
  pageIndex = 1;
  orderBy = 'asc';
  bookingList = null;

  updateItem = null;
  deleteItem = null;

  confirmationTitle = '';
  confirmationDescription = '';
  okText = '';
  cancelText = '';

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private pagerService: PagerService,
    private bookingsService: BookingsService,
    private storageService: StorageService,
    private loader: LoaderService
  ) { }

  ngOnInit(): void {
    this.hours = Array(12).fill(0).map((x, i) => {
      return { value: `${x + i}`.padStart(2, '0') };
    });
    this.minutes = Array(60).fill(0).map((x, i) => {
      return { value: `${x + i}`.padStart(2, '0') };
    });
    this.baseURL = environment.BASE_URL;
    this.currentDateObj = new Date();
    this.getBookingConfiguration();
    this.getTodayBooking();
    this.getBookingList(1);
  }

  getBookingConfiguration() {
    this.loader.show();
    this.bookingsService.getBookingConfiguration().subscribe(
      (response) => {
        if (response.status && response.data) {
          const bookingConfig = response.data;
          this.bookingConfig = bookingConfig.map((e) => {
            const time = e.value.split(':');
            const hour = (time[0] < 12) ? Number(time[0]) : Number(time[0] - 12) || 0;
            const minute = Number(time[1]) || 0;
            return {
              id: e.id,
              title: e.title,
              hour: (hour > 9) ? hour : '0' + hour,
              minute: (minute > 9) ? minute : '0' + minute,
              meridiem: (time[0] < 12) ? 'AM' : 'PM'
            }
          });
        } else {
          this.bookingConfig = null;
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  updateBookingConfiguration() {
    this.loader.show();
    const configuration = this.bookingConfig.map((e) => {
      const hour = (e.meridiem === 'AM') ? Number(e.hour) : Number(e.hour) + 12;
      const value = hour + ':' + e.minute + ':' + '00';
      return {
        config_id: e.id,
        title: e.title,
        value: value,
      }
    });
    var formData: FormData = new FormData();
    formData.append('data', JSON.stringify(configuration));
    this.bookingsService.updateBookingConfiguration(formData).subscribe(
      (response) => {
        if (response.status) {
          this.getBookingConfiguration();
          $('#booking-settings').modal('hide');
        } else {
          this.bookingConfig = null;
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  getTodayBooking() {
    this.loader.show();
    const currentDate = new Date();
    currentDate.setMinutes(currentDate.getTimezoneOffset() * -1);
    var formData: FormData = new FormData();
    formData.append('session_date', currentDate.toJSON().substring(0, 10));
    this.bookingsService.getTodayBookingList(formData).subscribe(
      (response) => {
        if (response.status) {
          this.todaySessionList = [];
          this.todaySessionList = response.allsessionlist || [];
          this.todaySessionList.map((e) => {
            e.start_time = new Date(currentDate.toJSON().substring(0, 10).replace(/-/g, '/') + ' ' + e.start_time),
              e.end_time = new Date(currentDate.toJSON().substring(0, 10).replace(/-/g, '/') + ' ' + e.end_time)
          });
          this.todaySessionList.sort((a, b) => a.start_time - b.start_time);
          this.todayClientList = response.clientlist || [];
          this.goToSession(0);
          this.filterClientList();
        } else {
          this.todaySessionList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  goToSession(value) {
    if (this.storageService.data && this.storageService.data.selectedSessionIndex) {
      this.selectedSessionIndex = this.storageService.data.selectedSessionIndex || 0;
    }
    this.selectedSessionIndex = this.selectedSessionIndex + value;
    if (this.selectedSessionIndex < 0) {
      this.selectedSessionIndex = 0
    }
    if (this.selectedSessionIndex > (this.todaySessionList.length - 1)) {
      this.selectedSessionIndex = this.todaySessionList.length - 1;
    }
    this.selectedSession = this.todaySessionList[this.selectedSessionIndex];
    this.storageService.data = { selectedSessionIndex: this.selectedSessionIndex };
    this.filterClientList();
  }

  filterClientList() {
    this.filterdClientList = this.todayClientList.filter((e) =>
      e.session_id == this.selectedSession.id && (e.status == BookingStatus.Booked || e.status == BookingStatus.BookedWaitlist || e.status == BookingStatus.BookedWaitlistLate));
  }

  navigateToClientProfile(item, tab) {
    this.router.navigate(['client-profile'], { state: { client_id: item.client_id, tab } });
  }

  setPage(page: number) {
    if (page < 1) { return; }
    if (page > this.pager.totalPages) { return; }

    this.pageIndex = page;
    this.pager = this.pagerService.getPager(this.rowCount, this.pageSize, this.pageIndex);
    if (this.iniFlag === 0) {
      if (this.viewFuture) {
        this.getBookingList(0);
      } else {
        this.getBookingHistory(0);
      }
    }
  }

  getBookingList(item) {
    this.loader.show();
    if (item === 1) {
      this.pageIndex = 1;
      this.iniFlag = 1;
    }
    this.viewFuture = true;
    var formData: FormData = new FormData();
    formData.append('searchname', this.searchText);
    formData.append('page_no', this.pageIndex.toString());
    formData.append('page_size', this.pageSize.toString());
    formData.append('order_by', this.orderBy.toString());
    this.bookingsService.getBookingList(formData).subscribe(
      (response) => {
        if (response.status && response.data) {
          this.bookingList = [];
          this.bookingList = response.data;
          this.bookingList.map((e) => {
            e.start_time = new Date(e.session_date.substring(0, 10).replace(/-/g, '/') + ' ' + e.start_time),
              e.end_time = new Date(e.session_date.substring(0, 10).replace(/-/g, '/') + ' ' + e.end_time)
          });
          this.rowCount = response.totalrow;
          if (this.iniFlag === 1) {
            this.setPage(1);
            this.iniFlag = 0;
          }
        } else {
          this.bookingList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  openAddUpdateBookingModal() {
    $('#book-clients').modal('show');
  }

  updateBooking(bookingId, sessionId, clientId, prevStatus, status) {
    this.loader.show();
    var formData: FormData = new FormData();
    formData.append('booking_id', bookingId);
    formData.append('session_id', sessionId);
    formData.append('client_id', clientId);
    formData.append('is_admin', '1');
    formData.append('reason', 'Admin');
    formData.append('status', status);
    formData.append('created_id', this.storageService.loggedInUser.id.toString());
    this.bookingsService.updateBooking(formData).subscribe(
      (response) => {
        if (response.status == BookingStatus.Cancelled) {
          if (prevStatus == BookingStatus.Booked || prevStatus == BookingStatus.BookedWaitlist || prevStatus == BookingStatus.BookedWaitlistLate) {
            this.toastr.success('Booking cancelled.');
          } else if (prevStatus == BookingStatus.Wait) {
            this.toastr.success('Waitlist cancelled.');
          }
        }
        this.getTodayBooking();
        this.getBookingList(1);
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  cancelBooking(item) {
    this.updateBooking(item.id, item.session_id, item.client_id, item.status, 0);
  }

  onRefresh(data) {
    this.getTodayBooking();
    this.getBookingList(1);
  }

  openConfirmDialog(item) {
    this.confirmationTitle = 'Mark as No Show';
    this.confirmationDescription = `Mark ${item.first_name + ' ' + item.last_name} as a NO SHOW for
      ${moment(item.start_time).format('hh:mm A')} session on ${moment(item.session_date).format('dddd Do MMMM yyyy')}.`;
    this.okText = 'Yes';
    this.cancelText = 'No';
    this.updateItem = item;
    $('#confirm-dialog').modal('show');
  }

  noShowBooking() {
    if (this.updateItem) {
      this.updateBooking(this.updateItem.id, this.updateItem.session_id, this.updateItem.client_id, this.updateItem.status, 6);
    }
  }

  openReasonDialog(item) {
    this.confirmationTitle = 'Reason for No Show';
    this.confirmationDescription = item.reason;
    this.okText = 'Ok';
    this.cancelText = 'Cancel';
    this.updateItem = null;
    $('#confirm-dialog').modal('show');
  }

  openDeleteDialog(item) {
    this.deleteItem = item;
    $('#delete-dialog').modal('show');
  }

  deleteBooking() {
    this.cancelBooking(this.deleteItem);
  }

  openFilterDialog() {
    $('#filter-dailog').modal('show');
  }

  closeFilterDialog(data) {
    if (data) {
      this.fromDate = data.fromDate;
      this.toDate = data.toDate;
      this.getBookingHistory(1);
    }
  }

  getBookingHistory(item) {
    this.loader.show();
    if (item === 1) {
      this.pageIndex = 1;
      this.iniFlag = 1;
    }
    this.viewFuture = false;
    $('#filter-dailog').modal('hide');
    var formData: FormData = new FormData();
    formData.append('start_date', this.fromDate);
    formData.append('end_date', this.toDate);
    formData.append('searchname', this.searchText);
    formData.append('page_no', this.pageIndex.toString());
    formData.append('page_size', this.pageSize.toString());
    formData.append('order_by', this.orderBy.toString());
    this.bookingsService.getBookingHistory(formData).subscribe(
      (response) => {
        if (response.status && response.data) {
          this.bookingList = response.data;
          this.bookingList.map((e) => {
            e.start_time = new Date(e.session_date.substring(0, 10).replace(/-/g, '/') + ' ' + e.start_time),
              e.end_time = new Date(e.session_date.substring(0, 10).replace(/-/g, '/') + ' ' + e.end_time)
          });
          this.rowCount = response.totalrow;
          if (this.iniFlag === 1) {
            this.setPage(1);
            this.iniFlag = 0;
          }
        } else {
          this.bookingList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }
}

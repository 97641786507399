<div class="container-fluid main-content">
    <div class="row">
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
                <h4 class="my-2">Exercises</h4>
            </div>
        </div>
    </div>
    <div class="row pt-5">
        <div class="col-lg-2 d-lg-block">
            <div class="d-flex align-items-center justify-content-between search-box">
                <input type="text" class="form-control" placeholder="Search..." autocomplete="off"
                    [(ngModel)]="searchText" (input)="getExerciseList(1)">
                <div class="icon px-2"><i class="fa fa-search"></i></div>
            </div>
        </div>
        <div class="col-lg-10 text-right align-self-center">
            <ul class="table-feature">
                <li>
                    <div class="view-btn pointer">
                        <div [class.active]="viewActive" (click)="filterExerciseList(true)">View Active</div>
                        <div>/</div>
                        <div [class.active]="!viewActive" (click)="filterExerciseList(false)">View All</div>
                    </div>
                </li>
                <li>
                    <div class="pagination" *ngIf="pager.pages && pager.pages.length">
                        <ul class="page-number">
                            <!-- <li>
                                <div (click)="setPage(1)" [ngClass]="{ disabled: pager.currentPage === 1 }">
                                    <i class="fa fa-angle-double-left"></i>
                                </div>
                            </li> -->
                            <li (click)="setPage(pager.currentPage - 1)" [class.disabled]="pager.currentPage === 1">
                                <i class="fa fa-angle-left"></i>
                            </li>
                            <li *ngFor="let page of pager.pages" (click)="setPage(page)"
                                [class.active]="pager.currentPage === page">
                                {{ page }}
                            </li>
                            <li (click)="setPage(pager.currentPage + 1)"
                                [class.disabled]="pager.currentPage === pager.totalPages">
                                <i class="fa fa-angle-right"></i>
                            </li>
                            <!-- <li>
                                <div (click)="setPage(pager.totalPages)"
                                    [ngClass]="{ disabled: pager.currentPage === pager.totalPages }">
                                    <i class="fa fa-angle-double-right"></i>
                                </div>
                            </li> -->
                        </ul>
                    </div>
                </li>
                <li>
                    <div class="sort-by">
                        <select [(ngModel)]="pageSize" (change)="getExerciseList(1)">
                            <option [value]="50">50/Pages</option>
                            <option [value]="100">100/Pages</option>
                            <option [value]="150">150/Pages</option>
                        </select>
                    </div>
                </li>
                <li>
                    <div class="sort-by">
                        <select [(ngModel)]="orderBy" (change)="getExerciseList(1)">
                            <option [value]="''">Sort by</option>
                            <option [value]="'asc'">A To Z</option>
                            <option [value]="'desc'">Z To A</option>
                        </select>
                    </div>
                </li>
                <li>
                    <div class="primary-btn pointer" (click)="openAddUpdateExerciseModal(1,null)">
                        Add Exercise <img src="assets/images/add-white.png" alt="images">
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="table-responsive table-list">
                <table class="table">
                    <thead class="thead-light">
                        <tr>
                            <th>Movement Title</th>
                            <th>Muscle Group</th>
                            <th>Movement Type</th>
                            <th>Exercise Type</th>
                            <th>Equipment</th>
                            <th>Description</th>
                            <th>Cues</th>
                            <th class="text-center">Media</th>
                            <th class="text-center">Active</th>
                            <th class="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of filteredExerciseList">
                            <td>{{item.title}}</td>
                            <td>{{item.muscle_group}} </td>
                            <td>{{item.movement_type}}</td>
                            <td>{{exerciseTypeList[item.exercise_type-1].name}}</td>
                            <td>
                                <ng-container>
                                    <span *ngFor="let equipmentId of item.equipment_ids; let last = last">
                                        {{ getEquipmentName(equipmentId) }}
                                        <span *ngIf="!last">, </span>
                                    </span>
                                </ng-container>
                            </td>
                            <td>{{item.description}}</td>
                            <td>{{item.cues ? item.cues : ''}}</td>
                            <td>
                                <div class="text-center">
                                    <img *ngIf="item.image_url" src="assets/images/image.svg"
                                        class="image-btn pointer success-color" alt="images"
                                        (click)="openImageLink(item)" />
                                    <img *ngIf="item.video_url" src="assets/images/video.svg"
                                        class="image-btn pointer success-color ml-2" alt="images"
                                        (click)="openVideoLink(item)" />
                                </div>
                            </td>
                            <td>
                                <div class="text-center">
                                    <img *ngIf="item.active" src="assets/images/check-square.png"
                                        class="image-btn pointer" alt="images" />
                                </div>
                            </td>
                            <td>
                                <div class="text-center">
                                    <img src="assets/images/edit.png" class="image-btn pointer" alt="images"
                                        (click)="openAddUpdateExerciseModal(0,item)" />
                                    <img src="assets/images/delete.png" class="image-btn pointer pl-2" alt="images"
                                        (click)="openDeleteDialog(item)" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<div class="modal fade p-0" id="add-exercise" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered add-session-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header text-center">
                <div class="d-flex w-100">
                    <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
                    <div class="modal-title w-100">{{(isCreate)?'Add':'Update'}} Movement</div>
                </div>
            </div>
            <div class="modal-body">
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Movement Title:</div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control no-radius" placeholder="Enter movement name"
                            [(ngModel)]="title">
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Muscle Group:</div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control no-radius" placeholder="Enter muscle group"
                            [(ngModel)]="muscleGroup">
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4 pr-0">Movement Type:</div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control no-radius" placeholder="Enter movement type"
                            [(ngModel)]="movementType">
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Exercise Type:</div>
                    <div class="col-sm-8">
                        <ng-select [items]="exerciseTypeList" placeholder="Select exercise type" bindValue="id"
                            bindLabel="name" [clearable]="false" [(ngModel)]="exerciseType">
                        </ng-select>
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Equipment:</div>
                    <div class="col-sm-8">
                        <ng-select [items]="equipmentList" placeholder="Select equipment type" bindValue="id"
                            bindLabel="name" [clearable]="true" [multiple]="true" [(ngModel)]="equipments">
                        </ng-select>
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4 pr-0">Description:</div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control no-radius" placeholder="Enter description"
                            [(ngModel)]="description">
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4 pr-0">Images:</div>
                    <div class="col-sm-8">
                        <div class="form-control form-file-control no-radius">
                            <label for="fileInput" class="file-name">{{(fileInput)?fileInput.name:'Upload
                                image'}}</label>
                            <label for="fileInput" class="file-btn">Browse</label>
                            <input type="file" class="d-none" id="fileInput" (change)="onFileChange($event)" />
                        </div>
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4 pr-0">Video URL:</div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control no-radius" placeholder="Enter video URL"
                            [(ngModel)]="videoURL">
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4 pr-0">Cues:</div>
                    <div class="col-sm-8">
                        <textarea class="form-control no-radius" placeholder="Enter Cues" [(ngModel)]="cues"
                            rows="4"></textarea>
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Exercise Active:</div>
                    <div class="col-sm-8">
                        <div class="d-flex align-items-center">
                            <i *ngIf="!active" (click)="active=true;"
                                class="fa fa-square-o checkbox secondary-color inactive"></i>
                            <i *ngIf="active" (click)="active=false;"
                                class="fa fa-check-square-o checkbox success-color"></i>
                        </div>
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Client Feedback:</div>
                    <div class="col-sm-8">
                        <div class="d-flex align-items-center">
                            <i *ngIf="!clientFeedback" (click)="clientFeedback=true;"
                                class="fa fa-square-o checkbox secondary-color inactive"></i>
                            <i *ngIf="clientFeedback" (click)="clientFeedback=false;"
                                class="fa fa-check-square-o checkbox success-color"></i>
                        </div>
                    </div>
                </div>
                <div *ngIf="clientFeedback">
                    <div class="row mb-3 align-items-center">
                        <div class="col-sm-4">Regression Exercise:</div>
                        <div class="col-sm-8">
                            <ng-select [multiple]="true" placeholder="Select regression exercise type"
                                [(ngModel)]="regExercise">
                                <ng-option *ngFor="let exercise of alternateExerciseList"
                                    [value]="exercise.id">{{exercise.title}}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="row mb-3 align-items-center">
                        <div class="col-sm-4">Lateralisations Exercise:</div>
                        <div class="col-sm-8">
                            <ng-select [multiple]="true" placeholder="Select regression exercise type"
                                [(ngModel)]="latExercise">
                                <ng-option *ngFor="let exercise of alternateExerciseList"
                                    [value]="exercise.id">{{exercise.title}}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="row mb-3 align-items-center">
                        <div class="col-sm-4">Progressions Exercise:</div>
                        <div class="col-sm-8">
                            <ng-select [multiple]="true" placeholder="Select regression exercise type"
                                [(ngModel)]="proExercise">
                                <ng-option *ngFor="let exercise of alternateExerciseList"
                                    [value]="exercise.id">{{exercise.title}}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 text-center">
                    <div class="btn pointer no-radius mb-2 mx-2 secondary-bg text-white" (click)="clearData()">
                        Clear Form
                    </div>
                    <!-- <div class="btn pointer no-radius mb-2 mx-2 secondary-bg text-white">
                        Save & Duplicate
                    </div> -->
                    <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="saveExercise()">
                        Save & Close
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-delete (onConfirm)="deleteExercise()"></app-delete>
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SessionsService } from '../sessions.service';
import * as moment from 'moment';
import { LoaderService } from 'src/app/shared/loader.service';
declare var $: any;

@Component({
  selector: 'app-override-sessions',
  templateUrl: './override-sessions.component.html',
  styleUrls: ['./override-sessions.component.scss']
})
export class OverrideSessionsComponent implements OnInit {

  requestMade = false;
  isCreate = false;
  isSessionCreate = false;
  isLoad = false;

  sessionOverrideList = null;
  filteredSessionOverrideList = null;
  viewActive = true;

  overrideId = null;
  startDate = null;
  endDate = null;
  description = null;
  active = true;

  sessionList = null;
  sessionData = null;

  deleteFrom = '';
  deleteItem = null;

  constructor(
    private toastr: ToastrService,
    private sessionsService: SessionsService,
    private loader: LoaderService
  ) { }

  ngOnInit(): void {
    this.getSessionOverrideList();
  }

  getSessionOverrideList() {
    this.loader.show();
    this.sessionsService.getSessionOverrideList().subscribe(
      (response) => {
        if (response.data) {
          this.sessionOverrideList = response.data;
          this.filterSessionOverrideList(true);
        } else {
          this.sessionOverrideList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  filterSessionOverrideList(view): void {
    this.viewActive = view;
    this.filteredSessionOverrideList = [];
    this.filteredSessionOverrideList = (this.viewActive) ? this.sessionOverrideList.filter((e) => e.active) : this.sessionOverrideList;
  }

  clearData() {
    this.overrideId = null;
    this.startDate = null;
    this.endDate = null;
    this.description = null;
    this.active = true;
  }

  openAddUpdateOverrideSessionModal(isCreate, data) {
    this.clearData();
    this.isCreate = isCreate;
    if (!this.isCreate) {
      this.overrideId = data.id;
      this.startDate = moment(data.start_date).format('YYYY-MM-DD');
      this.endDate = moment(data.end_date).format('YYYY-MM-DD');
      this.description = data.description;
      this.active = data.active;
      this.getOverrideSessionList();
    }
    $('#override-session').modal('show');
  }

  saveOverrideSession() {
    if (!this.startDate) {
      this.toastr.error('Please select start date.');
    }
    if (!this.endDate) {
      this.toastr.error('Please select end date.');
    }
    if (!this.description) {
      this.toastr.error('Please enter description.');
    }
    var formData: FormData = new FormData();
    formData.append('start_date', this.startDate);
    formData.append('end_date', this.endDate);
    formData.append('description', this.description);
    formData.append('active', (this.active) ? '1' : '0');

    if (this.isCreate) {
      this.createOverrideSession(formData);
    } else {
      formData.append('override_id', this.overrideId);
      this.updateOverrideSession(formData);
    }

  }

  createOverrideSession(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.sessionsService.createOverrideSession(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.isCreate = false;
            this.overrideId = response.data[0].id;
            this.toastr.success(response.message);
            this.getSessionOverrideList();
            this.getOverrideSessionList();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  updateOverrideSession(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.sessionsService.updateOverrideSession(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.isCreate = false;
            this.overrideId = response.data[0].id;
            this.toastr.success(response.message);
            this.getSessionOverrideList();
            this.getOverrideSessionList();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  getOverrideSessionList() {
    var formData: FormData = new FormData();
    formData.append('override_id', this.overrideId);
    this.loader.show();
    this.sessionsService.getOverrideSessionList(formData).subscribe(
      (response) => {
        if (response.data) {
          this.sessionList = response.data;
          this.sessionList.forEach((element) => {
            let daysOfWeek = [];
            if (element.sun) daysOfWeek.push('Sun');
            if (element.mon) daysOfWeek.push('Mon');
            if (element.tue) daysOfWeek.push('Tue');
            if (element.wed) daysOfWeek.push('Wed');
            if (element.thu) daysOfWeek.push('Thu');
            if (element.fri) daysOfWeek.push('Fri');
            if (element.sat) daysOfWeek.push('Sat');
            element.daysOfWeek = daysOfWeek.join(',');
          });
        } else {
          this.sessionList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  deactiveAllOverrideSession() {
    var formData: FormData = new FormData();
    formData.append('override_id', this.overrideId);
    this.sessionsService.deactiveAllOverrideSession(formData).subscribe(
      (response) => {
        this.getOverrideSessionList()
      }, (err) => {
        console.log(err);
      }
    );
  }

  deleteAllOverrideSession() {
    var formData: FormData = new FormData();
    formData.append('override_id', this.overrideId);
    this.sessionsService.deleteAllOverrideSession(formData).subscribe(
      (response) => {
        this.getOverrideSessionList()
      }, (err) => {
        console.log(err);
      }
    );
  }

  openAddUpdateSessionModal(isCreate, data) {
    this.isLoad = true;
    this.isSessionCreate = isCreate;
    this.sessionData = data;
    setTimeout(() => {
      $('#add-session').modal('show');
    }, 0);
  }

  onRefresh(data) {
    this.isLoad = false;
    this.getOverrideSessionList();
  }

  deleteSession() {
    var formData: FormData = new FormData();
    formData.append('session_id', this.deleteItem.id);
    if (!this.requestMade) {
      this.requestMade = true;
      this.loader.show();
      this.sessionsService.deleteSession(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-session').modal('hide');
            this.getOverrideSessionList();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  openDeleteDialog(deleteFrom, item) {
    this.deleteFrom = deleteFrom;
    this.deleteItem = item;
    $('#delete-dialog').modal('show');
  }

  deleteRecord() {
    if (this.deleteFrom == 'session') {
      this.deleteSession();
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserApi } from 'src/app/const/app.url';

@Injectable({
  providedIn: 'root'
})
export class SignInService {

  constructor(private http: HttpClient) { }

  signIn(body?: any) {
    return this.http.post<any>(UserApi.signIn, body)
  }
}

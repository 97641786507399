export class WorkoutProgram {
    program_id: number;
    program_title: string;
    program_description: string;
    program_active: number = 0;
    program_image: string;
    program_image_name: string;
    program_image_ext: string;
    image: string;
    workout: Workout[] = [];
}

export class Workout {
    id: number;
    week: number;
    workout_title: string;
    workout_description: string;
    workout_image: string;
    workout_image_name: string;
    workout_image_ext: string;
    image: string;
    is_delete: number;
    section: Section[] = [];
    week_notification: number = 0;
}

export class Section {
    id: number;
    is_delete: number;
    section_title: string;
    section_position: string;
    exercise: Exercise[] = [];
}

export class Exercise {
    id: number;
    exercise_id: number;
    exercise_title: string;
    exercise_sets: number;
    exercise_time: number;
    exercise_rest: number;
    exercise_position: number;
    target_min: number;
    target_max: number;
    is_delete: number;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { DeleteComponent } from './delete/delete.component';
import { FilterComponent } from './filter/filter.component';
import { BookClientComponent } from './book-client/book-client.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { AddSessionComponent } from './add-session/add-session.component';
import { LoaderComponent } from './loader/loader.component';

const componenent = [
  AddSessionComponent,
  ConfirmComponent,
  DeleteComponent,
  FilterComponent,
  BookClientComponent,
  LoaderComponent
]

@NgModule({
  declarations: [componenent, BookClientComponent, FilterComponent, ConfirmComponent, AddSessionComponent],
  exports: [componenent],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
  ]
})
export class ComponentsModule { }

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ExerciseApi } from 'src/app/const/app.url';

@Injectable({
  providedIn: 'root'
})
export class ExercisesService {

  constructor(private http: HttpClient) { }

  getExerciseList(body?: any) {
    return this.http.post<any>(ExerciseApi.getExerciseList, body)
  }

  createExercise(body?: any) {
    return this.http.post<any>(ExerciseApi.createExercise, body)
  }

  updateExercise(body?: any) {
    return this.http.post<any>(ExerciseApi.updateExercise, body)
  }

  deleteExercise(body?: any) {
    return this.http.post<any>(ExerciseApi.deleteExercise, body)
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PagerService } from 'src/app/shared/pager.service';
import { SettingsService } from './settings.service';
import { environment } from 'src/environments/environment';
import { LoaderService } from 'src/app/shared/loader.service';
declare var $: any;

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  requestMade = false;
  clientId = 0;
  isCreate = false;
  selectedTab = '';
  searchText = '';
  deleteFrom = '';
  deleteItem = null;

  // General
  generalSetting: any = {};

  // ASC Team
  teamMemberList = [];
  memberId = 0;
  memberName = '';
  memberDesignation = '';
  memberDescription = '';
  memberPosition = null;
  memberActive = true;

  fileInput = null;
  fileBase64 = null;
  fileExtension = null;


  // Document
  documentList = [];
  documentId = 0;
  documentTitle = '';
  documentContent = '';
  documentPosition = null;
  documentAgreement = true;
  documentActive = true;

  // Blog
  blogList = [];
  blogId = 0;
  blogTitle = '';
  blogUrlLink = '';
  blogDate = null;
  blogImage = null;
  blogImageBase64 = null;
  blogImageExtension = null;

  blogActive = true;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private settingsService: SettingsService,
    private loader: LoaderService
  ) { }

  ngOnInit(): void {
    this.selectedTab = 'ASCTeam';
    this.getTeamMemberList();
    this.getDocumentList();
    this.getBlogList();
  }

  //#region ASC Team

  getTeamMemberList() {
    this.loader.show();
    this.settingsService.getTeamMemberList().subscribe(
      (response) => {
        if (response.data) {
          this.teamMemberList = response.data;
        } else {
          this.teamMemberList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  openAddUpdateTeamMemberModal(isCreate, data) {
    this.clearData();
    this.isCreate = isCreate;
    if (!this.isCreate) {
      this.memberId = data.id;
      this.memberName = data.name;
      this.memberDesignation = data.designation;
      this.memberDescription = data.description;
      this.memberPosition = data.position;
      this.memberActive = data.is_active;
    }
    $('#add-team-member').modal('show');
  }

  clearData(): void {
    this.memberId = 0;
    this.memberName = '';
    this.memberDesignation = '';
    this.memberDescription = '';
    this.memberPosition = null;
    this.memberActive = true;
  }


  onFileChange($event) {
    this.fileInput = $event.target.files[0];
    this.fileExtension = '.' + this.fileInput.name.split('.').pop();
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      this.fileBase64 = myReader.result;
    }
    myReader.readAsDataURL(this.fileInput);
  }

  saveTeamMember() {
    if (!this.memberName) {
      this.toastr.error('Please enter first name.');
      return;
    }
    if (!this.memberDesignation) {
      this.toastr.error('Please enter last name.');
      return;
    }
    if (!this.memberDescription) {
      this.toastr.error('Please enter email.');
      return;
    }
    if (!this.memberPosition) {
      this.toastr.error('Please enter position.');
      return;
    }
    const position = this.teamMemberList.find((e) => e.position == this.memberPosition);
    if (position && position.id != this.memberId) {
      this.toastr.error(position.name + ' already exist' + ((position.position) ? ' in position ' + position.position : '' + '.'));
      return;
    }
    var formData: FormData = new FormData();
    formData.append('name', this.memberName);
    formData.append('designation', this.memberDesignation);
    formData.append('description', this.memberDescription);
    formData.append('position', this.memberPosition);
    formData.append('is_active', (this.memberActive) ? '1' : '0');
    if (this.fileInput) {
      formData.append('teamimage', this.fileBase64);
      formData.append('image_extension', this.fileExtension);
    }
    if (this.isCreate) {
      this.addTeamMember(formData);
    } else {
      formData.append('member_id', this.memberId.toString());
      this.updateTeamMember(formData);
    }
  }

  addTeamMember(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.settingsService.addTeamMember(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-team-member').modal('hide');
            this.getTeamMemberList();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  updateTeamMember(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.settingsService.updateTeamMember(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-team-member').modal('hide');
            this.getTeamMemberList();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  deleteTeamMember() {
    var formData: FormData = new FormData();
    formData.append('member_id', this.deleteItem.id);
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.settingsService.deleteTeamMember(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-team-member').modal('hide');
            this.getTeamMemberList();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }
  //#endregion

  // Document 
  getDocumentList() {
    this.loader.show();
    this.settingsService.getDocumentList().subscribe(
      (response) => {
        if (response.data) {
          this.documentList = response.data;
        } else {
          this.documentList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  openAddUpdateDocumentModal(isCreate, data) {
    this.clearData();
    this.isCreate = isCreate;
    if (!this.isCreate) {
      this.documentId = data.id;
      this.documentTitle = data.title;
      this.documentContent = data.content;
      this.documentPosition = data.position;
      this.documentAgreement = data.agreement;
      this.documentActive = data.active;
    }
    $('#add-document').modal('show');
  }

  clearDocumentData(): void {
    this.documentId = 0;
    this.documentTitle = '';
    this.documentContent = '';
    this.documentPosition = '';
    this.documentAgreement = true;
    this.documentActive = true;
  }

  saveDocument() {
    if (!this.documentTitle) {
      this.toastr.error('Please enter title.');
      return;
    }
    if (!this.documentContent) {
      this.toastr.error('Please enter content.');
      return;
    }
    if (!this.documentPosition) {
      this.toastr.error('Please enter position.');
      return;
    }
    const position = this.teamMemberList.find((e) => e.position == this.memberPosition);
    if (position && position.id != this.memberId) {
      this.toastr.error(position.name + ' already exist' + ((position.position) ? ' in position ' + position.position : '' + '.'));
      return;
    }
    var formData: FormData = new FormData();
    formData.append('title', this.documentTitle);
    formData.append('content', this.documentContent);
    formData.append('agreement', (this.documentAgreement) ? '1' : '0');
    formData.append('position', this.documentPosition);
    formData.append('active', (this.documentActive) ? '1' : '0');
    if (this.isCreate) {
      this.addDocument(formData);
    } else {
      formData.append('document_id', this.documentId.toString());
      this.updateDocument(formData);
    }
  }

  addDocument(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.settingsService.addDocument(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-document').modal('hide');
            this.getDocumentList();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  updateDocument(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.settingsService.updateDocument(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-document').modal('hide');
            this.getDocumentList();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  deleteDocument() {
    var formData: FormData = new FormData();
    formData.append('document_id', this.deleteItem.id);
    if (!this.requestMade) {
      this.requestMade = true;
      this.loader.show();
      this.settingsService.deleteDocument(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-document').modal('hide');
            this.getDocumentList();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  // Blog 
  getBlogList() {
    var formData: FormData = new FormData();
    this.loader.show();
    this.settingsService.getBlogList(formData).subscribe(
      (response) => {
        if (response.data) {
          this.blogList = response.data;
        } else {
          this.blogList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  openAddUpdateBlogModal(isCreate, data) {
    this.clearData();
    this.isCreate = isCreate;
    if (!this.isCreate) {
      console.log(data);
      this.blogId = data.id;
      this.blogTitle = data.title;
      this.blogUrlLink = data.url_link;
      this.blogDate = data.blog_date;
      //this.blogImage = data.blog_image;
      this.blogActive = data.active;
    }
    $('#add-blog').modal('show');
  }

  clearBlogData(): void {
    this.blogId = 0;
    this.blogTitle = '';
    this.blogUrlLink = '';
    this.blogDate = '';
    this.blogImage = '';
    this.blogActive = true;
  }

  saveBlog() {
    if (!this.blogTitle) {
      this.toastr.error('Please enter title.');
      return;
    }
    if (!this.blogUrlLink) {
      this.toastr.error('Please enter url.');
      return;
    }
    if (!this.blogDate) {
      this.toastr.error('Please enter blog date.');
      return;
    }
    if (this.isCreate) {
      if (!this.fileBase64) {
        this.toastr.error('Please upload blog image.');
        return;
      }
    }

    var formData: FormData = new FormData();
    formData.append('title', this.blogTitle);
    formData.append('url_link', this.blogUrlLink);
    formData.append('blog_date', this.blogDate);
    formData.append('active', (this.blogActive) ? '1' : '0');

    formData.append('blog_image', this.fileBase64);
    formData.append('blog_image_ext', this.fileExtension);

    if (this.isCreate) {
      this.addBlog(formData);
    } else {
      formData.append('blog_id', this.blogId.toString());
      this.updateBlog(formData);
    }
  }

  addBlog(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.settingsService.addBlog(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-blog').modal('hide');
            this.getBlogList();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  updateBlog(formData) {
    if (!this.requestMade) {
      this.requestMade = true;
      this.loader.show();
      this.settingsService.updateBlog(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-blog').modal('hide');
            this.getBlogList();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  deleteBlog() {
    var formData: FormData = new FormData();
    formData.append('blog_id', this.deleteItem.id);
    if (!this.requestMade) {
      this.requestMade = true;
      this.loader.show();
      this.settingsService.deleteBlog(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-blog').modal('hide');
            this.getBlogList();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }
  //#endregion
  openImageLink(item) {
    window.open(environment.BASE_URL + item, '_blank');
  }
  openDeleteDialog(deleteFrom, item) {
    this.deleteFrom = deleteFrom;
    this.deleteItem = item;
    $('#delete-dialog').modal('show');
  }

  deleteRecord() {
    if (this.deleteFrom == 'team') {
      this.deleteTeamMember();
    } else if (this.deleteFrom == 'document') {
      this.deleteDocument();
    } else if (this.deleteFrom == 'blog') {
      this.deleteBlog();
    }
  }

}

<div class="modal fade p-0" id="add-workout" tabindex="-1" role="dialog" *ngIf="workoutProgram">
	<div class="modal-dialog modal-dialog-centered add-workout-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header text-center">
				<div class="d-flex w-100">
					<img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
					<div class="modal-title w-100">Workout</div>
				</div>
			</div>
			<div class="modal-body p-3">
				<div class="row m-0">
					<div class="col-lg-3">
						<div class="row mb-2 align-items-center">
							<div class="col-sm-5">Program Title:</div>
							<div class="col-sm-7">
								<input type="text" class="form-control no-radius"
									placeholder="Enter workout program title"
									[(ngModel)]="workoutProgram.program_title">
							</div>
						</div>
					</div>
					<div class="col-lg-3">
						<div class="row mb-2 align-items-center">
							<div class="col-sm-4">Description:</div>
							<div class="col-sm-8">
								<input type="text" class="form-control no-radius" placeholder="Enter description"
									[(ngModel)]="workoutProgram.program_description">
							</div>
						</div>
					</div>
					<div class="col-lg-3">
						<div class="row mb-2 align-items-center">
							<div class="col-sm-2">Image:</div>
							<div class="col-sm-10">
								<div class="form-control form-file-control no-radius">
									<label for="fileInput2"
										class="file-name">{{(workoutProgram.program_image_name)?workoutProgram.program_image_name:'Upload
										image'}}</label>
									<label for="fileInput2" class="file-btn">Browse</label>
									<input type="file" class="d-none" id="fileInput2"
										(change)="onFileChange($event, 'program', 0)" />
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-3 p-0">
						<div class="row m-0 mb-2 align-items-center">
							<div class="col-lg-3">Active:</div>
							<div class="col-lg-2">
								<div class="d-flex align-items-center">
									<i *ngIf="workoutProgram.program_active==0"
										(click)="workoutProgram.program_active=1;"
										class="fa fa-square-o checkbox secondary-color inactive"></i>
									<i *ngIf="workoutProgram.program_active==1"
										(click)="workoutProgram.program_active=0;"
										class="fa fa-check-square-o checkbox success-color"></i>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div id="weeks-container">
					<div class="row m-0 mb-3 p-0 workout-container">
						<div class="col-lg-12 p-0 align-items-center">
							<ul id="weeks-list" class="nav nav-tabs border-0 week-tabs">
								<li *ngFor="let week of weeks" class="nav-item d-flex align-items-center">
									<a class="nav-link" [class.active]="week === activeWeekId"
										(click)="selectWeek(week)">
										Week {{ week }}
									</a>
								</li>
								<li class="nav-item">
									<a class="nav-link pointer" (click)="commonService.addWeek()">
										<img src="assets/images/add.png" class="image-btn pointer" alt="images" />
									</a>

								</li>
							</ul>
						</div>
					</div>
					<div class="row m-0 mb-3 py-2 workout-container">
						<div class="col-lg-8 align-items-center">
							<button class="btn pointer no-radius mr-2 primary-bg text-white" (click)="duplicateWeek()"
								[disabled]="!activeWeekId">
								Duplicate Week
							</button>
							<button class="btn pointer no-radius mr-2 primary-bg text-white" (click)="setAsWeek()">
								Set as Week
							</button>
							<select class="form-control d-inline-block w-auto" [(ngModel)]="selectedWeekId">
								<option *ngFor="let week of weeks" [value]="week">{{ week }}</option>
							</select>
							<button class="btn pointer no-radius danger-bg text-white ml-2" (click)="deleteWeek()">
								<i class="fa fa-trash" aria-hidden="true"></i>
							</button>
						</div>
						<div class="col-lg-4 p-0 text-right">
							<div class="row m-0 align-items-center">
								<div class="col-lg-5 p-0 text-left">
									<div class="row m-0 mb-2 align-items-center">
										<div class="col-6 p-0">Notification:</div>
										<div class="d-flex align-items-center">
											<i *ngIf="!isWeekNotificationActive(activeWeekId)" 
												(click)="toggleWeekNotification(activeWeekId, 1)"
												class="fa fa-square-o checkbox secondary-color inactive">
											</i>
											<i *ngIf="isWeekNotificationActive(activeWeekId)" 
												(click)="toggleWeekNotification(activeWeekId, 0)"
												class="fa fa-check-square-o checkbox success-color">
											</i>
										</div>
									</div>
        					</div>
								<div class="col-lg-6 text-right">
									<div class="btn pointer no-radius primary-bg text-white" (click)="addWorkout()">
										Add Day
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="tab-content">
					<ng-container class="tab-content">
						<div *ngFor="let week of weeks" [id]="week" class="tab-pane fade"
							[class.show]="week === activeWeekId" [class.active]="week === activeWeekId">
							<div *ngFor="let workout of workoutProgram.workout; let workoutIndex=index">
								<div [hidden]="workout.is_delete == 1 || (workout.week !== activeWeekId)"
									class="row m-0 mb-3 workout-container">
									<div class="col-12 p-3">
										<div class="row">
											<div class="col-lg-3">
												<div class="row mb-2 align-items-center">
													<div class="col-sm-5">Workout title:</div>
													<div class="col-sm-7">
														<input type="text" class="form-control no-radius"
															placeholder="Enter workout day title"
															[(ngModel)]="workout.workout_title">
													</div>
												</div>
											</div>
											<div class="col-lg-3">
												<div class="row mb-2 align-items-center">
													<div class="col-sm-4">Description:</div>
													<div class="col-sm-8">
														<input type="text" class="form-control no-radius"
															placeholder="Enter description"
															[(ngModel)]="workout.workout_description">
													</div>
												</div>
											</div>
											<div class="col-lg-3">
												<div class="row mb-2 align-items-center">
													<div class="col-sm-2">Image:</div>
													<div class="col-sm-10">
														<div class="form-control form-file-control no-radius">
															<label for="fileInput_{{workoutIndex}}" class="file-name">
																{{ (workout.workout_image_name) ?
																workout.workout_image_name : 'Upload image' }}
															</label>
															<label for="fileInput_{{workoutIndex}}"
																class="file-btn">Browse</label>
															<input type="file" class="d-none"
																id="fileInput_{{workoutIndex}}"
																(change)="onFileChange($event, 'workout', workoutIndex)" />
														</div>
													</div>
												</div>
											</div>
											<div class="col-lg-3">
												<div class="row mb-2 align-items-center justify-content-between">
													<div class="btn pointer no-radius mb-2 mx-2 danger-bg text-white"
														(click)="removeWorkout(workoutIndex)">
														<i class="fa fa-trash" aria-hidden="true"></i>
													</div>
													<div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white"
														(click)="addSection(workoutIndex)">
														Add Section
													</div>
												</div>
											</div>
										</div>
										<ng-container *ngFor="let section of workout.section; let sectionIndex=index">
											<div [hidden]="section.is_delete == 1" class="row section-container">
												<div class="col-12 p-3">
													<div class="row">
														<div class="col-lg-3">
															<div class="row mb-3 align-items-center">
																<div class="col-sm-5">Section title:</div>
																<div class="col-sm-7">
																	<input type="text" class="form-control no-radius"
																		placeholder="Enter section title"
																		[(ngModel)]="section.section_title">
																</div>
															</div>
														</div>
														<div class="col-lg-3">
															<div class="row mb-3 align-items-center">
																<div class="col-sm-4">Position:</div>
																<div class="col-sm-8">
																	<input type="text" class="form-control no-radius"
																		placeholder="Enter section position"
																		[(ngModel)]="section.section_position">
																</div>
															</div>
														</div>
														<div class="col-lg-6">
															<div
																class="row mb-3 align-items-center justify-content-between">
																<div class="btn pointer no-radius mb-2 mx-2 danger-bg text-white"
																	(click)="removeSection(workoutIndex, sectionIndex)">
																	<i class="fa fa-trash" aria-hidden="true"></i>
																</div>
																<div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white"
																	(click)="addExercise(workoutIndex, sectionIndex)">
																	Add Exercise
																</div>
															</div>
														</div>
													</div>
													<div class="row" *ngIf="section.exercise.length > 0">
														<div class="col-12">
															<div class="row">
																<div class="col-lg-3">Exercise</div>
																<div class="col-lg-1">Sets</div>
																<div class="col-lg-2">Min Reps/Time</div>
																<div class="col-lg-2">Max Reps/Time</div>
																<div class="col-lg-1">Rest</div>
																<div class="col-lg-1">Position</div>
																<div class="col-lg-1"></div>
															</div>
														</div>
													</div>
													<ng-container
														*ngFor="let exercise of section.exercise; let exerciseIndex=index">
														<div [hidden]="exercise.is_delete == 1" class="row">
															<div class="col-12">
																<div class="row">
																	<div class="col-lg-3">
																		<ng-select [items]="exerciseList"
																			placeholder="Select exercise" bindValue="id"
																			bindLabel="title" [clearable]="false"
																			[(ngModel)]="exercise.exercise_id">
																		</ng-select>
																	</div>
																	<div class="col-lg-1">
																		<input type="number"
																			class="form-control no-radius"
																			placeholder="Enter sets"
																			[(ngModel)]="exercise.exercise_sets">
																	</div>
																	<div class="col-lg-2">
																		<input type="number"
																			class="form-control no-radius"
																			placeholder="Enter min reps/time"
																			[(ngModel)]="exercise.target_min">
																	</div>
																	<div class="col-lg-2">
																		<input type="number"
																			class="form-control no-radius"
																			placeholder="Enter max reps/time"
																			[(ngModel)]="exercise.target_max">
																	</div>
																	<div class="col-lg-1">
																		<input type="number"
																			class="form-control no-radius"
																			placeholder="Enter rest"
																			[(ngModel)]="exercise.exercise_rest">
																	</div>
																	<div class="col-lg-1">
																		<input type="number"
																			class="form-control no-radius"
																			placeholder="Enter position"
																			[(ngModel)]="exercise.exercise_position">
																	</div>
																	<div class="row">
																		<div class="col-lg-1">
																			<div class="btn pointer no-radius mb-2 mx-2 danger-bg text-white"
																				(click)="removeExercise(workoutIndex, sectionIndex, exerciseIndex)">
																				<i class="fa fa-trash" aria-hidden="true"></i>
																			</div>
																		</div>
																		<div class="col-lg-1" *ngIf="type === 'clientProfile' && exercise.audit_count > 0">
																			<div class="table-list btn pointer no-radius mb-2 mx-2 text-white">
																				<img class="image-btn pointer px-3" src="assets/images/history.png" alt="logs"
																					(click)="openHistoryExerciseModal(exercise.id)"
																				/>
																			</div>
																		</div>
																	</div>
																	</div>
																</div>
														</div>
													</ng-container>
												</div>
											</div>
										</ng-container>
									</div>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
			<div class="col-lg-12 text-center">
				<div class="btn pointer no-radius mb-2 mx-2 secondary-bg text-white" (click)="commonService.clearData()">
					Clear Form
				</div>
				<div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="saveWorkoutProgram()" *ngIf="!isCreate">
					Save
				</div>
				<div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="saveWorkoutProgram(true)">
					{{ isCreate ? 'Save' : 'Save & Close' }}
				</div>
			</div>
		</div>
	</div>
</div>

    
<div class="modal fade" id="history-exercise-dialog" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-xl" role="document">
	  <div class="modal-content">
		<div class="modal-header">
		  <div class="d-flex w-100">
			<img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
			<div class="modal-title w-100 text-center">Exercise history</div>
		  </div>
		</div>
		<div class="modal-body px-4">
		  <div class="table-responsive table-list">
			<table class="table mb-0">
			  <thead class="thead-light">
				<tr>
				  <th>Exercise</th>
				  <th>Sets</th>
				  <th>Min Reps/Time</th>
				  <th>Max Reps/Time</th>
				  <th>Rest</th>
				  <th>Position</th>
				  <th>Action</th>
				  <th>Updated At</th>
				  <th>Updated By</th>
				</tr>
			  </thead>
			  <tbody>
				<tr *ngFor="let item of exerciseHistory">
				  <td>{{item.exercise_name}}</td>
				  <td>{{item.sets}}</td>
				  <td>{{item.target_min}}</td>
				  <td>{{item.target_max}}</td>
				  <td>{{item.rest_duration}}</td>
				  <td>{{item.ordered}}</td>
				  <td>{{item.action}}</td>
				  <td>{{item.audit_timestamp | date:'dd/MM/yyyy HH:mm:ss'}}</td>
				  <td>{{item.first_name}} {{item.last_name}}</td>
				</tr>
			  </tbody>
			</table>
		  </div>
		</div>
	  </div>
	</div>
</div>
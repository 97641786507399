import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ClientsComponent } from './clients/clients.component';
import { DefaultSessionsComponent } from './sessions/default-sessions/default-sessions.component';
import { OverrideSessionsComponent } from './sessions/override-sessions/override-sessions.component';
import { BookingsComponent } from './bookings/bookings.component';
import { ExercisesComponent } from './exercises/exercises.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { SettingsComponent } from './settings/settings.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ErrorComponent } from './error/error.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { ComponentsModule } from '../components/components.module';
import { ClientProfileComponent } from './client-profile/client-profile.component';
import { MeasurementsComponent } from './measurements/measurements.component';
import { MembershipComponent } from './membership/membership.component';
import { ChallengeComponent } from './challenge/challenge.component';
import { ReportsComponent } from './reports/reports.component';
import { StickersComponent } from './stickers/stickers.component';
import { WorkoutComponent } from './workout/workout.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import timeGridPlugin from '@fullcalendar/timegrid';
import momentPlugin from '@fullcalendar/moment';
import { CheckoutComponent } from './checkout/checkout.component';
import { PaymentComponent } from './payment/payment.component';
import { EquipmentsComponent } from './equipments/equipments.component';
import { WorkoutProgramComponent } from './workout-program/workout-program.component';

FullCalendarModule.registerPlugins([
  momentPlugin,
  timeGridPlugin,
  // interactionPlugin
])

@NgModule({
  declarations: [
    DashboardComponent,
    BookingsComponent,
    ClientsComponent,
    DefaultSessionsComponent,
    OverrideSessionsComponent,
    ExercisesComponent,
    NotificationsComponent,
    SettingsComponent,
    ResetPasswordComponent,
    ErrorComponent,
    SignInComponent,
    ClientProfileComponent,
    MeasurementsComponent,
    MembershipComponent,
    ChallengeComponent,
    StickersComponent,
    WorkoutComponent,
    ReportsComponent,
    CheckoutComponent,
    PaymentComponent,
    EquipmentsComponent,
    WorkoutProgramComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    FullCalendarModule,
    ComponentsModule
  ]
})
export class PagesModule { }

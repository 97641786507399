import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserApi, FoodDiaryApi, StickersApi, ChallengeApi, MembershipApi, WorkoutApi, EquipmentApi } from 'src/app/const/app.url';

@Injectable({
  providedIn: 'root'
})
export class ClientProfileService {

  constructor(private http: HttpClient) { }

  changePassword(body?: any) {
    return this.http.post<any>(UserApi.changePassword, body)
  }

  updateNotificationConfig(body?: any) {
    return this.http.post<any>(UserApi.updateNotificationConfig, body)
  }

  getPurchasedMembershipListByClientId(body?: any) {
    return this.http.post<any>(MembershipApi.getPurchasedMembershipListByClientId, body)
  }

  getBillingCreditListByClientId(body?: any) {
    return this.http.post<any>(MembershipApi.getBillingCreditListByClientId, body)
  }

  assignBillToClient(body?: any) {
    return this.http.post<any>(MembershipApi.assignBillToClient, body)
  }

  getPaymentMethodList() {
    return this.http.get<any>(MembershipApi.getPaymentMethodList)
  }

  updateBillingCreditData(body?: any) {
    return this.http.post<any>(MembershipApi.updateBillingCreditData, body)
  }

  getClientPaymentList(body?: any) {
    return this.http.post<any>(MembershipApi.getClientPaymentList, body)
  }

  checkPaymentStatus(body?: any) {
    return this.http.post<any>(MembershipApi.checkPaymentStatus, body)
  }

  checkCreditCardPaymentStatus(body?: any) {
    return this.http.post<any>(MembershipApi.checkCreditCardPaymentStatus, body);
  }

  checkBenefitPaymentStatus(body?: any) {
    return this.http.post<any>(MembershipApi.checkBenefitPaymentStatus, body);
  }

  updateClientPaymentData(body?: any) {
    return this.http.post<any>(MembershipApi.updateClientPaymentData, body)
  }

  clientRequestToPauseMembership(body?: any) {
    return this.http.post<any>(MembershipApi.clientRequestToPauseMembership, body)
  }

  resumeMembershipManually(body?: any) {
    return this.http.post<any>(MembershipApi.resumeMembershipManually, body)
  }

  getPausedMembershipDetail(body?: any) {
    return this.http.post<any>(MembershipApi.getPausedMembershipDetailByCMid, body)
  }

  upgradeDowngradeMembership(body?: any) {
    return this.http.post<any>(MembershipApi.upgradeDowngradeMembership, body)
  }

  cancelMembership(body?: any) {
    return this.http.post<any>(MembershipApi.cancelMembership, body)
  }

  updateClientMembershipData(body?: any) {
    return this.http.post<any>(MembershipApi.updateClientMembershipData, body)
  }

  getMembershipListForUserGroup(body?: any) {
    return this.http.post<any>(MembershipApi.getMembershipListForUserGroup, body)
  }

  purchaseMembership(body?: any) {
    return this.http.post<any>(MembershipApi.purchaseMembership, body)
  }

  addPurchasedMembershipData(body?: any) {
    return this.http.post<any>(MembershipApi.addPurchasedMembershipData, body)
  }

  getClientPreTrialMembershipSessionListAdmin(body?: any) {
    return this.http.post<any>(MembershipApi.getClientPreTrialMembershipSessionListAdmin, body)
  }
  addUpdatePreTrialOneOnOneSession(body?: any) {
    return this.http.post<any>(MembershipApi.addUpdatePreTrialOneOnOneSession, body)
  }
  deletePreTrialOneOnOneSession(body?: any) {
    return this.http.post<any>(MembershipApi.deletePreTrialOneOnOneSession, body)
  }
  getFoodDiaryList(body?: any) {
    return this.http.post<any>(FoodDiaryApi.getFoodDiaryList, body)
  }

  addFoodDiary(body?: any) {
    return this.http.post<any>(FoodDiaryApi.addFoodDiary, body)
  }

  updateFoodDiary(body?: any) {
    return this.http.post<any>(FoodDiaryApi.updateFoodDiary, body)
  }

  deleteFoodDiary(body?: any) {
    return this.http.post<any>(FoodDiaryApi.deleteFoodDiary, body)
  }

  getClientChallengesList(body?: any) {
    return this.http.post<any>(ChallengeApi.getClientChallengeList, body)
  }

  getAllChallengeList() {
    return this.http.get<any>(ChallengeApi.getAllChallengeList)
  }

  deleteClientChallenge(body?: any) {
    return this.http.post<any>(ChallengeApi.deleteClientChallenge, body)
  }

  assignChallengeToClient(body?: any) {
    return this.http.post<any>(ChallengeApi.assignChallengeToClient, body)
  }

  getClientChallengeLogList(body?: any) {
    return this.http.post<any>(ChallengeApi.getClientChallengeLogList, body)
  }

  getClientStickerList(body?: any) {
    return this.http.post<any>(StickersApi.getClientStickerList, body)
  }

  getAllStickerList() {
    return this.http.get<any>(StickersApi.getAllStickerList)
  }

  deleteClientSticker(body?: any) {
    return this.http.post<any>(StickersApi.deleteClientSticker, body)
  }

  assignStickerToClient(body?: any) {
    return this.http.post<any>(StickersApi.assignStickerToClient, body)
  }

  updateAssignedSticker(body?: any) {
    return this.http.post<any>(StickersApi.updateAssignedSticker, body)
  }

  getClientWorkoutList(body?: any) {
    return this.http.post<any>(WorkoutApi.getAllClientAssignedWorkoutList, body)
  }

  getClientWorkoutLogList(body?: any) {
    return this.http.post<any>(WorkoutApi.getAllAssignedClientWorkoutLog, body)
  }

  getWorkoutLogsList(body?: any) {
    return this.http.post<any>(WorkoutApi.getClientWorkoutAllLogs, body)
  }  

  deleteClientWorkout(body?: any) {
    return this.http.post<any>(WorkoutApi.deleteAssignedClientWorkout, body)
  }

  deleteClientWorkoutLog(body?: any) {
    return this.http.post<any>(WorkoutApi.deleteAssignedClientWorkoutLog, body)
  }

  getClientEquipmentList(id?: any){
    return this.http.get<any>(EquipmentApi.clientEquipment + '/'+ `${id}`)
  }

  deleteClientEquipment(body?: any) {
    return this.http.post<any>(EquipmentApi.deleteAssignedClientEquipment, body)
  }

}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderService } from './loader.service';
import { WorkoutService } from '../pages/workout/workout.service';
import { WorkoutProgram } from '../model/workout.model';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
workoutProgram: WorkoutProgram;
weeks = [];
activeWeekId: number;
selectedWeekId: number;
exerciseList = [];

  constructor(
    private loader: LoaderService,
    private workoutService: WorkoutService,
    private toastr: ToastrService
  ) {}

  getWorkoutDetailById(program_id): Promise<void> {
    return new Promise((resolve, reject) => {
      this.loader.show();
      const formData: FormData = new FormData();
      formData.append('program_id', program_id);
      this.workoutService.getWorkoutDetailById(formData).subscribe(
        (response) => {
          if (response.data) {
            this.workoutProgram = response.data.workout_program;
            this.getWeeks();
          } else {
            this.workoutProgram = null;
          }
          this.loader.hide();
          resolve();
        },
        (err) => {
          this.loader.hide();
          console.log(err);
          reject(err);
        }
      );
    });
  }

  getWeeks() {
    const uniqueWeeks = [
      ...new Set(this.workoutProgram.workout.map((workout) => workout.week)),
    ];
    this.weeks = uniqueWeeks.sort((a, b) => a - b);
  }

  addWeek() {
    const newWeekNumber =
      this.weeks.length > 0 ? Math.max(...this.weeks) + 1 : 1;
    this.weeks.push(newWeekNumber);
    this.selectWeek(newWeekNumber);
  }

  selectWeek(weekId: number) {
    this.activeWeekId = weekId;
    this.selectedWeekId = weekId;
  }
  
  clearData() {
    this.workoutProgram = new WorkoutProgram();
    this.weeks = []; // clear weeks array
    this.addWeek(); // add first week
  }

  getExerciseList() {
    this.loader.show();
    this.workoutService.getAllExerciseList().subscribe(
      (response) => {
        if (response.status) {
          this.exerciseList = response.data;
        } else {
          this.exerciseList = [];
          this.toastr.error(response.message);
        }
        this.loader.hide();
      },
      (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PagerService {

  getPager(totalItems: number, pageSize: number = 50, currentPage: number = 1) {

    const totalPages = Math.ceil(totalItems / pageSize);

    let displayPage = 3;
    let centerPage = 2;
    let sidePage = 1;
    let startPage = 0;
    let endPage = 0;

    if (totalPages <= displayPage) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= centerPage) {
        startPage = 1;
        endPage = displayPage;
      } else if (currentPage + sidePage >= totalPages) {
        startPage = totalPages - sidePage;
        endPage = totalPages;
      } else {
        startPage = currentPage - sidePage;
        endPage = currentPage + sidePage;
      }
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
    const pages = range(startPage, endPage, 1);

    // return object with all pager properties required by the view
    return {
      totalItems,
      pageSize,
      currentPage,
      totalPages,
      startPage,
      endPage,
      startIndex,
      endIndex,
      pages
    };
  }
}

<nav class="d-flex flex-column side-navbar" [ngClass]="{'collapsed-navbar':!guiService.showNavbar}">

    <div class="company-logo d-flex align-items-center justify-content-center ">
        <img src="assets/images/ASC---White@3x.png" alt="">
    </div>
    <div class="color-white" *ngFor="let menu of storageService.menus; let i = index">
        <ng-template [ngIf]="menu.hasSubMenu" [ngIfElse]="subMenu">
            <div class="menu-item d-flex align-items-center justify-content-between"
                (click)="menu.isOpened=!menu.isOpened">
                <div class="no-outline pointer">{{ menu.title }}</div>
                <i *ngIf="menu.isOpened" class="fa fa-chevron-up" style="font-size: 12px;" aria-hidden="true"></i>
                <i *ngIf="!menu.isOpened" class="fa fa-chevron-down" style="font-size: 12px;" aria-hidden="true"></i>
            </div>
            <ng-container *ngIf="menu.isOpened">
                <div class="sub-menu-item" *ngFor="let subMenu of menu.subMenu; let i = index">
                    <div class="d-flex no-outline pointer" routerDirection="root" [routerLink]="[subMenu.url]">
                        {{ subMenu.title }}
                    </div>
                </div>
            </ng-container>
        </ng-template>
        <ng-template #subMenu>
            <div class="menu-item">
                <div class="d-flex no-outline pointer" (click)="goToPage(menu.url)">
                    {{ menu.title }}
                </div>
            </div>
        </ng-template>
    </div>
    <div class="p-3 text-center text-white mt-auto"> Version {{appVersion}} </div>
</nav>
<div class="d-md-none overlay" *ngIf="guiService.showNavbar" (click)="closeNav()"></div>
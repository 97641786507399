<div class="modal" [id]="id" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{title}}</h5>
                <button type="button" class="close" aria-label="Close" (click)="onCancelClicked()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>{{description}}</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn no-radius mx-2 px-5 primary-bg text-white" (click)="onConfirmClicked()">
                    {{confirmButtonText}}
                </button>
                <button type="button" class="btn no-radius mx-2 px-5 secondary-bg text-white" (click)="onCancelClicked()">
                    {{cancelButtonText}}
                </button>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit, ViewChild } from '@angular/core';
import { SessionsService } from '../sessions.service';
import { ToastrService } from 'ngx-toastr';
import { CalendarOptions, DateSelectArg, EventApi, EventClickArg } from '@fullcalendar/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { GuiService } from 'src/app/shared/gui.service';
import { LoaderService } from 'src/app/shared/loader.service';
declare var $: any;

@Component({
  selector: 'app-default-sessions',
  templateUrl: './default-sessions.component.html',
  styleUrls: ['./default-sessions.component.scss']
})
export class DefaultSessionsComponent implements OnInit {

  requestMade = false;
  isCreate = false;
  isLoad = false;

  calendarVisible = true;
  calendarOptions: CalendarOptions;
  @ViewChild('fullcalendar') fullcalendar: FullCalendarComponent;

  selectedDateObj: any;
  dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  selectedWeek = [];
  trainerList = [];
  calendarSessionList = null;
  sessionEvents = [];

  sessionTypeList = [];
  sessionTypeTypeList = [{ id: 'Booking' }, { id: 'Consultation' }];
  sessionTypeId = '';
  sessionTypeTitle = '';
  sessionTypeType = '';
  sessionTypeActive = true;

  sessionList = null;
  filteredSessionList = null;
  viewActive = true;
  viewCalender = true;
  sessionData = null;

  deleteFrom = '';
  deleteItem = null;

  constructor(
    public guiService: GuiService, 
    private toastr: ToastrService, 
    private sessionsService: SessionsService,
    private loader: LoaderService
  ) { }

  ngOnInit(): void {
    const currentDate = new Date();
    this.guiService.navbarToggled.subscribe((data) => {
      if (data) {
        // this.fullcalendar.getApi().updateSize();
        setTimeout(() => {
          this.fullcalendar.getApi().updateSize();
        }, 250);
      }
    })
    this.getTrainerList();
    this.getSelectWeekDates(currentDate).then((response) => {
      this.calendarSessionList = [];
      this.getCalendarSessionList(0);
    });
    this.getSessionList();
    this.getSessionTypeList();
  }

  //#region Calendar View
  goToSession(value) {
    let first = this.selectedDateObj.getDate() - this.selectedDateObj.getDay() + (value * 7);
    var date = new Date(this.selectedDateObj.getFullYear(), this.selectedDateObj.getMonth(), first);
    date.setMinutes(date.getTimezoneOffset() * -1);
    this.getSelectWeekDates(date).then((response) => {
      this.calendarSessionList = [];
      if (value == 1) {
        this.fullcalendar.getApi().next();
      } else {
        this.fullcalendar.getApi().prev();
      }
      this.calendarSessionList = [];
      this.getCalendarSessionList(0);
    });
  }

  getSelectWeekDates(selectedDate) {
    return new Promise<void>((resolve, reject) => {
      this.selectedDateObj = selectedDate;
      const selectedWeek = [];
      for (let i = 0; i < 7; i++) {
        let first = this.selectedDateObj.getDate() - this.selectedDateObj.getDay() + (i);
        var date = new Date(this.selectedDateObj.getFullYear(), this.selectedDateObj.getMonth(), first);
        date.setMinutes(date.getTimezoneOffset() * -1);
        selectedWeek.push(date);
      }
      this.selectedWeek = selectedWeek;
      resolve();
    });
  }

  getCalendarSessionList(index) {
    if (index == 7) {
      this.setEvents();
      return;
      };
    this.loader.show();
    const selectedDateObj = this.selectedWeek[index];
    var formData: FormData = new FormData();
    formData.append('client_id', '1');
    formData.append('session_date', selectedDateObj.toJSON().substring(0, 10));
    this.sessionsService.getDateWiseSessionList(formData).subscribe(
      (response) => {
        if (response.status && response.data) {
          let data = [];
          data = response.data;
          data.map((e) => e.session_date = selectedDateObj);
          this.calendarSessionList = this.calendarSessionList.concat(data);
        }
        this.loader.hide();
        this.getCalendarSessionList(index + 1);
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  setEvents() {
    this.sessionEvents = [];
    this.calendarVisible = false;
    const activeSessionList = this.calendarSessionList.filter((e) => e.active);
    for (const element of activeSessionList) {
      let color = '#DCBBFD' //purple
      if (element.override) {
        color = '#FFF99E'; //yellow
      }
      // event.backgroundColor = '#1EACE278'; //sky
      let event: any = {};
      event.id = element.id;
      event.title = element.first_name + ' ' + element.last_name;
      event.start = new Date(element.session_date).toJSON().substr(0, 10) + 'T' + element.start_time;
      event.end = new Date(element.session_date).toJSON().substr(0, 10) + 'T' + element.end_time;
      event.classNames = ['no-radius'];
      event.backgroundColor = color;
      event.borderColor = '#000000';
      event.textColor = '#000000';
      this.sessionEvents.push(event);
    }

    this.calendarOptions = {
      initialView: 'timeGridWeek',
      headerToolbar: false,
      dayHeaderFormat: 'DD/MM',
      slotDuration: '00:30:00',
      slotLabelFormat: [{ hour: 'numeric', minute: '2-digit', meridiem: true },],
      slotMinTime: '05:00:00',
      // slotMaxTime: '24:00:00',
      expandRows: true,
      allDaySlot: false,
      slotEventOverlap: false,
      eventClassNames: ['text-center'],
      eventBorderColor: '#FFF99E',
      events: this.sessionEvents,
      weekends: true,
      editable: true,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      // handleWindowResize: true,
      // windowResizeDelay: 200,
      eventClick: (arg) => this.handleEventClick(arg),
    };
    this.calendarVisible = true;
  }

  handleEventClick(clickInfo: EventClickArg) {
    const sessionId = clickInfo.event.id;
    const session = this.calendarSessionList.find(e => e.id == sessionId);
    this.openAddUpdateSessionModal(0, session);
  }

  //#endregion


  getTrainerList(): void {
    this.loader.show();
    this.sessionsService.getTrainerList().subscribe(
      (response) => {
        if (response.data) {
          this.trainerList = response.data.map((e) => { return { id: e.id.toString(), name: e.first_name + ' ' + e.last_name } });
        } else {
          this.trainerList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  getSessionList() {
    this.sessionList = [];
    this.loader.show();
    this.sessionsService.getSessionList().subscribe(
      (response) => {
        if (response.data) {
          this.sessionList = response.data;
          this.filterSessionList(true);
        } else {
          this.sessionList = [];
          this.filteredSessionList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  filterSessionList(view): void {
    this.viewActive = view;
    this.filteredSessionList = [];
    this.filteredSessionList = (this.viewActive) ? this.sessionList.filter((e) => e.active && !e.override) : this.sessionList.filter((e) => !e.override);
    this.filteredSessionList.forEach((element) => {
      let daysOfWeek = [];
      if (element.sun) daysOfWeek.push('Sun');
      if (element.mon) daysOfWeek.push('Mon');
      if (element.tue) daysOfWeek.push('Tue');
      if (element.wed) daysOfWeek.push('Wed');
      if (element.thu) daysOfWeek.push('Thu');
      if (element.fri) daysOfWeek.push('Fri');
      if (element.sat) daysOfWeek.push('Sat');
      element.daysOfWeek = daysOfWeek.join(',');
    });
  }

  openAddUpdateSessionModal(isCreate, data) {
    this.isLoad = true;
    this.isCreate = isCreate;
    this.sessionData = data;
    setTimeout(() => {
      $('#add-session').modal('show');
    }, 0);
  }

  onRefresh(data) {
    this.isLoad = false;
    this.isCreate = false;
    this.calendarSessionList = [];
    this.getSessionList();
    this.getSessionTypeList();
    this.getCalendarSessionList(0);
  }

  deleteSession() {
    var formData: FormData = new FormData();
    formData.append('session_id', this.deleteItem.id);
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.sessionsService.deleteSession(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-session').modal('hide');
            this.getSessionList();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  clearSessionTypeData() {
    this.sessionTypeId = null;
    this.sessionTypeTitle = null;
    this.sessionTypeType = null;
    this.sessionTypeActive = true;
  }

  getSessionTypeList(): void {
    var formData: FormData = new FormData();
    formData.append('searchname', '');
    formData.append('page_no', '1');
    formData.append('order_by', 'asc');
    this.loader.show();
    this.sessionsService.getSessionTypeList(formData).subscribe(
      (response) => {
        if (response.data) {
          this.sessionTypeList = response.data;
        } else {
          this.sessionTypeList = [];
        }
        this.loader.hide();
      }, (err) => {
        console.log(err);
        this.loader.hide();
      }
    );
  }

  openAddUpdateSessionTypeModal(isCreate, data) {
    this.clearSessionTypeData();
    this.isCreate = isCreate;
    if (!this.isCreate) {
      this.sessionTypeId = data.id;
      this.sessionTypeTitle = data.title;
      this.sessionTypeType = data.type;
      this.sessionTypeActive = data.is_active;
    }
    $('#add-update-session-type').modal('show');
  }

  addUpdateSessionType() {
    if (!this.sessionTypeTitle) {
      this.toastr.error('Please enter title.');
    }
    if (!this.sessionTypeType) {
      this.toastr.error('Please select type.');
    }
    var formData: FormData = new FormData();
    formData.append('title', this.sessionTypeTitle);
    formData.append('type', this.sessionTypeType);
    formData.append('is_active', (this.sessionTypeActive) ? '1' : '0');
    if (this.isCreate) {
      this.createSessionType(formData);
    } else {
      formData.append('session_type_id', this.sessionTypeId);
      this.updateSessionType(formData);
    }
  }

  createSessionType(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.sessionsService.createSessionType(formData).subscribe(
        (response) => {
          this.requestMade = false;
          $('#add-update-session-type').modal('hide');
          if (response.status) {
            this.toastr.success(response.message);
            this.clearSessionTypeData();
            this.isCreate = true;
            this.getSessionTypeList();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  updateSessionType(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.sessionsService.updateSessionType(formData).subscribe(
        (response) => {
          this.requestMade = false;
          $('#add-update-session-type').modal('hide');
          if (response.status) {
            this.toastr.success(response.message);
            this.clearSessionTypeData();
            this.isCreate = true;
            this.getSessionTypeList();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  deleteSessionType() {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      var formData: FormData = new FormData();
      formData.append('session_type_id', this.deleteItem.id);
      this.sessionsService.deleteSessionType(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            this.getSessionTypeList();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  openDeleteDialog(deleteFrom, item) {
    this.deleteFrom = deleteFrom;
    this.deleteItem = item;
    $('#delete-dialog').modal('show');
  }

  deleteRecord() {
    if (this.deleteFrom == 'session') {
      this.deleteSession();
    } else if (this.deleteFrom == 'session_type') {
      this.deleteSessionType();
    }
  }
}

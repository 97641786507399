import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GuiService } from 'src/app/shared/gui.service';
declare var ThreeDS: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  status = '0';

  constructor(
    private route: ActivatedRoute,
    private guiService: GuiService,
  ) { }

  ngOnInit(): void {
    this.guiService.showNavbar = false;
    this.guiService.showToggleMenu = false;
    this.guiService.showProfileMenu = false;
    this.status = this.route.snapshot.queryParamMap.get('status');
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ExerciseApi, WorkoutApi, ClientApi, EquipmentApi } from 'src/app/const/app.url';

@Injectable({
  providedIn: 'root'
})
export class WorkoutService {

  constructor(
    private http: HttpClient
  ) { }

  getAllExerciseList() {
    return this.http.get<any>(ExerciseApi.getAllExerciseList)
  }

  getWorkoutDetailById(body?: any) {
    return this.http.post<any>(WorkoutApi.getWorkoutDetailById, body)
  }

  createWorkout(body?: any) {
    return this.http.post<any>(WorkoutApi.createWorkout, body)
  }

  updateWorkout(body?: any) {
    return this.http.post<any>(WorkoutApi.updateWorkout, body)
  }

  getWorkoutList(body?: any) {
    return this.http.post<any>(WorkoutApi.getAdminWorkoutList, body)
  }

  deleteWorkout(body?: any) {
    return this.http.post<any>(WorkoutApi.deleteWorkout, body)
  }

  getClientList() {
    return this.http.get<any>(ClientApi.getOnlyClientList)
  }

  getAllWorkoutList() {
    return this.http.get<any>(WorkoutApi.getAllWorkoutList)
  }

  assignWorkoutProgramToClient(body?: any) {
    return this.http.post<any>(WorkoutApi.assignWorkoutToClients, body)
  }

  getClientEquipmentList(id?: any){
    return this.http.get<any>(EquipmentApi.clientEquipment + '/'+ `${id}`)
  }

  getExerciseEquipment(body?: any){
    return this.http.post<any>(EquipmentApi.getEquipmentForExercises, body)
  }

  UpdateAssignWorkout(body?: any) {
    return this.http.post<any>(WorkoutApi.updateAssignedWorkout, body)
  }

  getUpdatedExercise(body?: any){
    return this.http.post<any>(WorkoutApi.getUpdatedExercise, body)
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ClientApi, UserApi } from 'src/app/const/app.url';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(private http: HttpClient) { }

  getOnlyClientList() {
    return this.http.get<any>(ClientApi.getOnlyClientList)
  }

  getClientList(body?: any) {
    return this.http.post<any>(ClientApi.getClientList, body)
  }

  getClientList2(body?: any) {
    return this.http.post<any>(ClientApi.getClientList2, body)
  }

  createClient(body?: any) {
    return this.http.post<any>(ClientApi.createClient, body)
  }

  updateClient(body?: any) {
    return this.http.post<any>(ClientApi.updateClient, body)
  }

  deleteClient(body?: any) {
    return this.http.post<any>(ClientApi.deleteClient, body)
  }

  getClientDetails(body?: any) {
    return this.http.post<any>(ClientApi.getClientDetails, body)
  }

  getClientGoalList(body?: any) {
    return this.http.post<any>(ClientApi.getClientGoalList, body)
  }

  getGoalList() {
    return this.http.get<any>(ClientApi.getGoalList)
  }

  createGoal(body?: any) {
    return this.http.post<any>(ClientApi.createGoal, body)
  }

  updateGoal(body?: any) {
    return this.http.post<any>(ClientApi.updateGoal, body)
  }

  deleteGoal(body?: any) {
    return this.http.post<any>(ClientApi.deleteGoal, body)
  }

  getAttendance(body?: any) {
    return this.http.post<any>(ClientApi.getAttendance, body)
  }

  updateAttendance(body?: any) {
    return this.http.post<any>(ClientApi.updateAttendance, body)
  }

  getPersonalGoalList(body?: any) {
    return this.http.post<any>(ClientApi.getPersonalGoalList, body)
  }

  createPersonalGoal(body?: any) {
    return this.http.post<any>(ClientApi.createPersonalGoal, body)
  }

  updatePersonalGoal(body?: any) {
    return this.http.post<any>(ClientApi.updatePersonalGoal, body)
  }

  deletePersonalGoal(body?: any) {
    return this.http.post<any>(ClientApi.deletePersonalGoal, body)
  }

  getInjuryList(body?: any) {
    return this.http.post<any>(ClientApi.getInjuryList, body)
  }

  createInjury(body?: any) {
    return this.http.post<any>(ClientApi.createInjury, body)
  }

  updateInjury(body?: any) {
    return this.http.post<any>(ClientApi.updateInjury, body)
  }

  deleteInjury(body?: any) {
    return this.http.post<any>(ClientApi.deleteInjury, body)
  }

  getNoteList(body?: any) {
    return this.http.post<any>(ClientApi.getNoteList, body)
  }

  createNote(body?: any) {
    return this.http.post<any>(ClientApi.createNote, body)
  }

  updateNote(body?: any) {
    return this.http.post<any>(ClientApi.updateNote, body)
  }

  deleteNote(body?: any) {
    return this.http.post<any>(ClientApi.deleteNote, body)
  }

  replacePassword(body?: any) {
    return this.http.post<any>(ClientApi.replacePassword, body)
  }

  getGroupList() {
    return this.http.get<any>(ClientApi.groupList)
  }

  createGroup(body?: any) {
    return this.http.post<any>(ClientApi.createGroup, body)
  }

  updateGroup(body?: any) {
    return this.http.post<any>(ClientApi.updateGroup, body)
  }

  deleteGroup(body?: any) {
    return this.http.post<any>(ClientApi.deleteGroup, body)
  }
}

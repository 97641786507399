<div class="container-fluid main-content">
    <div class="row">
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
                <h4 class="my-2">Notifications</h4>
            </div>
        </div>
    </div>
    <div class="row pt-5">
        <div class="col-lg-2 d-none d-lg-block">
            <div class="d-flex align-items-center justify-content-between search-box">
                <input type="text" class="form-control" placeholder="Search..." autocomplete="off"
                    [(ngModel)]="searchText" (input)="getNotificationTypeList(1)">
                <div class="icon px-2"><i class="fa fa-search"></i></div>
            </div>
        </div>
        <div class="col-lg-10 text-right align-self-center">
            <ul class="table-feature">
                <li>
                    <div class="view-btn pointer">
                        <div [class.active]="viewActive" (click)="filterNotificationTypeList(true)">View Active</div>
                        <div>/</div>
                        <div [class.active]="!viewActive" (click)="filterNotificationTypeList(false)">View All</div>
                    </div>
                </li>
                <li>
                    <div class="pagination" *ngIf="pager.pages && pager.pages.length">
                        <ul class="page-number">
                            <li (click)="setPage(pager.currentPage - 1)" [class.disabled]="pager.currentPage === 1">
                                <i class="fa fa-angle-left"></i>
                            </li>
                            <li *ngFor="let page of pager.pages" (click)="setPage(page)"
                                [class.active]="pager.currentPage === page">
                                {{ page }}
                            </li>
                            <li (click)="setPage(pager.currentPage + 1)"
                                [class.disabled]="pager.currentPage === pager.totalPages">
                                <i class="fa fa-angle-right"></i>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <div class="sort-by">
                        <select [(ngModel)]="pageSize" (change)="getNotificationTypeList(1)">
                            <option [value]="50">50/Pages</option>
                            <option [value]="100">100/Pages</option>
                            <option [value]="150">150/Pages</option>
                        </select>
                    </div>
                </li>
                <li>
                    <div class="sort-by">
                        <select [(ngModel)]="orderBy" (change)="getNotificationTypeList(1)">
                            <option [value]="''">Sort by</option>
                            <option [value]="'asc'">A To Z</option>
                            <option [value]="'desc'">Z To A</option>
                        </select>
                    </div>
                </li>
                <li>
                    <div class="primary-btn pointer" (click)="openAddUpdateNotificationTypeModal(1,null)">
                        Add Notifications <img src="assets/images/add-white.png" alt="images">
                    </div>
                </li>
                <li>
                    <div class="primary-btn pointer" (click)="openSendNotificationModal()">
                        Send Notification
                    </div>
                </li>
            </ul>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="table-responsive table-list">
                <table class="table">
                    <thead class="thead-light">
                        <tr>
                            <th>Notification Title</th>
                            <th>Description</th>
                            <th class="text-center">Active</th>
                            <th class="text-center px-3">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of filteredNotificationTypeList">
                            <td>{{item.title}}</td>
                            <td>{{item.description}}</td>
                            <td>
                                <div class="text-center">
                                    <img *ngIf="item.is_active" src="assets/images/check-square.png"
                                        class="image-btn pointer" />
                                </div>
                            </td>
                            <td>
                                <div class="text-center">
                                    <img src="assets/images/edit.png" class="image-btn pointer" alt="images"
                                        (click)="openAddUpdateNotificationTypeModal(0,item)" />
                                    <!-- <img *ngIf="item.configurable" src="assets/images/setting.png" class="image-btn pointer"
                                        (click)="openAddUpdateNotificationTypeModal(0,item)" /> -->
                                    <img src="assets/images/delete.png" class="image-btn pointer ml-2"
                                        (click)="openDeleteDialog(item);" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<div class="modal fade p-0" id="add-update-notification-types" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered add-session-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header text-center">
                <div class="d-flex w-100">
                    <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
                    <div class="modal-title w-100">{{(isCreate)?'Add':'Update'}} Notification</div>
                </div>
            </div>
            <div class="modal-body">
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Title:</div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control no-radius" placeholder="Enter title" [(ngModel)]="title">
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4 pr-0">Description:</div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control no-radius" placeholder="Enter description"
                            [(ngModel)]="description">
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Notification Type:</div>
                    <div class="col-sm-8">
                        <ng-select [items]="typeList" placeholder="Select notification type" bindValue="value"
                            bindLabel="value" [clearable]="false" [(ngModel)]="type">
                        </ng-select>
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Configurable:</div>
                    <div class="col-sm-8">
                        <div class="d-flex align-items-center">
                            <i *ngIf="!configurable" (click)="configurable=true;"
                                class="fa fa-square-o checkbox secondary-color inactive"></i>
                            <i *ngIf="configurable" (click)="configurable=false;"
                                class="fa fa-check-square-o checkbox success-color"></i>
                        </div>
                    </div>
                </div>
                <div *ngIf="configurable" class="row mb-3 align-items-center">
                    <div class="col-sm-4">Time:</div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control no-radius" placeholder="Enter time" [(ngModel)]="time">
                    </div>
                </div>
                <div *ngIf="configurable" class="row mb-3 align-items-center">
                    <div class="col-sm-4">Select Query:</div>
                    <div class="col-sm-8">
                        <textarea rows="3" class="form-control no-radius" placeholder="Enter query"
                            [(ngModel)]="query"></textarea>
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Message Content:</div>
                    <div class="col-sm-8">
                        <textarea rows="3" class="form-control no-radius" placeholder="Enter message content"
                            [(ngModel)]="content"></textarea>
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Active:</div>
                    <div class="col-sm-8">
                        <div class="d-flex align-items-center">
                            <i *ngIf="!active" (click)="active=true;"
                                class="fa fa-square-o checkbox secondary-color inactive"></i>
                            <i *ngIf="active" (click)="active=false;"
                                class="fa fa-check-square-o checkbox success-color"></i>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 text-center">
                    <div class="btn pointer no-radius mb-2 mx-2 secondary-bg text-white" (click)="clearData()">
                        Clear Form
                    </div>
                    <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="saveExercise()">
                        Save & Close
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade p-0" id="send-notification" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered add-session-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header text-center">
                <div class="d-flex w-100">
                    <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
                    <div class="modal-title w-100">Send Notification</div>
                </div>
            </div>
            <div class="modal-body">
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Send to all clients:</div>
                    <div class="col-sm-8">
                        <div class="d-flex align-items-center">
                            <i *ngIf="!sendtoall" (click)="sendtoall=true;selectAllClients(true);"
                                class="fa fa-square-o checkbox secondary-color inactive"></i>
                            <i *ngIf="sendtoall" (click)="sendtoall=false;selectAllClients(false);"
                                class="fa fa-check-square-o checkbox success-color"></i>
                        </div>
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Clients:</div>
                    <div class="col-sm-8">
                        
                        <ng-select [multiple]="true" placeholder="Select Clients"
                                    [(ngModel)]="clientId">
                            <ng-option *ngFor="let client of clientList" [value]="client.id">{{client.first_name}} {{client.last_name}}</ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Notification Message:</div>
                    <div class="col-sm-8">
                        <textarea class="form-control no-radius" placeholder="Enter message content" [(ngModel)]="messageContent"></textarea>
                    </div>
                </div>
                <div class="col-lg-12 text-center">
                    <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="sendNotification()">
                        Send Notification
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-delete (onConfirm)="deleteNotificationType()"></app-delete>
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  @Input() id = 'confirm-dialog';
  @Input() title = 'Are you sure?';
  @Input() description = 'Are you sure you want to continue?';
  @Input() data: any;
  @Input() cancelButtonText = 'Cancel';
  @Input() confirmButtonText = 'Confirm';

  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() onConfirm: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onCancelClicked() {
    setTimeout(() => {
      this.onCancel.emit(false);
      this.close();
    }, 50);
  }

  onConfirmClicked() {
    setTimeout(() => {
      this.onConfirm.emit(true);
      this.close();
    }, 50);
  }

  close() {
    $(`#${this.id}`).modal('hide');
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EquipmentApi } from 'src/app/const/app.url';

@Injectable({
  providedIn: 'root'
})
export class EquipmentsService {

  constructor(private http: HttpClient) { }

  getEquipmentList() {
    return this.http.get<any>(EquipmentApi.equipment)
  }

  addOrUpdateEquipment(body?: any) {
    return this.http.put<any>(EquipmentApi.equipment, body)
  }

  deleteEquipment(id?: any) {
    return this.http.delete<any>(EquipmentApi.equipment + '/'+ `${id}`)
  }

  assignEquipmentToClient(body?: any){
    return this.http.post<any>(EquipmentApi.clientEquipment, body)

  }
}

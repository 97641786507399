<div class="container-fluid main-content">
    <div class="row">
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
                <h4 class="my-2">Reports</h4>
            </div>
        </div>
    </div>
    <div class="row pt-5">
        <div class="col-lg-2 d-none d-lg-block">
            <div class="d-flex align-items-center justify-content-between search-box">
                <input type="text" class="form-control" placeholder="Search..." autocomplete="off"
                    [(ngModel)]="searchText" (input)="getReportList(1)">
                <div class="icon px-2"><i class="fa fa-search"></i></div>
            </div>
        </div>
        <div class="col-lg-10 text-right align-self-center">
            <ul class="table-feature">
                <li>
                    <div class="view-btn pointer">
                        <div [class.active]="viewActive" (click)="filterReportList(true)">View Active</div>
                        <div>/</div>
                        <div [class.active]="!viewActive" (click)="filterReportList(false)">View All</div>
                    </div>
                </li>
                <li>
                    <div class="pagination" *ngIf="pager.pages && pager.pages.length">
                        <ul class="page-number">
                            <!-- <li>
                                <div (click)="setPage(1)" [ngClass]="{ disabled: pager.currentPage === 1 }">
                                    <i class="fa fa-angle-double-left"></i>
                                </div>
                            </li> -->
                            <li (click)="setPage(pager.currentPage - 1)" [class.disabled]="pager.currentPage === 1">
                                <i class="fa fa-angle-left"></i>
                            </li>
                            <li *ngFor="let page of pager.pages" (click)="setPage(page)"
                                [class.active]="pager.currentPage === page">
                                {{ page }}
                            </li>
                            <li (click)="setPage(pager.currentPage + 1)"
                                [class.disabled]="pager.currentPage === pager.totalPages">
                                <i class="fa fa-angle-right"></i>
                            </li>
                            <!-- <li>
                                <div (click)="setPage(pager.totalPages)"
                                    [ngClass]="{ disabled: pager.currentPage === pager.totalPages }">
                                    <i class="fa fa-angle-double-right"></i>
                                </div>
                            </li> -->
                        </ul>
                    </div>
                </li>
                <li>
                    <div class="sort-by">
                        <select [(ngModel)]="pageSize" (change)="getReportList(1)">
                            <option [value]="50">50/Pages</option>
                            <option [value]="100">100/Pages</option>
                            <option [value]="150">150/Pages</option>
                        </select>
                    </div>
                </li>
                <li>
                    <div class="sort-by">
                        <select [(ngModel)]="orderBy" (change)="getReportList(1)">
                            <option [value]="''">Sort by</option>
                            <option [value]="'asc'">A To Z</option>
                            <option [value]="'desc'">Z To A</option>
                        </select>
                    </div>
                </li>
                <li>
                    <div class="primary-btn pointer" (click)="openAddUpdateReportModal(1,null)">
                        Add Report <img src="assets/images/add-white.png" alt="images">
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="table-responsive table-list">
                <table class="table" id="excel-table">
                    <thead class="thead-light">
                        <tr>
                            <th>Report Title</th>
                            <th>Description</th>
                            <th class="text-center">Active</th>
                            <th class="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of filteredReportList">
                            <td>{{item.title}}</td>
                            <td>{{item.description}}</td>
                            <td>
                                <div class="text-center">
                                    <img *ngIf="item.active" src="assets/images/check-square.png"
                                        class="image-btn pointer" alt="images" />
                                </div>
                            </td>
                            <td>
                                <div class="text-center">
                                    <!-- <a *ngIf="item.configure_client==0 && item.configure_date_range==0" style="cursor: pointer" (click)="exportToExcel(item)"> Download </a> 

                                    <a *ngIf="(item.configure_client==1 || item.configure_date_range==1)" style="cursor: pointer" (click)="openRunResultModal(item)"> Download </a> -->

                                    <img *ngIf="item.configure_client==0 && item.configure_date_range==0"
                                        src="assets/images/download.png" class="image-btn pointer" alt="images"
                                        (click)="exportToExcel(item)" />

                                    <img *ngIf="(item.configure_client==1 || item.configure_date_range==1)"
                                        src="assets/images/download.png" class="image-btn pointer" alt="images"
                                        (click)="openRunResultModal(item)" />

                                    <img src="assets/images/edit.png" class="image-btn pointer pl-2" alt="images"
                                        (click)="openAddUpdateReportModal(0,item)" />
                                    <img src="assets/images/delete.png" class="image-btn pointer pl-2" alt="images"
                                        (click)="openDeleteDialog(item)" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<div class="modal fade p-0" id="add-report" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered add-session-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header text-center">
                <div class="d-flex w-100">
                    <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
                    <div class="modal-title w-100">{{(isCreate)?'Add':'Update'}} Report</div>
                </div>
            </div>
            <div class="modal-body">
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Report Title:</div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control no-radius" placeholder="Enter report title"
                            [(ngModel)]="title">
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4 pr-0">Description:</div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control no-radius" placeholder="Enter description"
                            [(ngModel)]="description">
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4 pr-0">Select Query:</div>
                    <div class="col-sm-8">
                        <textarea class="form-control no-radius" placeholder="Enter query"
                            [(ngModel)]="query"></textarea>
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Configure Client:</div>
                    <div class="col-sm-8">
                        <div class="d-flex align-items-center">
                            <i *ngIf="!configure_client" (click)="configure_client=true;"
                                class="fa fa-square-o checkbox secondary-color inactive"></i>
                            <i *ngIf="configure_client" (click)="configure_client=false;"
                                class="fa fa-check-square-o checkbox success-color"></i>
                        </div>
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Configure Date Range:</div>
                    <div class="col-sm-8">
                        <div class="d-flex align-items-center">
                            <i *ngIf="!configure_date_range" (click)="configure_date_range=true;"
                                class="fa fa-square-o checkbox secondary-color inactive"></i>

                            <i *ngIf="configure_date_range" (click)="configure_date_range=false;"
                                class="fa fa-check-square-o checkbox success-color"></i>
                        </div>
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-sm-4">Report Active:</div>
                    <div class="col-sm-8">
                        <div class="d-flex align-items-center">
                            <i *ngIf="!active" (click)="active=true;"
                                class="fa fa-square-o checkbox secondary-color inactive"></i>
                            <i *ngIf="active" (click)="active=false;"
                                class="fa fa-check-square-o checkbox success-color"></i>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 text-center">
                    <div class="btn pointer no-radius mb-2 mx-2 secondary-bg text-white" (click)="clearData()">
                        Clear Form
                    </div>
                    <!-- <div class="btn pointer no-radius mb-2 mx-2 secondary-bg text-white">
                        Save & Duplicate
                    </div> -->
                    <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="saveReport()">
                        Save & Close
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade p-0" id="run-report" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered add-session-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header text-center">
                <div class="d-flex w-100">
                    <img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
                    <div class="modal-title w-100">Run Report</div>
                </div>
            </div>
            <div class="modal-body">
                <div class="row mb-3 align-items-center" *ngIf="configureClient==1">
                    <div class="col-sm-4">All clients:</div>
                    <div class="col-sm-8">

                        <i *ngIf="!all_client" (click)="all_client=true;"
                            class="fa fa-square-o checkbox secondary-color inactive"></i>
                        <i *ngIf="all_client" (click)="all_client=false;"
                            class="fa fa-check-square-o checkbox success-color"></i>
                    </div>
                </div>
                <div class="row mb-3 align-items-center" *ngIf="configureClient==1">
                    <div class="col-sm-4">Clients:</div>
                    <div class="col-sm-8">

                        <ng-select [multiple]="true" placeholder="Select Clients" [(ngModel)]="assignReportClients">

                            <ng-option *ngFor="let client of clientList" [value]="client.id">{{client.first_name}}
                                {{client.last_name}}</ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="row mb-3 align-items-center" *ngIf="configureDateRange==1">
                    <div class="col-sm-4 pr-0">Date From:</div>
                    <div class="col-sm-8">
                        <input type="date" class="form-control no-radius" placeholder="Select date from"
                            [(ngModel)]="startDate">
                    </div>
                </div>
                <div class="row mb-3 align-items-center" *ngIf="configureDateRange==1">
                    <div class="col-sm-4 pr-0">Date To:</div>
                    <div class="col-sm-8">
                        <input type="date" class="form-control no-radius" placeholder="Select date to"
                            [(ngModel)]="endDate">
                    </div>
                </div>
                <input type="hidden" class="form-control no-radius" placeholder="Select date to" [(ngModel)]="reportId">
                <div class="col-lg-12 text-center">
                    <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="runReport()">
                        Run Report
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-delete (onConfirm)="deleteReport()"></app-delete>
<div class="container-fluid main-content">
	<div class="row">
		<div class="col-12">
			<div class="d-flex align-items-center justify-content-between">
				<h4 class="my-2">Workout Program</h4>
			</div>
		</div>
	</div>
	<div class="row pt-5">
		<div class="col-lg-2 d-none d-lg-block">
			<div class="d-flex align-items-center justify-content-between search-box">
				<input type="text" class="form-control" placeholder="Search..." autocomplete="off"
					[(ngModel)]="searchText" (input)="getWorkoutList(1)">
				<div class="icon px-2"><i class="fa fa-search"></i></div>
			</div>
		</div>
		<div class="col-lg-10 text-right align-self-center">
			<ul class="table-feature">
				<li>
					<div class="view-btn pointer">
						<div [class.active]="viewActive" (click)="filterWorkoutListData(true)">View Active</div>
						<div>/</div>
						<div [class.active]="!viewActive" (click)="filterWorkoutListData(false)">View All</div>
					</div>
				</li>
				<li>
					<div class="pagination" *ngIf="pager.pages && pager.pages.length">
						<ul class="page-number">
							<li (click)="setPage(pager.currentPage - 1)" [class.disabled]="pager.currentPage === 1">
								<i class="fa fa-angle-left"></i>
							</li>
							<li *ngFor="let page of pager.pages" (click)="setPage(page)"
								[class.active]="pager.currentPage === page">
								{{ page }}
							</li>
							<li (click)="setPage(pager.currentPage + 1)"
								[class.disabled]="pager.currentPage === pager.totalPages">
								<i class="fa fa-angle-right"></i>
							</li>
						</ul>
					</div>
				</li>
				<li>
					<div class="sort-by">
						<select [(ngModel)]="pageSize" (change)="getWorkoutList(1)">
							<option [value]="50">50/Pages</option>
							<option [value]="100">100/Pages</option>
							<option [value]="150">150/Pages</option>
						</select>
					</div>
				</li>
				<li>
					<div class="sort-by">
						<select [(ngModel)]="orderBy" (change)="getWorkoutList(1)">
							<option [value]="''">Sort by</option>
							<option [value]="'asc'">A To Z</option>
							<option [value]="'desc'">Z To A</option>
						</select>
					</div>
				</li>
				<li>
					<div class="primary-btn pointer" (click)="openAddUpdateWorkoutModal(1,null)">
						Add Program <img src="assets/images/add-white.png" alt="images">
					</div>
				</li>
			</ul>
		</div>
	</div>
	<div class="row">
		<div class="col-12">
			<div class="table-responsive table-list">
				<table class="table">
					<thead class="thead-light">
						<tr>
							<th>Program Title</th>
							<th>Description</th>
							<th class="text-center">Active</th>
							<th class="text-center">Action</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let item of filterWorkoutList">
							<td>{{item.title}}</td>
							<td>{{item.description}}</td>
							<td>
								<div class="text-center">
									<img *ngIf="item.active" src="assets/images/check-square.png"
										class="image-btn pointer" alt="images" />
								</div>
							</td>
							<td>
								<div class="text-center">
									<img src="assets/images/add.png" class="image-btn pointer"
										(click)="openAssignWorkoutModal(item)" alt="images" />

									<img src="assets/images/edit.png" class="image-btn pointer pl-2"
										(click)="openAddUpdateWorkoutModal(0,item)" alt="images" />

									<img src="assets/images/copy.svg" class="image-btn pointer pl-2"
										(click)="duplicateWorkoutProgram(item.id)" alt="images" />

									<img src="assets/images/delete.png" class="image-btn pointer pl-2"
										(click)="openDeleteDialog(item)" alt="images" />
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>
<app-workout-program [isCreate]="isCreate" [type]="'workout'" [selectedWeekId]="commonService.selectedWeekId" [exerciseList]="commonService.exerciseList"
	[weeks]="commonService.weeks" [activeWeekId]="commonService.activeWeekId" [workoutProgram]="commonService.workoutProgram"
	(onModalHidden)="handleModalHidden(true)">
</app-workout-program>

<div class="modal fade p-0" id="assign-workout" tabindex="-1" role="dialog">
	<div class="modal-dialog modal-dialog-centered add-session-dialog" role="document">
		<div class="modal-content" *ngIf="!warningModal">
			<div class="modal-header text-center">
				<div class="d-flex w-100">
					<img src="assets/images/back.png" class="modal-back-btn pointer" data-dismiss="modal" />
					<div class="modal-title w-100">Assign Workout Program</div>
				</div>
			</div>
			<div class="modal-body">
				<div class="row mb-3 align-items-center">
					<div class="col-sm-4">Workout:</div>
					<div class="col-sm-8">
						<ng-select [items]="workoutDropdownList" placeholder="Select workout" bindValue="id"
							bindLabel="title" [clearable]="false" [(ngModel)]="assignWorkoutId"
							(change)="changeWorkout($event)">
						</ng-select>
					</div>
				</div>
				<div class="row mb-3 align-items-center">
					<div class="col-sm-4">Clients:</div>
					<div class="col-sm-8">
						<ng-select [multiple]="true" placeholder="Select Clients" [(ngModel)]="assignWorkoutClients">
							<ng-option *ngFor="let client of clientList" [value]="client.id">{{client.first_name}}
								{{client.last_name}}</ng-option>
						</ng-select>
					</div>
				</div>
				<div class="row mb-3 align-items-center">
					<div class="col-sm-4 pr-0">Start Date:</div>
					<div class="col-sm-8">
						<input type="date" class="form-control no-radius" placeholder="Enter Start Date"
							[(ngModel)]="startDate">
					</div>
				</div>
				<div class="row mb-3 align-items-center">
					<div class="col-sm-4">Trainer:</div>
					<div class="col-sm-8">
						<ng-select [multiple]="true" placeholder="Select Trainer" [clearable]="false"
							[(ngModel)]="assignClientTrainer">
							<ng-option *ngFor="let trainer of trainerList" [value]="trainer.id">{{trainer.name}}
							</ng-option>
						</ng-select>
					</div>
				</div>
				<div class="row mb-3 align-items-center">
					<div class="col-sm-4 pr-0">Notification Message:</div>
					<div class="col-sm-8">
						<textarea class="form-control no-radius" placeholder="Enter Notification Message"
							[(ngModel)]="notificationMsg"></textarea>
					</div>
				</div>
				<div class="col-lg-12 text-center">
					<div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white"
						(click)="getClientEquipmentList()">
						Assign Workout
					</div>
				</div>
			</div>
		</div>
		<div class="modal-content" *ngIf="warningModal">
			<div class="modal-header text-center">
				<div class="d-flex w-100">
					<img src="assets/images/back.png" class="modal-back-btn pointer" (click)="warningModal = false" />
					<div class="modal-title w-100">Client Equipments</div>
				</div>
			</div>
			<div class="modal-body">
				<div class="row mb-3 align-items-center" *ngFor="let item of clientEquipments">
					<div class="col-sm-12">
						<div *ngIf="item.missingEquipments.length > 0">
							{{ item.clientName }} does not have
							<ng-container *ngFor="let exercise of item.missingEquipments">
								<ng-container *ngFor="let equipment of exercise.missingEquipmentNames">
									{{ equipment }}
								</ng-container>
								for {{ exercise.exerciseName }}.
							</ng-container>
						</div>
					</div>
				</div>
				<div class="row align-items-center">
					<div class="col-lg-6 text-center">
						<div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white">
							Cancel
						</div>
					</div>
					<div class="col-lg-6 text-center">
						<div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white"
							(click)="assignWorkoutProgram()">
							Continue
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<app-delete (onConfirm)="deleteWorkout()"></app-delete>
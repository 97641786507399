import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ReportsService } from './reports.service';
import { PagerService } from 'src/app/shared/pager.service';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';
import { LoaderService } from 'src/app/shared/loader.service';
declare var $: any;

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  requestMade = false;
  isCreate = false;

  currentDateObj = null;

  searchText = '';
  viewActive = true;
  iniFlag: any = 1;
  pager: any = {};
  rowCount = 0;
  pageSize = 50;
  pageIndex = 1;
  orderBy = 'asc';

  reportList = null;
  filteredReportList = null;

  reportId = null;
  title = null;
  description = null;
  query = null;
  configure_client = null;
  configure_date_range = null;
  active = null;

  all_client = null;
  assignReportClients: any;
  clientList = null;
  startDate = null;
  endDate = null;
  configureDateRange = null;
  configureClient = null;

  fileInput = null;
  fileBase64 = null;
  fileExtension = null;

  deleteItem = null;

  constructor(
    private toastr: ToastrService,
    private pagerService: PagerService,
    private reportsService: ReportsService,
    private loader: LoaderService
  ) { }


  ngOnInit(): void {
    this.clearData();
    this.getReportList(1);
    this.getClientList();
  }
  setPage(page: number) {
    if (page < 1) { return; }
    if (page > this.pager.totalPages) { return; }

    this.pageIndex = page;
    this.pager = this.pagerService.getPager(this.rowCount, this.pageSize, this.pageIndex);
    if (this.iniFlag === 0) {
      this.getReportList(0);
    }
  }

  getReportList(item) {
    this.loader.show();
    if (item === 1) {
      this.pageIndex = 1;
      this.iniFlag = 1;
    }
    var formData: FormData = new FormData();
    formData.append('searchname', this.searchText);
    formData.append('page_no', this.pageIndex.toString());
    formData.append('page_size', this.pageSize.toString());
    formData.append('order_by', this.orderBy.toString());
    this.reportsService.getReportList(formData).subscribe(
      (response) => {
        if (response.data) {
          this.reportList = response.data;
          this.rowCount = response.totalrow;
          if (this.iniFlag === 1) {
            this.setPage(1);
            this.iniFlag = 0;
          }
          this.filterReportList(this.viewActive)
        } else {
          this.reportList = [];
          this.filteredReportList = [];
        }
        this.loader.hide();
      }, (err) => {
        console.log(err);
        this.loader.hide();
      }
    );
  }

  filterReportList(view): void {
    this.viewActive = view;
    this.filteredReportList = [];
    this.filteredReportList = (this.viewActive) ? this.reportList.filter((e) => e.active) : this.reportList;
    this.sortReportList();
  }

  sortReportList() {
    if (this.orderBy == 'asc') {
      this.filteredReportList.sort((a, b) => {
        if (a.title.toLowerCase() > b.title.toLowerCase()) { return 1; }
        if (a.title.toLowerCase() < b.title.toLowerCase()) { return -1; }
      });
    } else {
      this.filteredReportList.sort((a, b) => {
        if (a.title.toLowerCase() > b.title.toLowerCase()) { return -1; }
        if (a.title.toLowerCase() < b.title.toLowerCase()) { return 1; }
      });
    }
  }

  openAddUpdateReportModal(isCreate, data) {
    this.clearData();
    this.isCreate = isCreate;
    if (!this.isCreate) {
      this.reportId = data.id;
      this.title = data.title;
      this.description = data.description;
      this.query = data.query;
      this.configure_client = data.configure_client;
      this.configure_date_range = data.configure_date_range;
      this.active = data.active;
    }
    $('#add-report').modal('show');
  }

  clearData(): void {
    this.reportId = null;
    this.title = null;
    this.description = null;
    this.query = null;
    this.configure_client = null;
    this.configure_date_range = null;
    this.active = null;

    this.startDate = null;
    this.endDate = null;
    this.configureClient = null;
    this.configureDateRange = null;
    this.assignReportClients = null;
    this.all_client = null;
  }

  saveReport() {
    if (!this.title) {
      this.toastr.error('Please enter title.');
      return;
    }
    if (!this.query) {
      this.toastr.error('Please enter query.');
      return;
    }
    var formData: FormData = new FormData();
    formData.append('title', this.title);
    formData.append('description', this.description);
    formData.append('query', this.query);
    formData.append('configure_client', (this.configure_client) ? '1' : '0');
    formData.append('configure_date_range', (this.configure_date_range) ? '1' : '0');
    formData.append('active', (this.active) ? '1' : '0');
    if (this.isCreate) {
      this.createReport(formData);
    } else {
      formData.append('report_id', this.reportId);
      this.updateReport(formData);
    }
  }

  createReport(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.reportsService.createReport(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-report').modal('hide');
            this.getReportList(1);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  updateReport(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.reportsService.updateReport(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-report').modal('hide');
            this.getReportList(1);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }

  openDeleteDialog(item) {
    this.deleteItem = item;
    $('#delete-dialog').modal('show');
  }

  deleteReport() {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      var formData: FormData = new FormData();
      formData.append('report_id', this.deleteItem.id);
      this.reportsService.deleteReport(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            this.getReportList(1);
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }
  exceltitle = 'angular-export-to-excel';

  dataForExcel = [];

  exportToExcel(item) {
    var formData: FormData = new FormData();
    formData.append('report_id', item.id);
    this.exportToExcelFile(formData);
  }

  exportToExcelFile(formData) {
    this.loader.show();
    this.dataForExcel = [];
    this.reportsService.getReportResult(formData).subscribe(
      (response) => {
        if (response.status) {
          response.data.forEach((row: any) => {
            this.dataForExcel.push(Object.values(row))
          });
          let reportData = {
            title: response.title,
            data: this.dataForExcel,
            headers: Object.keys(response.data[0])
          }
          this.reportsService.exportExcel(reportData);
        } else {
          this.toastr.error(response.message);
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  openRunResultModal(data) {
    this.clearData();
    if (data) {
      this.reportId = data.id;
      this.configureClient = data.configure_client;
      this.configureDateRange = data.configure_date_range;
    }
    $('#run-report').modal('show');
  }

  getClientList() {
    this.loader.show();
    this.reportsService.getClientList().subscribe(
      (response) => {
        this.requestMade = false;
        if (response.status) {
          this.clientList = response.data;
        } else {
          this.toastr.error(response.message);
        }
        this.loader.hide();
      }, (err) => {
        this.requestMade = false;
        this.loader.hide();
        console.log(err);
      }
    );
  }

  runReport() {
    var formData: FormData = new FormData();
    if (this.configureDateRange == 1) {
      if (!this.startDate) {
        this.toastr.error('Please enter start date.');
        return;
      }
      if (!this.endDate) {
        this.toastr.error('Please enter end date.');
        return;
      }
      formData.append('start_date', this.startDate);
      formData.append('end_date', this.endDate);
    }
    if (this.configureClient == 1) {
      formData.append('all_client', (this.all_client) ? '1' : '0');
      formData.append('client_ids', this.assignReportClients);
    }
    formData.append('report_id', this.reportId);
    //formData.append('client_ids', this.assignReportClients);
    this.exportToExcelFile(formData);
    $('#run-report').modal('hide');
  }
}

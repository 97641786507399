<app-loader></app-loader>
<div class="d-flex flex-column w-100 h-100">
    <div class="row m-0 p-0">
        <app-header class="col p-0"></app-header>
    </div>
    <div class="row m-0 h-100">
        <app-nav class="col-auto p-0 main"></app-nav>
        <div class="col p-0 overflow-auto main d-flex flex-column content-wrapper">
            <router-outlet></router-outlet>
            <app-footer></app-footer>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { GuiService } from 'src/app/shared/gui.service';
import { StorageService } from 'src/app/shared/storage.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nav',
  templateUrl: './app-nav.component.html',
  styleUrls: ['./app-nav.component.scss']
})
export class AppNavComponent implements OnInit {

  appVersion: string = environment.appVersion;

  constructor(
    private router: Router,
    public guiService: GuiService,
    public storageService: StorageService
  ) { }

  ngOnInit(): void {
  }

  closeNav() { this.guiService.showNavbar = false; }

  goToPage(url) {
    if (url == 'log-out') {
      this.logout();
    } else {
      this.router.navigate([url]);
    }
  }

  logout() {
    this.storageService.clearStorage();
    this.router.navigate(['sign-in']);
  }

}

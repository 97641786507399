import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReportApi, ClientApi } from 'src/app/const/app.url';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient) { }

  getReportList(body?: any) {
    return this.http.post<any>(ReportApi.getReportList, body)
  }

  createReport(body?: any) {
    return this.http.post<any>(ReportApi.createReport, body)
  }

  updateReport(body?: any) {
    return this.http.post<any>(ReportApi.updateReport, body)
  }

  deleteReport(body?: any) {
    return this.http.post<any>(ReportApi.deleteReport, body)
  }
  getReportResult(body?: any) {
    return this.http.post<any>(ReportApi.getReportResult, body)
  }
  getClientList() {
    return this.http.get<any>(ClientApi.getOnlyClientList)
  }
  exportExcel(excelData) {

    //Title, Header & Data
    const title = excelData.title;
    const header = excelData.headers
    const data = excelData.data;

    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(excelData.title);


    //Add Row and formatting
    //worksheet.mergeCells('C1', 'F4');
    let titleRow = worksheet.getCell('A1');
    titleRow.value = title
    titleRow.font = {
      name: 'Calibri',
      size: 16,
      underline: 'single',
      bold: true,
      color: { argb: '0085A3' }
    }
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

    // Date
    //worksheet.mergeCells('G1:H4');
    let d = new Date();
    let date = d.getDate() + '-' + (d.getMonth() + 1) + '-' + d.getFullYear();
    let dateCell = worksheet.getCell('B1');
    dateCell.value = date;
    dateCell.font = {
      name: 'Calibri',
      size: 12,
      bold: true
    }
    dateCell.alignment = { vertical: 'middle', horizontal: 'center' }

    //Add Image
    //let myLogoImage = workbook.addImage({
    //base64: logo.imgBase64,
    //extension: 'png',
    //});
    //worksheet.mergeCells('A1:B4');
    //worksheet.addImage(myLogoImage, 'A1:B4');

    //Blank Row 
    worksheet.addRow([]);

    //Adding Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    })

    // Adding Data with Conditional Formatting
    data.forEach(d => {
      let row = worksheet.addRow(d);
    });

    worksheet.getColumn(3).width = 20;
    worksheet.addRow([]);

    //Footer Row
    let footerRow = worksheet.addRow(['Report generated at ' + date]);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFB050' }
    };

    //Merge Cells
    worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      var currentDate = new Date();
      var timestamp = currentDate.getTime()
      fs.saveAs(blob, title + '_' + timestamp + '.xlsx');
    })

  }

}

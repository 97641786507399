import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaymentApi } from 'src/app/const/app.url';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  constructor(private httpClient: HttpClient,) { }

  generateId(clientId: string) {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const monthString = ("0" + month).slice(-2);
    const date = now.getDate();
    const dateString = ("0" + date).slice(-2);
    const hour = now.getHours();
    const hourString = ("0" + hour).slice(-2);
    const min = now.getMinutes();
    const minString = ("0" + min).slice(-2);
    const sec = now.getSeconds();
    const secString = ("0" + sec).slice(-2);
    const nowString = year.toString() + monthString + dateString + hourString + minString + secString;
    const id = clientId + '-' + nowString;
    return id;
  }

  getHash(body?: any) {
    return this.httpClient.post<any>(PaymentApi.getHash, body);
  }

  createSession() {
    return this.httpClient.post<any>(PaymentApi.createSession, {});
  }

  updateSession(body?: any) {
    return this.httpClient.post<any>(PaymentApi.updateSession, body);
  }

  getClientCheckoutData(body?: any) {
    return this.httpClient.post<any>(PaymentApi.getClientCheckoutData, body);
  }

  sendPayRequest(body?: any) {
    return this.httpClient.post<any>(PaymentApi.sendPayRequest, body);
  }
}

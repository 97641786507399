import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { DashboardService } from './dashboard.service';
import { ClientProfileService } from '../client-profile/client-profile.service';
import { ClientsService } from '../clients/clients.service';
import { PagerService } from 'src/app/shared/pager.service';

import * as moment from 'moment';
import { loadavg } from 'os';
import { LoaderService } from 'src/app/shared/loader.service';
declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  pageSize = 20;
  pageIndex = 1;
  pager: any = {};
  iniFlag: any = 1;
  rowCount = 0;

  baseURL: string;
  counts: any;
  sessionList: any;
  date: any;

  requestMade = false;
  isCreate = true;

  clientList: any;
  client_id: null;
  billStartDate: any = new Date();
  billEndDate: any = new Date();
  billStatus: any;
  billAmount: any;
  billDescription: any;

  genderList = null;
  clientGroupList = null;
  userTypeList = null;
  statusList = null;

  newPassword = '';
  confirmPassword = '';
  clientId = null;
  userDetails = null;

  activityTimeline = [];

  constructor(
    private router: Router,
    private dashboardService: DashboardService,
    private clientProfileService: ClientProfileService,
    private clientService: ClientsService,
    private pagerService: PagerService,
    private toastr: ToastrService,
    private loader: LoaderService
  ) { }

  ngOnInit(): void {
    this.baseURL = environment.BASE_URL;
    this.getDashboardCounts();
    this.todaySessionList();
    this.clearData();
    this.getGenderList();
    this.getUserTypeList();
    this.getStatusList();
    this.getClientGroupList();
    // this.getActivityTimeline(0);
    const nth = function (d) {
      if (d > 3 && d < 21) return 'th';
      switch (d % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
      }
    }
    const fortnightAway = new Date(+new Date);
    let date = fortnightAway.getDate();
    let month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][fortnightAway.getMonth()];
    let weekday = fortnightAway.toLocaleDateString('en-us', { weekday: 'long' });

    this.date = weekday + " " + date + nth(date) + " " + month + " " + fortnightAway.getFullYear();
  }

  getDashboardCounts() {
    this.loader.show();
    this.dashboardService.getDashboardCounts().subscribe(
      (response) => {
        if (response.status) {
          this.counts = response.data;
          this.counts.revenue = Math.floor(this.counts.revenue);
        } else {
          this.toastr.error(response.message);
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  todaySessionList() {
    this.loader.show();
    this.dashboardService.todaySessionList().subscribe(
      (response) => {
        if (response.status) {
          this.sessionList = response.data;
        } else {
          this.toastr.error(response.message);
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  goToBooking() {
    this.router.navigate(['bookings']);
    return;
  }

  goToClient(status) {
    this.router.navigate(['clients'], { state: { status: status } });
    return;
  }

  // Open client bill modal

  getClientList() {
    this.loader.show();
    this.dashboardService.getClientList().subscribe(
      (response) => {
        if (response.data) {
          this.clientList = response.data;
        } else {
          this.clientList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }
  openAddBillDialog() {
    this.getClientList();
    this.clearAddBill();

    $('#add-bill').modal('show');
  }
  clearAddBill() {
    this.billStartDate = moment(new Date()).format('YYYY-MM-DD');
    this.billEndDate = moment(new Date()).format('YYYY-MM-DD');
    this.billDescription = null;
    this.billAmount = null;
  }
  saveBill() {
    if (!this.client_id) {
      this.toastr.error('Please select client.');
      return;
    }
    if (!this.billStartDate) {
      this.toastr.error('Please select bill start date.');
      return;
    }
    if (!this.billEndDate) {
      this.toastr.error('Please select bill end date.');
      return;
    }
    if (!this.billDescription) {
      this.toastr.error('Please enter bill description.');
      return;
    }
    if (!this.billAmount) {
      this.toastr.error('Please enter bill amount.');
      return;
    }

    var formData: FormData = new FormData();
    formData.append('billing_amount', this.billAmount);
    formData.append('billing_status', '0');
    formData.append('description', this.billDescription);
    formData.append('start_date', this.billStartDate);
    formData.append('end_date', this.billStartDate);

    formData.append('client_id', this.client_id);
    this.assignBillToClient(formData);
  }

  assignBillToClient(formData) {
    this.loader.show();
    this.clientProfileService.assignBillToClient(formData).subscribe(
      (response) => {
        if (response.status) {
          this.toastr.success(response.message);
          this.getDashboardCounts();
          $('#add-bill').modal('hide');
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }
  openAddUpdateClientModal(isCreate, data) {
    this.clearData();
    this.isCreate = isCreate;
    $('#add-client').modal('show');
  }

  clearData(): void {
    this.userDetails = {
      first_name: null,
      last_name: null,
      email: null,
      phone: null,
      dob: null,
      gender: null,
      groups: null,
      user_type: null,
      active: null,
      feature_video: 1,
      feature_program: null,
      feature_log: 1,
      feature_diary: 1,
      feature_measurements: 1,
      feature_challenges: 1,
      feature_stickers: 1,
    }
  }

  saveClient() {
    if (!this.userDetails.first_name) {
      this.toastr.error('Please enter first name.');
      return;
    }
    if (!this.userDetails.last_name) {
      this.toastr.error('Please enter last name.');
      return;
    }
    if (!this.userDetails.email) {
      this.toastr.error('Please enter email.');
      return;
    }
    if (!this.userDetails.phone) {
      this.toastr.error('Please enter phone.');
      return;
    }
    if (!this.userDetails.dob) {
      this.toastr.error('Please select date of birth.');
      return;
    }
    if (!this.userDetails.gender) {
      this.toastr.error('Please select gender.');
      return;
    }
    if (!this.userDetails.groups) {
      this.toastr.error('Please select group.');
      return;
    }
    if (!this.userDetails.user_type) {
      this.toastr.error('Please select user type.');
      return;
    }
    if (this.userDetails.active == null) {
      this.toastr.error('Please select status.');
      return;
    }
    if (this.isCreate && !this.newPassword) {
      this.toastr.error('Please enter password.');
      return;
    }
    if (this.newPassword != this.confirmPassword) {
      this.toastr.error('Password and confirm password not match.');
      return;
    }

    var formData: FormData = new FormData();
    formData.append('first_name', this.userDetails.first_name);
    formData.append('last_name', this.userDetails.last_name);
    formData.append('email', this.userDetails.email);
    formData.append('phone', this.userDetails.phone);
    formData.append('dob', this.userDetails.dob);
    formData.append('gender', this.userDetails.gender);
    formData.append('groups', this.userDetails.groups);
    formData.append('user_type', this.userDetails.user_type);
    formData.append('active', this.userDetails.active);
    formData.append('feature_video', (this.userDetails.feature_video) ? '1' : '0');
    formData.append('feature_program', (this.userDetails.feature_program) ? '1' : '0');
    formData.append('feature_log', (this.userDetails.feature_log) ? '1' : '0');
    formData.append('feature_diary', (this.userDetails.feature_diary) ? '1' : '0');
    formData.append('feature_measurements', (this.userDetails.feature_measurements) ? '1' : '0');
    formData.append('feature_challenges', (this.userDetails.feature_challenges) ? '1' : '0');
    formData.append('feature_stickers', (this.userDetails.feature_stickers) ? '1' : '0');
    if (this.newPassword) {
      formData.append('password', this.newPassword);
    }
    if (this.isCreate) {
      this.createClient(formData);
    }
  }

  createClient(formData) {
    if (!this.requestMade) {
      this.loader.show();
      this.requestMade = true;
      this.clientService.createClient(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-client').modal('hide');
            this.getDashboardCounts();
          } else {
            this.toastr.error(response.message);
          }
          this.loader.hide();
        }, (err) => {
          this.requestMade = false;
          this.loader.hide();
          console.log(err);
        }
      );
    }
  }
  getGenderList() {
    this.genderList = [];
    this.genderList.push({ value: 'Male' });
    this.genderList.push({ value: 'Female' });
  }

  getClientGroupList() {
    this.loader.show();
    this.clientService.getGroupList().subscribe(
      (response) => {
        if (response.data) {
          this.clientGroupList = response.data;
        } else {
          this.clientGroupList = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  getUserTypeList() {
    this.userTypeList = [];
    this.userTypeList.push({ value: 'Admin' });
    this.userTypeList.push({ value: 'Trainer' });
    this.userTypeList.push({ value: 'Client' });
  }

  getStatusList() {
    this.statusList = [];
    this.statusList.push({ id: 1, value: 'Active' });
    this.statusList.push({ id: 0, value: 'Inactive' });
  }

  getActivityTimeline(item) {
    this.loader.show();
    if (item === 1) {
      this.pageIndex = 1;
      this.iniFlag = 1;
    }
    var formData: FormData = new FormData();
    formData.append('page_no', this.pageIndex.toString());
    formData.append('page_size', this.pageSize.toString());
    this.dashboardService.activityTimeline(formData).subscribe(
      (response) => {
        if (response.status) {
          this.activityTimeline = response.data;
          this.rowCount = response.totalrow;
          if (this.iniFlag === 1) {
            this.setPage(1);
            this.iniFlag = 0;
          }
        } else {
          this.activityTimeline = [];
        }
        this.loader.hide();
      }, (err) => {
        this.loader.hide();
        console.log(err);
      }
    );
  }

  setPage(page: number) {

    if (page < 1) { return; }
    if (page > this.pager.totalPages) { return; }

    this.pageIndex = page;
    this.pager = this.pagerService.getPager(this.rowCount, this.pageSize, this.pageIndex);
    if (this.iniFlag === 0) {
      this.getActivityTimeline(0);
    }
  }
}

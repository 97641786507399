<div class="container-fluid main-content d-flex align-items-center justify-content-center">
    <div class="h-100 w-100 row d-flex align-items-center justify-content-center">
        <div class="col-lg-4">
            <div class="row pb-5">
                <img src="assets/logo.png" class="logo">
            </div>
            <div class="row mb-3 align-items-center">
                <div class="col-sm-4">Email:</div>
                <div class="col-sm-8">
                    <input type="email" class="form-control no-radius" placeholder="Enter email"
                        [(ngModel)]="email">
                </div>
            </div>
            <div class="row mb-3 align-items-center">
                <div class="col-sm-4">Password:</div>
                <div class="col-sm-8">
                    <input type="password" class="form-control no-radius" placeholder="Enter new password"
                        [(ngModel)]="password">
                </div>
            </div>
            <div class="row mb-3 align-items-center">
                <div class="col-sm-12 text-center">
                    <div class="btn pointer no-radius mb-2 mx-2 primary-bg text-white" (click)="signIn()">
                        Login
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SessionsService } from 'src/app/pages/sessions/sessions.service';
declare var $: any;

@Component({
  selector: 'app-add-session',
  templateUrl: './add-session.component.html',
  styleUrls: ['./add-session.component.scss']
})
export class AddSessionComponent implements OnInit {

  @Input() isCreate: any;
  @Input() sessionId: any;
  @Input() sessionData: any;
  @Input() overrideId: any;

  requestMade = false;
  // updateItem = null;
  isProcess = 1;

  hours = [];
  minutes = [];
  trainerList = [];
  sessionTypeList = [];

  // sessionId = null;
  startTime = null
  endTime = null
  duration = null;
  daysOfWeek = null;
  sessionName = null;
  sessionType = null;
  sessionSize = null;
  creditRequired = null;
  sessionActive = false;
  waitlistActive = false;
  waitlistSize = null;

  @Output() onRefresh: EventEmitter<any> = new EventEmitter<any>();

  constructor(private toastr: ToastrService, private sessionsService: SessionsService) { }

  ngOnInit(): void {
    $('#add-session').on('hidden.bs.modal', () => { this.onRefresh.emit(true); });
    this.hours = Array(12).fill(0).map((x, i) => {
      return { value: `${x + i}`.padStart(2, '0') };
    });
    this.minutes = Array(60).fill(0).map((x, i) => {
      return { value: `${x + i}`.padStart(2, '0') };
    });
    this.clearData();
    this.getTrainerList();
    this.getSessionTypeList();
    if (!this.isCreate && this.sessionData) {
      this.sessionId = this.sessionData.id;
      const startTime = this.sessionData.start_time.split(':');
      const endTime = this.sessionData.end_time.split(':');
      const startHour = (startTime[0] < 12) ? Number(startTime[0]) : Number(startTime[0] - 12);
      const endHour = (endTime[0] < 12) ? Number(endTime[0]) : Number(endTime[0] - 12);
      this.startTime = {
        hour: (startHour > 9) ? startHour : '0' + startHour,
        minute: startTime[1],
        meridiem: (startTime[0] < 12) ? 'AM' : 'PM'
      };
      this.endTime = {
        hour: (endHour > 9) ? endHour : '0' + endHour,
        minute: endTime[1],
        meridiem: (endTime[0] < 12) ? 'AM' : 'PM'
      };
      this.duration = this.sessionData.duration.substring(0, 5);;
      this.daysOfWeek.forEach((element) => {
        element.active = this.sessionData[element.dayName.toLowerCase()];
        const trainer = this.sessionData['trainer_' + element.dayName.toLowerCase()];
        element.trainer = (trainer && trainer != 'null') ? trainer : null;
      });
      this.sessionName = this.sessionData.name;
      this.sessionType = this.sessionData.type;
      this.sessionSize = this.sessionData.size;
      this.creditRequired = this.sessionData.credits;
      this.sessionActive = this.sessionData.active;
      this.waitlistActive = this.sessionData.waitlist;
      this.waitlistSize = this.sessionData.waitlist_size;
    };
  }

  getTrainerList(): void {
    this.sessionsService.getTrainerList().subscribe(
      (response) => {
        if (response.data) {
          this.trainerList = response.data.map((e) => { return { id: e.id.toString(), name: e.first_name + ' ' + e.last_name } });
        } else {
          this.trainerList = [];
        }
      }, (err) => {
        console.log(err);
        // this.toastr.error(err);
      }
    );
  }

  getSessionTypeList(): void {
    var formData: FormData = new FormData();
    formData.append('searchname', '');
    formData.append('page_no', '1');
    formData.append('order_by', 'asc');
    this.sessionsService.getSessionTypeList(formData).subscribe(
      (response) => {
        if (response.data) {
          this.sessionTypeList = response.data;
        } else {
          this.sessionTypeList = [];
        }
      }, (err) => {
        console.log(err);
        // this.toastr.error(err);
      }
    );
  }

  clearData(): void {
    this.startTime = {
      hour: null,
      minute: null,
      meridiem: 'AM'
    };
    this.endTime = {
      hour: null,
      minute: null,
      meridiem: 'AM'
    };
    this.duration = null;
    this.daysOfWeek = [
      {
        dayName: 'Sun',
        active: false,
        trainer: null
      },
      {
        dayName: 'Mon',
        active: false,
        trainer: null
      },
      {
        dayName: 'Tue',
        active: false,
        trainer: null
      },
      {
        dayName: 'Wed',
        active: false,
        trainer: null
      },
      {
        dayName: 'Thu',
        active: false,
        trainer: null
      },
      {
        dayName: 'Fri',
        active: false,
        trainer: null
      },
      {
        dayName: 'Sat',
        active: false,
        trainer: null
      },
    ];
    this.sessionName = null;
    this.sessionType = null;
    this.sessionSize = null;
    this.creditRequired = null;
    this.sessionActive = false;
    this.waitlistActive = false;
    this.waitlistSize = null;
  }

  calculateDuration() {
    this.duration = '';
    if (this.startTime.meridiem === this.endTime.meridiem) {
      if (this.startTime.hour && this.endTime.hour) {
        if (this.startTime.hour > this.endTime.hour) {
          setTimeout(() => { this.endTime.hour = null; }, 0);
          this.toastr.error('Please select valid end hour.');
          return;
        }
      }
      if (this.startTime.minute && this.endTime.minute) {
        if (this.startTime.hour == this.endTime.hour && (this.startTime.minute > this.endTime.minute || this.startTime.minute === this.endTime.minute)) {
          setTimeout(() => { this.endTime.minute = null; }, 0);
          this.toastr.error('Please select valid end minute.');
          return;
        }
      }
    }
    if (this.startTime.hour && this.startTime.minute && this.endTime.hour && this.endTime.minute) {
      let durationHour = 0;
      let durationMinute = 0;
      if (this.startTime.meridiem === this.endTime.meridiem) {
        durationHour = Number(this.endTime.hour) - Number(this.startTime.hour);
      } else {
        const startHour = (this.startTime.meridiem === 'AM') ? Number(this.startTime.hour) : Number(this.startTime.hour) + 12;
        const endHour = (this.endTime.meridiem === 'AM') ? Number(this.endTime.hour) : Number(this.endTime.hour) + 12;
        durationHour = Number(endHour) - Number(startHour);
        durationHour = (durationHour < 0) ? (durationHour + 24) : durationHour;
      }
      if (this.startTime.minute > this.endTime.minute) {
        durationMinute = (Number(this.endTime.minute) + 60) - Number(this.startTime.minute);
        durationHour = durationHour - 1;
      } else {
        durationMinute = Number(this.endTime.minute) - Number(this.startTime.minute);
      }
      this.duration = ((durationHour > 9) ? durationHour : '0' + durationHour) + ':' + ((durationMinute > 9) ? durationMinute : '0' + durationMinute);
    }
  }

  saveSession() {
    if (!this.startTime.hour) {
      this.toastr.error('Please select start time hour.');
      return;
    }
    if (!this.startTime.minute) {
      this.toastr.error('Please select start time minute.');
      return;
    }
    if (!this.endTime.hour) {
      this.toastr.error('Please select end time hour.');
      return;
    }
    if (!this.endTime.minute) {
      this.toastr.error('Please select end time minute.');
      return;
    }
    if (this.daysOfWeek.filter((e) => e.active).length == 0) {
      this.toastr.error('Please select days of week.');
      return;
    }
    const daysOfWeek = this.daysOfWeek.filter((e) => e.active && !e.trainer && e.trainer != 'null');
    if (daysOfWeek.length > 0) {
      this.toastr.error('Please select trainer for ' + daysOfWeek[0].dayName + '.');
      return;
    }
    if (!this.sessionName) {
      this.toastr.error('Please enter session name.');
      return;
    }
    if (!this.sessionType) {
      this.toastr.error('Please select session type.');
      return;
    }
    if (!this.sessionSize) {
      this.toastr.error('Please enter session size.');
      return;
    }
    // if (!this.creditRequired) {
    //   this.toastr.error('Please creadit required.');
    //   return;
    // }
    if (this.waitlistActive && !this.waitlistSize) {
      this.toastr.error('Please enter waitlist size.');
      return;
    }
    const startHour = (this.startTime.meridiem === 'AM') ? Number(this.startTime.hour) : Number(this.startTime.hour) + 12;
    const endHour = (this.endTime.meridiem === 'AM') ? Number(this.endTime.hour) : Number(this.endTime.hour) + 12;
    const startTime = ((startHour <= 9) ? '0' + startHour : startHour) + ':' + this.startTime.minute + ':' + '00';
    const endTime = ((endHour <= 9) ? '0' + endHour : endHour) + ':' + this.endTime.minute + ':' + '00';

    var formData: FormData = new FormData();
    formData.append('start_time', startTime);
    formData.append('end_time', endTime);
    formData.append('duration', this.duration);
    this.daysOfWeek.forEach((element) => {
      formData.append(element.dayName.toLowerCase(), (element.active) ? '1' : '0');
      if (element.active) {
        formData.append('trainer_' + element.dayName.toLowerCase(), element.trainer);
      } else {
        formData.append('trainer_' + element.dayName.toLowerCase(), '');
      }
    });
    formData.append('name', this.sessionName);
    formData.append('type', (this.sessionType) ? this.sessionType : '');
    formData.append('size', (this.sessionSize) ? this.sessionSize : '1');
    formData.append('credits', (this.creditRequired) ? this.creditRequired : '');
    formData.append('active', (this.sessionActive) ? '1' : '0');
    formData.append('waitlist', (this.waitlistActive) ? '1' : '0');
    formData.append('waitlist_size', (this.waitlistSize) ? this.waitlistSize : '0');
    formData.append('override', (this.overrideId) ? '1' : '0');
    formData.append('override_id', (this.overrideId) ? this.overrideId : '0');

    if (this.isCreate) {
      this.createSession(formData);
    } else {
      this.isProcess = 1;
      const days = [];
      this.daysOfWeek.forEach((element) => {
        const active = this.sessionData[element.dayName.toLowerCase()];
        if (!element.active && active != element.active) {
          days.push('\'' + element.dayName + '\'');
        }
      });
      const dayofweek = days.join(',');
      if (dayofweek) {
        this.isProcess = 2;
      }
      if (this.sessionSize != this.sessionData.size
        || this.sessionActive != this.sessionData.active
      ) {
        this.isProcess = 3;
      }
      if (this.waitlistSize != this.sessionData.waitlist_size
        || this.waitlistActive != this.sessionData.waitlist
      ) {
        this.isProcess = 4;
      }
      if (startTime != this.sessionData.start_time
        || endTime != this.sessionData.end_time
        || this.sessionType != this.sessionData.type
        || this.creditRequired != this.sessionData.credits
      ) {
        this.isProcess = 5;
      }
      formData.append('session_id', this.sessionId);
      formData.append('is_process', this.isProcess.toString());
      formData.append('is_dayofweek', dayofweek);
      this.updateSession(formData);
    }
  }

  createSession(formData) {
    if (!this.requestMade) {
      this.requestMade = true;
      this.sessionsService.createSession(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-session').modal('hide');
          } else {
            this.toastr.error(response.message);
          }
        }, (err) => {
          this.requestMade = false;
          console.log(err);
          // this.toastr.error(err);
        }
      );
    }
  }

  updateSession(formData) {
    if (!this.requestMade) {
      this.requestMade = true;
      this.sessionsService.updateSession(formData).subscribe(
        (response) => {
          this.requestMade = false;
          if (response.status) {
            this.toastr.success(response.message);
            $('#add-session').modal('hide');
          } else {
            this.toastr.error(response.message);
          }
        }, (err) => {
          this.requestMade = false;
          console.log(err);
          // this.toastr.error(err);
        }
      );
    }
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SessionsService } from 'src/app/pages/sessions/sessions.service';
import { BookingStatus } from 'src/app/enum/booking-status.enum';
import { BookingsService } from 'src/app/pages/bookings/bookings.service';
import { ClientsService } from 'src/app/pages/clients/clients.service';
import { StorageService } from 'src/app/shared/storage.service';

@Component({
  selector: 'app-book-client',
  templateUrl: './book-client.component.html',
  styleUrls: ['./book-client.component.scss']
})
export class BookClientComponent implements OnInit {

  @Input() clientId: string;

  clientList = null;
  selectedClient = null;

  daysInThisMonth = null;
  daysInLastMonth = null;
  daysInNextMonth = null;
  dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  dayFullNames = ['Sunday', 'Monday', 'Tueday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  currentDateObj = null;
  currentDate = null;
  currentMonth = null;
  currentYear = null;
  selectedDateObj = null;
  selectedMonth = null;
  selectedYear = null;
  selectedDate = null;
  selectedDay = null;
  selectedTab = 1;

  isSessionAvailable = false;
  bookingSessionList = null;
  consultationSessionList = null;

  @Output() onRefresh: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private toastr: ToastrService,
    private clientService: ClientsService,
    private bookingsService: BookingsService,
    private sessionsService: SessionsService,
    private storageService: StorageService,
  ) { }

  ngOnInit(): void {
    this.selectedClient = (this.clientId) ? this.clientId : null;
    this.currentDateObj = new Date();
    this.currentDate = this.currentDateObj.getDate();
    this.currentMonth = this.currentDateObj.getMonth();
    this.currentYear = this.currentDateObj.getFullYear();
    this.selectedDate = this.currentDateObj.getDate();
    this.selectedMonth = this.currentDateObj.getMonth();
    this.selectedYear = this.currentDateObj.getFullYear();
    this.getDaysOfMonth();
    this.getClientList();
    if (this.clientId) {
      this.getDateWiseSessionList();
    }
  }

  getClientList() {
    this.clientService.getOnlyClientList().subscribe(
      (response) => {
        if (response.data) {
          this.clientList = response.data;
          this.clientList.map((e) => { e.name = e.first_name + ' ' + e.last_name });
        } else {
          this.clientList = [];
        }
      }, (err) => {
        console.log(err);
      }
    );
  }

  getDaysOfMonth() {
    this.daysInThisMonth = [];
    this.daysInLastMonth = [];
    this.daysInNextMonth = [];
    this.selectedYear = this.currentDateObj.getFullYear();
    this.selectedMonth = this.currentDateObj.getMonth();
    const selectedDate = new Date(this.selectedYear, this.selectedMonth, this.selectedDate);
    this.selectedDay = selectedDate.getDay();
    this.selectedDateObj = selectedDate;

    var thisMonthFirstDay = new Date(this.currentDateObj.getFullYear(), this.currentDateObj.getMonth(), 1).getDay();
    var prevMonthDays = new Date(this.currentDateObj.getFullYear(), this.currentDateObj.getMonth(), 0).getDate();
    for (var i = prevMonthDays - (thisMonthFirstDay - 1); i <= prevMonthDays; i++) {
      this.daysInLastMonth.push(i);
    }

    var thisMonthDays = new Date(this.currentDateObj.getFullYear(), this.currentDateObj.getMonth() + 1, 0).getDate();
    for (var i = 0; i < thisMonthDays; i++) {
      this.daysInThisMonth.push(i + 1);
    }

    var thisMonthLastDay = new Date(this.currentDateObj.getFullYear(), this.currentDateObj.getMonth() + 1, 0).getDay();
    for (var i = 1; i < (7 - thisMonthLastDay); i++) {
      this.daysInNextMonth.push(i);
    }

    var totalDays = this.daysInLastMonth.length + this.daysInThisMonth.length + this.daysInNextMonth.length;
    if (totalDays < 35) {
      for (var i = (7 - thisMonthLastDay); i < ((7 - thisMonthLastDay) + 7); i++) {
        this.daysInNextMonth.push(i);
      }
    }
  }

  setLastMonthDate(day) {
    this.selectedDate = day;
    this.goToLastMonth();
  }

  goToLastMonth() {
    this.currentDateObj = new Date(this.currentDateObj.getFullYear(), this.currentDateObj.getMonth(), 0);
    this.getDaysOfMonth();
    this.getDateWiseSessionList();
  }

  setNextMonthDate(day) {
    this.selectedDate = day;
    this.goToNextMonth();
  }

  goToNextMonth() {
    this.currentDateObj = new Date(this.currentDateObj.getFullYear(), this.currentDateObj.getMonth() + 2, 0);
    this.getDaysOfMonth();
    this.getDateWiseSessionList();
  }

  setSelectedDate(day) {
    if (this.selectedDate != day) {
      this.selectedDate = day;
      const selectedDate = new Date(this.selectedYear, this.selectedMonth, this.selectedDate);
      this.selectedDay = selectedDate.getDay();
      this.getDateWiseSessionList();
    }
  }

  setSelectedTab(tab) {
    this.selectedTab = tab;
    this.getDateWiseSessionList();
  }

  getDateWiseSessionList() {
    const selectedDateObj = new Date(this.selectedYear, this.selectedMonth, this.selectedDate);
    selectedDateObj.setMinutes(selectedDateObj.getTimezoneOffset() * -1);
    const currentDateObj = new Date();
    currentDateObj.setHours(0, 0, 0, 0);
    if (selectedDateObj < currentDateObj) {
      this.bookingSessionList = [];
      this.consultationSessionList = [];
      this.isSessionAvailable = false;
    } else {
      if (this.selectedClient) {
        this.selectedDateObj = selectedDateObj;
        var formData: FormData = new FormData();
        formData.append('client_id', this.selectedClient.toString());
        formData.append('session_date', selectedDateObj.toJSON().substring(0, 10));
        this.sessionsService.getDateWiseSessionList(formData).subscribe(
          (response) => {
            this.bookingSessionList = [];
            this.consultationSessionList = [];
            if (response.status && response.data) {
              this.isSessionAvailable = true;
              const bookingSessionList = response.data.filter((e) => !e.type || e.type == 'Booking');
              if (bookingSessionList.length > 0) {
                bookingSessionList.map((e) => {
                  e.start_time = new Date(selectedDateObj.toJSON().substring(0, 10).replace(/-/g, '/') + ' ' + e.start_time),
                    e.end_time = new Date(selectedDateObj.toJSON().substring(0, 10).replace(/-/g, '/') + ' ' + e.end_time)
                });
                this.bookingSessionList = bookingSessionList;
                this.bookingSessionList.sort((a, b) => a.start_time - b.start_time);
              }
              const consultationSessionList = response.data.filter((e) => !e.type || e.type == 'Consultation');
              if (consultationSessionList.length > 0) {
                consultationSessionList.map((e) => {
                  e.start_time = new Date(selectedDateObj.toJSON().substring(0, 10).replace(/-/g, '/') + ' ' + e.start_time),
                    e.end_time = new Date(selectedDateObj.toJSON().substring(0, 10).replace(/-/g, '/') + ' ' + e.end_time)
                });
                this.consultationSessionList = consultationSessionList;
                this.consultationSessionList.sort((a, b) => a.start_time - b.start_time);
              } 
            }
          }, (err) => {
            console.log(err);
            // this.sharedService.showToast(err);
          }
        );
      }
    }
  }

  addBooking(sessionId) {
    const selectedDateObj = new Date(this.selectedYear, this.selectedMonth, this.selectedDate);
    selectedDateObj.setMinutes(selectedDateObj.getTimezoneOffset() * -1);
    var formData: FormData = new FormData();
    formData.append('session_id', sessionId);
    formData.append('client_id', this.selectedClient.toString());
    formData.append('session_date', selectedDateObj.toJSON().substring(0, 10));
    formData.append('is_admin', '1');
    formData.append('created_id', this.storageService.loggedInUser.id.toString());
    this.bookingsService.addBooking(formData).subscribe(
      (response) => {
        this.onRefresh.emit(true);
        if (response.status == BookingStatus.Booked || response.status == BookingStatus.BookedWaitlist || response.status == BookingStatus.BookedWaitlistLate) {
          this.toastr.success('Booking confirmed.');
        } else if (response.status == BookingStatus.Wait) {
          this.toastr.success('User added on the waitlist.')
        }
        this.getDateWiseSessionList();
      }, (err) => {
        console.log(err);
      }
    );
  }

}
